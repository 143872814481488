import styled from 'styled-components/macro';

export const FlexFill = styled.div`
  flex: 1;
`;

export const FlexRow = styled.div`
  flex-grow: 0;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const HR = styled.hr`
  height: 1px;
  background: ${(props) => props.theme.Colors.Neutrals._500};
`;

export const FormField = styled.div`
  margin-bottom: 18px;
  flex: 0;
`;

export const Callout = styled.div`
  background: ${(props) => props.theme.Colors.Yellows._300};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 20px;
  border-radius: 24px;
`;
