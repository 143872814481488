import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC} from 'react';
import toast from 'react-hot-toast';
import {useCreateProduct} from '../../API';
import {requireAdmin} from '../../Managers/AuthUtils';
import {ProductForm} from './ProductForm';

const CreateProductComponent: FC = () => {
  const createProduct = useCreateProduct();

  const handleSave = async (values: any) => {
    console.log('create product', values);
    await createProduct
      .mutateAsync({...values, productId: values.id})
      .then((r) => {
        console.log('create result', r);
        toast.success('Product has been successfully created!');
      })
      .catch((error) => {
        console.log('Error creating product', error);
        toast.error('There was a problem creating the product!');
      });
  };

  return (
    <>
      <ProductForm onSubmit={handleSave} title="New Product" />
    </>
  );
};

export const CreateProduct: FC = withAuthenticationRequired(CreateProductComponent, {claimCheck: requireAdmin});
