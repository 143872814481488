import {Formik} from 'formik';
import {FC, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useQueryClient} from 'react-query';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {IAuthorization, IOrganization, useUpdateMySubscription, useUpdateOrganization} from '../../API';
import {Body1, FullPageLoader, H4} from '../../Components';
import {CENTER_POSITION} from '../../constants/constants';
import {routePaths} from '../../constants/routePaths';
import {productLessProductList} from '../Admin/adminUtils';
import {OnboardingPanel} from './Layout';
import {LocationSelection} from './LocationSelection';
import {LocationsMap} from './LocationsMap';
import {SubscriptionDetailsPanel} from './OnboardingSubscriptionDetails';
import {ILocation} from './SetupMap';
import {getUniqueAuthorizations} from './getUniqueAuthorizations';
import {useMixpanel} from '../../Managers/Mixpanel/useMixpanel';

export interface IAuthCondensed {
  targetId: string;
  targetType: string;
}

export const defaultViewSchema = yup.object({
  initialLat: yup
    .number()
    .label('Center latitude')
    .required('Please enter default latitude.')
    .min(-90, 'Please enter valid latitude. Minimum is -90.')
    .max(90, 'Please enter valid latitude. Maximum is 90.')
    .nullable(),
  initialLon: yup
    .number()
    .label('Center longitude')
    .required('Please enter default longitude.')
    .min(-180, 'Please enter valid longitude. Minimum is -180.')
    .max(80, 'Please enter valid longitude. Maximum is 80.')
    .nullable(),
  initialZoom: yup
    .number()
    .label('Initial zoom')
    .required('Please enter default zoom.')
    .min(3, 'Please enter valid zoom. Minimum is 3.')
    .max(18, 'Please enter valid zoom. Maximum is 18.')
    .nullable(),
});

const DefaultLocationHeader: FC = () => {
  return (
    <>
      <H4 style={{textAlign: 'center', margin: 0}}>One Last Step</H4>
      <Body1 style={{margin: 0}}>
        Please select your default view by scrolling and dragging on the map below, or by entering the values. This will determine what the
        map shows every time you open the app.
      </Body1>
    </>
  );
};

export const OrganizationLocationsPanels: FC<{
  hideDefaultPosition?: boolean;
  hideSidePanel?: boolean;
  hideTitle?: boolean;
  hideMembers?: boolean;
  isForTrial?: boolean;
  onSubmitLocations?: (values: IOrganization) => Promise<any>;
  onUpdateSuccess?: () => void;
  organization: IOrganization;
  readOnly?: boolean;
  updateSubscriptionOnly?: boolean;
}> = ({
  hideDefaultPosition,
  hideSidePanel,
  hideTitle,
  hideMembers,
  isForTrial,
  onSubmitLocations,
  onUpdateSuccess,
  organization,
  readOnly,
  updateSubscriptionOnly,
}) => {
  const [authorizations, setAuthorizations] = useState<IAuthCondensed[]>([]);
  const [currentSelectedLocation, setCurrentSelectedLocation] = useState<ILocation | null>(null);
  const [isSubmittingLocation, setIsSubmitting] = useState<boolean>(false);

  const navigate = useNavigate();
  const updateOrganization = useUpdateOrganization();
  const updateMySubscription = useUpdateMySubscription({
    skipInvalidations: !!onUpdateSuccess,
  });
  // const queryClient = useQueryClient();
  const {mixpanel} = useMixpanel();

  useEffect(() => {
    setAuthorizations(getUniqueAuthorizations(organization?.authorizations || []));
  }, [organization?.authorizations]);

  const handleSubmitPosition = async (values: any, actions: any) => {
    try {
      setIsSubmitting(true);

      const organizationResult = await updateOrganization.mutateAsync({
        ...values,
        organizationId: organization?.id,
        id: organization?.id,
      });
      if (!organizationResult.error) {
        toast.success('Your map default position has been updated.');

        if (hideTitle && hideSidePanel && readOnly) {
          navigate(routePaths.map);
        }
      }
    } catch (error) {
      console.log('Error saving organization', error);
    } finally {
      setIsSubmitting(false);
      actions.setSubmitting(false);
    }
  };

  const handleSubmitLocations = async () => {
    try {
      if (onSubmitLocations) {
        await onSubmitLocations({...organization, authorizations: authorizations as IAuthorization[]});
      }

      if (updateSubscriptionOnly) {
        await updateMySubscription.mutateAsync({...organization, authorizations: authorizations as IAuthorization[]});
        toast.success('Your subscription has been updated.');
        if (onUpdateSuccess) {
          onUpdateSuccess();
        }
        // await queryClient.invalidateQueries(['ONBOARDING', 'ORGANIZATION']).catch(() => {});
        setIsSubmitting(false);
      } else {
        mixpanel.track('Onboarding Locations Added');
        navigate(routePaths.onboardingPayment);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log('Error saving organization', error);
    }
  };

  const {initialLat, initialLon, initialZoom} = organization || {};
  const defaultPosition: Record<string, string> =
    initialLat && initialLon && initialZoom
      ? {initialLat: initialLat.toFixed(4), initialLon: initialLon.toFixed(4), initialZoom: initialZoom.toFixed(2)}
      : {...CENTER_POSITION};

  const findLocation = (auth: IAuthCondensed): ILocation | null => {
    const product = productLessProductList.find((p) => p.id === `${auth.targetType}:${auth.targetId}`);
    if (!product) return null;
    return product as ILocation;
  };

  const maxLocations = organization?.subscription_type === 'local' ? 1 : 20;

  const handleAddAuth = (auth: IAuthCondensed) => {
    if (authorizations.length >= maxLocations) {
      toast(
        `You can only select ${maxLocations !== 1 ? `up to ${maxLocations} locations` : `1 location`}. Please remove one to add another.`,
      );
      return;
    }

    const hasNationwide = authorizations.find((a) => a.targetType === 'nationwide');

    const hasStateCounties =
      auth.targetType === 'state' && authorizations.find((a) => a.targetType === 'county' && a.targetId.startsWith(auth.targetId));

    const countyInState =
      auth.targetType === 'county' && authorizations.find((a) => a.targetType === 'state' && auth.targetId.startsWith(a.targetId));

    if (auth.targetType === 'nationwide') {
      if (authorizations.length > 0) {
        toast('State and county selections were removed.');
      }

      setAuthorizations([auth]);
    } else if (!authorizations.find((a) => a.targetId === auth.targetId && a.targetType === auth.targetType)) {
      if (hasNationwide) {
        // remove nationwide if a state or county is selected
        toast('Nationwide selection was removed.');
        setAuthorizations([auth]);
      } else if (hasStateCounties) {
        // remove counties for the selected state
        toast('Counties for the selected state were removed from the list.');
        setAuthorizations([
          ...authorizations.filter(
            (a) => a.targetId !== auth.targetId && (a.targetType !== 'county' || !a.targetId.startsWith(auth.targetId)),
          ),
          auth,
        ]);
      } else if (countyInState) {
        // don't add county if state is already selected
        toast('The selected county is in one of the previously selected states.');
      } else {
        setAuthorizations([...authorizations, auth]);
      }
    }

    const location = findLocation(auth);
    if (location) {
      setCurrentSelectedLocation(location);
    }
  };

  const handleRemoveAuth = (auth: IAuthCondensed) => {
    setAuthorizations(authorizations.filter((a) => a.targetId !== auth.targetId || a.targetType !== auth.targetType));
  };

  const handleFormReset = async () => {
    setAuthorizations(getUniqueAuthorizations(organization?.authorizations || []));
  };

  return (
    <Formik
      initialValues={defaultPosition}
      onSubmit={(values, actions) => handleSubmitPosition(defaultViewSchema.cast(values) as IOrganization, actions)}
      validationSchema={defaultViewSchema}
      enableReinitialize>
      {(formikProps) => (
        <>
          {updateMySubscription?.isLoading && <FullPageLoader />}

          <OnboardingPanel style={{gap: 28, flex: 3, overflow: 'visible'}}>
            {hideSidePanel && <DefaultLocationHeader />}

            {!readOnly && <LocationSelection handleAddAuth={handleAddAuth} hideTitle={hideTitle} organization={organization} />}

            <LocationsMap
              formikProps={formikProps}
              defaultPosition={defaultPosition}
              isSubmittingLocation={isSubmittingLocation}
              initialLat={+(initialLat || 0)}
              initialLon={+(initialLon || 0)}
              initialZoom={+(initialZoom || 0)}
              currentSelectedLocation={currentSelectedLocation}
              hideDefaultPosition={hideDefaultPosition}
              hideSidePanel={hideSidePanel}
            />
          </OnboardingPanel>

          {!hideSidePanel && (
            <SubscriptionDetailsPanel
              formikProps={formikProps}
              organization={organization}
              hideMembers={hideMembers}
              users={organization?.users || []}
              authorizations={authorizations}
              hideActionButtons={readOnly}
              showUpdateButton={updateSubscriptionOnly || isForTrial}
              skipDirtyCheck={!updateSubscriptionOnly && !isForTrial}
              onResetForm={handleFormReset}
              onSubmitLocations={handleSubmitLocations}
              onRemoveAuth={handleRemoveAuth}
            />
          )}
        </>
      )}
    </Formik>
  );
};
