// This is here rather than in Components because it's not reused

import {ChevronDownIcon, SearchIcon} from '@heroicons/react/outline';
import {Formik} from 'formik';
import {FC, useEffect, useRef, useState} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useMyProfile} from '../../../API/Queries/useMyProfile';
import {Sheet, TextInput, Theme, TooltipTrigger} from '../../../Components';
import {EquityIndex, PhillyBuildings, SolarEligibility} from '../../../Managers/Datasets';
import {AllDatasets} from '../../../Managers/MapManager';
import {useMixpanel} from '../../../Managers/Mixpanel/useMixpanel';

const SearchWrapper = styled.div`
  flex: 0;
  height: 52px;
  margin-right: 20px;
  position: relative;
`;

const Dropdown = styled(Sheet)`
  position: absolute;
  margin-top: 8px;
  top: 52px;
  left: 0;
  z-index: 10;
`;

export interface IndicatorSearchProps {
  value: string | null;
  index: number;
  otherSelectedId: string | null;
  onSelectDataset: (id: string | null) => void;
  disabled?: boolean;
}

export const IndicatorSearch: FC<IndicatorSearchProps> = ({disabled, value, index, otherSelectedId, onSelectDataset}) => {
  const selectedEntry = AllDatasets.find((entry) => entry.id === value);
  const selectedDataset = AllDatasets.find((ds) => ds.id === value);

  const [showingDropdown, setShowingDropdown] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = () => {
    setInputFocused(false);
    setShowingDropdown(false);
  };

  // call handleBlur when the user clicks outside the search box
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        handleBlur();
      }
    };

    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [inputRef]);
  // const myProfile = useAppSelector((state) => state.user.myProfile);
  const {data: myProfile} = useMyProfile();

  const handleSearch = () => {
    setShowingDropdown(false);
  };

  let datasets = AllDatasets;
  if (!myProfile?.user?.organization?.showEquityIndex) {
    datasets = datasets.filter((ds) => ds.id !== 'equityIndex');
  }

  if (!myProfile?.user?.organization?.showPhillyBuildings) {
    datasets = datasets.filter((ds) => ds.id !== PhillyBuildings.id);
  }

  if (!myProfile?.user?.organization?.showSolarEligibility) {
    datasets = datasets.filter((ds) => ds.id !== SolarEligibility.id);
  }

  if (index === 2) {
    datasets = datasets.filter((ds) => ds.combinable);
  }

  if (otherSelectedId) {
    datasets = datasets.filter((ds) => ds.id !== otherSelectedId);
  }

  const {mixpanel} = useMixpanel();

  return (
    <SearchWrapper>
      <Formik initialValues={{q: selectedEntry?.downloadTitle || ''}} onSubmit={handleSearch} enableReinitialize>
        {({values, setFieldValue}) => {
          const entries = datasets.map((dataset) => ({id: dataset.id, label: dataset.downloadTitle}));
          entries.unshift({id: '', label: 'Not Selected'});

          const handleFocus = () => {
            setInputFocused(true);
            setShowingDropdown(true);
          };

          const handleDown = () => {
            if (!showingDropdown) {
              setFieldValue('q', '');
            }

            setShowingDropdown(!showingDropdown);
          };

          const handleChooseDataset = (entry: any) => {
            mixpanel.track('Indicator Selected', {indicatorId: entry?.label, indicatorIndex: index});
            onSelectDataset(entry?.id || null);
            setShowingDropdown(false);
            setInputFocused(false);
            setFieldValue('q', entry?.downloadTitle);

            const newParams = createSearchParams(searchParams);

            if (!!entry?.id) {
              newParams.set(`in${index}`, entry?.id);

              if (entry.id === EquityIndex.id) {
                newParams.delete('in2');
              }
            } else {
              newParams.delete(`in${index}`);
              if (index === 1) {
                newParams.delete('in2');
              }
            }

            setSearchParams(newParams);
          };

          return (
            <div ref={inputRef}>
              <TooltipTrigger data-for={'indicator-search'} data-tip={selectedDataset?.description || ''}>
                <TextInput
                  disabled={disabled}
                  errorField={false}
                  name="q"
                  onFocus={handleFocus}
                  placeholder="Search for indicator..."
                  style={{paddingLeft: 46}}
                  type="text"
                />
              </TooltipTrigger>

              <SearchIcon
                style={{top: 16, left: 16, position: 'absolute', width: 20}}
                color={disabled ? Theme.Colors.Neutrals._400 : Theme.Colors.Neutrals._200}
              />

              <ChevronDownIcon
                color={disabled ? Theme.Colors.Neutrals._400 : Theme.Colors.Neutrals._200}
                onClick={() => (!disabled ? handleDown() : {})}
                style={{top: 16, right: 16, position: 'absolute', width: 20, cursor: 'pointers'}}
              />

              {showingDropdown || inputFocused ? (
                <Dropdown
                  items={entries.filter(
                    (entry) =>
                      entry?.id !== selectedEntry?.id && // don't show the selected entry
                      (values.q === '' || // show all if nothing is entered
                        entry?.label?.toLowerCase().includes(values?.q?.toLowerCase()) || // show all that match
                        (selectedEntry?.downloadTitle?.toLowerCase() === values?.q?.toLowerCase() &&
                          entry?.label?.toLowerCase() !== values?.q?.toLowerCase())), // if search matches selected entry, show all that don't match
                  )}
                  onClick={handleChooseDataset}
                />
              ) : (
                <></>
              )}
            </div>
          );
        }}
      </Formik>
    </SearchWrapper>
  );
};
