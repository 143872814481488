import {ChevronRightIcon} from '@heroicons/react/outline';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {Theme} from '..';

export const BreadCrumbLink = styled(Link)`
  text-decoration: none;
  color: ${Theme.Colors.Neutrals._200};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
`;

export const BreadCrumbs = ({name = ''}) => {
  const location = useLocation();
  const locationSections = location.pathname.split('/');
  const parent = locationSections[2];
  const parentPath = locationSections.slice(0, -1).join('/');

  return (
    <div style={{flexDirection: 'row', flex: 0, gap: 12}}>
      <BreadCrumbLink to={parentPath} style={{textDecoration: 'none'}}>
        {parent}
      </BreadCrumbLink>
      <ChevronRightIcon style={{width: 20, color: Theme.Colors.Neutrals._200}} />
      <BreadCrumbLink to={location.pathname}>{name}</BreadCrumbLink>
    </div>
  );
};
