import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC, useCallback, useMemo, useState} from 'react';
import {Column} from 'react-table';
import {IUser, useAdminUsers, useUpdateUser} from '../../API';
import {FullPageLoader} from '../../Components';
import {requireAdmin} from '../../Managers/AuthUtils';
import {Header} from '../Map/Header';
import {AdminMenu} from './AdminMenu';
import {AdminTable} from './AdminTable';
import {UserOrganizationCell} from './AdminTable/UserOrganizationCell';
import {UserStatusCell} from './AdminTable/UserStatusCell';
import {UserTypeCell} from './AdminTable/UserTypeCell';
import {AdminPageWrapper} from './Layout';
import {UserNameCell} from './UserNameCell';
// import {DeleteListofUsersButton} from './DeleteListofUsersButton';

const UsersComponent: FC = () => {
  const users = useAdminUsers();
  const [activeSwitch, setActiveSwitch] = useState(0);

  const filteredUsers = useMemo(() => {
    if (activeSwitch === 1) {
      return users.data?.filter((u) => u.user_status === 'active');
    }
    if (activeSwitch === 2) {
      return users.data?.filter((u) => u.user_status === 'inactive');
    }
    return users.data;
  }, [users.data, activeSwitch]);

  const updateUser = useUpdateUser();
  const [saving, setSaving] = useState(false);

  // const deleteUser = useDeleteUser();

  // const onDeleteUser = async (id: IUser['id']) => {
  //   deleteUser.mutateAsync(id);
  // };

  const handleChangeUserType = useCallback(
    (user_type: string, row: any) => {
      setSaving(true);
      updateUser
        .mutateAsync({userId: row.original.id, user_type})
        .then((r) => console.log('updated', row.original, r))
        .catch((e) => console.log('update error', e))
        .finally(() => setSaving(false));
    },
    [updateUser],
  );

  const handleChangeUserStatus = useCallback(
    (user_status: string, row: any) => {
      setSaving(true);
      updateUser
        .mutateAsync({userId: row.original.id, user_status})
        .then((r) => console.log('updated', row.original, r))
        .catch((e) => console.log('update error', e))
        .finally(() => setSaving(false));
    },
    [updateUser],
  );

  const handleChangeUserOrganization = useCallback(
    (organizationId: string, row: any) => {
      setSaving(true);
      updateUser
        .mutateAsync({userId: row.original.id, organizationId})
        .then((r) => console.log('updated', row.original, r))
        .catch((e) => console.log('update error', e))
        .finally(() => setSaving(false));
    },
    [updateUser],
  );

  const columns = useMemo(
    () =>
      [
        {
          id: 'username',
          Header: 'Name',
          accessor: (row: IUser) => `${row.firstName} ${row.lastName}`,
          Cell: ({value, row}) => <UserNameCell value={value} row={row} />,
        },
        {
          Header: 'Email',
          accessor: 'email',
        },
        {
          Header: 'Phone',
          accessor: 'phone_number',
          disableSortBy: true,
        },
        {
          Header: 'Types',
          accessor: (row: IUser) => (row.user_type === 'admin' ? 'staff' : row.user_type),
          Cell: ({value, row}) => <UserTypeCell value={value} row={row} disabled={saving} onChange={handleChangeUserType} />,
        },

        {
          Header: 'Status',
          accessor: 'user_status',
          Cell: ({value, row}) => <UserStatusCell value={value} row={row} disabled={saving} onChange={handleChangeUserStatus} />,
          disableSortBy: true,
        },
        {
          Header: 'Organization',
          accessor: 'organizationId',
          Cell: ({value, row}) => (
            <UserOrganizationCell value={value} row={row} disabled={saving} onChange={handleChangeUserOrganization} />
          ),
          disableSortBy: true,
        },

        // {
        //   Header: 'Actions',
        //   accessor: 'id',
        //   Cell: ({value, row}: {value: string; row: Row}) => (
        //     <StandardButton
        //       disabled={deleteUser?.isLoading}
        //       style={{width: 52, justifyContent: 'center', alignItems: 'center', display: 'flex'}}
        //       onClick={() => {
        //         if (window.confirm('Delete ' + row.values.email + '? This cannot be undone!')) {
        //           onDeleteUser(row.values.id);
        //         }
        //       }}>
        //       <TrashIcon style={{width: 24}} />
        //     </StandardButton>
        //   ),
        //   disableSortBy: true,
        // },
      ] as Column[],
    [],
  );

  return (
    <AdminPageWrapper style={{maxWidth: '100%'}}>
      <AdminMenu />
      <Header />
      {/* <DeleteListofUsersButton /> */}
      {users.isLoading || (!users.data && <FullPageLoader />)}
      <AdminTable
        title="Users"
        data={filteredUsers || []}
        columns={columns}
        sortBy={[{id: 'email', desc: false}]}
        showAddButton
        toggleValue={activeSwitch}
        onToggleSelect={(value: number) => setActiveSwitch(value)}
        toggleLabels={['All', 'Active', 'Inactive']}
      />

      {saving ? <FullPageLoader /> : <></>}
    </AdminPageWrapper>
  );
};

export const Users: FC = withAuthenticationRequired(UsersComponent, {claimCheck: requireAdmin});
