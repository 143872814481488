import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IAuthorization, IOrganization, IUser} from '../../API';
import { v4 as uuidv4 } from 'uuid';

export interface IOnboardingState {
  organization: IOrganization | null;
  addUsers: IUser[];
  removeUsers: IUser[];
  addAuths: IAuthorization[];
  removeAuths: IAuthorization[];
}

const initialState: IOnboardingState = {
  organization: null,
  addAuths: [],
  removeAuths: [],
  addUsers: [],
  removeUsers: [],
};

export const authsEqual = (a: any, b: any) =>
  a.targetType === b.targetType && a.targetId === b.targetId;

const authsArrayContains = (arr: any, test: any) =>
  arr && arr.findIndex((auth: any) => authsEqual(auth, test)) > -1;

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IOrganization | null>) => {
      state.organization = action.payload;
    },
    updateOrganization: (state, action: PayloadAction<IOrganization | null>) => {
      state.organization = {
        ...state.organization,
        ...action.payload,
      } as IOrganization;
    },
    setAddUsers: (state, action) => {
      state.addUsers = action.payload.addUsers;
    },

    setRemoveUsers: (state, action) => {
      state.removeUsers = action.payload.removeUsers;
    },
    addNewUser: (state, action) => {
      const newUser = action.payload || {
        // Doesn't appear that email is unique among users,
        // otherwise the UUIDs here wouldn't be necessary
        id: uuidv4(),
        email: '',
        user_type: 'user',
        user_status: 'inactive',
        limitedView: false,
      };
      console.log('New user', newUser);
      // This shouldn't be null at this point, since the org should
      // already have a single admin user (the user adding new users).
      if (state.organization?.users) {
        state.organization.users.push(newUser as IUser);
      } else if (state.organization) {
        state.organization.users = [newUser as IUser];
      }
    },
    removeUser: (state, action: PayloadAction<IUser | null>) => {
      if (state.organization) {
        state.organization.users = state.organization?.users?.filter((user) => user.id !== action.payload?.id);
      }
    },
    handleAddAuth: (state, action) => {
      const organization = state.organization;
      const auth = action.payload.auth
      if (auth && !authsArrayContains(state.addAuths, auth) && (!authsArrayContains(organization?.authorizations, auth) || authsArrayContains(state.removeAuths, auth))) {
        state.removeAuths = state.removeAuths.filter((a) => !authsEqual(a, auth));
        if (!authsArrayContains(organization?.authorizations, auth)) {
          state.addAuths = [...state.addAuths, auth];
        }
      }
    },
    handleRemoveAuth: (state, action) => {
      const organization = state.organization;
      const auth = action.payload.auth
      if (auth && !authsArrayContains(state.removeAuths, auth) && (authsArrayContains(state.addAuths, auth) || authsArrayContains(organization?.authorizations, auth))) {
        state.addAuths = state.addAuths.filter((a) => !authsEqual(a, auth));
        if (authsArrayContains(organization?.authorizations, auth)) {
          state.removeAuths = [...state.removeAuths, auth];
        }
      }
    },
    handleClearAuths: (state) => {
      const organization = state.organization;
      organization?.authorizations?.forEach((auth) => {
        if (auth && !authsArrayContains(state.removeAuths, auth) && (authsArrayContains(state.addAuths, auth) || authsArrayContains(organization?.authorizations, auth))) {
          state.addAuths = state.addAuths.filter((a) => !authsEqual(a, auth));
          if (authsArrayContains(organization?.authorizations, auth)) {
            state.removeAuths = [...state.removeAuths, auth];
          }
        }
      });
    }
  },
});

export const {addNewUser, removeUser, setOrganization, updateOrganization, setAddUsers, setRemoveUsers, handleAddAuth, handleRemoveAuth, handleClearAuths} = onboardingSlice.actions;
export default onboardingSlice.reducer;
