import {FC, useMemo} from 'react';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import {useNavigate} from 'react-router-dom';
import {requireAdmin} from '../../Managers/AuthUtils';
import {AdminPage, AdminPageWrapper} from './Layout';
import {useAdminProducts} from '../../API';
import {AdminTable} from './AdminTable';
import {AdminMenu} from './AdminMenu';
import {Header} from '../Map/Header';
import {FullPageLoader, StandardButton} from '../../Components';
import {PencilIcon} from '@heroicons/react/outline';

const ProductsComponent: FC = () => {
  const products = useAdminProducts();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Center Lat',
        accessor: 'initialLat',
        disableSortBy: true,
      },
      {
        Header: 'Center Lon',
        accessor: 'initialLon',
        disableSortBy: true,
      },
      {
        Header: 'Initial Zoom',
        accessor: 'initialZoom',
        disableSortBy: true,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({value}: {value: string}) => (
          <div>
            <StandardButton style={{width: 52, padding: '4px 0 0 3px'}} onClick={() => navigate(`/admin/products/${value}`)}>
              <PencilIcon style={{width: 24}} />
            </StandardButton>
          </div>
        ),
        disableSortBy: true,
      },
    ],
    [navigate],
  );

  return (
    <AdminPageWrapper>
      <AdminMenu />
      <Header />

      {products.isLoading || !products.data ? (
        <AdminPage>
          <FullPageLoader />
        </AdminPage>
      ) : (
        <AdminTable title="Products" data={products.data || []} columns={columns} />
      )}
    </AdminPageWrapper>
  );
};

export const Products: FC = withAuthenticationRequired(ProductsComponent, {claimCheck: requireAdmin});
