import {useMutation, useQueryClient} from 'react-query';
import {updateOrganization} from '../Calls/Admin';

export const useUpdateOrganization = () => {
  const qc = useQueryClient();

  return useMutation(updateOrganization, {
    onSuccess: () => {
      // We COULD use the result to update the one organization we edited. But we invalidate the query anyway because this is a good time
      // to make sure another admin isn't doing something (or the user doesn't accidentally have multiple tabs open).
      qc.invalidateQueries(['ONBOARDING', 'ORGANIZATION']).catch(() => {});
      qc.invalidateQueries(['PROFILE']).catch(() => {});
    },

    onError: (err: Error) => {
      console.log('Error updating organization', err.message);
    },
  });
};
