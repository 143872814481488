import {useElements, useStripe} from '@stripe/react-stripe-js';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {IOrganization, IStripeSubscription, ISubscription, useSubscriptionPricing} from '../../../API';
import {BoldSubhead1, FlexRow, H6, LocationDetailsSection, MemberDetailsSection, StandardButton} from '../../../Components';
import {routePaths} from '../../../constants/routePaths';
import {IAuthCondensed} from '../OrganizationLocationsPanels';
import {Divider} from './SetupPayment';
import {TotalsFromInvoice} from './TotalsFromInvoice';

export const PaymentDetailsPanel: FC<{
  organization?: IOrganization;
  subscription?: ISubscription;
  stripeSubscription: IStripeSubscription;
  onSubmit: (e: any) => Promise<void>;
  isReady: boolean;
  submitting: boolean;
}> = ({organization, subscription, stripeSubscription, onSubmit, isReady, submitting}) => {
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const subscriptionTierPricing = subscriptionTypes?.[organization?.subscription_type || 'standard'];

  const users = organization?.users || [];

  return (
    <div style={{width: 500, minWidth: 296, gap: 16}}>
      <H6 style={{padding: 0, marginBottom: '0px'}}>Subscription Details</H6>

      <div style={{flexGrow: 1, overflowY: 'visible', gap: 16}}>
        <Divider />

        <div style={{padding: 0, flexGrow: 0}}>
          <BoldSubhead1 style={{marginBottom: '0px'}}>Type</BoldSubhead1>

          {!!organization?.subscription_type && (
            <span style={{marginTop: '8px', fontSize: '14px', color: '#6A6F7B'}}>
              {subscriptionTypes?.[organization.subscription_type]?.label}
            </span>
          )}
        </div>

        <Divider />

        <div style={{padding: 0, flexGrow: 0}}>
          <BoldSubhead1 style={{marginBottom: '0px'}}>Organization</BoldSubhead1>
          <div style={{marginTop: '8px', fontSize: '14px', color: '#6A6F7B'}}>{organization?.name}</div>
          <div style={{marginTop: '8px', wordBreak: 'break-word', fontSize: '14px', color: '#6A6F7B'}}>{organization?.primary_email}</div>
        </div>

        <Divider />

        <MemberDetailsSection users={users} subscriptionTierPricing={subscriptionTierPricing} />

        <Divider />

        <div style={{padding: 0}}>
          <LocationDetailsSection
            authorizations={organization?.authorizations as IAuthCondensed[]}
            organization={organization as IOrganization}
            hideActionButtons
          />
        </div>
      </div>
      <Divider />

      <div style={{padding: 0, flexGrow: 0, justifyContent: 'flex-end'}}>
        <TotalsFromInvoice stripeSubscription={stripeSubscription} hideItems />

        <FlexRow style={{marginTop: 28}}>
          <StandardButton
            type="button"
            style={{flex: 1, marginRight: 8, fontSize: '32px', fontWeight: 400}}
            outline
            onClick={() =>
              navigate(
                subscription && subscription?.type !== subscriptionTypes?.local?.value
                  ? routePaths.onboardingLocations
                  : routePaths.onboardingLocal,
              )
            }>
            &lt;
          </StandardButton>

          {stripe && elements ? (
            <StandardButton form="onboarding-payment-form" type="submit" style={{flex: 2}} disabled={!isReady || submitting}>
              {subscription && subscription.type !== subscriptionTypes?.local?.value ? 'Pay' : 'Submit'}
            </StandardButton>
          ) : (
            <StandardButton onClick={onSubmit} style={{flex: 2}} disabled={submitting}>
              {subscription && subscription.type !== subscriptionTypes?.local?.value ? 'Pay' : 'Submit'}
            </StandardButton>
          )}
        </FlexRow>
      </div>
    </div>
  );
};
