import {Field, Form, Formik} from 'formik';
import {FC, useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {
  IOrganization,
  SubscriptionType,
  useCreateOrganization,
  useGetPropublicaOrg,
  useSearchPropublica,
  useSubscriptionPricing,
  useUpdateOrganization,
} from '../../API';
import {useCreateTrialSubscription} from '../../API/Mutations/useCreateTrialSubscription';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {
  Body2,
  CountyField,
  ErrorBody,
  FieldLabel,
  FieldWrapper,
  FlexRow,
  FormField,
  FullPageLoader,
  H4,
  InputWrapper,
  RequiredSpan,
  StandardButton,
  TextInput,
} from '../../Components';
import {DropdownWithSuggestions} from '../../Components/Molecules/DropdownWithSuggestions';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {useOnboardingSteps} from '../../Hooks/useOnboardingSteps';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper, OnboardingPanel} from './Layout';
import {OnboardingSteps} from './OnboardingSteps';
import {IAuthCondensed} from './SetupLocations';

const organization501c3InfoSchema = yup.object({
  name: yup.string().trim().required("Please enter your organization's name."),
  county: yup.string().trim().required("Please enter your organization's county."),
  revenue: yup.number().required("Please enter your organization's revenue in the prior fiscal year."),
  ein: yup.number().required("Please enter your organization's EIN."),
});

export const Organization501c3Form: FC = () => {
  const {organization: cartOrganization, updateCartOrganization: updateCartOrg} = useCartOrganization();
  const navigate = useNavigate();
  const updateOrganizationAPI = useUpdateOrganization();
  const createOrganizationAPI = useCreateOrganization();
  const {currentStep, stepMap} = useOnboardingSteps();
  const {data: myProfile} = useMyProfile();
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;
  const createTrialSubscription = useCreateTrialSubscription();

  const [selectedPropublicaOrg, setSelectedPropublicaOrg] = useState<any>(null);

  // If local org data is empty, return them to the beginning of the
  // onboarding flow where they can set that up. The onboarding flow
  // should happen from start to finish each time to ensure the user
  // provides all the necessary information (org type, member info,
  // location info) and prevent confusion, where they thought they
  // changed the org type, but didn't (which could happen if they
  // navigated away from the page before finalizing their onboarding
  // details, and then returned halfway through). Currently, we don't
  // save unfinished onboarding details outside of browser memory.
  useEffect(() => {
    if (!cartOrganization) {
      navigate(routePaths.onboardingOrganization);
    }
  });

  const handleTrialSetup = async (values: any) => {
    const trialResult = await createTrialSubscription.mutateAsync({organization: values});
    if (trialResult?.data?.createTrialSubscription?.id) {
      toast.success('Trial subscription successfully created.');
      navigate(routePaths.adminOrganizations);
    }
  };

  // Since a 501c3 get's created at this point, we need to update the cart organization as well as the api
  const handleSubmit = async (values: any, actions: any) => {
    const authorization: IAuthCondensed = {targetId: values.county, targetType: 'county'};

    // This is a check to make sure the user is selecting the correct organization.  It's a bit of a hack, but we don't have a better way to do this right now.
    if (selectedPropublicaOrg?.ein !== values?.ein) {
      setTimeout(() => {
        toast.error('Unfortunately, your organization does not qualify for the 501(c)(3) subscription. Check other available plans!');
        actions.setSubmitting(false);
      }, Math.random() * 3000);

      return;
    }

    try {
      if (cartOrganization?.id) {
        const res = await updateOrganizationAPI.mutateAsync({
          ...values,
          organizationId: cartOrganization?.id,
          id: cartOrganization?.id,
          county: values.county,
          revenue: values.revenue,
          subscription_type: subscriptionTypes?.local?.value as SubscriptionType,
        });
        toast.success('Organization updated successfully');
        updateCartOrg(res);
        await handleTrialSetup({...res, authorizations: [authorization]});
        navigate(routePaths.onboardingPaymentConfirmed);
      } else {
        const newOrg = await createOrganizationAPI.mutateAsync({
          ...values,
        });

        toast.success('Organization created successfully');

        updateCartOrg(newOrg);
        await handleTrialSetup({...newOrg, authorizations: [authorization]});
        navigate(routePaths.onboardingPaymentConfirmed);
      }
    } catch (error: any) {
      console.log('Error saving organization');
      toast.error(error?.response?.data || error?.message || error?.response?.message || error?.data || error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formSchema = organization501c3InfoSchema;

  const handleUseOrg = async (propublicaOrg: any, values: any) => {
    const {name, address, city, state, zipcode} = propublicaOrg.organization;
    setSelectedPropublicaOrg(propublicaOrg.organization);

    const newOrg = {...values, address1: address, name, city, state, zip: zipcode, primary_email: myProfile?.user?.email} as IOrganization;

    updateCartOrg(newOrg);
  };

  return (
    <OnboardingPageWrapper>
      {updateOrganizationAPI.isLoading && <FullPageLoader />}
      <OnboardingPage style={{maxWidth: 600}}>
        <OnboardingPanel style={{overflow: 'visible'}}>
          <H4 style={{textAlign: 'center'}}>Independent Organization Application</H4>

          <Body2>
            In order for us to verify your application as a 501(c)(3) company, we need you to answer the questions below. Once we review
            your case, we will apply a discount to your subscription.
          </Body2>

          <Formik
            enableReinitialize
            initialValues={cartOrganization || ({} as IOrganization)}
            onSubmit={(values, actions) => {
              handleSubmit(formSchema.cast(values), actions);
            }}
            validationSchema={formSchema}>
            {({dirty, setFieldValue, isSubmitting, isValid, errors, values, validateForm}) => {
              return (
                <Form>
                  {isSubmitting && <FullPageLoader />}

                  <FormField style={{flex: 1}}>
                    <Field name="county">
                      {({
                        field,
                        form,
                        meta,
                      }: {
                        field: {name: string; value: string};
                        form: {setFieldValue: (field: string, value: any) => void};
                        meta: {touched: boolean; error: string};
                      }) => (
                        <FieldWrapper>
                          <FieldLabel>
                            Organization County
                            <RequiredSpan> *</RequiredSpan>
                          </FieldLabel>

                          <InputWrapper>
                            <CountyField
                              name={field.name}
                              value={cartOrganization?.county}
                              onChange={(option: {value: string}) => form.setFieldValue(field.name, option.value)}
                            />
                          </InputWrapper>

                          <ErrorBody>{meta?.error}</ErrorBody>

                          <Body2 style={{margin: 0}}>
                            NOTE: Please select the county that best suits your needs as it will determine the only county accessible under
                            your 501(c)(3) subscription plan.
                          </Body2>
                        </FieldWrapper>
                      )}
                    </Field>
                  </FormField>

                  <FlexRow style={{gap: 16}}>
                    <FormField style={{flex: 1}}>
                      <PropublicaSearchBox
                        onChange={(value) => setFieldValue('name', value)}
                        onSelectForUse={(v) => handleUseOrg(v, values)}
                        organization={values}
                      />

                      {!!errors?.name && <ErrorBody>{errors?.name}</ErrorBody>}
                    </FormField>

                    <FormField style={{flex: 1}}>
                      <TextInput name="ein" type="number" placeholder="Enter EIN" label="What is your organization’s EIN?" required />
                    </FormField>
                  </FlexRow>

                  <FormField style={{flex: 1}}>
                    <TextInput
                      name="revenue"
                      type="number"
                      placeholder="Enter Revenue"
                      label="What was your organization’s revenue in the prior fiscal year?"
                      required
                    />
                  </FormField>

                  <FlexRow style={{marginBottom: 18}}>
                    <StandardButton
                      type="button"
                      style={{flex: 1, marginRight: 30}}
                      outline
                      onClick={() => navigate(stepMap[currentStep - 1])}>
                      Back
                    </StandardButton>

                    <StandardButton type="submit" style={{flex: 1}} disabled={isSubmitting || !isValid}>
                      Submit
                    </StandardButton>
                  </FlexRow>
                </Form>
              );
            }}
          </Formik>
        </OnboardingPanel>
      </OnboardingPage>
      <OnboardingSteps />
    </OnboardingPageWrapper>
  );
};

const PropublicaSearchBox: FC<{
  organization: IOrganization;
  onChange?: (value: any) => void;
  onSelect?: (value: any) => void;
  onSelectForUse: (value: any) => void;
}> = ({onSelect, onSelectForUse, organization, onChange}) => {
  const [query, setQuery] = useState(organization?.name || ''); // Default query
  const [dbquery, setdbQuery] = useState(''); // Default query
  const [ein, setEin] = useState(0); // Default query
  const {data, isFetching} = useSearchPropublica(dbquery);
  const {data: orgData, isFetching: orgIsFetching} = useGetPropublicaOrg(ein);

  // const [selected, setSelected] = useState<any>(null);

  const handleChange = async (value: any) => {
    setQuery(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const sanitizedQuery = query.toString().replace(/[^a-zA-Z0-9 ]/g, '');

      setdbQuery(sanitizedQuery);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [query, 1000]);

  useEffect(() => {
    if (orgData?.organization && !orgIsFetching) {
      onSelectForUse(orgData);
    }
  }, [orgData]);

  const options = data?.organizations?.map((org: any) => ({
    label: `${org.name} (${org.city}, ${org.state})`,
    value: org,
  }));

  const handleSelect = (option: any) => {
    // setSelected(option);
    setEin(option.ein);
    !!onSelect && onSelect(option);
  };

  return (
    <FlexRow style={{gap: 30}}>
      <div style={{flex: 2}}>
        <DropdownWithSuggestions
          label="Find Your Organization"
          placeholder={'Enter Organization Name or EIN...'}
          onSearchChange={handleChange}
          value={query}
          onSelect={handleSelect}
          options={options}
          required
          isLoading={isFetching}
        />
      </div>
    </FlexRow>
  );
};
