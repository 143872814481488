import {FC} from 'react';
import styled from 'styled-components/macro';
import {DropdownOption} from './Dropdown';
import {FlexRow} from '../Layout';
import {Body2, Theme} from '../Atoms';

const SwitchWrapper = styled.div`
  width: 44px;
  height: 24px;
  border-radius: 24px;
  flex: 0 0 24px;
  display: flex;
  cursor: pointer;
  background-color: ${(props) => props.theme.Colors.Oranges._000};

  & span {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin: 2px;
    background-color: #fff;
    transition: 0.3s;
  }
`;

const ToggleWrapper = styled(FlexRow)`
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
`;

const ToggleLabel = styled(Body2)`
  margin: 0;
`;

export const BasicToggle: FC<{label?: string; onChange: (value: boolean) => void; value: boolean}> = ({label, onChange, value}) => (
  <ToggleWrapper>
    {label && <ToggleLabel>{label}</ToggleLabel>}
    <div>
      <SwitchWrapper
        onClick={() => onChange(!value)}
        style={{backgroundColor: value ? Theme.Colors.Oranges._000 : Theme.Colors.Neutrals._400}}>
        <span style={{marginLeft: value ? 22 : 2}} />
      </SwitchWrapper>
    </div>
  </ToggleWrapper>
);

export const SwitchToggle: FC = () => <div />;

const ToggleButtonsWrapper = styled.div<{$showBorder?: boolean}>`
  border: ${(props) => (props.$showBorder ? `2px solid ${props.theme.Colors.Neutrals._500}` : 'none')};
  border-radius: 16px;
  flex-direction: row;
  background: white;
  box-shadow: ${(props) =>
    !props.$showBorder ? '0px 4px 6px -2px rgba(106, 111, 123, 0.05), 0px 10px 15px -3px rgba(106, 111, 123, 0.10)' : 'none'};
`;

const ToggleButton = styled.button`
  border: none;
  font-size: 14px;
  border-radius: 16px;
  line-height: 20px;
  height: 52px;
  flex: 0;
  font-weight: 600;
  padding: 0 16px;
  cursor: pointer;
  white-space: nowrap;
  background: transparent;
  color: ${(props) => props.theme.Colors.Oranges._000};

  &.active {
    color: #fff;
    background: ${(props) => props.theme.Colors.Oranges._000};
    border: none;
    flex: 1;
    height: 52px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
  }
  &:disabled {
    color: ${(props) => props.theme.Colors.Neutrals._400};
    border: none;
    flex: 1;
    height: 52px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
    cursor: not-allowed;
  }

  &:disabled.active {
    background: ${(props) => props.theme.Colors.Neutrals._300};
  }
`;

interface ToggleButtonsProps {
  buttons: string[];
  selected: number;
  onClick: (index: number) => void;
  options?: Array<DropdownOption>;
  value?: any;
  hideSearchIcon?: boolean;
  showBorder?: boolean;
  disabled?: boolean;
}

export const ToggleButtons: FC<ToggleButtonsProps> = ({buttons, selected, onClick, showBorder, disabled}) => {
  return (
    <ToggleButtonsWrapper $showBorder={showBorder}>
      {buttons.map((button, index) => (
        <ToggleButton
          key={index}
          className={selected === index ? 'active' : ''}
          disabled={button !== 'Features' && disabled}
          onClick={() => onClick(index)}>
          {button}
        </ToggleButton>
      ))}
    </ToggleButtonsWrapper>
  );
};
