import {ChevronDownIcon, ChevronUpIcon, PlusIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {UseSortByColumnProps, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import styled from 'styled-components';
import {Body2, Dropdown, DropdownOption, FlexFill, FlexRow, H4, StandardButton, Subhead2, Theme, ToggleButtons} from '../../../Components';
import {routePaths} from '../../../constants/routePaths';
import {AdminPage} from '../Layout';
import {Pagination} from './Pagination';
import {QuickFilter} from './QuickFilter';
import {Container, TBR, TBody, TD, TH, THR, THead, Table} from './TableLayout';

const SortIcon = ({column}: {column: UseSortByColumnProps<any>}) => {
  if (!column.canSort) {
    return null;
  }
  if (column.isSorted && column.isSortedDesc) {
    return <ChevronUpIcon style={{width: 20, height: 40, marginLeft: 5, color: Theme.Colors.Neutrals._000}} />;
  } else if (column.isSorted) {
    return <ChevronDownIcon style={{width: 20, height: 40, marginLeft: 5, color: Theme.Colors.Neutrals._000}} />;
  } else {
    return (
      <div style={{height: 30, marginLeft: 5}}>
        <ChevronUpIcon style={{width: 20, marginBottom: -5, color: Theme.Colors.Neutrals._400}} />
        <ChevronDownIcon style={{width: 20, marginTop: -5, color: Theme.Colors.Neutrals._400}} />
      </div>
    );
  }
};

const Header = styled(Subhead2)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export interface AdminTableProps {
  columns: any[];
  data: any[];
  onToggleSelect?: any;
  pageSize?: number;
  showAddButton?: boolean;
  showHeader?: boolean;
  sortBy?: any[];
  title?: string;
  toggleLabels?: string[];
  toggleValue?: number;
  onDropdownSelect?: (value: any) => void;
  dropdownOptions?: DropdownOption[];
}

// TODO: Research why react-table types aren't working.
// See https://react-table.tanstack.com/docs/examples/filtering and other documentation pages. React Table is a very powerful table
// rendering engine. Instead of focusing on drawing the HTML itself, it lets you do that, while focusing on providing a plug-based
// approach to things like filtering, pagination, row editing, etc.
export const AdminTable: FC<AdminTableProps> = ({
  title,
  data,
  columns,
  pageSize = 15,
  showAddButton = true,
  showHeader = true,
  sortBy = [{id: 'name', desc: false}],
  onToggleSelect,
  toggleValue,
  toggleLabels = ['Active', 'Inactive'],
  onDropdownSelect,
  dropdownOptions = [],
}) => {
  // @ts-ignore
  const instance = useTable({columns, data, initialState: {pageSize: pageSize, sortBy}}, useGlobalFilter, useSortBy, usePagination);
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow} = instance;

  // @ts-ignore
  const {pageOptions, page, state, previousPage, nextPage, canPreviousPage, canNextPage} = instance;
  // @ts-ignore
  const {preGlobalFilteredRows, setGlobalFilter} = instance;
  // @ts-ignore
  const {pageIndex, globalFilter} = state;
  const location = useLocation();
  const navigate = useNavigate();

  const isOrganizations = location.pathname.includes(routePaths.adminOrganizations);
  const isUsers = location.pathname.includes(routePaths.adminUsers);

  const handleAddNew = () => {
    if (isOrganizations) {
      navigate(`${routePaths.adminOrganizationCreate}`);
    } else if (isUsers) {
      // navigate(`${routePaths.admin.UsersCreate}`);
    } else {
      // navigate(`${routePaths.admin.SettingsCreate}`);
    }
  };

  return (
    <AdminPage style={{maxWidth: '100%', overflow: 'unset'}}>
      {!!title && (
        <div style={{flexDirection: 'row', flex: 0, alignItems: 'center', marginBottom: 20}}>
          <H4 style={{marginBottom: 0}}>{title}</H4>
          <FlexFill />
        </div>
      )}
      <Container>
        {showHeader && (
          <FlexRow style={{padding: '20px 16px 16px', gap: 32}}>
            <div style={{flexGrow: 2}}>
              <QuickFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            </div>

            {!!onToggleSelect && (
              <div style={{maxWidth: 300, minWidth: 300}}>
                <ToggleButtons onClick={onToggleSelect} buttons={toggleLabels} selected={toggleValue || 0} />
              </div>
            )}

            {!!onDropdownSelect && (
              <div style={{maxWidth: 200, minWidth: 200}}>
                <Dropdown options={dropdownOptions || []} onChange={onDropdownSelect} defaultValue={dropdownOptions?.[0]} hideSearchIcon />
              </div>
            )}

            {showAddButton && (
              <StandardButton
                style={{
                  width: 200,
                  minWidth: 200,
                  padding: '0 20px',
                  whiteSpace: 'nowrap',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={handleAddNew}>
                <PlusIcon style={{width: 20, marginRight: 15}} color="white" />
                Add New
              </StandardButton>
            )}
          </FlexRow>
        )}
        {data && data.length ? (
          <Table {...getTableProps()}>
            <THead>
              {headerGroups.map((headerGroup) => (
                <THR {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TH
                      {...column.getHeaderProps({
                        ...column.getSortByToggleProps(),
                        style: {maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width},
                      })}>
                      <Header>
                        {column.render('Header')} <SortIcon column={column} />{' '}
                      </Header>
                    </TH>
                  ))}
                </THR>
              ))}
            </THead>

            <TBody {...getTableBodyProps()}>
              {page.map((row: any) => {
                prepareRow(row);

                return (
                  <TBR {...row.getRowProps()}>
                    {row.cells.map((cell: any) => {
                      return <TD {...cell.getCellProps()}>{cell.render('Cell')}</TD>;
                    })}
                  </TBR>
                );
              })}
            </TBody>
          </Table>
        ) : (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Body2>No data available</Body2>
          </div>
        )}
      </Container>
      <Pagination {...{previousPage, canPreviousPage, nextPage, canNextPage, pageIndex, pageOptions}} />
    </AdminPage>
  );
};
