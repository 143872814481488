import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../../../Managers/Datasets/Types';
import {BasicCheckbox, TooltipTrigger} from '../../../../Components';

interface Props {
  dataset: IDatasetConfig;
  onUpdateDataset: (dataset: IDatasetConfig) => void;
  options: IDatasetOptions<number | IDemographicValue | IRedliningValue | IBuildingValue | ITribeValue>;
}

export const VariantCheckbox: FC<Props> = ({dataset, options, onUpdateDataset}) => {
  const handleToggleVariant = (id: string, on: boolean) => {
    const datasetCopy = {...dataset};
    if (on) {
      datasetCopy.subsetVariants = [...(datasetCopy.subsetVariants || []), id];
    } else {
      datasetCopy.subsetVariants = datasetCopy?.subsetVariants?.filter((e) => e !== id);
    }
    onUpdateDataset(datasetCopy);
  };

  return (
    <>
      {options.subsetVariants?.map((variant, index) => (
        <TooltipTrigger data-tip={variant.description || ''} data-for="checkbox-tooltips" key={index}>
          <BasicCheckbox
            key={variant.id}
            name={variant.id}
            label={variant.title}
            style={{marginBottom: 16}}
            onChange={(v) => handleToggleVariant(variant.id, v)}
          />
        </TooltipTrigger>
      ))}
      <ReactTooltip
        id="checkbox-tooltips"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 8, left: 120}}
        wrapper="span"
        className="info-tooltip"
      />
    </>
  );
};
