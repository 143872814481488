import {FC} from 'react';
import {IOrganization} from '../../API';
import {BasicToggle, Body1, Body2, FlexRow, H6, Subhead1} from '../../Components';
import BuildinPinsIcon from '../../Components/Atoms/img/Rectangle 190.svg';
import CityBoundariesIcon from '../../Components/Atoms/img/Rectangle 191.svg';
import EquityIndexIcon from '../../Components/Atoms/img/Rectangle 215.svg';
import ProductPickerIcon from '../../Components/Atoms/img/Rectangle 216.svg';
import {AdminPanel} from './Layout';

const FeaturesMap = {
  showBuildingPins: {
    cost: 0,
    description: 'Enable the Denver building pins on the map.',
    icon: BuildinPinsIcon,
    name: 'showBuildingPins',
    title: 'Building Pins',
  },
  showCityBoundaries: {
    cost: 0,
    description: 'Enable city boundaries on the map.',
    icon: CityBoundariesIcon,
    name: 'showCityBoundaries',
    title: 'City Boundaries',
  },
  showEquityIndex: {
    cost: 0,
    description: 'Enable Equity Index on the map.',
    icon: EquityIndexIcon,
    name: 'showEquityIndex',
    title: 'Equity Index',
  },
  showProducts: {
    cost: 0,
    description: 'Enable product picker on the map.',
    icon: ProductPickerIcon,
    name: 'showProducts',
    title: 'Product Picker',
  },
  showPhillyBuildings: {
    cost: 0,
    description: 'Enable Philadeplphia Buildings indicator on the map.',
    icon: BuildinPinsIcon,
    name: 'showPhillyBuildings',
    title: 'Philadelphia Buildings',
  },
  showSolarEligibility: {
    cost: 0,
    description: 'Enable Solar Potential indicator on the map.',
    icon: EquityIndexIcon,
    name: 'showSolarEligibility',
    title: 'Solar Potential',
  },
};

export const FeaturesTab: FC<{organization: IOrganization; onUpdate: (values: {}) => any}> = ({organization, onUpdate}) => {
  return (
    <div style={{maxWidth: 1000, gap: 16}}>
      <div>
        <H6 style={{margin: '16px 0 8px 0'}}>Manage Additional Features</H6>
        <Body1 style={{margin: 0}}>Enabled features will be available for all the products and members of the organization.</Body1>
      </div>

      {Object.values(FeaturesMap).map((feature) => (
        <AdminPanel
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
            display: 'flex',
            minHeight: 140,
            padding: 24,
          }}>
          <FlexRow style={{justifyContent: 'space-between', gap: 24, alignItems: 'center'}}>
            <div style={{flex: 0}}>
              <img src={feature.icon} alt={feature.description} />
            </div>
            <div style={{flex: 1, gap: 8}}>
              <H6 style={{margin: 0}}>{feature.title}</H6>
              {!!feature.cost && (
                <Subhead1 style={{margin: 0}}>
                  {new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'USD'}).format(feature.cost)}
                </Subhead1>
              )}
              <Body2 style={{margin: 0}}>{feature.description} </Body2>
            </div>
            <div style={{flex: 0, display: 'flex'}}>
              <BasicToggle
                label={!!organization[feature.name as keyof IOrganization] ? 'Enabled' : 'Disabled'}
                onChange={() => {
                  onUpdate({id: organization.id, [feature.name]: !organization[feature.name as keyof IOrganization]});
                }}
                value={!!organization[feature.name as keyof IOrganization]}
              />
            </div>
          </FlexRow>
        </AdminPanel>
      ))}
    </div>
  );
};
