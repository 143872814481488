import {FormikProps} from 'formik';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {IAuthorization, IOrganization, IUser, useSubscriptionPricing} from '../../API';
import {BoldSubhead1, FlexRow, H6, StandardButton, TextButton} from '../../Components';
import {LocationDetailsSection} from '../../Components/Molecules/LocationDetailsSection';
import {MemberDetailsSection} from '../../Components/Molecules/MemberDetailsSection';
import {TotalRow} from '../../Components/Molecules/TotalRow';
import {useOnboardingSteps} from '../../Hooks/useOnboardingSteps';
import {calcTotalCost} from '../../Managers/MathUtils';
import {DEFAULT_SUBSCRIPTION_TYPE} from '../../constants/constants';
import {UpdateSubscriptionButton} from '../OrgAdmin/UpdateSubscriptionButton';
import {Divider, OnboardingSidePanel} from './Layout';
import {IAuthCondensed} from './OrganizationLocationsPanels';
import {TotalsFromInvoice} from './Payment/TotalsFromInvoice';
import {getUniqueAuthorizations} from './getUniqueAuthorizations';
import {useSubscriptionTotals} from './useSubscriptionTotals';

const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.Colors.Neutrals._200};
`;

const LocalButton = styled(TextButton)`
  color: ${(props) => props.theme.Colors.Oranges._000};
  text-decoration: underline;
  padding-bottom: 20px;
  text-wrap: nowrap;

  &:hover {
    color: ${(props) => props.theme.Colors.Oranges._300};
  }
`;

export const SubscriptionDetailsPanel: FC<{
  authorizations: IAuthCondensed[];
  formikProps: FormikProps<any>;
  hideActionButtons?: boolean;
  hideLocations?: boolean;
  hideMembers?: boolean;
  onClickSubmit?: () => Promise<any>;
  onRemoveAuth?: (auth: IAuthCondensed) => void;
  onResetForm?: () => Promise<any>;
  onSubmitLocations?: () => Promise<any>;
  organization: IOrganization;
  showUpdateButton?: boolean;
  skipDirtyCheck?: boolean;
  title?: string;
  users: IUser[];
}> = ({
  authorizations: currentAuthorizations,
  formikProps,
  hideActionButtons,
  hideLocations,
  hideMembers,
  onClickSubmit,
  onRemoveAuth,
  onResetForm,
  onSubmitLocations,
  organization,
  showUpdateButton,
  skipDirtyCheck,
  title = 'Subscription Details',
  users: allUsers,
}) => {
  const navigate = useNavigate();
  const {stepMap, currentStep} = useOnboardingSteps();
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const SUBSCRIPTION_TYPES = subscriptionPricing?.subscriptionTypes;
  const subscriptionTierPricing = SUBSCRIPTION_TYPES?.[organization?.subscription_type || DEFAULT_SUBSCRIPTION_TYPE];

  // remove staff from list for cost calculation
  const usersForCost = allUsers?.filter((user) => user.user_type !== 'admin');

  // There were some issues with the authorizations array having duplicates.
  const authorizations = getUniqueAuthorizations(currentAuthorizations);

  // Currently, this only works for new users who only purchase
  // counties/states/nationwide locations. It ignores tracts/products.
  // TODO: What should we do about legacy tracts/products?
  const updatedCost = calcTotalCost(authorizations, usersForCost?.length, subscriptionPricing, organization?.subscription_type);

  const {amount_due, isLoading, selectedPercentOff, userCost, previewLines, previewSubtotal, previewEndingBalance} = useSubscriptionTotals({
    organization,
    updatedOrganization: {...organization, authorizations: authorizations as IAuthorization[], users: usersForCost},
    newCost: updatedCost,
  });

  const locationCost = updatedCost - userCost;

  const handleSubmit = async () => {
    onClickSubmit && (await onClickSubmit());
    if (onSubmitLocations) {
      await onSubmitLocations();
    }
  };

  const handleFormReset = () => {
    onResetForm && onResetForm();
  };

  const showLowerPanel = !!organization?.id;
  const needsLocations = !hideLocations && !!onSubmitLocations && !authorizations?.length;
  const needsMembers = !hideMembers && !allUsers?.length;

  const currentAuthList = authorizations.map((auth) => auth.targetId).sort();

  const initialAuthList = organization?.authorizations?.map((auth) => auth.targetId).sort();

  const authorizationsChanged = JSON.stringify(currentAuthList) !== JSON.stringify(initialAuthList);

  const canSubmitLocations = !!onSubmitLocations && !needsLocations && (skipDirtyCheck || authorizationsChanged);

  const canSubmitMembers =
    !!onClickSubmit && !formikProps.isSubmitting && formikProps.isValid && (formikProps.dirty || skipDirtyCheck) && !needsMembers;

  const canSubmit = !formikProps.isSubmitting && !isLoading && (canSubmitMembers || canSubmitLocations);

  return (
    <div style={{gap: 28}}>
      <OnboardingSidePanel>
        <div style={{flex: 0, padding: '28px', gap: 8}}>{title && <H6 style={{marginBottom: '0px'}}>{title}</H6>}</div>

        <div style={{flexGrow: 1, overflowY: 'auto'}}>
          {!showLowerPanel && (
            <>
              <Divider />

              <div style={{padding: '16px 28px', flexGrow: 0}}>
                <BoldSubhead1 style={{marginBottom: '0px'}}>Organization</BoldSubhead1>

                <Text style={{marginTop: '8px'}}>{organization?.name}</Text>
              </div>
            </>
          )}

          {!hideMembers && (
            <>
              <Divider />

              <div style={{padding: '16px 28px', flex: 0}}>
                <MemberDetailsSection users={allUsers} subscriptionTierPricing={subscriptionTierPricing} />
              </div>
            </>
          )}

          {!hideLocations && (
            <>
              <Divider />

              <div style={{padding: '16px 28px'}}>
                <LocationDetailsSection
                  authorizations={authorizations}
                  disabled={isLoading || formikProps.isSubmitting}
                  hideActionButtons={hideActionButtons}
                  onRemoveAuth={onRemoveAuth}
                  organization={organization}
                />
              </div>
            </>
          )}
        </div>

        {authorizationsChanged && !!onResetForm && (
          <div style={{padding: '16px 28px', flex: 0, justifyContent: 'flex-end'}}>
            <LocalButton onClick={handleFormReset} style={{marginTop: 8}}>
              Reset Locations
            </LocalButton>
          </div>
        )}

        {showLowerPanel && (
          <div style={{padding: '16px 28px', flex: 0, justifyContent: 'flex-end'}}>
            {!hideMembers && <TotalRow label={`Total Members`} cost={userCost} />}

            {!hideLocations && <TotalRow label={`Total Locations`} cost={locationCost} />}
          </div>
        )}

        {!showLowerPanel && (
          <div style={{padding: '16px 28px', flexGrow: 0, justifyContent: 'flex-end'}}>
            <TotalRow label="Total" cost={updatedCost} />

            {!hideActionButtons && (
              <FlexRow style={{marginTop: 8}}>
                {showUpdateButton && (
                  <UpdateSubscriptionButton
                    onSubmit={handleSubmit}
                    newPrice={updatedCost || 0}
                    amountDue={amount_due / 100}
                    disabled={!canSubmit}
                  />
                )}

                {!showUpdateButton && (
                  <>
                    <StandardButton
                      type="button"
                      style={{flex: 1, marginRight: 8, fontSize: '32px', fontWeight: 400}}
                      outline
                      onClick={() => navigate(stepMap[currentStep - 1])}>
                      &lt;
                    </StandardButton>

                    <StandardButton onClick={handleSubmit} style={{flex: 2}} disabled={!canSubmit}>
                      Next
                    </StandardButton>
                  </>
                )}
              </FlexRow>
            )}
          </div>
        )}
      </OnboardingSidePanel>

      {showLowerPanel && (
        <OnboardingSidePanel style={{flex: 0}}>
          <div style={{padding: 28, flexDirection: 'column', gap: 16}}>
            <H6 style={{marginBottom: '0px'}}>Subscription</H6>

            <div style={{flex: 0}}>
              <TotalsFromInvoice
                hideItems={!canSubmitMembers && !canSubmitLocations}
                amount_due={!isLoading ? amount_due : 0}
                stripeSubscription={organization?.stripeSubscription}
                newCost={updatedCost * 100}
                newPercentOff={selectedPercentOff}
                previewLines={previewLines}
                previewSubtotal={previewSubtotal}
                previewEndingBalance={previewEndingBalance}
              />
            </div>

            {!hideActionButtons && (
              <FlexRow>
                {showUpdateButton && (
                  <UpdateSubscriptionButton
                    onSubmit={handleSubmit}
                    newPrice={updatedCost || 0}
                    amountDue={amount_due / 100}
                    disabled={!canSubmit}
                  />
                )}

                {!showUpdateButton && (
                  <>
                    <StandardButton
                      type="button"
                      style={{flex: 1, marginRight: 8, fontSize: '32px', fontWeight: 400}}
                      outline
                      onClick={() => navigate(stepMap[currentStep - 1])}>
                      &lt;
                    </StandardButton>

                    <StandardButton onClick={handleSubmit} style={{flex: 2}} disabled={!canSubmit}>
                      Next
                    </StandardButton>
                  </>
                )}
              </FlexRow>
            )}
          </div>
        </OnboardingSidePanel>
      )}
    </div>
  );
};
