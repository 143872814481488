// cartSlice.ts

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAuthorization, IOrganization, IUser} from '../../API';

interface CartState {
  // key is userId
  organizations: Record<string, IOrganization>; // Key-value object with userId as key
}

const initialState: CartState = {
  organizations: {},
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<{userId: string; organization: IOrganization}>) => {
      const {userId, organization} = action.payload;
      if (!state?.organizations) {
        state.organizations = {}; // Initialize with an empty object
      }
      state.organizations[userId] = organization;
    },

    addAuthorization: (state, action: PayloadAction<{userId: string; authorization: IAuthorization}>) => {
      const {userId, authorization} = action.payload;
      state.organizations[userId]?.authorizations?.push(authorization);
    },

    setAuthorizations: (state, action: PayloadAction<{userId: string; authorizations: IAuthorization[]}>) => {
      const {userId, authorizations} = action.payload;
      if (!state.organizations) {
        state.organizations = {}; // Initialize with an empty object if not exists
      }
      state.organizations[userId].authorizations = authorizations;
    },

    deleteAuthorization: (state, action: PayloadAction<{userId: string; authorizationId: number}>) => {
      const {userId, authorizationId} = action.payload;

      const userOrganization = state.organizations[userId];
      if (userOrganization?.authorizations) {
        const authorizationIndex = userOrganization.authorizations.findIndex((auth) => auth.id === authorizationId);
        if (authorizationIndex !== -1) {
          userOrganization.authorizations.splice(authorizationIndex, 1);
        }
      }
    },

    addUser: (state, action: PayloadAction<{userId: string; user: IUser}>) => {
      const {userId, user} = action.payload;
      state.organizations[userId]?.users?.push(user);
    },

    setUsers: (state, action: PayloadAction<{userId: string; users: IUser[]}>) => {
      const {userId, users} = action.payload;
      if (!state.organizations) {
        state.organizations = {}; // Initialize with an empty object if not exists
      }
      state.organizations[userId].users = users;
    },

    deleteUser: (state, action: PayloadAction<{userId: string; userIdToDelete: string}>) => {
      const {userId, userIdToDelete} = action.payload;

      const userOrganization = state.organizations[userId];
      if (userOrganization?.users) {
        const userIndex = userOrganization.users.findIndex((user) => user.id === userIdToDelete);
        if (userIndex !== -1) {
          userOrganization.users.splice(userIndex, 1);
        }
      }
    },
    resetCart: (state) => {
      state.organizations = {}; // Reset the organizations to an empty object
    },
  },
});

export const {setOrganization, addAuthorization, setAuthorizations, deleteAuthorization, addUser, setUsers, deleteUser, resetCart} =
  cartSlice.actions;

export default cartSlice.reducer;
