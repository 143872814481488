import {DownloadIcon, PencilIcon} from '@heroicons/react/outline';
import {format} from 'date-fns';
import {FC, useState} from 'react';
import {Row} from 'react-table';
import styled from 'styled-components';
import {IOrganization, useGetMyOrganization, useGetSubscriptionPaymentMethod, useListSubscriptionInvoices} from '../../API';
import {Body3, FullPageLoader, H6, Subhead3, TextButton, Theme} from '../../Components';
import AmexImage from '../../Components/Atoms/img/AmericanExpress2.svg';
import MasterCardImage from '../../Components/Atoms/img/MasterCard2.svg';
import VisaImage from '../../Components/Atoms/img/Visa2.svg';
import {OrganizationHeader} from '../../Components/Molecules/OrganizationHeader';
import {AdminTable} from '../Admin/AdminTable';
import {AdminPage, AdminPageDividerWrapper, AdminPageWrapper} from '../Admin/Layout';
import {Header} from '../Map/Header';
import {OnboardingSidePanel} from '../Onboarding/Layout';
import {AdminMenu} from './AdminMenu';
import {PaymentMethodModal} from './PaymentMethodModal';
import {SubscriptionDetails} from './SubscriptionDetails';
import {useMyProfile} from '../../API/Queries/useMyProfile';

const ActionButton = styled(TextButton)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: ${Theme.Colors.Oranges._000};
`;

const CardContainer = styled.div`
  position: relative;
  width: 240px;
  min-height: 148px;
  height: 148px;
  border-radius: 16px;
  align-self: center;
  background: rgb(239, 68, 68);
  background: linear-gradient(30deg, rgba(239, 68, 68, 1) 0%, rgba(255, 145, 38, 1) 39%, rgba(239, 68, 68, 1) 79%);
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 0 28px;
  padding: 16px 16px 8px 16px;
  // background: rgba(0, 0, 0, 0.01);
  // backdrop-filter: blur(26px);
`;

const CardLogoImage = styled.img`
  align-self: flex-end;
  width: 48px;
`;

const CardTextContainer = styled.div`
  flex: 0;
  gap: 4px;
`;

const CardNumberContainer = styled.div`
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 4px;
  margin: 0;
  color: #ffffff;
`;

const CardNameContainer = styled(Subhead3)`
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
`;

const CardExpContainer = styled(Body3)`
  color: #ffffff;
  margin: 0;
`;

const SidePanel = styled(OnboardingSidePanel)`
  min-width: 296px;
`;

const columns = [
  {
    Header: 'Billing Date',
    accessor: 'created',
    sortType: 'number',
    minWidth: 200,
    Cell: ({value, row}: {value: string; row: Row}) => {
      return format(new Date(+value * 1000), 'MM/dd/yyyy');
    },
  },
  {
    Header: 'Total Price',
    accessor: 'amount_due',
    sortType: 'number',
    minWidth: 200,
    Cell: ({value, row}: {value: number; row: Row}) => {
      return (value / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortType: 'string',
    minWidth: 200,
  },
  {
    Header: 'Actions',
    accessor: 'invoice_pdf',
    disableSortBy: true,
    Cell: ({value, row}: {value: string; row: Row}) => (
      <ActionButton onClick={() => (window.location.href = value || window.location.href)}>
        <DownloadIcon style={{stroke: Theme.Colors.Oranges._000, width: '24px'}} />
        <div>Download</div>
      </ActionButton>
    ),
  },
];

const getPaymentMethodImage = (cardBrand: string) => {
  switch (cardBrand?.toLowerCase()) {
    case 'visa':
      return VisaImage;
    case 'mastercard':
      return MasterCardImage;
    case 'american express':
      return AmexImage;
    default:
      return null;
  }
};

export const MySubscription: FC = () => {
  const {data: invoices, isFetching: isFetchingInvoices} = useListSubscriptionInvoices();
  const {data: myProfile} = useMyProfile();
  const {data: organization, isFetching: isFetchingOrg} = useGetMyOrganization(!!myProfile?.user?.organizationId);
  const {data: paymentMethod, isFetching: isFetchingPayment} = useGetSubscriptionPaymentMethod();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<boolean>(false);
  const paymentMethodImage = getPaymentMethodImage(paymentMethod?.card?.brand);

  return (
    <AdminPageWrapper>
      {(isFetchingInvoices || isFetchingPayment || isFetchingOrg) && <FullPageLoader />}
      <AdminMenu />
      <Header />
      <OrganizationHeader organization={organization as IOrganization} title="My Subscription" />
      <AdminPage style={{maxWidth: '100%', overflowY: 'auto'}}>
        <AdminPageDividerWrapper>
          <div style={{flex: 3, margin: 0, padding: 0, minHeight: '100%', overflowY: 'scroll'}}>
            <AdminTable
              columns={columns}
              data={invoices || []}
              sortBy={[{id: 'created', desc: true}]}
              showAddButton={false}
              showHeader={false}
            />
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'column', gap: 28}}>
            <SidePanel style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 336}}>
              <H6 style={{padding: '28px', marginBottom: '0px', display: 'flex', justifyContent: 'space-between'}}>
                Payment Method
                <PencilIcon
                  style={{width: '24px', stroke: Theme.Colors.Oranges._000, cursor: 'pointer'}}
                  onClick={() => setShowPaymentMethodModal(true)}
                />
              </H6>

              <CardContainer>
                {!!paymentMethodImage ? <CardLogoImage src={paymentMethodImage} alt={paymentMethod?.card?.brand} /> : <div></div>}
                <CardTextContainer>
                  <CardNumberContainer>•••• •••• •••• {paymentMethod?.card?.last4 || '****'}</CardNumberContainer>
                  <CardNameContainer>{paymentMethod?.billing_details?.name || '----- -----'}</CardNameContainer>
                  <CardExpContainer>
                    {paymentMethod?.card?.exp_month || '--'}/{paymentMethod?.card?.exp_year || '----'}
                  </CardExpContainer>
                </CardTextContainer>
              </CardContainer>
              {/* </div> */}
            </SidePanel>
            <SidePanel style={{flex: 1, padding: '1rem', background: 'white', maxWidth: '500px'}}>
              <SubscriptionDetails showMembers showLocations />
            </SidePanel>
          </div>
        </AdminPageDividerWrapper>
      </AdminPage>
      <PaymentMethodModal
        isOpen={showPaymentMethodModal}
        onSubmit={() => setShowPaymentMethodModal(false)}
        onCancel={() => setShowPaymentMethodModal(false)}
      />
    </AdminPageWrapper>
  );
};
