import {ITractData} from '../API';
import {Theme} from '../Components';
import * as Datasets from './Datasets';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITractColorMap,
  ITribeValue,
} from './Datasets/Types';
import {cacheCountyData, cacheDownloadValue, clearCachedValues} from './DownloadManager';

// This is an array so we can provide a specific order
export const AllDatasets = [
  Datasets.EquityIndex,
  Datasets.Asthma,
  Datasets.EnergyBurden,
  Datasets.Above3EnergyBurden,
  Datasets.Above6EnergyBurden,
  Datasets.Above10EnergyBurden,
  Datasets.EvictionRate,
  Datasets.InsuranceStress,
  Datasets.Redlining,
  Datasets.HighLivingCost,
  Datasets.MedianHouseholdCosts,
  Datasets.IncomeStress,
  Datasets.LackOfInternet,
  Datasets.IndigenousBoundaries,
  Datasets.HousingType,
  Datasets.RenterVsOwner,
  Datasets.PhillyBuildings,
  Datasets.RacialComposition,
  Datasets.SolarEligibility,
  Datasets.TransportationBurden,
  Datasets.UrbanHeatIndex,
  Datasets.UrbanTreeCanopy,
];

/**
 * This creates a map of radio subset titles,
 * except for the "racial composition" dataset.
 */
export const RadioSubsetTitlesMap = AllDatasets.reduce(
  (
    prev: Record<string, any>,
    dataset:
      | IDatasetOptions<number>
      | IDatasetOptions<IDemographicValue>
      | IDatasetOptions<IRedliningValue>
      | IDatasetOptions<IBuildingValue>
      | IDatasetOptions<ITribeValue>,
  ) => {
    dataset?.subsets?.forEach((subset) => {
      if (
        dataset.subsetType === 'radio' &&
        ![Datasets.RacialComposition.id, Datasets.Redlining.id, Datasets.PhillyBuildings.id].includes(dataset.id)
      )
        prev[subset.id] = subset.title;
    });
    return prev;
  },
  {},
);

export const Colors2d = {
  L: {L: Theme.Colors.Cyans._400, M: Theme.Colors.Teals._400, H: Theme.Colors.Yellows._400},
  M: {L: Theme.Colors.Cyans._200, M: Theme.Colors.Teals._200, H: Theme.Colors.Yellows._200},
  H: {L: Theme.Colors.Cyans._000, M: Theme.Colors.Teals._000, H: Theme.Colors.Yellows._000},
};

export const Colors2dR1 = {
  H: {L: Theme.Colors.Cyans._400, M: Theme.Colors.Teals._400, H: Theme.Colors.Yellows._400},
  M: {L: Theme.Colors.Cyans._200, M: Theme.Colors.Teals._200, H: Theme.Colors.Yellows._200},
  L: {L: Theme.Colors.Cyans._000, M: Theme.Colors.Teals._000, H: Theme.Colors.Yellows._000},
};

export const Colors2dR2 = {
  L: {H: Theme.Colors.Cyans._400, M: Theme.Colors.Teals._400, L: Theme.Colors.Yellows._400},
  M: {H: Theme.Colors.Cyans._200, M: Theme.Colors.Teals._200, L: Theme.Colors.Yellows._200},
  H: {H: Theme.Colors.Cyans._000, M: Theme.Colors.Teals._000, L: Theme.Colors.Yellows._000},
};

export const Opacities2d = {
  L: {L: 0.15, M: 0.15, H: 0.15},
  M: {L: 0.5, M: 0.5, H: 0.5},
  H: {L: 1, M: 1, H: 1},
};

export const setMapData = async (map: any, data: ITractData, dataset1: IDatasetConfig, dataset2: IDatasetConfig) => {
  cacheCountyData(data.countyData);

  // This is an experiment, don't active it in prod yet
  // const [cityByLatLon] = data.citiesByLatLon;
  const cityByLatLon = null as any;
  if (cityByLatLon) {
    const cityUrl = `https://storage.googleapis.com/greenlink-prod-web-resources/geojson-files/cities/${cityByLatLon.GEOID}.json`;
    map?.getSource('city').setData(cityUrl);
  } else {
    // We're getting zero sometimes for the cityGeoId, so we're using the second one
    const closestCity = data?.cityGeoId?.[0]?.[0] && Number(data?.cityGeoId?.[0]?.[0]) ? data.cityGeoId[0] : data.cityGeoId[1];

    if (closestCity?.[0] && Number(closestCity[0])) {
      try {
        const cityUrl = `https://storage.googleapis.com/greenlink-prod-web-resources/geojson-files/cities/${closestCity[0]}.json`;
        map?.getSource('city').setData(cityUrl);
      } catch (e) {
        // NOP
      }
    }
  }

  // map?.getSource('tracts').setData(data.tractsUrl);

  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);

  clearCachedValues();
  (data.values || []).forEach((row) => {
    const key = row.tractId || row.stateCountyId || '';
    cacheDownloadValue(key, 'Tract Name', row.tractName || row.countyName + ', ' + row.stateName, row);
  });

  let tractColors = {fills: [], opacities: []} as ITractColorMap;
  if (ds2) {
    const tractBuckets1 = ds1?.getDoubleSetBuckets(dataset1, data.values);
    const tractBuckets2 = ds2?.getDoubleSetBuckets(dataset2, data.values);

    Object.entries(tractBuckets1?.values || {}).forEach(([tractId, level1]) => {
      const level2 = tractBuckets2.values[tractId];
      tractColors.fills.push(tractId);
      tractColors.opacities.push(tractId);
      tractColors.fills.push(Colors2d[level1][level2]);
      tractColors.opacities.push(1);
    });
  } else {
    tractColors = ds1?.getSingleSetColors(dataset1, data.values) || {fills: [], opacities: []} || [];
  }

  if (tractColors.fills.length > 0 && tractColors.opacities.length > 0) {
    map?.setPaintProperty('tract-fills', 'fill-color', ['match', ['get', 'GEOID'], ...tractColors.fills, 'rgba(0,0,0,0)']);
    map?.setPaintProperty('tract-fills', 'fill-opacity', ['match', ['get', 'GEOID'], ...tractColors.opacities, 0]);
  } else {
    map?.setPaintProperty('tract-fills', 'fill-color', 'rgb(0,0,0)');
    map?.setPaintProperty('tract-fills', 'fill-opacity', 0.01);
  }
};
