import {useAuth0} from '@auth0/auth0-react';
import {FC, useState} from 'react';
import {toast} from 'react-hot-toast';
import styled from 'styled-components';
import {setAccessToken, useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {useCancelMySubscription} from '../../API/Mutations/useCancelMySubscription';
import {BoldSubhead1, FlexRow, H6, Modal, StandardButton, TextField, Theme} from '../../Components';
import {SubscriptionDetailRow} from '../../Components/Molecules/SubscriptionDetail';
import {calcSingleLocationCost, calculateUserCost, formatTractCount, getTractCount} from '../../Managers/MathUtils';
import {DEFAULT_SUBSCRIPTION_TYPE} from '../../constants/constants';
import {formatAuthorizationTitle, productLessProductList} from '../Admin/adminUtils';
import {Divider} from '../Onboarding/Layout';
import {TotalsFromInvoice} from '../Onboarding/Payment/TotalsFromInvoice';
import {getUniqueAuthorizations} from '../Onboarding/getUniqueAuthorizations';
import {resetPersist} from '../../store';
import {useMyProfile} from '../../API/Queries/useMyProfile';

const userOrUsers = (numberOfUsers: number) => {
  return numberOfUsers === 1 ? 'user' : 'users';
};

const ModalText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`;

const ModalTextBox = styled(TextField)`
  border-color: ${Theme.Colors.Neutrals._400};
  margin: 16px 0px;
`;

interface ISubscriptionDetailsProps {
  showMembers: boolean;
  showLocations: boolean;
}

export const SubscriptionDetails: FC<ISubscriptionDetailsProps> = ({showMembers, showLocations}: ISubscriptionDetailsProps) => {
  const {data: myProfile} = useMyProfile();
  const getOrganization = useGetMyOrganization(!!myProfile?.user?.organizationId);
  const organization = getOrganization?.data;
  const getSubscriptionPricing = useSubscriptionPricing();
  const [cancelModalState, setCancelModalState] = useState<{open: boolean; reason: string}>({open: false, reason: ''});
  const cancelMySubscription = useCancelMySubscription();
  const subscriptionTypes = getSubscriptionPricing?.data?.subscriptionTypes;
  const subscriptionTierPricing = subscriptionTypes?.[organization?.subscription_type || DEFAULT_SUBSCRIPTION_TYPE];
  const {logout} = useAuth0();

  const users = organization?.users || [];

  const authorizations = getUniqueAuthorizations(organization?.authorizations || []);

  const handleCancelSubscription = async () => {
    try {
      await cancelMySubscription.mutateAsync({reason: cancelModalState.reason});
      toast.success('Subscription successfully cancelled.');

      setAccessToken(null);
      logout({returnTo: window.location.origin + '/'});
      resetPersist();
    } catch (error) {
      toast.error('Error cancelling subscription.');
    }
  };

  const numberOfAdmins = users?.filter((user) => user.user_type === 'org-admin').length || 0;

  return (
    <>
      <H6 style={{padding: '28px', marginBottom: '0px'}}>Subscription Details</H6>

      <div style={{flexGrow: 1, overflowY: 'auto'}}>
        <Divider />

        {showMembers && (
          <>
            <div style={{padding: '16px 28px', gap: 8}}>
              <BoldSubhead1 style={{marginBottom: '0px'}}>Members</BoldSubhead1>

              <SubscriptionDetailRow
                label={numberOfAdmins + ' Admin ' + userOrUsers(numberOfAdmins)}
                cost={calculateUserCost(numberOfAdmins, subscriptionTierPricing)}
              />

              <SubscriptionDetailRow
                label={users?.length - numberOfAdmins + ' Default ' + userOrUsers(users.length - numberOfAdmins)}
                cost={calculateUserCost(users?.length - numberOfAdmins, subscriptionTierPricing)}
              />
            </div>
          </>
        )}

        {showLocations && (
          <>
            <Divider />

            <div style={{padding: '16px 28px', gap: 8}}>
              <BoldSubhead1 style={{marginBottom: '0px'}}>Locations</BoldSubhead1>

              {[...authorizations]
                .sort((a, b) => {
                  if (a.targetType === b.targetType) {
                    return a.targetId.localeCompare(b.targetId);
                  } else {
                    return a.targetType.localeCompare(b.targetType);
                  }
                })
                .map((auth) => {
                  const authPrice =
                    calcSingleLocationCost(auth, getSubscriptionPricing?.data, organization?.subscription_type || 'standard') || 0;

                  return (
                    <SubscriptionDetailRow
                      key={`${auth.targetType}:${auth.targetId}`}
                      cost={authPrice}
                      header={formatAuthorizationTitle(auth, productLessProductList)}
                      label={
                        auth.targetType === 'nationwide'
                          ? 'All tracts'
                          : formatTractCount(getTractCount(auth, getSubscriptionPricing?.data))
                      }
                    />
                  );
                })}
            </div>
          </>
        )}
      </div>

      <div style={{padding: '16px 28px', flexGrow: 0, justifyContent: 'flex-end'}}>
        <FlexRow style={{marginBottom: '18px', alignItems: 'center'}}>
          <TotalsFromInvoice hideItems stripeSubscription={organization?.stripeSubscription} />
        </FlexRow>

        <FlexRow>
          <StandardButton
            type="button"
            style={{flex: 1, marginRight: 8, fontSize: '14px', fontWeight: 600}}
            outline
            onClick={() => setCancelModalState({open: true, reason: ''})}>
            Cancel Subscription
          </StandardButton>
        </FlexRow>
      </div>

      <Modal
        title="Cancel Subscription?"
        isOpen={cancelModalState.open}
        button1Outline
        button1Text="Keep Subscription"
        button2Outline={false}
        button2Text="Confirm"
        button1Action={() => setCancelModalState({open: false, reason: ''})}
        button2Action={() => handleCancelSubscription()}>
        <div style={{marginBottom: 28}}>
          <ModalText>
            Leaving so soon? We value your thoughts and would like to hear any feedback you have. If there's anything we can improve, please
            let us know.
          </ModalText>

          <ModalTextBox
            placeholder="Reason for cancellation"
            value={cancelModalState.reason}
            onChange={(e) => setCancelModalState({...cancelModalState, reason: e.target.value})}
          />

          <ModalText>
            Are you sure you want to cancel your subscription?. To cancel, click 'Confirm.' To continue your subscription, click 'Keep My
            Subscription'.
          </ModalText>
        </div>
      </Modal>
    </>
  );
};
