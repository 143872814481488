import {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import {BasicCheckbox, FilterSlider, RadioButton, TooltipTrigger} from '../../../../Components';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../../../Managers/Datasets/Types';
import {AllDatasets} from '../../../../Managers/MapManager';
import {useAppSelector} from '../../../../store';

interface Props {
  dataset: IDatasetConfig;
  options: IDatasetOptions<number | IDemographicValue | IRedliningValue | IBuildingValue | ITribeValue>;
  onUpdateDataset: (dataset: IDatasetConfig) => void;
}

const Container = styled.div``;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Checkbox = styled(BasicCheckbox)`
  margin: 0;
`;

const Radio = styled(RadioButton)`
  margin: 0;
`;

const Slider = styled(FilterSlider)`
  margin: 0;
  padding: 0 0 0 28px;
`;

export const SubsetRadioSliders: FC<Props> = ({dataset, options, onUpdateDataset}) => {
  const mapData = useAppSelector((state) => state.map.mapData);

  const handleSetSubset = (subset: string) => {
    const datasetCopy = {...dataset};
    datasetCopy.subsets = subset ? [subset] : [];
    onUpdateDataset(datasetCopy);
  };

  const handleSetSliderValue = (value: number, index: number) => {
    const tempValues = [...dataset.radioSliderValues];
    tempValues[index] = value;
    onUpdateDataset({...dataset, radioSliderValues: tempValues});
  };

  const ds1 = AllDatasets.find((d) => d.id === dataset.id) || AllDatasets[0];

  return (
    <Wrapper>
      <Container>
        {options.subsets?.map((subset, index) => (
          <TooltipTrigger data-tip={subset.description || ''} data-for="radio-tooltips" key={index}>
            <>
              {subset?.selectorType === 'checkbox' && (
                <>
                  <Checkbox key={subset.id} name={subset.id} label={subset.title} style={{marginBottom: 16}} />
                </>
              )}
            </>
          </TooltipTrigger>
        ))}
      </Container>
      <Container>
        {options.subsets?.map((subset, index) => {
          const minMax = ds1.getMinMax(dataset, mapData?.values || [], subset?.id);
          return (
            <TooltipTrigger data-tip={subset.description || ''} data-for="radio-tooltips" key={index}>
              <>
                {subset?.selectorType === 'slider' && (
                  <div
                    onClick={() => handleSetSubset(subset.id)}
                    onTouchStart={() => handleSetSubset(subset.id)}
                    onMouseDown={() => handleSetSubset(subset.id)}>
                    <Radio
                      key={subset.id}
                      name={dataset.id + '-subset'}
                      value={subset.id}
                      label={subset.title}
                      onChange={handleSetSubset}
                    />
                    <Slider
                      formatter={(value: any) => ds1.formatValue({...dataset, subsets: [subset.id]}, value)}
                      min={minMax[0]}
                      max={minMax[1]}
                      isInverted={subset.isInverted}
                      onChange={(val) => handleSetSliderValue(val, index)}
                      value={dataset.cutoff1}
                    />
                  </div>
                )}
              </>
            </TooltipTrigger>
          );
        })}
      </Container>
      <ReactTooltip
        id="radio-tooltips"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 8}}
        wrapper="span"
        className="info-tooltip"
      />
    </Wrapper>
  );
};
