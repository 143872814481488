import {createContext} from 'react';
import mixpanel from 'mixpanel-browser';
import {getConfig} from '../../getConfig';
import {useIdleTimer} from 'react-idle-timer';

const mixpanelContext = createContext();
const config = getConfig();

const MixpanelContextProvider = ({debug, children}) => {
  mixpanel.init(config.mixpanel, {debug});

  const timer = useIdleTimer({
    debounce: 500,
    timeout: 600000,
    onIdle: () => {
      const duration = timer.getTotalActiveTime();
      console.log('IDLE', duration);
      mixpanel.track('ActiveInteraction', {duration});
      timer.reset();
    },
    onActive: (e) => {
      console.log('ACTIVE', e);
    },
  });

  return <mixpanelContext.Provider value={{mixpanel}}>{children}</mixpanelContext.Provider>;
};

export {mixpanelContext, MixpanelContextProvider};
