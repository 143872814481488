import {FC, useEffect} from 'react';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {IAuthorization, IOrganization} from '../../API';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper} from './Layout';
import {OnboardingSteps} from './OnboardingSteps';
import {OrganizationLocationsPanels} from './OrganizationLocationsPanels';

export interface IAuthCondensed {
  targetId: string;
  targetType: string;
}

export interface IProductCondensed {
  id: string;
  name: string;
}

export const OrganizationLocations: FC = () => {
  const navigate = useNavigate();
  const {organization, updateCartAuthorizations} = useCartOrganization();

  const handleSubmitLocations = async (values: IOrganization) => {
    try {
      updateCartAuthorizations(values?.authorizations as IAuthorization[]);
      toast.success('Locations have been successfully updated!');
    } catch (error) {
      console.log('Error saving organization', error);
    }
  };

  useEffect(() => {
    if (!organization) {
      navigate(routePaths.onboardingOrganization);
    }
  }, [organization]);

  return (
    <OnboardingPageWrapper>
      <OnboardingPage style={{maxWidth: 1000}}>
        <OrganizationLocationsPanels
          hideDefaultPosition
          onSubmitLocations={handleSubmitLocations}
          organization={organization as IOrganization}
        />
      </OnboardingPage>
      <OnboardingSteps />
    </OnboardingPageWrapper>
  );
};
