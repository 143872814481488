import {FC} from 'react';
import {IOrganization} from '../../API';
import {FlexRow, H4} from '../../Components';

export const OrganizationHeader: FC<{organization: IOrganization; title?: string}> = ({organization, title}) => {
  return (
    <FlexRow style={{flex: 0, alignItems: 'flex-start', justifyContent: 'space-between'}}>
      <H4 style={{margin: 0}}>{title || organization?.name}</H4>
    </FlexRow>
  );
};
