import {useState} from 'react';
import {IAuthorization} from '../../API';
import {Authorization} from './AuthorizationCard';

/**
 * Hooks and callbacks related to th <Authorization /> component.
 */
const useAuthorizations = (authorizations: IAuthorization[] | undefined) => {
  const [addAuths, setAddAuths] = useState<Authorization[]>([]);
  const [removeAuths, setRemoveAuths] = useState<Authorization[]>([]);
  const [saveable, setSaveable] = useState(false);

  const authsArrayContains = (arr: IAuthorization[] = [], test: Authorization) =>
    arr.findIndex((auth) => auth.targetType === test.targetType && auth.targetId === test.targetId) > -1;

  const handleAddAuth = (value: any) => {
    const [targetType, targetId] = (value || '').split(':');
    const toAdd = {targetType, targetId} as Authorization;
    if (targetType && targetId && !authsArrayContains(addAuths as IAuthorization[], toAdd) && !authsArrayContains(authorizations, toAdd)) {
      setAddAuths([...addAuths, {targetType, targetId}]);
      setSaveable(true);
    }

    setRemoveAuths(removeAuths.filter((auth) => auth.targetId !== targetId || auth.targetType !== targetType));
  };

  const handleRemoveAuth = (remove: Authorization) => {
    const tempAddAuths = [...addAuths];
    const existingAddIndex = tempAddAuths.findIndex((auth) => auth.targetType === remove.targetType && auth.targetId === remove.targetId);
    if (existingAddIndex > -1) {
      tempAddAuths.splice(existingAddIndex, 1);
      setAddAuths(tempAddAuths);
    }
    setSaveable(true);
    setRemoveAuths([...removeAuths, remove]);
  };

  const resetAuths = () => {
    setAddAuths([]);
    setRemoveAuths([]);
  };

  return {addAuths, handleAddAuth, handleRemoveAuth, removeAuths, resetAuths, saveable};
};

export default useAuthorizations;
