import {useMemo} from 'react';
import styled from 'styled-components';
import {IOrganization, useAdminOrganizations} from '../../API';
import {Body2, BoldSubhead1, H6, Theme} from '../../Components';
import {AdminPanel} from './Layout';

const Header = styled(BoldSubhead1)`
  margin-bottom: 8px;
`;

const SummaryDetailBlock = styled.div`
  // display: flex;
  // flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 1;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;
  min-width: 120px;
  max-width: 120px;
  max-height: 120px;
  border-radius: 50%;
  border: 2px dashed ${Theme.Colors.Blues._000};
  margin-bottom: 16px;
`;

const SummaryDetail = ({label, value}: {label: string; value?: string}) => {
  return (
    <SummaryDetailBlock>
      <Body2 style={{marginBottom: 4}}>
        <span style={{}}>{label}</span>: <span style={{fontWeight: 'bold', fontSize: 16}}>{value || '-'}</span>
      </Body2>
    </SummaryDetailBlock>
  );
};

const SummaryBigDetail = ({label, value}: {label: string; value?: string | number}) => {
  return (
    <SummaryDetailBlock>
      <Header>{label} </Header>
      <Circle>
        <H6 style={{margin: 0, fontSize: 32}}>{value || '-'} </H6>
      </Circle>
    </SummaryDetailBlock>
  );
};

export const AdminSummaryBlock = () => {
  const {data: organizations} = useAdminOrganizations(true);
  const activeOrgs = useMemo(() => organizations?.filter((u) => !!u.subscription), [organizations])?.length;

  const noSubscriptions = useMemo(() => organizations?.filter((u) => !u.subscription)?.length, [organizations]);

  const subscriptionTypeCounts = useMemo(() => {
    return organizations?.reduce((acc: any, o: IOrganization) => {
      if (o.subscription?.type) {
        acc[o.subscription?.type] = (acc[o.subscription?.type] || 0) + 1;
      }
      return acc;
    }, {});
  }, [organizations]);

  // collect unique counts of subscription types using reduce
  const subscriptionStatusCounts = useMemo(() => {
    return organizations?.reduce((acc: any, o: IOrganization) => {
      if (o.subscription?.status) {
        acc[o.subscription?.status] = (acc[o.subscription?.status] || 0) + 1;
      }
      return acc;
    }, {});
  }, [organizations]);

  return (
    <AdminPanel style={{overflow: 'unset', flex: 0}}>
      <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 24}}>
        <SummaryDetailBlock>
          <Header>Subscription Types</Header>
          {Object.keys(subscriptionTypeCounts || {}).map((key) => {
            return <SummaryDetail label={key} value={subscriptionTypeCounts[key]} />;
          })}
        </SummaryDetailBlock>

        <SummaryDetailBlock>
          <Header>Subscription Status</Header>
          {Object.keys(subscriptionStatusCounts || {}).map((key) => {
            return <SummaryDetail label={key} value={subscriptionStatusCounts[key]} />;
          })}
        </SummaryDetailBlock>

        <SummaryBigDetail label="Total Organizations" value={organizations?.length?.toString()} />
        <SummaryBigDetail label="Active Subscriptions" value={activeOrgs} />
        <SummaryBigDetail label="No Subscriptions" value={noSubscriptions} />
      </div>
    </AdminPanel>
  );
};
