import React from 'react';
import {Body1, FlexRow, Theme} from '..';
import {ToastBar, Toaster} from 'react-hot-toast';
import {CheckCircleIcon, ExclamationIcon, XCircleIcon} from '@heroicons/react/outline';

export const ToastIcon = ({type = ''}) => {
  switch (type) {
    case 'success':
      return <CheckCircleIcon style={{width: 24, minWidth: 24, color: Theme.Colors.Greens._000}} />;
    case 'error':
      return <XCircleIcon style={{width: 24, minWidth: 24, color: Theme.Colors.Reds._000}} />;
    default:
      return <ExclamationIcon style={{width: 24, minWidth: 24, color: Theme.Colors.Yellows._000}} />;
  }
};

export const CustomToaster = () => {
  return (
    <Toaster
      containerClassName="toast-container"
      toastOptions={{
        duration: 4000,
        style: {
          background: Theme.Colors.Yellows._300,
          borderRadius: 24,
          minWidth: 600,
          padding: 24,
          boxShadow: '0px 12px 24px 0px rgba(176, 184, 192, 0.30)',
          flexDirection: 'column',
          marginLeft: -32,
        },
        error: {
          duration: 7000,
          style: {
            background: Theme.Colors.Reds._300,
            color: 'white',
          },
        },
        success: {
          style: {
            background: Theme.Colors.Greens._300,
            color: 'white',
          },
        },
      }}>
      {(t) => (
        <ToastBar toast={t}>
          {({icon, message}) => (
            <FlexRow style={{alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', gap: 24}}>
              <Body1 style={{margin: 0}}>{message}</Body1>
              <ToastIcon type={t.type} />
            </FlexRow>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
