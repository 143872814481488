import {useAuth0} from '@auth0/auth0-react';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {IOrganization, IUser} from '../../API';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper} from './Layout';
import {OnboardingSteps} from './OnboardingSteps';
import {OrganizationMembersPanels} from './OrganizationMembersPanels';


export const OrganizationMembers: FC = () => {
  const {organization: cartOrganization = {} as IOrganization, updateCartUsers, updateCartOrganization} = useCartOrganization();
  const navigate = useNavigate();
  const {user} = useAuth0();

  useEffect(() => {
    if (!cartOrganization) {
      navigate(routePaths.onboardingOrganization);
    }
  }, []);

  // add my email if not already in the list
  useEffect(() => {
    if (user?.email) {
      const userExists = cartOrganization?.users?.find((u) => u.email === user?.email);

      if (!userExists) {
        updateCartOrganization({
          ...cartOrganization,
          users: [...(cartOrganization?.users || []), {email: user?.email, user_type: 'org-admin'} as IUser],
        });
      }
    }
  }, [user?.email]);

  const handleSubmit = async (values: any) => {
    try {
      const usersToUpdate = values?.users?.map((user: IUser) => ({
        ...user,
        user_status: 'inactive',
      }));

      updateCartUsers(usersToUpdate);
    } catch (error) {
      console.log('Error saving organization', error);
    }
  };

  return (
    <OnboardingPageWrapper>
      <OnboardingPage style={{maxWidth: 1000}}>
        <OrganizationMembersPanels onSubmit={handleSubmit} organization={cartOrganization as IOrganization} />
      </OnboardingPage>
      <OnboardingSteps />
    </OnboardingPageWrapper>
  );
};
