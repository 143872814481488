import {FC, useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import {Body2} from '../Atoms';

const Wrapper = styled.div`
  flex-direction: row;
  margin: 16px 0 0;
  height: 20px;
  width: 100%;
  flex: 0;
`;

const Value = styled(Body2)`
  flex: 0;
`;

const CurrentValue = styled(Body2)`
  flex: 0;
  text-align: center;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  height: 8px;
  flex: 1;
  margin: 6px 12px 0 12px;
  border-radius: 4px;
  background: ${(props) => props.theme.Colors.Neutrals._500};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.Colors.Oranges._000};
    border-radius: 100%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.Colors.Oranges._000};
    border-radius: 100%;
    cursor: pointer;
  }
`;

const Anchor = styled.a`
  cursor: pointer;
`;

export interface FilterSliderProps {
  formatter: (value: number) => string;
  min: number;
  max: number;
  value: number;
  tooltip?: string;
  isInverted?: boolean;
  onChange: (value: number) => void;
}

export const FilterSlider: FC<FilterSliderProps> = ({formatter, tooltip, min, max, value, onChange, isInverted, ...props}) => {
  // Debounce the onChange event so we don't redraw the map until the user lets go of the control
  const [val, setVal] = useState<number>(value > 0 ? Math.round((value - min) / (max - min)) : 0);

  // We do this 1..100 because native input-range controls don't handle very small decimals very well. They work if the range is say
  // 12..73, but not if it's 0.031..0.047.
  const extraProps = tooltip ? ({'data-tip': tooltip} as any) : ({} as any);

  // Need to reset the slider and value when changing indicators
  useEffect(() => {
    if (!value) {
      setVal(isInverted ? 100 : 0);
      onChange(isInverted ? max : min);
    }
  }, [min, max]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(+e.target.value);
    onChange(min + (+e.target.value / 100) * (max - min));
  };

  // TODO: All tooltips need their own ID!
  return (
    <Anchor {...extraProps} data-for="filter-slider-1">
      <Wrapper {...props}>
        <Value>{formatter(isInverted ? max : min)}</Value>
        <Slider
          type="range"
          style={{direction: isInverted ? 'rtl' : 'ltr'}}
          min={0}
          max={100}
          value={val}
          onChange={handleChange}
          // onMouseUp={() => onChange(min + (val / 100) * (max - min))}
        />
        <Value>{formatter(isInverted ? min : max)}</Value>
      </Wrapper>
      <CurrentValue>{formatter(min + (val / 100) * (max - min))}</CurrentValue>
      <ReactTooltip
        id="filter-slider-1"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: -7, left: -5}}
        wrapper="span"
        className="info-tooltip"
      />
    </Anchor>
  );
};
