import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/outline';
import {FlexFill} from '../../../Components';

export const Wrapper = styled.div`
  flex: 0;
  margin: 16px 0 0 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PagerButton = styled.button`
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  background: transparent;
`;

export const PageNumber = styled.div`
  flex: 0;
  font-size: 14px;
  padding: 0 16px;
  display: inline-block;
  white-space: nowrap;
`;

interface PaginationProps {
  previousPage: any;
  canPreviousPage: any;
  pageIndex: number;
  pageOptions: any;
  nextPage: any;
  canNextPage: any;
}

export const Pagination: FC<PaginationProps> = ({previousPage, canPreviousPage, pageIndex, pageOptions, nextPage, canNextPage}) => {
  return (
    <Wrapper>
      <FlexFill />
      <PagerButton onClick={() => previousPage()} disabled={!canPreviousPage}>
        <ChevronLeftIcon />
      </PagerButton>
      <PageNumber>
        Page {pageIndex + 1} of {pageOptions.length}
      </PageNumber>
      <PagerButton onClick={() => nextPage()} disabled={!canNextPage}>
        <ChevronRightIcon />
      </PagerButton>
      <FlexFill />
    </Wrapper>
  );
};
