import {Formik} from 'formik';
import React, {FC, useEffect} from 'react';
import styled from 'styled-components/macro';
import {SubsetCheckbox, SubsetEquitySlider, SubsetRadio, SubsetRadioSliders} from './SubsetControls';
import {IDatasetConfig} from '../../../Managers/Datasets/Types';
import {AllDatasets} from '../../../Managers/MapManager';
import {Subhead2} from '../../../Components';
import {useIndicatorOptions} from '../../../API/Queries/useIndicatorOptions';
import {EquityIndex} from '../../../Managers/Datasets';
import {VariantCheckbox} from './SubsetControls/VariantCheckbox';

const Wrapper = styled.div`
  margin: 28px 0 12px 0;
`;

const NoDataWrapper = styled(Subhead2)`
  text-align: center;
  margin-top: 1em;
`;

const FieldLabel = styled(Subhead2)`
  margin: 0 0 16px;
`;

interface SubsetsProps {
  dataset: IDatasetConfig;
  indicators?: string[];
  onUpdateDataset: (dataset: IDatasetConfig) => void;
}

export const Subsets: FC<SubsetsProps> = ({dataset, onUpdateDataset}) => {
  // We need to limit equity index indicators to configired indicators
  const {indicators} = useIndicatorOptions();
  const Datasets = [{...AllDatasets[0]}, ...AllDatasets.slice(1)];
  const eIndexSubsets = Datasets[0].subsets?.filter((subset) => indicators.includes(subset.id));
  Datasets[0].subsets = eIndexSubsets;

  const options = Datasets.find((ds) => ds.id === dataset.id);

  const subsets = (options?.subsets || []).map((subset) => subset.id);

  const subsetVariants = (options?.subsetVariants || []).map((variant) => variant.id);

  useEffect(() => {
    if (options?.subsetType === 'checkbox' && (!dataset.subsets || !dataset.subsets.length)) {
      onUpdateDataset({...dataset, subsets});
    }
  }, [dataset.subsets, subsets]);

  useEffect(() => {
    if (options?.subsetVariantsType === 'checkbox' && (!dataset.subsetVariants || !dataset.subsetVariants.length)) {
      onUpdateDataset({...dataset, subsetVariants});
    }
  }, [dataset.subsets, subsets]);

  // The user has not yet selected a dataset for this indicator
  if (dataset.id === EquityIndex.id && (!options || !options.subsets || !options.subsets.length)) {
    return <NoDataWrapper>Select at least 2 indicators</NoDataWrapper>;
  }

  const initialValues = {} as Record<string, string | boolean>;
  if (options?.subsetType === 'radio' || options?.subsetType === 'radioSlider') {
    initialValues[dataset.id + '-subset'] = dataset.subsets?.[0] || '';
  } else {
    options?.subsets?.forEach((subset) => {
      initialValues[subset.id] = dataset.subsets?.includes(subset.id) || false;
    });
  }

  if (options?.subsetVariantsType === 'checkbox') {
    options?.subsetVariants?.forEach((subset) => {
      initialValues[subset.id] = dataset.subsetVariants?.includes(subset.id) || false;
    });
  }

  return (
    <Wrapper>
      <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={() => {}}>
        <>
          {!!options?.subsetTitle && <FieldLabel>{options?.subsetTitle}</FieldLabel>}
          {options?.subsetVariantsType === 'checkbox' ? (
            <VariantCheckbox dataset={dataset} options={options} onUpdateDataset={onUpdateDataset} />
          ) : (
            <></>
          )}
          {options?.subsetType === 'radio' ? <SubsetRadio dataset={dataset} options={options} onUpdateDataset={onUpdateDataset} /> : <></>}
          {options?.subsetType === 'checkbox' ? (
            <SubsetCheckbox dataset={dataset} options={options} onUpdateDataset={onUpdateDataset} />
          ) : (
            <></>
          )}
          {options?.subsetType === 'equitySlider' ? (
            <SubsetEquitySlider dataset={dataset} options={options} onUpdateDataset={onUpdateDataset} />
          ) : (
            <></>
          )}
          {options?.subsetType === 'radioSlider' ? (
            <SubsetRadioSliders dataset={dataset} options={options} onUpdateDataset={onUpdateDataset} />
          ) : (
            <></>
          )}
        </>
      </Formik>
    </Wrapper>
  );
};
