import {formatPercent, getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';

const getValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  return config.subsets?.[0] === 'renterOccupied' ? row.renterOccupied : row.ownerOccupied;
};

export const RenterVsOwner: IDatasetOptions<number> = {
  id: 'renterVsOwner',
  title: 'Housing: Renter or Owner',
  downloadTitle: 'Percent Renter Occupied',
  description: 'Shows the median percentage of owner-occupied households compared to renter-occupied households.',
  combinable: true,
  subsetType: 'radio',
  subsets: [
    // {
    //   id: 'all',
    //   title: 'All',
    //   description: 'Highlights the majority renter vs. owner group for each area.',
    //   type: '1D',
    // },
    {
      id: 'renterOccupied',
      title: 'Renter Occupied',
      description: 'Shows the relative minority/majority of renters as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'ownerOccupied',
      title: 'Owner Occupied',
      description: 'Shows the relative minority/majority of owners as compared to the entire region.',
      type: 'Both',
    },
  ],
  defaultSubsets: ['renterOccupied'],
  defaultSubsets2D: ['renterOccupied'],

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => formatPercent(value),

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) =>
    getMinMax(getAllValues(config, rows, getValue, RenterVsOwner.downloadTitle)),

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = getAllValues(config, rows, getValue, RenterVsOwner.downloadTitle);
    const [min, max] = getMinMax(values);

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      map.fills.push(String(tractId));
      map.fills.push(fillBuckets[getValueBucket(value, config.cutoff1 || min, max, opacityBuckets.length)]);
      map.opacities.push(String(tractId));
      map.opacities.push(1);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    const values = getAllValues(config, rows, getValue, RenterVsOwner.downloadTitle);
    const [min, max] = getMinMax(values);
    const floor = config.cutoff1 || min;
    const lCutoff = floor + (max - min) * 0.2;
    const hCutoff = floor + (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (value <= lCutoff) {
        map.values[tractId] = 'L';
      } else if (value >= hCutoff) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
