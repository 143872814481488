import {CheckCircleIcon} from '@heroicons/react/outline';
import {Field, useField} from 'formik';
import {FC} from 'react';
import {Theme} from '../../Components';

export const CardRadio: FC<{
  id?: string;
  currentValue: string;
  value?: string;
  name: string;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
}> = ({currentValue, value, name, title, subtitle, children, id, disabled, onClick}) => {
  const [, meta] = useField(name);
  const hasError = !!meta.error && meta.touched;

  const handleClick = (e: any) => {
    onClick?.(e.target.value);
  };

  return (
    <div className="card-radio" style={{position: 'relative'}}>
      <Field type="radio" name={name} value={value} id={id} required disabled={disabled} onClick={handleClick} />

      <label htmlFor={id} style={{flex: 1, justifyContent: 'flex-start'}}>
        {children}

        <span className="description">
          <h5 className="title">{title}</h5>

          <span className="subtitle">{subtitle}</span>
        </span>

        {currentValue === value && (
          <CheckCircleIcon
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              width: 24,
              color: Theme.Colors.Oranges._000,
            }}
          />
        )}
      </label>

      {hasError && <div className="error">{meta.error}</div>}
    </div>
  );
};
