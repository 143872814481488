import {useMutation, useQueryClient} from 'react-query';
import {createProduct} from '../Calls/Admin';

export const useCreateProduct = () => {
  const qc = useQueryClient();

  return useMutation(createProduct, {
    onSuccess: (params) => {
      console.log('Creating product', params);
      qc.invalidateQueries(['ADMIN', 'PRODUCTS']).catch(() => {});
    },

    onError: (err: Error) => {
      console.log('Error creating product', err.message);
    },
  });
};
