import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC} from 'react';
import toast from 'react-hot-toast';
import {IOrganization, useCreateOrganization} from '../../API';
import {requireAdmin} from '../../Managers/AuthUtils';
import {useNavigate} from 'react-router-dom';
import {routePaths} from '../../constants/routePaths';
import {OrganizationForm} from '../Onboarding/OrganizationForm';
import {AdminPage, AdminPageWrapper, AdminPanel} from './Layout';
import {AdminMenu} from './AdminMenu';
import {Header} from '../Map/Header';
import {H4} from '../../Components';
import {useRoles} from '../../Hooks/useRoles';

const CreateOrganizationComponent: FC = () => {
  const createOrganization = useCreateOrganization();
  const navigate = useNavigate();

  const {isAdmin} = useRoles();

  const handleCreateOrg = async (values: IOrganization) => {
    if (isAdmin) {
      values.primary_email = null;
    }

    await createOrganization
      .mutateAsync(values)
      .then((r) => {
        console.log('save result', r);
        toast.success('Organization has been successfully created!');
        navigate(routePaths.adminOrganizationsById.replace(':organizationId', r?.id));
      })
      .catch((e) => {
        console.log('save error', e);
        toast.error('There was a problem creating the organization!');
      });
  };

  return (
    <AdminPageWrapper>
      <AdminMenu />

      <Header />

      <AdminPage>
        <H4>Create Organization</H4>

        <AdminPanel>
          <OrganizationForm enableTypeSelection handleSaveOrg={handleCreateOrg} submitType="save" />
        </AdminPanel>
      </AdminPage>
    </AdminPageWrapper>
  );
};

export const CreateOrganization: FC = withAuthenticationRequired(CreateOrganizationComponent, {claimCheck: requireAdmin});
