import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';

const getValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  return row.heatIndex;
};

export const UrbanHeatIndex: IDatasetOptions<number> = {
  id: 'urbanHeatIndex',
  title: 'Urban Heat Index',
  downloadTitle: 'Urban Heat Index',
  description:
    'The urban heat index translates a census tract’s temperatures into a severity score relative to other tracts in the metro area.',
  combinable: true,

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => {
    return value ? `${value.toFixed(2)}` : '---';
  },

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) =>
    getMinMax(getAllValues(config, rows, getValue, UrbanHeatIndex.downloadTitle), true),

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = getAllValues(config, rows, getValue, UrbanHeatIndex.downloadTitle);
    const [min, max] = getMinMax(values);

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      if (value) {
        map.fills.push(String(tractId));
        map.fills.push(fillBuckets[getValueBucket(value, config.cutoff1 || min, max, opacityBuckets.length)]);
        map.opacities.push(String(tractId));
        map.opacities.push(1);
      }
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    const values = getAllValues(config, rows, getValue, UrbanHeatIndex.downloadTitle);
    const [min, max] = getMinMax(values);
    const floor = config.cutoff1 || min;
    const lCutoff = floor + (max - min) * 0.2;
    const hCutoff = floor + (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (value <= lCutoff) {
        map.values[tractId] = 'L';
      } else if (value >= hCutoff) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
