import styled from 'styled-components/macro';

export const H1 = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 96px;
  line-height: 124px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const H2 = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 60px;
  line-height: 76px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const H3 = styled.h3`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const H4 = styled.h4`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 34px;
  line-height: 44px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const H5 = styled.h5`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const H6 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Subhead1 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const BoldSubhead1 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Subhead2 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Subhead3 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Subhead4 = styled.h6`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Body1 = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Body2 = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Body3 = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

export const Body4 = styled.p`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin: 0 0 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;
