import {useMutation, useQueryClient} from 'react-query';
import {previewUpcomingInvoice} from '../Calls/Onboarding';

export const usePreviewUpcomingInvoice = () => {
  const qc = useQueryClient();

  return useMutation(previewUpcomingInvoice, {
    onSuccess: (params) => {
      // qc.invalidateQueries(['ADMIN', 'ORGANIZATIONS']).catch(() => {});
    },

    onError: (err: Error) => {
      console.log('Error previewing invoice', err.message);
    },
  });
};
