import {useMutation, useQueryClient} from 'react-query';
import {createMySubscription} from '../Calls/OrgAdmin';

export const useCreateMySubscription = () => {
  const qc = useQueryClient();

  return useMutation(createMySubscription, {
    onSuccess: (organization) => {},

    onError: (err: Error, _, context) => {
      console.log('Error creating my subscription', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries(['ONBOARDING', 'SUBSCRIPTION', 'PROFILE']);
      qc.invalidateQueries(['ONBOARDING', 'ORGANIZATION']);
    },
  });
};
