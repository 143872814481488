import {FC, useRef, useState} from 'react';
import styled from 'styled-components/macro';
import {useGetMyOrganization} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {useClickOutside} from '../../Hooks/useClickOutside';
import {useRoles} from '../../Hooks/useRoles';
import {H6} from '../Atoms';

const Wrapper = styled.div`
  flex: 0 0 52px;
  width: 52px;
  height: 52px;
  color: #fff;
  z-index: 10;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const Bubble = styled(H6)`
  margin: 0;
  color: #fff;
  width: 52px;
  height: 52px;
  border-radius: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-color: ${(props) => props.theme.Colors.Oranges._000};

  &:hover {
    cursor: pointer;
  }
`;

const Dropdown = styled.div`
  top: 52px;
  right: 0;
  width: 300px;
  padding: 12px;
  position: absolute;
  border-radius: 16px;
  color: black;
  background-color: #fff;
  box-shadow: 0 20px 25px -5px rgba(106, 111, 123, 0.1), 0px 10px 10px -5px rgba(106, 111, 123, 0.04);
`;

const DropdownItem = styled.div`
  color: #000;
  height: 52px;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;

  &:hover {
    color: #fff;
    cursor: pointer;
    background: ${(props) => props.theme.Colors.Oranges._000};
  }
`;

export type TAvatarMenuAction = 'map' | 'help' | 'admin' | 'logout';

export interface AvatarMenuProps {
  onSelectAction: (action: TAvatarMenuAction) => void;
}

export const AvatarMenu: FC<AvatarMenuProps> = ({onSelectAction}) => {
  const [showingDropdown, setShowingDropdown] = useState(false);
  const inputRef = useRef(null);

  useClickOutside(inputRef, () => setShowingDropdown(false));

  const {data: myProfile} = useMyProfile();
  const {isAdmin, isOrgAdmin} = useRoles();

  const handleSelectAction = (action: TAvatarMenuAction) => {
    setShowingDropdown(false);
    onSelectAction(action);
  };

  const {data: accountOrganization} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  const orgHasSubscription =
    !!accountOrganization?.subscription &&
    (accountOrganization?.subscription?.status === 'active' || accountOrganization?.subscription?.status === 'trialing');

  const isLocal = accountOrganization?.subscription_type === 'local';

  return (
    <Wrapper ref={inputRef}>
      <Bubble onClick={() => setShowingDropdown(!showingDropdown)}>
        {myProfile?.user?.firstName?.substring(0, 1)}
        {myProfile?.user?.lastName?.substring(0, 1)}
      </Bubble>

      {showingDropdown ? (
        <Dropdown>
          {/* <Subhead2 style={{padding: '0 16px', margin: '8px 0'}}>{myProfile?.user?.email}</Subhead2> */}
          {(orgHasSubscription || isAdmin) && <DropdownItem onClick={() => handleSelectAction('map')}>Map</DropdownItem>}
          {/* <DropdownItem onClick={() => handleSelectAction('help')}>Need Help?</DropdownItem> */}
          {isAdmin || (isOrgAdmin && orgHasSubscription && !isLocal) ? (
            <DropdownItem onClick={() => handleSelectAction('admin')}>Admin</DropdownItem>
          ) : (
            <></>
          )}
          <DropdownItem onClick={() => handleSelectAction('logout')}>Sign Out</DropdownItem>
        </Dropdown>
      ) : (
        <></>
      )}
    </Wrapper>
  );
};
