import {withAuthenticationRequired} from '@auth0/auth0-react';
import {PencilIcon, TrashIcon} from '@heroicons/react/outline';
import {FC, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Column, Row} from 'react-table';
import styled from 'styled-components';
import {IOrganization, useAdminOrganizations} from '../../API';
import {useDeleteOrganization} from '../../API/Mutations/useDeleteOrganization';
import {FullPageLoader, H4, StandardButton} from '../../Components';
import {requireAdmin} from '../../Managers/AuthUtils';
import {Header} from '../Map/Header';
import {AdminMenu} from './AdminMenu';
import {AdminTable} from './AdminTable';
import {AdminPage, AdminPageWrapper} from './Layout';
import {AdminSummaryBlock} from './SummaryBlock';

const ALLOW_DELETE = false;
const ActionsBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ActionButton = styled(StandardButton)`
  width: 52px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const findOrgAdmin = (org: IOrganization) => {
  return org.users?.find((s) => {
    return org?.subscription?.stripe_customer_id === s.stripe_customer_id;
  });
};

const OrganizationsComponent: FC = () => {
  const organizations = useAdminOrganizations(true);
  const [activeSwitch, setActiveSwitch] = useState<string | null>('all');

  const navigate = useNavigate();
  const deleteOrganization = useDeleteOrganization();

  const filteredOrg = useMemo(() => {
    if (activeSwitch === 'active') {
      return organizations.data?.filter((u) => !!u.subscription);
    }
    if (activeSwitch === 'inactive') {
      return organizations.data?.filter((u) => !u.subscription);
    }
    return organizations.data;
  }, [organizations.data, activeSwitch]);

  const handleDeleteOrganization = (id: string) => {
    deleteOrganization.mutateAsync(id);
  };

  const columns = [
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: ({value, row}: {value: string; row: Row}) => (
        <ActionsBlock>
          <ActionButton onClick={() => navigate(`/admin/organizations/${value}`)}>
            <PencilIcon style={{width: 24}} />
          </ActionButton>
          {ALLOW_DELETE && (
            <ActionButton
              disabled={deleteOrganization?.isLoading}
              onClick={() => {
                if (window.confirm('Are you sure you want to delete ' + row.values.name + '? This cannot be undone!')) {
                  handleDeleteOrganization(row.values.id);
                }
              }}>
              <TrashIcon style={{width: 24}} />
            </ActionButton>
          )}
        </ActionsBlock>
      ),
      disableSortBy: true,
    },
    {
      Header: 'Names',
      accessor: 'name',
      sortType: 'string',
      minWidth: 200,
    },
    {
      Header: 'Address',
      accessor: 'address1',
      Cell: ({row, value}: {row: Row; value: string}) => (
        <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
          {row.values.address1}
          {row.values.address2 && <div>{row.values.address2}</div>}
        </div>
      ),
      minWidth: 200,
    },
    {
      Header: 'Members',
      id: 'members',
      accessor: (row: IOrganization) => row.users?.length ?? '-',
      sortType: 'number',
      minWidth: 100,
    },
    {
      Header: 'Products',
      accessor: (row: IOrganization) => row.authorizations?.length ?? '-',
      minWidth: 100,
    },
    {
      Header: 'Primary Contact',
      accessor: 'primary_contact',
      minWidth: 200,
    },
    {
      Header: 'Email',
      accessor: 'primary_email',
      minWidth: 200,
    },
    {
      Header: 'Org Admin',
      accessor: (row: IOrganization) =>
        row.users
          ?.filter((u) => u.user_type === 'admin')
          ?.map((u) => u.email)
          .join(', '),
      Cell: ({row, value}: {row: any; value: string}) => {
        // const user = row.original.users?.find((u: IUser) => u.stripe_customer_id);

        return <div>{value}</div>;
      },
      minWidth: 200,
    },
    {
      Header: 'Subscription Type',
      id: 'type',
      accessor: (row: IOrganization) => row.subscription?.type,
      minWidth: 200,
      Cell: ({value, row}: {value: string; row: {original: IOrganization}}) => {
        return <>{value || '-'}</>;
      },
    },
    {
      Header: 'Subscription Status',
      id: 'status',
      accessor: (row: IOrganization) => row.subscription?.status,
      minWidth: 200,
      Cell: ({value, row}: {value: string; row: {original: IOrganization}}) => {
        return <>{value || '-'}</>;
      },
    },
    {
      Header: 'Subscription Peroid Start',
      id: 'current_period_start',
      accessor: (row: IOrganization) => row.subscription?.current_period_start,
      minWidth: 200,
      Cell: ({value, row}: {value: string; row: {original: IOrganization}}) => {
        return <>{value ? new Date(value).toLocaleDateString() : '-'}</>;
      },
    },
    {
      Header: 'Subscription Peroid End',
      id: 'current_period_end',
      accessor: (row: IOrganization) => row.subscription?.current_period_end,
      minWidth: 200,
      Cell: ({value, row}: {value: string; row: {original: IOrganization}}) => {
        return <>{value ? new Date(value).toLocaleDateString() : '-'}</>;
      },
    },
  ] as Column[];

  return (
    <AdminPageWrapper style={{maxWidth: '100%'}}>
      <AdminMenu />
      <H4 style={{margin: 0}}>Organizations</H4>
      <AdminSummaryBlock />
      <Header />
      {organizations.isLoading || !organizations.data ? (
        <AdminPage>
          <FullPageLoader />
        </AdminPage>
      ) : (
        <AdminTable
          // title="Organizations"
          data={filteredOrg || []}
          showAddButton
          columns={columns}
          toggleLabels={['All', 'Active', 'Inactive']}
          onDropdownSelect={(value) => setActiveSwitch(value.value)}
          dropdownOptions={[
            {label: 'All', value: 'all'},
            {label: 'Active', value: 'active'},
            {label: 'Inactive', value: 'inactive'},
          ]}
        />
      )}
    </AdminPageWrapper>
  );
};

export const Organizations: FC = withAuthenticationRequired(OrganizationsComponent, {claimCheck: requireAdmin});
