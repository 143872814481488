import {IAuthCondensed} from './OrganizationLocationsPanels';

export const getUniqueAuthorizations = (currentAuthorizations: IAuthCondensed[]) => {
  const uniqueAuthsArr: IAuthCondensed[] = [];
  currentAuthorizations.forEach((auth) => {
    const existingAuth = uniqueAuthsArr.find((a) => a.targetId === auth.targetId && a.targetType === auth.targetType);
    if (!existingAuth) {
      uniqueAuthsArr.push(auth);
    }
  });
  return uniqueAuthsArr;
};
