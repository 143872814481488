import {useMutation, useQueryClient} from 'react-query';
import {createTrialSubscription} from '../Calls/Admin';

export const useCreateTrialSubscription = () => {
  const qc = useQueryClient();

  return useMutation(createTrialSubscription, {
    onSuccess: (organization) => {
      console.log('Created trial subscription', organization);
    },

    onError: (err: Error, _, context) => {
      console.log('Error creating trial subscription', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries(['ONBOARDING', 'SUBSCRIPTION', 'PROFILE']);
      qc.invalidateQueries(['ADMIN', 'ORGANIZATIONS']).catch(() => {});
      qc.invalidateQueries(['ADMIN', 'ORGANIZATION']).catch(() => {});
      qc.invalidateQueries(['PROFILE']).catch(() => {});
    },
  });
};
