import EnterpriseBuildings from '../Components/Atoms/img/EnterpriseBuildings.svg';
import NotForProfitBuildings from '../Components/Atoms/img/NotForProfitBuildings.svg';
import SmallBuildings from '../Components/Atoms/img/SmallBuildings.svg';

export const CENTER_POSITION = {initialLat: '38.5683', initialLon: '-96.547', initialZoom: '3'};

export const DEFAULT_SUBSCRIPTION_TYPE = 'standard';
export const LOCAL_SUBSCRIPTION_KEY = 'local';

export const SUBSCRIPTION_IMAGES = {
  standard: EnterpriseBuildings,
  discounted: SmallBuildings,
  local: NotForProfitBuildings,
};

export const SUBSCRIPTION_INTERVAL = 'Year';

export const STRIPE_STATUS_MESSAGES = {
  active: {
    value: 'active',
    message: 'Your Subscription is Active',
  },
  trialing: {
    value: 'trialing',
    message: 'Your Subscription is in Trial',
  },
  incomplete: {
    value: 'incomplete',
    message: 'Your Subscription is Incomplete',
  },
  incomplete_expired: {
    value: 'incomplete_expired',
    message: 'Your Subscription is Incomplete',
  },
  not_found: {
    value: 'not_found',
    message: 'Your Subscription is Not Found',
  },
  past_due: {
    value: 'past_due',
    message: 'Your Subscription is Past Due',
  },
  canceled: {
    value: 'canceled',
    message: 'Your Subscription is Canceled',
  },
  unpaid: {
    value: 'unpaid',
    message: 'Your Subscription is Unpaid',
  },
};
