import {useQuery} from 'react-query';
import {getMapData} from '../Calls/MapData';

export const useMapData = (
  authorizationId: number | null,
  lat: number | null,
  lon: number | null,
  year: number | null,
  zoom: number | null,
) =>
  useQuery({
    queryKey: [
      'MAP_DATA',
      // Cache (as "the same") any queries where the authId hasn't changed, the lat/lon are within 0.1 degree, and zoom is within 0.5
      // {authorizationId, lat: Math.round(lat || 0), lon: Math.round(lon || 0), zoom: Math.round((zoom || 0) * 2)},
      {
        authorizationId,
        lat: Math.round((lat || 0) * 10),
        lon: Math.round((lon || 0) * 10),
        zoom: Math.round(zoom || 0),
      },
    ],
    queryFn: () => getMapData(authorizationId, lat, lon, year, zoom),
    retry: false,
    staleTime: 10 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    notifyOnChangeProps: ['data', 'error'],
  });
