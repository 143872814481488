import {ITractDataValue} from '../../API';
import {fillBuckets} from '../ColorUtils';
import {cacheDownloadValue} from '../DownloadManager';
import {formatPercent} from '../MathUtils';
import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap, ITribeValue} from './Types';

// Indegenous Boundaries describes ancestral and contemporary connections of indigenous peoples to a geographic area
// This will be a binary dataset, with a single value of 1 if the tract is within a boundary, and 0 if not
// The data will come from 3 columns containing the names of the tribes

export const indigenousSubsets: Record<
  string,
  {id: string; title: string; column: keyof ITractDataValue; countyColumn: keyof ITractDataValue}
> = {
  histTribe: {
    id: 'histTribe',
    title: 'Native Lands',
    column: 'histTribe',
    countyColumn: 'histTribeCount',
  },
  fedTribe: {
    id: 'fedTribe',
    title: 'Federally Recognized Tribes',
    column: 'fedTribe',
    countyColumn: 'fedTribeCount',
  },
  stateTribe: {
    id: 'stateTribe',
    title: 'State Recognized Tribes',
    column: 'stateTribe',
    countyColumn: 'stateTribeCount',
  },
};

const getValue = (config: IDatasetConfig, row: ITractDataValue): ITribeValue => {
  const histTribes = row?.histTribe || '';
  const histTribeCount = row?.histTribeCount ? String(row?.histTribeCount) : '';
  const fedTribes = row?.fedTribe || '';
  const fedTribeCount = row?.fedTribeCount ? String(row?.fedTribeCount) : '';
  const stateTribes = row?.stateTribe || '';
  const stateTribeCount = row?.stateTribeCount ? String(row?.stateTribeCount) : '';

  return {
    histTribes: histTribes || histTribeCount,
    fedTribes: fedTribes || fedTribeCount,
    stateTribes: stateTribes || stateTribeCount,
    tractId: row?.tractId || '',
  };
};

const hasFedValue = (config: IDatasetConfig, row: ITractDataValue) => {
  return Boolean(getValue(config, row)?.fedTribes);
};

const hasAValue = (config: IDatasetConfig, row: ITractDataValue) => {
  return (
    Boolean(getValue(config, row)?.fedTribes) || Boolean(getValue(config, row)?.histTribes) || Boolean(getValue(config, row)?.stateTribes)
  );
};

export const IndigenousBoundaries: IDatasetOptions<ITribeValue> = {
  id: 'indigenousBoundaries',
  title: 'Native Lands',
  downloadTitle: 'Native Lands',
  combinable: true,
  hideCutoffs: true,
  isBinary: true,
  defaultSubsets: [indigenousSubsets.histTribe.id],
  description: 'Describes ancestral and contemporary connections of indigenous peoples to a geographic area.',
  note: 'Also referred to as a "traditional territory"',
  getValue,
  formatValue: (config: IDatasetConfig, value: number) => {
    return formatPercent(value * 100);
  },
  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    return [0, 1];
  },
  // This is a single-set color range.
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = {} as Record<string, number | null>;
    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;

      if (hasAValue(config, row)) {
        cacheDownloadValue(key, indigenousSubsets.histTribe.title, getValue(config, row).histTribes || '', row);
        cacheDownloadValue(key, indigenousSubsets.fedTribe.title, getValue(config, row).fedTribes || '', row);
        cacheDownloadValue(key, indigenousSubsets.stateTribe.title, getValue(config, row).stateTribes || '', row);
      }

      // Skip if we don't have a value for this tract.
      if (hasFedValue(config, row)) {
        values[key] = 1;
      } else if (hasAValue(config, row)) {
        values[key] = 0;
      } else {
        values[key] = null;
      }
    });

    // This dataset is binary (on or off), so we don't need min and max.

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      if (value !== null) {
        map.fills.push(String(tractId));
        // This dataset is binary (on or off), so we only need one of two colors.
        map.fills.push(fillBuckets[value ? fillBuckets.length - 1 : 0]);

        map.opacities.push(String(tractId));
        map.opacities.push(1);
      }
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const values = {} as Record<string, number | null>;
    const map = {values: {}} as ITract2DBucketMap;

    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;
      if (hasFedValue(config, row)) {
        values[key] = 1;
      } else if (hasAValue(config, row)) {
        values[key] = 0;
      } else {
        values[key] = null;
      }

      if (hasAValue(config, row)) {
        cacheDownloadValue(key, indigenousSubsets.histTribe.title, getValue(config, row).histTribes || '', row);
        cacheDownloadValue(key, indigenousSubsets.fedTribe.title, getValue(config, row).fedTribes || '', row);
        cacheDownloadValue(key, indigenousSubsets.stateTribe.title, getValue(config, row).stateTribes || '', row);
      }
    });

    Object.entries(values).forEach(([tractId, value]) => {
      if (value) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'L';
      }
    });

    return map;
  },
};
