import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {IOrganization, useGetMyOrganization} from '../../API';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {FlexFill} from '../../Components';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper} from './Layout';
import {OrganizationLocationsPanels} from './OrganizationLocationsPanels';
import {useMyProfile} from '../../API/Queries/useMyProfile';

export const OrganizationDefaultPosition: FC = () => {
  const navigate = useNavigate();
  const {organization} = useCartOrganization();
  const {data: myProfile} = useMyProfile();
  const {data: accountOrganization, isLoading} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  useEffect(() => {
    if (!isLoading && !accountOrganization) {
      navigate(routePaths.onboardingOrganization);
    }
  }, [accountOrganization, organization]);

  return (
    <OnboardingPageWrapper>
      <OnboardingPage>
        <div style={{display: 'flex', flexDirection: 'row', gap: 24}}>
          <OrganizationLocationsPanels
            organization={(accountOrganization || organization) as IOrganization}
            hideTitle
            readOnly
            hideSidePanel
          />
        </div>
      </OnboardingPage>
      <FlexFill style={{flex: 0}} />
    </OnboardingPageWrapper>
  );
};
