import {formatPercent, getMinMax} from '../MathUtils';
import {IDatasetConfig, IDatasetOptions, IRedliningValue, ITract2DBucketMap, ITractColorMap} from './Types';
import {fillBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';
import {cacheDownloadValue, cacheSingleDownloadValue} from '../DownloadManager';
import {Theme} from '../../Components';

export const redliningSubsets: Record<string, {id: string; title: string; column: keyof ITractDataValue}> = {
  HOLCA: {
    id: 'HOLCA',
    title: 'A: "Best"',
    column: 'redliningA',
  },
  HOLCB: {
    id: 'HOLCB',
    title: 'B: "Still Desirable"',
    column: 'redliningB',
  },
  HOLCC: {
    id: 'HOLCC',
    title: 'C: "Definitely Declining"',
    column: 'redliningC',
  },
  HOLCD: {
    id: 'HOLCD',
    title: 'D: "Hazardous" (redlined)',
    column: 'redliningD',
  },
};

const getSingleValue = (_config: IDatasetConfig, row: ITractDataValue, column: keyof ITractDataValue): number => {
  return Number(row[column]) || 0;
};

const getValue = (config: IDatasetConfig, row: ITractDataValue): IRedliningValue => {
  const redliningA = row.redliningA || 0;
  const redliningB = row.redliningB || 0;
  const redliningC = row.redliningC || 0;
  const redliningD = row.redliningD || 0;

  return {redliningA, redliningB, redliningC, redliningD};
};

const hasAValue = (config: IDatasetConfig, row: ITractDataValue) => {
  return Object.keys(getValue(config, row)).some((key) => getValue(config, row)[key as keyof IRedliningValue] > 0);
};

export const Redlining: IDatasetOptions<IRedliningValue> = {
  id: 'redlining',
  title: 'Historical Redlining',
  downloadTitle: 'Historical Redlining',
  combinable: true,
  subsetType: 'radio',
  subsetTitle: 'HOLC Grade',
  isBinary: true,
  subsets: [
    {
      id: redliningSubsets.HOLCA.id,
      title: redliningSubsets.HOLCA.title,
      description:
        'Best - Minimal risk for banks and other mortgage lenders when they were determinant who should received loans and which areas in the city were save investments.',
      type: 'Both',
      color: Theme.Colors.Greens._000,
    },
    {
      id: redliningSubsets.HOLCB.id,
      title: redliningSubsets.HOLCB.title,
      description:
        'Desirable - Generally nearly or completely White, U.S. -born neighborhoods that HOLC defined as “still desirable” and sound investments for mortgage lenders.',
      type: 'Both',
      color: Theme.Colors.Blues._000,
    },
    {
      id: redliningSubsets.HOLCC.id,
      title: redliningSubsets.HOLCC.title,
      description:
        'Declining - Areas where the residents were often working-class and/or first or second generation immigrants from Europe. These areas often lacked utilities and were characterized by older building stock.',
      type: 'Both',
      color: Theme.Colors.Yellows._000,
    },
    {
      id: redliningSubsets.HOLCD.id,
      title: redliningSubsets.HOLCD.title,
      description:
        'Hazardous (“Redlined”) - Areas here often received this grade because they were “infiltrated” with “undesirable populations” such as Jewish, Asian, Mexican, and Black families. These areas were more likely to be close to industrial areas and to have older housing.',
      color: Theme.Colors.Reds._000,
    },
  ],
  defaultSubsets: [redliningSubsets.HOLCD.id],
  description:
    'The homeowners loan corporation (HOLC) was established in 1933 as part of the New Deal. It classified neighborhoods  into grades based on their perceived mortgage-lending risk which denied certain residents access to home loans, mortgages, insurance, and other financial services. The grades ranged from A to D.  Grade D was traditionally colored in red and is what coined the term ‘redlining’. Impacts of socioeconomic disinvestment due to redlining are still seen today.',
  note: '* original dataset created between 1935 and 1940',

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => {
    return formatPercent(value * 100);
  },

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const [subset] = config.subsets;
    const values = {} as Record<string, number>;
    rows.forEach((row) => {
      values[row.tractId || row.stateCountyId] = getSingleValue(config, row, redliningSubsets[subset]?.column);
    });

    return getMinMax(values);
  },

  // This is a single-set color range.
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const [subset] = config.subsets;
    const subsetTitle = Redlining.subsets?.find((s) => s.id === subset)?.title || '';
    const subsetValues = {} as Record<string, number | null>;
    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;
      // Skip if we don't have a value for this tract.
      if (hasAValue(config, row)) {
        subsetValues[key] = getSingleValue(config, row, redliningSubsets[subset]?.column);
      } else {
        subsetValues[key] = null;
      }
    });

    // This dataset is binary (on or off), so we don't need min and max.
    // const [min, max] = getMinMax(subsetValues);

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(subsetValues).forEach(([tractId, value]) => {
      if (value !== null) {
        cacheSingleDownloadValue(tractId, `Percent ${subsetTitle}`, subsetValues[tractId] || 0);
        map.fills.push(String(tractId));
        // This dataset is binary (on or off), so we only need one of two colors.
        map.fills.push(fillBuckets[value ? fillBuckets.length - 1 : 0]);

        map.opacities.push(String(tractId));
        map.opacities.push(1);
      }
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const [subset] = config.subsets;
    const subsetTitle = Redlining.subsets?.find((s) => s.id === subset)?.title || '';
    const values = {} as Record<string, number | null>;

    const map = {values: {}} as ITract2DBucketMap;

    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;
      values[key] = getSingleValue(config, row, redliningSubsets[subset]?.column);
      cacheDownloadValue(key, `Percent ${subsetTitle}`, values[key] || 0, row);
    });

    Object.entries(values).forEach(([tractId, value]) => {
      if (value) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'L';
      }
    });

    return map;
  },
};
