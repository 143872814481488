import {FC} from 'react';
import {FlexRow, Subhead1} from '..';
import {SUBSCRIPTION_INTERVAL} from '../../constants/constants';

export const TotalRow: FC<{label: string; cost: number; color?: string; hideInterval?: boolean}> = ({label, cost, color, hideInterval}) => {
  return (
    <FlexRow style={{justifyContent: 'space-between', alignItems: 'center', ...(color ? {color} : {})}}>
      <Subhead1 style={{margin: 0, ...(color ? {color} : {})}}>{label}</Subhead1>
      <span style={{marginLeft: 'auto', fontSize: '20px', fontWeight: 600}}>
        {cost ? cost?.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) : '-------'}
      </span>
      {!hideInterval && <span style={{fontSize: '16px'}}>/{SUBSCRIPTION_INTERVAL}</span>}
    </FlexRow>
  );
};
