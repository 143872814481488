interface RGBAValue {
  r: number;
  g: number;
  b: number;
  a: number;
}

const hex2RGB = function (hex: string): RGBAValue {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4] || 'FF', 16),
      }
    : {r: 0, g: 0, b: 0, a: 0};
};

const rgba2String = ({r, g, b, a}: RGBAValue) => {
  return `rgba(${r},${g},${b},${a})`;
};

// Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
// Taken from the awesome ROT.js roguelike dev library at
// https://github.com/ondras/rot.js
export const interpolateRGBValues = (color1: RGBAValue, color2: RGBAValue, factor = 0.5): RGBAValue => ({
  r: Math.round(color1.r + factor * (color2.r - color1.r)),
  g: Math.round(color1.g + factor * (color2.g - color1.g)),
  b: Math.round(color1.b + factor * (color2.b - color1.b)),
  a: Math.round(color1.a + factor * (color2.a - color1.a)),
});

export const interpolateColors = (color1: string, color2: string, factor = 0.5) =>
  rgba2String(interpolateRGBValues(hex2RGB(color1), hex2RGB(color2), factor));

export const opacityBuckets = [0.05, 0.13, 0.21, 0.29, 0.37, 0.45, 0.53, 0.61, 0.69, 0.77, 0.85];

const colorList = ['#FFF4E9', '#FFE4C9', '#FFD3A8', '#FFC388', '#FFB267', '#FFA247', '#FF9126', '#F28A24', '#E68322', '#D97B20', '#CC741E'];

export const fillBuckets = colorList.map((color) => rgba2String(hex2RGB(color)));
