import {CheckIcon, ChevronDownIcon, ChevronUpIcon, SearchIcon} from '@heroicons/react/outline';
import React, {FC} from 'react';
import Select, {GroupBase, OptionProps, Options, OptionsOrGroups, ValueContainerProps, components} from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import {Accessors} from 'react-select/dist/declarations/src/useCreatable';
import {Theme} from '..';

const DropdownIndicator: FC = (props) => {
  const {
    // @ts-ignore
    selectProps: {menuIsOpen},
  } = props;
  return menuIsOpen ? (
    <ChevronUpIcon width={20} style={{width: 20}} color={Theme.Colors.Neutrals._000} />
  ) : (
    <ChevronDownIcon width={20} style={{width: 20}} color={Theme.Colors.Neutrals._000} />
  );
};

export interface DropdownOption {
  value: string | null;
  label: string;
}

export type TValidOption = (
  inputValue: string,
  value: Options<any>,
  options: OptionsOrGroups<any, GroupBase<unknown>>,
  accessors: Accessors<any>,
) => boolean;

interface DropdownProps {
  defaultOptions?: any[];
  defaultValue?: DropdownOption;
  disabled?: boolean;
  formatCreateLabel?: (inputValue: string) => React.ReactNode;
  hideSearchIcon?: boolean;
  id?: string;
  isAsync?: boolean;
  isCreatable?: Boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  isValidNewOption?: TValidOption;
  loadOptions?: (inputValue: string, callback: (options: any[]) => void) => void;
  maxListLength?: number;
  menuIsOpen?: boolean;
  name?: string;
  onChange: (value: any) => void;
  onClose?: () => void;
  onCreateOption?: (value: any) => void;
  onOpen?: () => void;
  options?: Array<DropdownOption>;
  value?: any;
}

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 500,
    borderRadius: 8,
    flexDirection: 'row',
    border: 2,
    borderColor: 'black',
    fontFamily: 'Poppins',
    marginBottom: '2em',
    width: '100%!important',
  }),

  menuList: (provided: any) => ({
    ...provided,
    maxHeight: window.innerHeight * 0.5,
    overflowY: 'auto',
    padding: 12,
    margin: '0 0 16px',
    borderRadius: 16,
    border: 'none',
    position: 'relative',
    boxSizing: 'border-box',
    fontSize: 14,
    backgroundcolor: 'white',
    flexDirection: 'row',
    boxshadow: '0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05)',
  }),

  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 16,
    minHeight: 52,
    paddingLeft: '1em',
    outline: 'none',
    flexWrap: 'nowrap',
    backgroundColor: Theme.Colors.Neutrals._500,
    ...(state.isFocused ? {border: `5px solid ${Theme.Colors.Oranges._100}`} : {}),
    border: state.isFocused || state.isSelected ? `1px solid ${Theme.Colors.Oranges._100}` : `1px solid ${Theme.Colors.Neutrals._500}`,
    boxShadow: state.isFocused ? `0 0 0 5px ${Theme.Colors.Oranges._400}` : 'none',
    ':hover': {
      border: state.isFocused || state.isSelected ? `1px solid ${Theme.Colors.Oranges._100}` : `1px solid ${Theme.Colors.Neutrals._500}`,
    },
  }),

  indicatorsContainer: (provided: any) => ({
    ...provided,
    marginRight: 16,
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    padding: 16,
    borderRadius: 8,
    backgroundColor: 'white',
    color: Theme.Colors.Neutrals._000,
    ':hover': {
      color: 'white',
      backgroundColor: Theme.Colors.Oranges._000,
    },
  }),

  multiValue: (provided: any) => ({
    ...provided,
    flexDirection: 'row !important',
  }),

  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      alignSelf: 'center',
      color: Theme.Colors.Neutrals._000,
      fontSize: 14,
      flex: 1,
    };
  },
};

const ValueContainer = ({children, selectProps, ...props}: ValueContainerProps) => {
  return (
    <components.ValueContainer selectProps={selectProps} {...props}>
      <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row', marginLeft: -8}}>
        {
          // @ts-ignore
          !selectProps.hideSearchIcon && selectProps.isSearchable && (
            <SearchIcon style={{marginRight: 16, width: 20}} width={20} color={Theme.Colors.Neutrals._300} />
          )
        }
        <div style={{flex: 1, display: 'contents'}}>{children}</div>
      </div>
    </components.ValueContainer>
  );
};

const Option = (props: OptionProps) => {
  const {
    isSelected,
    // @ts-ignore
    data: {label},
  } = props;
  return (
    <components.Option {...props}>
      <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}>
        <span>{label}</span>
        {isSelected && <CheckIcon style={{width: '20px', paddingBottom: '1px', marginLeft: '8px'}} />}
      </div>
    </components.Option>
  );
};

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  defaultOptions,
  defaultValue,
  disabled,
  formatCreateLabel,
  hideSearchIcon = false,
  id,
  isAsync,
  isCreatable = false,
  isMulti = false,
  isSearchable = true,
  isValidNewOption,
  loadOptions,
  menuIsOpen,
  onChange,
  onClose,
  onCreateOption,
  onOpen,
  options,
  value,
}) => {
  const componentProps = {
    className: 'custom-select',
    components: {DropdownIndicator, ValueContainer, Option, IndicatorSeparator: null},
    defaultValue,
    id,
    isDisabled: disabled,
    isSearchable,
    isMulti,
    hideSelectedOptions: false,
    onChange,
    onMenuClose: onClose,
    onMenuOpen: onOpen,
    hideSearchIcon,
    options,
    styles: customStyles,
    theme: (theme: any) => ({
      ...theme,
      borderRadius: 8,
      colors: {
        ...theme.colors,
        primary: Theme.Colors.Oranges._100,
        primary25: Theme.Colors.Neutrals._500,
        primary50: Theme.Colors.Neutrals._500,
      },
    }),
    value,
    menuIsOpen,
  };

  if (isCreatable) {
    return (
      <CreatableSelect
        {...componentProps}
        createOptionPosition="first"
        isValidNewOption={isValidNewOption}
        onCreateOption={onCreateOption}
        formatCreateLabel={formatCreateLabel}
      />
    );
  }

  if (isAsync) {
    return <AsyncSelect {...componentProps} loadOptions={loadOptions} defaultOptions={defaultOptions} placeholder="Search..." />;
  }

  return <Select {...componentProps} />;
};
