import {CSSProperties, FC} from 'react';
import {useIsFetching, useIsMutating} from 'react-query';
import styled from 'styled-components/macro';
import {FullPageLoader} from '../../Components';

// NOTE: Short on time and trying to marry legacy/new code. This was a quick win.

export const AdminPageWrapper = styled.div`
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background: #f3f3f4;
  padding: 120px 22px 0 330px;
  gap: 20px;
  max-width: 1280px;
  margin: 0 auto;
`;

export const AdminPageDiv = styled.div`
  overflow-y: auto;
  margin-bottom: 20px;
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1000px;
  min-width: 296px;
`;

export const AdminPanel = styled.div`
  padding: 28px;
  background: white;
  border-radius: 16px;
  overflow-y: scroll;
  box-shadow: 0px 10px 10px -5px rgba(106, 111, 123, 0.04), 0px 20px 25px -5px rgba(106, 111, 123, 0.1);
`;

export const AdminPageDividerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin: 0;
  align-items: flex-start;
`;

export const AdminPage: FC<{style?: CSSProperties}> = ({children, style}) => {
  const isFetching = useIsFetching();
  const isFetchingPropublica = useIsFetching({queryKey: ['ONBOARDING', 'NONPROFITS']});
  const isMutating = useIsMutating();

  return (
    <AdminPageDiv style={style}>
      {(!!isFetching || !!isMutating) && !isFetchingPropublica && <FullPageLoader />}

      {children}
    </AdminPageDiv>
  );
};
