import {FC} from 'react';
import {IOrganization} from '../../../API';
import {FlexRow} from '../../../Components';
import {OnboardingPanel} from '../../Onboarding/Layout';
import {OrganizationForm} from '../../Onboarding/OrganizationForm';

export const OrganizationTab: FC<{organization: IOrganization; onUpdate: (values: {}) => any; disabled?: boolean}> = ({
  organization,
  onUpdate,
  disabled,
}) => {
  return (
    <FlexRow style={{gap: 28}}>
      <OnboardingPanel style={{flex: 1}}>
        <OrganizationForm
          handleSaveOrg={onUpdate}
          submitType="save"
          organization={organization}
          readOnly
          disabled={disabled}
        />
      </OnboardingPanel>
    </FlexRow>
  );
};
