import {Form, FormikProps} from 'formik';
import {FC} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import {Body3, FlexRow, FormField, FullPageLoader, StandardButton, Subhead2, TextInput} from '../../Components';
import {DefaultMap} from './DefaultMap';
import {ILocation} from './SetupMap';
import {getDirtyValues} from './getDirtyValues';

const LocationField = styled(FormField)`
  flex: 1;
  margin: 0;
  gap: 8px;
`;

export const defaultViewSchema = yup.object({
  initialLat: yup
    .number()
    .label('Center latitude')
    .required('Please enter default latitude.')
    .min(-90, 'Please enter valid latitude. Minimum is -90.')
    .max(90, 'Please enter valid latitude. Maximum is 90.')
    .nullable(),
  initialLon: yup
    .number()
    .label('Center longitude')
    .required('Please enter default longitude.')
    .min(-180, 'Please enter valid longitude. Minimum is -180.')
    .max(80, 'Please enter valid longitude. Maximum is 80.')
    .nullable(),
  initialZoom: yup
    .number()
    .label('Initial zoom')
    .required('Please enter default zoom.')
    .min(3, 'Please enter valid zoom. Minimum is 3.')
    .max(18, 'Please enter valid zoom. Maximum is 18.')
    .nullable(),
});

export const LocationsMap: FC<{
  defaultPosition: Record<string, string>;
  formikProps: FormikProps<Record<string, string>>;
  isSubmittingLocation: boolean;
  initialLat: number;
  initialLon: number;
  initialZoom: number;
  currentSelectedLocation: ILocation | null;
  hideDefaultPosition?: boolean;
  hideSidePanel?: boolean;
}> = ({
  defaultPosition,
  formikProps,
  isSubmittingLocation,
  initialLat,
  initialLon,
  initialZoom,
  currentSelectedLocation,
  hideDefaultPosition,
  hideSidePanel,
}) => {
  const {isSubmitting: isSubmittingPosition, isValid, setValues} = formikProps;
  const handlePositionChange = ({lat = 0, lng = 0, zoom = 0}) => {
    setValues({
      initialLat: (+lat).toFixed(4),
      initialLon: (+lng).toFixed(4),
      initialZoom: (+zoom).toFixed(2),
    });
  };

  // This form is embedded in a larger form, so we need to manually check if any of the fields are dirty
  const dirtyValues = getDirtyValues(formikProps.values, defaultPosition);
  const dirty = Object.keys(dirtyValues).some((key) => Object.keys(defaultPosition).includes(key));

  return (
    <Form style={{width: '100%'}}>
      {(isSubmittingPosition || isSubmittingLocation) && <FullPageLoader />}
      <div style={{gap: 16, display: 'flex', minWidth: '100%'}}>
        <div style={{margin: '0', minHeight: 367, position: 'relative'}}>
          <div style={{flex: 1, position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, minHeight: '100%'}}>
            <DefaultMap
              position={{
                initialLat,
                initialLon,
                initialZoom,
              }}
              currentSelectedLocation={currentSelectedLocation}
              onPositionChange={handlePositionChange}
            />
          </div>
        </div>

        {!hideDefaultPosition && (
          <>
            {!hideSidePanel && (
              <div>
                <Subhead2 style={{marginBottom: 8}}>Set Up Default Position</Subhead2>

                <Body3 style={{marginBottom: 0}}>
                  To set up the default position scroll and drag on the map above or enter the corresponding values.
                </Body3>
              </div>
            )}

            <FlexRow style={{gap: 16, justifyContent: 'flex-start'}}>
              <LocationField style={{flex: 1, margin: 0}}>
                <TextInput type="text" name="initialZoom" placeholder="Initial Zoom..." label="Zoom" />

                {dirty && <Body3>Saved: {defaultPosition.initialZoom}</Body3>}
              </LocationField>

              <LocationField style={{flex: 1, margin: 0}}>
                <TextInput type="text" name="initialLat" placeholder="Latitude..." label="Latitude" />

                {dirty && <Body3>Saved: {defaultPosition.initialLat}</Body3>}
              </LocationField>

              <LocationField style={{flex: 1, margin: 0}}>
                <TextInput type="text" name="initialLon" placeholder="Longitude..." label="Longitude" />

                {dirty && <Body3>Saved: {defaultPosition.initialLon}</Body3>}
              </LocationField>

              {!hideSidePanel && (
                <StandardButton style={{flex: 0, maxWidth: 400, marginTop: 28}} disabled={!isValid || !dirty}>
                  Save
                </StandardButton>
              )}
            </FlexRow>

            {hideSidePanel && (
              <FlexRow style={{justifyContent: 'center'}}>
                <StandardButton style={{flex: 1, maxWidth: 400}} disabled={!isValid || !dirty}>
                  Save and Start
                </StandardButton>
              </FlexRow>
            )}
          </>
        )}
      </div>
    </Form>
  );
};
