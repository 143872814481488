import React, {FC} from 'react';
import {useAsyncDebounce} from 'react-table';
import styled from 'styled-components';
import {SearchIcon} from '@heroicons/react/outline';

interface QuickFilterProps {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

const Input = styled.input`
  height: 52px;
  padding: 0 16px 0 48px;
  font-size: 14px;
  min-width:100%;
  border-radius: 16px;
  margin: 0 16px 0 0;
  color: ${(props) => props.theme.Colors.Neutrals._100};
  border: 1px solid ${(props) => props.theme.Colors.Neutrals._500};
  background-color: ${(props) => props.theme.Colors.Neutrals._500};

  &.error {
    border: 1px solid ${(props) => props.theme.Colors.Reds._000};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.Colors.Oranges._100};
    outline: 5px solid ${(props) => props.theme.Colors.Oranges._400};
  }
`;

const Control = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

export const QuickFilter: FC<QuickFilterProps> = ({preGlobalFilteredRows, globalFilter, setGlobalFilter}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Control>
      <SearchIcon style={{left: 16, position: 'absolute', width: 20}} color="#989ba3" />
      <Input
        type="text"
        placeholder={`Search ${count} records...`}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </Control>
  );
};
