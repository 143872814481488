import {FC, useState} from 'react';
import {IAuthorization, IOrganization} from '../../API';
import {BoldFieldLabel, Dropdown, FieldWrapper, FlexRow, H4, H6} from '../../Components';
import {LocationsSelect} from './LocationsSelect';
import {IAuthCondensed} from './OrganizationLocationsPanels';

interface ILocationFilterOption {
  label: string;
  value: string;
}

const locationFilterOptions: ILocationFilterOption[] = [
  {label: 'Counties', value: 'county'},
  {label: 'States', value: 'state'},
  {label: 'Nationwide', value: 'nationwide'},
];

export const LocationSelection: FC<{
  handleAddAuth: (auth: IAuthCondensed) => void;
  hideTitle?: boolean;
  organization: IOrganization;
}> = ({hideTitle, handleAddAuth, organization}) => {
  const defaultOption = locationFilterOptions[0];

  const locationOptions = organization?.subscription_type === 'local' ? [locationFilterOptions[0]] : locationFilterOptions;

  const [locationFilter, setLocationFilter] = useState<string[]>([defaultOption?.value]);

  const handleUpdateFilter = (values: ILocationFilterOption) => {
    setLocationFilter([values.value]); // Originall was coded to handle multiple values
    // setLocationFilter(values?.map((f) => f.value));
  };

  return (
    <>
      <FlexRow>
        {!hideTitle ? (
          <H4 style={{flex: 2, margin: 0}}>Add Location(s)</H4>
        ) : (
          <H6 style={{flex: 2, margin: 0}}>Add Locations by Searching Below</H6>
        )}
      </FlexRow>

      <FlexRow style={{gap: 16}}>
        <FieldWrapper style={{flex: 2}}>
          <BoldFieldLabel>Search</BoldFieldLabel>

          <LocationsSelect
            locationFilter={locationFilter}
            onChange={(option: {value: string}) => {
              const [targetType, targetId] = (option.value || '').split(':');
              const auth = {targetType, targetId};
              handleAddAuth(auth as IAuthorization);
            }}
          />
        </FieldWrapper>

        <FieldWrapper style={{flex: 1, minWidth: 120}}>
          <BoldFieldLabel>Filters</BoldFieldLabel>

          <Dropdown
            defaultValue={defaultOption}
            options={locationOptions}
            onChange={handleUpdateFilter}
            hideSearchIcon
            // isMulti
          />
        </FieldWrapper>
      </FlexRow>
    </>
  );
};
