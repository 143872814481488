import {DownloadIcon} from '@heroicons/react/outline';
import {format} from 'date-fns';
import {FC} from 'react';
import {Row} from 'react-table';
import styled from 'styled-components';
import {IOrganization, useAdminOrganizationInvoices} from '../../../API';
import {TextButton, Theme} from '../../../Components';
import {AdminTable} from '../AdminTable';
import {AdminPage} from '../Layout';

const ActionButton = styled(TextButton)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  color: ${Theme.Colors.Oranges._000};
`;

const columns = [
  {
    Header: 'Billing Date',
    accessor: 'created',
    sortType: 'number',
    minWidth: 200,
    Cell: ({value, row}: {value: string; row: Row}) => {
      return format(new Date(+value * 1000), 'MM/dd/yyyy');
    },
  },
  {
    Header: 'Total Price',
    accessor: 'amount_due',
    sortType: 'number',
    minWidth: 200,
    Cell: ({value, row}: {value: number; row: Row}) => {
      return (value / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
    sortType: 'string',
    minWidth: 200,
  },
  {
    Header: 'Actions',
    accessor: 'invoice_pdf',
    disableSortBy: true,
    Cell: ({value, row}: {value: string; row: Row}) => (
      <ActionButton onClick={() => (window.location.href = value || window.location.href)}>
        <DownloadIcon style={{stroke: Theme.Colors.Oranges._000, width: '24px'}} />
        <div>Download</div>
      </ActionButton>
    ),
  },
];
export const BillingTab: FC<{organization: IOrganization}> = ({organization}) => {
  const {data: invoices, isFetching} = useAdminOrganizationInvoices(organization.id);

  if (!isFetching && !invoices?.invoices?.data) {
    return (
      <AdminPage style={{maxWidth: 1000, overflowY: 'auto'}}>
        <div style={{flex: 1, margin: 0, padding: 0, overflowY: 'scroll'}}>No invoices found.</div>
      </AdminPage>
    );
  }

  return (
    <AdminPage style={{maxWidth: '100%', overflowY: 'auto'}}>
      <div style={{flex: 1, margin: 0, padding: 0, overflowY: 'scroll'}}>
        <AdminTable
          columns={columns}
          data={invoices?.invoices?.data || []}
          sortBy={[{id: 'created', desc: true}]}
          showAddButton={false}
          showHeader={false}
        />
      </div>
    </AdminPage>
  );
};
