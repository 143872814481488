import {useMutation, useQueryClient} from 'react-query';
import {updateMyProfile} from '../Calls/Session';

// NOTE: This returns a full "login response" to save the front-end a few calls
export const useUpdateMyProfile = () => {
  const qc = useQueryClient();

  return useMutation(updateMyProfile, {
    onSuccess: (profile) => {
      console.log('Setting user profiles', profile);
    },

    onError: (err: Error, _newProfile, context) => {
      console.log('Error updating profile', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries('PROFILE');
    },
  });
};
