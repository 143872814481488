import {EyeIcon} from '@heroicons/react/outline';
import {useField, useFormikContext} from 'formik';
import {FC, useState} from 'react';
import styled from 'styled-components/macro';
import {Body3, Subhead2} from '../Atoms';

export const FieldWrapper = styled.div`
  flex: 0;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const FieldLabel = styled(Subhead2)`
  margin: 0 0 8px;
  white-space: nowrap;
`;

export const BoldFieldLabel = styled(FieldLabel)`
  font-weight: 500;
`;

export const TextField = styled.input`
  height: 52px;
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
  border-radius: 16px;
  color: ${(props) => props.theme.Colors.Neutrals._200};
  border: 1px solid ${(props) => props.theme.Colors.Neutrals._500};
  background-color: ${(props) => props.theme.Colors.Neutrals._500};
  resize: none;

  &:disabled {
    color: ${(props) => props.theme.Colors.Neutrals._300};
  }

  &:disabled::placeholder {
    color: ${(props) => props.theme.Colors.Neutrals._300};
  }

  &.error {
    border: 1px solid ${(props) => props.theme.Colors.Reds._000};
    border-radius: 16px;
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.Colors.Oranges._100};
    // outline: 5px solid ${(props) => props.theme.Colors.Oranges._400};
    // outline: 5px solid ${(props) => props.theme.Colors.Oranges._400};
    outline: none;
    box-shadow: 0 0 0 5px ${(props) => props.theme.Colors.Oranges._400};
  }
`;

const PasswordButton = styled.button<{passwordVisible?: boolean}>`
  border: none;
  align-items: center;
  align-content: center;
  justify-content: center;
  align-self: center;
  position: absolute;
  right: 0;
  top: 14px;
  background-color: inherit;
  margin: 0 10px 20px;
  color: ${(props) => (props.passwordVisible ? props.theme.Colors.Oranges._000 : props.theme.Colors.Neutrals._300)};

  &:hover {
    cursor: pointer;
  }
`;

export const ErrorBody = styled(Body3)`
  margin: 8px 0 0;
  color: ${(props) => props.theme.Colors.Reds._000};
`;

const PasswordIcon = styled(EyeIcon)`
  width: 22px;
  height: 22px;
  text-align: center;
  flex: 0;
  align-self: center;
`;

export const RequiredSpan = styled.span`
  color: ${(props) => props.theme.Colors.Reds._000};
`;

export interface IStandardInput {
  name: string;
  label?: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'date';
  style?: any;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  autoComplete?: string;
  errorField?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (value: any) => void;
  as?: any;
  min?: string;
  max?: string;
  rows?: number;
}

export const TextInput: FC<IStandardInput> = ({
  name,
  label,
  style,
  type = 'text',
  placeholder,
  required = false,
  disabled,
  autoComplete = 'off',
  errorField = true,
  onFocus,
  onBlur,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(name);
  const {submitCount} = useFormikContext();
  const [passwordVisible, setPasswordVisible] = useState(false);

  const hasError = !!meta.error && (meta.touched || submitCount > 0);

  const handleChange = (value: any) => {
    field.onChange(value);
    onChange && onChange(value);
  };

  return (
    <FieldWrapper>
      {label ? (
        <FieldLabel>
          {label} {required && !disabled && <RequiredSpan>*</RequiredSpan>}
        </FieldLabel>
      ) : (
        <></>
      )}

      <InputWrapper>
        <TextField
          name={name}
          type={passwordVisible ? 'text' : type}
          style={style}
          className={hasError ? 'error' : ''}
          disabled={disabled}
          // required={required}
          placeholder={placeholder}
          autoComplete={autoComplete}
          value={field.value != null ? field.value : ''}
          onChange={handleChange}
          onFocus={(e: any) => {
            onFocus && onFocus();
          }}
          onBlur={(e: any) => {
            field.onBlur(e);
            onBlur && onBlur();
          }}
          {...props}
        />
        {type === 'password' && (
          <PasswordButton passwordVisible={passwordVisible} onClick={() => setPasswordVisible(!passwordVisible)}>
            <PasswordIcon />
          </PasswordButton>
        )}
      </InputWrapper>
      {errorField && hasError && <ErrorBody>{meta?.error}</ErrorBody>}
    </FieldWrapper>
  );
};
