import {ITractDataValue} from '../../API';
import {Theme} from '../../Components';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {cacheDownloadTractData, cacheDownloadValue} from '../DownloadManager';
import {formatNumber, getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {IBuildingValue, IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';

// To handle outliers, we cap the max value at 4000 sqft for livable area and 13 units for total units
const LIVEABLE_AREA_MAX = 4000;
const TOTAL_UNITS_MAX = 13;

type VariantType = 'default' | 'singleFamily' | 'multiFamily';
type ValueType = 'buildingCount' | 'medianYrBuilt' | 'medianUnitArea' | 'medianLivableArea' | 'medianTotalUnits';

const hasData = (rows: ITractDataValue[], config: IDatasetConfig) =>
  rows.some((row) => {
    return getSingleValue(config, row);
  });

const formatterMap = {
  buildingCount: (value: number) => formatNumber(value?.toFixed(0)),
  medianYrBuilt: (value: number) => (value ? value.toFixed(0) : 'N/A'),
  medianUnitArea: (value: number) => formatNumber(value?.toFixed(0)),
  medianLivableArea: (value: number) => formatNumber(value?.toFixed(0)),
  medianTotalUnits: (value: number) => formatNumber(value?.toFixed(0)),
};

const columnKeys: Record<VariantType, Record<ValueType, keyof ITractDataValue>> = {
  default: {
    buildingCount: 'buildingCount',
    medianYrBuilt: 'medianYrBuilt',
    medianUnitArea: 'medianUnitArea',
    medianLivableArea: 'medianLivableArea',
    medianTotalUnits: 'medianTotalUnits',
  },
  singleFamily: {
    buildingCount: 'buildingCountSF',
    medianYrBuilt: 'medianYrBuiltSF',
    medianUnitArea: 'medianUnitAreaSF',
    medianLivableArea: 'medianLivableAreaSF',
    medianTotalUnits: 'medianTotalUnitsSF',
  },
  multiFamily: {
    buildingCount: 'buildingCountMF',
    medianYrBuilt: 'medianYrBuiltMF',
    medianUnitArea: 'medianUnitAreaMF',
    medianLivableArea: 'medianLivableAreaMF',
    medianTotalUnits: 'medianTotalUnitsMF',
  },
};

const getVariant = (config: IDatasetConfig): VariantType => {
  const variants = (config?.subsetVariants as VariantType[]) ?? ['default'];
  return variants.length !== 1 ? 'default' : variants[0];
};

const getSingleValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  const key = config.subsets[0] as ValueType;
  const variant = getVariant(config);
  const rowKey = columnKeys[variant][key];
  const value = +(row[rowKey] || 0);

  if (key === 'medianLivableArea' && value > LIVEABLE_AREA_MAX) {
    return LIVEABLE_AREA_MAX;
  } else if (key === 'medianTotalUnits' && value > TOTAL_UNITS_MAX) return TOTAL_UNITS_MAX;
  else {
    return value;
  }
};

const getValue = (config: IDatasetConfig, row: ITractDataValue): IBuildingValue => {
  const subsets = [...(config?.subsets ?? [])];
  const variants = (config?.subsetVariants as VariantType[]) ?? ['default'];

  const variant = variants.length !== 1 ? 'default' : variants[0];

  if (subsets.length < 1) {
    subsets.push('singleFamily');
  }

  const rowValues = {
    default: {
      buildingCount: row.buildingCount,
      medianYrBuilt: row.medianYrBuilt,
      medianUnitArea: row.medianUnitArea,
      medianLivableArea: row.medianLivableArea,
      medianTotalUnits: row.medianTotalUnits,
    },
    singleFamily: {
      buildingCount: row.buildingCountSF,
      medianYrBuilt: row.medianYrBuiltSF,
      medianUnitArea: row.medianUnitAreaSF,
      medianLivableArea: row.medianLivableAreaSF,
      medianTotalUnits: row.medianTotalUnitsSF,
    },
    multiFamily: {
      buildingCount: row.buildingCountMF,
      medianYrBuilt: row.medianYrBuiltMF,
      medianUnitArea: row.medianUnitAreaMF,
      medianLivableArea: row.medianLivableAreaMF,
      medianTotalUnits: row.medianTotalUnitsMF,
    },
  };

  return rowValues[variant] as IBuildingValue;
};

const getVariantTitle = (variantId: string) => {
  const variant = PhillyBuildings.subsetVariants?.find((s) => s.id === variantId);

  return variant?.title || 'All';
};

const cacheValue = ({row, variant}: {row: ITractDataValue; variant: VariantType}) => {
  const key = row.tractId || row.stateCountyId;
  cacheDownloadTractData(row);
  cacheDownloadValue(key, 'Type', getVariantTitle(variant), row);
  PhillyBuildings.subsets?.forEach((s) => {
    const columnKey = columnKeys[variant][s.id as ValueType];
    cacheDownloadValue(key, s.title, row[columnKey] as number, row);
  });
};

const getBucketPosition = (value: number, lCutoff: number, hCutoff: number, isInverted?: boolean) => {
  if (value <= lCutoff) {
    return isInverted ? 'H' : 'L';
  } else if (value >= hCutoff) {
    return isInverted ? 'L' : 'H';
  } else {
    return 'M';
  }
};

export const PhillyBuildings: IDatasetOptions<IBuildingValue> = {
  id: 'buildings',
  title: 'Philadelphia Buildings',
  downloadTitle: 'Philadelphia Buildings',
  description:
    'The Philadelphia building dataset displays the city’s residential building stock through an equity lens, to guide and inform the City in advancing a clean energy transition that is equitable and just. The dataset includes breakdowns by building type, count, age, and size aggregated by census tract.',
  combinable: true,
  hideCutoffs: true,
  subsetType: 'radioSlider',
  subsetVariantsType: 'checkbox',
  subsetTitle: 'Type',
  subsets: [
    {id: 'buildingCount', title: 'Building Count', description: 'Building Count', selectorType: 'slider', color: Theme.Colors.Greens._000},
    {
      id: 'medianYrBuilt',
      title: 'Median Year Built',
      description: 'Median Year Built',
      selectorType: 'slider',
      color: Theme.Colors.Blues._000,
      isInverted: true,
      isInvertedHigh: true,
      topLabel: 'Earliest',
      bottomLabel: 'Latest',
    },
    {
      id: 'medianUnitArea',
      title: 'Median Unit Area',
      description: 'Median Unit Area',
      selectorType: 'slider',
      color: Theme.Colors.Yellows._000,
    },
    {
      id: 'medianLivableArea',
      title: 'Median Livable Area',
      description: 'Median Livable Area',
      selectorType: 'slider',
      color: Theme.Colors.Reds._000,
    },
    {
      id: 'medianTotalUnits',
      title: 'Median Total Units',
      description: 'Median Total Units',
      selectorType: 'slider',
      color: Theme.Colors.Purples._000,
    },
  ],
  subsetVariants: [
    {
      id: 'singleFamily',
      title: 'Single Family',
      description: 'Single Family',
      selectorType: 'checkbox',
    },
    {
      id: 'multiFamily',
      title: 'Multi Family',
      description: 'Multi Family',
      selectorType: 'checkbox',
    },
  ],
  defaultSubsets: ['buildingCount'],

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => {
    const [subset] = config.subsets;
    if ((subset === 'medianLivableArea' && value >= LIVEABLE_AREA_MAX) || (subset === 'medianTotalUnits' && value >= TOTAL_UNITS_MAX)) {
      return formatterMap[config.subsets[0] as ValueType] && formatterMap[config.subsets[0] as ValueType](value) + '+';
    } else return formatterMap[config.subsets[0] as ValueType] && formatterMap[config.subsets[0] as ValueType](value);
  },

  // For this subset, we need the min max of all the rows.
  // Substitute the subsetId of the selected subset since
  // this dataset has multiple subset values.
  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[], subsetId: string = '') => {
    return getMinMax(getAllValues({...config, subsets: [subsetId]}, rows, getSingleValue, PhillyBuildings.downloadTitle), true);
  },

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const map: ITractColorMap = {fills: [], opacities: []};
    if (!hasData(rows, config)) {
      return map;
    }

    const subsetValues = {} as Record<string, number>;
    const [subset] = config.subsets as ValueType[];
    const isInverted = PhillyBuildings.subsets?.find((s) => s.id === subset)?.isInverted;
    const variant = getVariant(config);
    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;
      subsetValues[key] = getSingleValue(config, row);
      cacheValue({row, variant});
    });

    const [min, max] = getMinMax(subsetValues);

    const subsetIndex = PhillyBuildings.subsets?.findIndex((s) => s.id === subset) || 0;
    const conditionalMinMax = isInverted
      ? [min, config.radioSliderValues[subsetIndex] || max]
      : [config.radioSliderValues[subsetIndex] || min, max];

    Object.entries(subsetValues).forEach(([tractId, value]) => {
      const colorBuckets = isInverted ? [...fillBuckets].reverse() : fillBuckets;
      map.fills.push(tractId);
      map.fills.push(
        colorBuckets[getValueBucket(value, conditionalMinMax[0], conditionalMinMax[1], opacityBuckets.length)] || 'transparent ',
      );
      map.opacities.push(String(tractId));
      map.opacities.push(1);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;
    if (!hasData(rows, config)) {
      return map;
    }

    const subsetValues = {} as Record<string, number>;
    const [subset] = config.subsets as ValueType[];
    const subsetIndex = PhillyBuildings.subsets?.findIndex((s) => s.id === subset) || 0;
    const isInverted = PhillyBuildings.subsets?.find((s) => s.id === subset)?.isInverted;
    const variant = getVariant(config);

    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId;
      subsetValues[key] = getSingleValue(config, row);
      cacheValue({row, variant});
    });

    const [min, max] = getMinMax(subsetValues);

    const conditionalMinMax = isInverted
      ? [min, config.radioSliderValues[subsetIndex] || max]
      : [config.radioSliderValues[subsetIndex] || min, max];

    const floor = config.radioSliderValues[subsetIndex] || conditionalMinMax[0];

    const lCutoff = floor + (conditionalMinMax[1] - conditionalMinMax[0]) * 0.2;
    const hCutoff = floor + (conditionalMinMax[1] - conditionalMinMax[0]) * 0.8;

    Object.entries(subsetValues).forEach(([tractId, value]) => {
      map.values[tractId] = getBucketPosition(value, lCutoff, hCutoff, isInverted);
    });

    return map;
  },
};
