import * as yup from 'yup';
import {IProduct} from '../../API';

interface IProductSchema {
  products: IProduct[];
  product?: IProduct;
}

export const productSchema = ({products, product}: IProductSchema) =>
  yup.object({
    name: yup
      .string()
      .trim()
      .required('Please enter product name.')
      .test(
        'is-unique', // product name is either same as current name, or a unique new name
        'Please choose a unique product name.',
        (value) =>
          product?.name.toLowerCase() === value?.toLowerCase() ||
          !Boolean(products?.find((p: IProduct) => p?.name?.trim().toLowerCase() === value?.toLowerCase())),
      ),
    initialLat: yup
      .number()
      .label('Center latitude')
      .required('Please enter center latitude.')
      .min(-90, 'Please enter valid latitude. Minimum is -90.')
      .max(90, 'Please enter valid latitude. Maximum is 90.'),
    initialLon: yup
      .number()
      .label('Center longitude')
      .required('Please enter center longitude.')
      .min(-180, 'Please enter valid longitude. Minimum is -180.')
      .max(80, 'Please enter valid longitude. Maximum is 80.'),
    initialZoom: yup
      .number()
      .label('Initial zoom')
      .required('Please enter initial zoom.')
      .min(0, 'Please enter valid zoom. Minimum is 0.')
      .max(22, 'Please enter valid zoom. Maximum is 22.'),
  });
