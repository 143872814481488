import {FC, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import {Body2, Subhead3} from '../Atoms';

const Wrapper = styled.div`
  margin: 16px 0 0;
  width: 100%;
  flex: 0;
`;

const Inner = styled.div`
  flex-direction: row;
  width: 100%;
  flex: 0;
`;

const Value = styled(Body2)`
  flex: 0 0 52px;
  width: 52px;
  text-align: right;
  white-space: nowrap;
  margin-bottom: 8px;
`;

const Label = styled(Subhead3)`
  flex: 0;
  margin-bottom: 8px;
  color: ${(props) => props.theme.Colors.Neutrals._200};
`;

const Slider = styled.input`
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  height: 8px;
  flex: 1;
  margin: 6px 12px 0 12px;
  border-radius: 4px;
  background: ${(props) => props.theme.Colors.Neutrals._500};

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.Colors.Oranges._000};
    border-radius: 100%;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: ${(props) => props.theme.Colors.Oranges._000};
    border-radius: 100%;
    cursor: pointer;
  }
`;

export interface EquityIndexSliderProps {
  value: number;
  label: string;
  tooltip?: string;
  onChange: (value: number) => void;
}

export const EquityIndexSlider: FC<EquityIndexSliderProps> = ({label, tooltip, value, onChange, ...props}) => {
  // Debounce the onChange event so we don't redraw the map until the user lets go of the control
  const [val, setVal] = useState<number>(value || 0);

  // We do this 1..100 because native input-range controls don't handle very small decimals very well. They work if the range is say
  // 12..73, but not if it's 0.031..0.047.
  // const extraProps = tooltip ? ({'data-tip': tooltip} as any) : ({} as any);

  // TODO: All tooltips need their own ID!
  return (
    <>
      <Wrapper {...props}>
        <Label>{label}</Label>

        <Inner>
          <Slider
            type="range"
            min={1}
            max={10}
            step={1}
            value={val}
            onChange={(e) => setVal(+e.target.value)}
            onMouseUp={() => onChange(val)}
          />
          <Value>X {value}</Value>
        </Inner>
      </Wrapper>

      <ReactTooltip
        id="filter-slider-1"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: -7, left: -5}}
        wrapper="span"
        className="info-tooltip"
      />
    </>
  );
};
