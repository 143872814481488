import {FC} from 'react';
import {H6, Modal} from '../../Components';

export const OrgChangeModal: FC<{isOpen: boolean; onClick1: () => void; onClick2: () => void; message?: string}> = ({
  isOpen = false,
  onClick1,
  onClick2,
  message = 'Changing your org type requires you to cancel your subscription. View your subscription payment details to get started.',
}) => {
  return (
    <Modal
      title="Want to changed your organization type?"
      isOpen={isOpen}
      button1Outline={true}
      button1Text="Cancel"
      button1Action={onClick1}
      button2Outline={false}
      button2Text="Update Subscription"
      button2Action={onClick2}>
      <div style={{flexDirection: 'column', alignItems: 'center'}}>
        <img alt="Person shopping" width="250" height="250" src="/person-shopping.png"></img>
        <H6 style={{marginTop: '20px'}}>Update Your Subscription</H6>
        <p style={{marginTop: 0, marginBottom: '20px', maxWidth: '400px', textAlign: 'center'}}>{message}</p>
      </div>
    </Modal>
  );
};
