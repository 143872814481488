import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {IUser} from '../../API';
import {H6} from '../Atoms';

const Wrapper = styled(H6)`
  width: 52px;
  height: 52px;
  color: #fff;
  margin: 0;
  border-radius: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  background-color: ${(props) => props.theme.Colors.Oranges._000};
`;

interface AvatarProps {
  user: IUser | null;
}

/**
 * Displays a bubble with the first letter of a user's first name and the first letter of a user's last name.
 */
export const Avatar: FC<AvatarProps> = ({user}) => (
  <Wrapper>
    {user?.firstName?.substr(0, 1)}
    {user?.lastName?.substr(0, 1)}
  </Wrapper>
);
