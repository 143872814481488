// This is here rather than in Components because it's not reused

import {useAuth0} from '@auth0/auth0-react';
import {FC} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled, {CSSProperties} from 'styled-components/macro';
import {setAccessToken, useAdminProducts, useGetMyOrganization} from '../../API';
import {useRoles} from '../../Hooks/useRoles';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {AddressSearch, AvatarMenu, H6, Logo, TAvatarMenuAction, TextButton} from '../../Components';
import {Dropdown} from '../../Components/Molecules/Dropdown';
import {routePaths} from '../../constants/routePaths';
import {completeProductList, formatAuthorizationTitle} from '../Admin/adminUtils';
import {mapComponent} from './MapBox';
import {resetPersist} from '../../store';

// This is a more accurate layout mechanism for edge-case browser widths and heights than trying to use a single container
const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  position: absolute;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 14px;
  flex: 0 0 80px;
  flex-wrap: wrap;
  background: #fff;
  align-items: center;
  border-radius: 16px;
  gap: 16px;
  flex-direction: row;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

const ProductWrapper = styled.div`
  margin-right: 29px;
  max-width: 300px;
`;

const ProductPicker: FC = () => {
  const {isAdmin} = useRoles();
  const {data: myProfile} = useMyProfile();
  // TODO: The metaphor here is kind of weird. We're calling an "admin" named endpoint for every user-view load. And actually we call it
  // twice because the header is rendered very early, which async session-load functions are still completing. So we make a couple of
  // calls that error out with 401's because we haven't even set the user's token yet. We should probably refactor this cycle.
  const products = useAdminProducts(isAdmin)?.data;
  const organization = useGetMyOrganization(!!myProfile?.user?.organizationId);
  const authorizations = organization?.data?.authorizations;

  const options =
    authorizations?.map((auth) => ({
      value: `${auth.id}`,
      label: formatAuthorizationTitle(auth, completeProductList({products})),
    })) || [];

  options.sort((c, d) => {
    const a = c.label?.split(':')[1];
    const b = d.label?.split(':')[1];
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  });

  options?.unshift({label: 'Choose a Location...', value: '0'});

  const handleSelectProduct = (value: any) => {
    const authorization = authorizations?.find((auth) => auth.id.toString() === value.value);
    const authProduct = completeProductList({products: products}).find(
      (product) => product.id === `${authorization?.targetType}:${authorization?.targetId}`,
    );
    const {initialLat, initialLon, initialZoom = 6} = authProduct || {};
    if (initialLat && initialLon) {
      mapComponent?.flyTo({center: [initialLon, initialLat], zoom: initialZoom});
    }
  };

  return (
    <ProductWrapper>
      <Dropdown id="authorization-select" options={options} defaultValue={options[0]} onChange={handleSelectProduct} isSearchable={false} />
    </ProductWrapper>
  );
};

export const Header: FC<{style?: CSSProperties}> = ({style}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {logout} = useAuth0();
  const {data: myProfile} = useMyProfile();
  const organization = useGetMyOrganization(!!myProfile?.user?.organizationId);
  const {isOrgAdmin, isAdmin} = useRoles();
  const isLocal = organization?.data?.subscription_type === 'local';

  // TODO: If we had more time I'd refactor this to a common menu component. See AdminMenu.tsx as well.
  const handleSelectAction = (action: TAvatarMenuAction) => {
    switch (action) {
      case 'map':
        navigate(routePaths.map);
        break;

      case 'help':
        alert('TODO');
        break;

      case 'admin':
        if (isAdmin) {
          navigate(routePaths.admin);
        } else if (isOrgAdmin && !isLocal) {
          navigate(routePaths.orgAdminOrganization);
        }
        break;

      case 'logout':
        setAccessToken(null);
        logout({returnTo: window.location.origin + '/'});
        resetPersist();
        break;
    }
  };
  const hasNationwide = organization?.data?.authorizations?.find((authorization) => authorization.targetType === 'nationwide');

  const handleSelectAddress = ({lat, lng, entry}: {lat: number; lng: number; entry: any}) => {
    console.log('Selected Address, flying to...', entry.types);
    let zoomTo = 0;
    // State
    if ((entry.types || []).includes('administrative_area_level_1')) zoomTo = 6.5;
    // County
    else if ((entry.types || []).includes('administrative_area_level_2')) zoomTo = 8.5;
    // City
    else if ((entry.types || []).includes('locality')) zoomTo = 10.5;
    // "Manhattan"
    else if ((entry.types || []).includes('sublocality')) zoomTo = 11.5;
    // "Edgewood, Atlanta, GA"
    else if ((entry.types || []).includes('neighborhood')) zoomTo = 13;

    // Bugfender.log(`Selected Address`, entry, {lat, lng, zoomTo});
    if (zoomTo) {
      console.log('Zooming', zoomTo);
      mapComponent?.flyTo({center: [lng, lat], zoom: zoomTo, essential: true});
    } else {
      mapComponent?.flyTo({center: [lng, lat], essential: true});
    }
  };

  const isMapPage = location.pathname === routePaths.map;
  const showProductPicker = !hasNationwide && organization?.data?.showProducts && !isAdmin;

  return (
    <HeaderContainer style={style}>
      <HeaderWrapper>
        <Logo />
        <TextButton
          onClick={() => navigate(routePaths.adminOrganizationsById.replace(':organizationId', myProfile?.user?.organizationId || ''))}
          style={{
            width: '100%',
            margin: 0,
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: !isAdmin ? 'default' : 'pointer',
          }}
          disabled={!isAdmin}>
          <H6 style={{margin: '0 32px'}}>{myProfile?.user?.organization?.name}</H6>
        </TextButton>
        {showProductPicker && isMapPage && <ProductPicker />}
        {!showProductPicker && isMapPage && <AddressSearch onSelectAddress={handleSelectAddress} />}
        {/* <Button onClick={() => navigate(routePaths.settings)} /> */}
        <AvatarMenu onSelectAction={handleSelectAction} />
      </HeaderWrapper>
    </HeaderContainer>
  );
};
