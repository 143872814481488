// We cache calculated values here for two reasons. First, when we go to download a data set, we don't want to have to recalculate
// everything because with some sets like Equity Index that's expensive. Second, it's hard for the download routines to "get to"
// the data set calculators in the first place because they're decoupled. So as we calculate data we store it here for later use
// by anything that needs it.

import html2canvas from 'html2canvas';
import {unparse} from 'papaparse';
import {ICountyData, ITractDataValue} from '../API';
import logo from '../Components/Atoms/img/gem-logo.png';
import {IDatasetConfig} from './Datasets/Types';
import {formatIncomeStressCurrencyInt} from './MathUtils';

interface IDownloadValue {
  label: string;
  value: number | string;
}

let cachedValues = {} as Record<string, number>;
let downloadValues = {} as Record<string, IDownloadValue[]>;

/**
 * Get a value from the cache. This cache is not just used for downloads. Equity Index uses it too to help bypass expensive recalculations.
 */
export const getCachedValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  const key = row.tractId || row.stateCountyId || '';
  if (['8031000904'].includes(key)) {
    console.log('Getting cached value', key, cachedValues[key]);
  }

  return cachedValues[key] || 0;
};

/**
 * Clear the value cache
 */
export const clearCachedValues = () => {
  cachedValues = {};
  downloadValues = {};
};

/**
 * Cache a value.
 */
export const cacheValue = (row: ITractDataValue, value: number) => {
  const key = row.tractId || row.stateCountyId || '';
  cachedValues[key] = value;
};

/**
 * Cache a value.
 */
export const cacheValueByKey = (key: string, value: number) => {
  cachedValues[key] = value;
};

let countyData = null as ICountyData | null;

export const cacheCountyData = (data: ICountyData) => {
  countyData = data;
};

/**
 * Cache a value for later downloads. This was tricky to wire up because data sets all arrive at their calculations in different
 * ways. Some, like equity index, are further complicated because they aggregate multiple base values. What we're doing here is
 * building a set of entries that unparse() can use later. See https://www.papaparse.com/docs and 'With implicit header row'
 * for this structure.
 */
export const cacheDownloadTractData = (row: ITractDataValue) => {
  if (row.tractId) {
    const key = row.tractId;
    downloadValues[key] = downloadValues[key] || [];
    cacheDownloadValue(key, 'County', countyData?.countyName || '', row);
    cacheDownloadValue(key, 'State', countyData?.stateName || '', row);
  } else {
    const key = row.stateCountyId || '';
    downloadValues[key] = downloadValues[key] || {};
    cacheDownloadValue(key, 'County', countyData?.countyName || '', row);
    cacheDownloadValue(key, 'State', countyData?.stateName || '', row);
  }
};

/**
 * Cache a value for later downloads. This was tricky to wire up because data sets all arrive at their calculations in different
 * ways. Some, like equity index, are further complicated because they aggregate multiple base values. What we're doing here is
 * building a set of entries that unparse() can use later. See https://www.papaparse.com/docs and 'With implicit header row'
 * for this structure.
 */
export const cacheDownloadValue = (key: string, label: string, value: number | string, row: ITractDataValue) => {
  cacheSingleDownloadValue(key, label, value);
  cacheSingleDownloadValue(key, 'Disadvantage Communities', row.disadvComm || '');
  cacheSingleDownloadValue(key, 'Households', row.over200k || '');
  cacheSingleDownloadValue(key, 'Median Income', formatIncomeStressCurrencyInt(row.incomeStress) || '');
};

export const cacheSingleDownloadValue = (key: string, label: string, value: number | string) => {
  downloadValues[key] = downloadValues[key] || [];

  // check if is array

  const entry = Array.isArray(downloadValues?.[key]) && downloadValues?.[key]?.find((e) => e.label === label);
  if (entry) {
    entry.value = value;
  } else {
    Array.isArray(downloadValues?.[key]) && downloadValues[key].push({label, value});
  }
};

/**
 * Trigger a local file download.
 * This is better in React than doing window.href= or similar to trigger a download. For a description of the technique
 * see https://stackoverflow.com/questions/8126623/downloading-canvas-element-to-an-image
 */
const localDownload = (filename: string, data: string) => {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function () {
    const url = window.URL.createObjectURL(xhr.response);

    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();

    window.URL.revokeObjectURL(url);
  };

  xhr.open('GET', data);
  xhr.send();
};

const logoWidth = 228;
const logoHeight = 53;

const downloadImageWithLogo = (filename: string, ctx: CanvasRenderingContext2D, startXPos = 0) => {
  // white background for logo with rounded corners

  ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
  ctx.beginPath();
  ctx.moveTo(startXPos + 40, 20);
  ctx.lineTo(startXPos + logoWidth + 20, 20);
  ctx.arcTo(startXPos + logoWidth + 40, 20, startXPos + logoWidth + 40, 40, 20);
  ctx.lineTo(startXPos + logoWidth + 40, logoHeight + 20);
  ctx.arcTo(startXPos + logoWidth + 40, 92, startXPos + logoWidth + 20, 92, 20);
  ctx.lineTo(startXPos + 40, logoHeight + 40);
  ctx.arcTo(startXPos + 20, logoHeight + 40, startXPos + 20, logoHeight + 20, 20);
  ctx.lineTo(startXPos + 20, 40);
  ctx.arcTo(startXPos + 20, 20, startXPos + 40, 20, 20);
  ctx.fill();

  const logoImg = new Image();
  logoImg.src = logo;
  logoImg.onload = () => {
    ctx.drawImage(logoImg, startXPos + 30, 30, logoWidth, logoHeight);
    localDownload(filename, ctx.canvas.toDataURL('image/png'));
  };
};

/**
 * Download the current map view as a PNG file.
 */
export const downloadImage = async (filename: string) => {
  const mapCanvas = document.getElementsByClassName('mapboxgl-canvas')[0] as HTMLCanvasElement;

  const newCanvas = document.createElement('canvas');
  const ctx = newCanvas.getContext('2d');

  const openLegendButton = document.getElementById('open-legend') as HTMLButtonElement;
  if (openLegendButton) {
    openLegendButton.click();
    // wait for the legend to open
    await new Promise((resolve) => setTimeout(resolve, 200));
  }

  const legendInnerDiv = document.getElementById('map-panel') as HTMLDivElement;

  if (!legendInnerDiv) {
    newCanvas.width = mapCanvas.width;
    newCanvas.height = mapCanvas.height;

    if (ctx) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, mapCanvas.width, mapCanvas.height);
      ctx.drawImage(mapCanvas, 0, 0);

      downloadImageWithLogo(filename, ctx, 30);
    }
    return;
  }

  const boxShadow = legendInnerDiv.style.boxShadow;
  legendInnerDiv.style.boxShadow = 'none';

  html2canvas(legendInnerDiv, {scrollY: -window.scrollY}).then((legendCanvas) => {
    newCanvas.width = mapCanvas.width + legendCanvas.width;
    newCanvas.height = Math.max(mapCanvas.height, legendCanvas.height + 80);

    if (ctx) {
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
      ctx.drawImage(mapCanvas, 0, 0);
      ctx.drawImage(legendCanvas, mapCanvas.width, 80);

      downloadImageWithLogo(filename, ctx, mapCanvas.width + legendCanvas.width / 2 - logoWidth / 2 - 20);
    }
  });

  if (legendInnerDiv) {
    legendInnerDiv.style.boxShadow = boxShadow;
  }

  if (openLegendButton) {
    const closeButton = document.getElementById('close-legend') as HTMLButtonElement;
    closeButton?.click();
  }
};

/**
 * Download the current map data as a CSV file.
 */
export const downloadMapData = (filename: string) => {
  const rows = Object.values(downloadValues);
  const [firstRow] = rows;
  console.log('Download data row sample', firstRow);

  // Prepare two suffix row entries in the second column
  const suffixRows = [
    ['', 'Data from Greenlink Equity Map'],
    ['', 'https://www.equitymap.org/'],
  ];

  const csv = unparse({
    fields: firstRow.map(({label}) => label),
    data: [...rows.map((columns) => columns.map(({value}) => value)), ...suffixRows],
  });

  localDownload(filename, 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv));
};
