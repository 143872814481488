import styled from 'styled-components/macro';

export const Container = styled.div`
  background: white;
  overflow: scroll;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

export const Table = styled.table`
  overflow-y: scroll;
  width: 100%;
`;

export const THead = styled.thead``;

export const TBody = styled.thead``;

export const TH = styled.th`
  text-align: left;
  padding: 6px 16px;
  border-bottom: 1px solid #f3f3f4;

  h6 {
    line-height: 52px;
    margin: 0;
  }
`;

export const TD = styled.td`
  text-align: left;
  padding: 12px 16px;
  border-bottom: 1px solid #f3f3f4;
`;

export const THR = styled.tr``;

export const TBR = styled.tr``;

// Ran out of time/budget but wanted to at least improve this a LITTLE
export const SimpleSelect = styled.select`
  background: ${(props) => props.theme.Colors.Neutrals._500};
  border-radius: 16px;
  border: 3px solid ${(props) => props.theme.Colors.Neutrals._500};
  padding: 2px 1em;
  outline: none;
  font-family: Poppins, sans-serif;
`;
