import {Auth0Provider} from '@auth0/auth0-react';
import React, {FC} from 'react';
import {toast} from 'react-hot-toast';
import {MutationCache, QueryCache, QueryClient, QueryClientProvider} from 'react-query';
import {Provider as StateProvider} from 'react-redux';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import {ThemeProvider} from 'styled-components';
import {Theme} from './Components';
import {CustomToaster} from './Components/Molecules/CustomToaster';
import {MixpanelContextProvider} from './Managers/Mixpanel/MixpanelContext';
import {routePaths} from './constants/routePaths';
import {getConfig} from './getConfig';
import {persistor, store} from './store';
import {MainRouter} from './Views/MainRouter';

const {REACT_APP_APP_ENV = 'local'} = process.env;

const Auth0ProviderWithRedirectCallback: FC = ({children}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(routePaths.onBoardingConfirm);
  };
  return (
    <Auth0Provider
      audience={config.audience}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation={window.Cypress ? 'localstorage' : 'memory'}
      clientId={config.auth0ClientId}
      domain={config.authBaseURL}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
      scope="openid profile email picture">
      {children}
    </Auth0Provider>
  );
};

function handleError(err: any, query: any) {
  if (REACT_APP_APP_ENV === 'local') {
    if (!(err?.response?.status >= 400 && err?.response?.status < 500)) {
      toast.error(
        'Error: ' +
          (query?.state?.error?.response?.data || err?.response?.message || err?.response?.data || err?.message || 'Unknown error'),
      );
    }

    // if (err?.response?.status >= 400 && err?.response?.status < 500) {
    //   toast(query?.state?.error?.response?.data || err?.response?.data);
    // }
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 1000 * 60 * 5,
    },
  },
  queryCache: new QueryCache({
    onError: handleError,
  }),
  mutationCache: new MutationCache({
    onError: handleError,
  }),
});

const config = getConfig();

const App: FC = () => {
  // TODO: Parameterize the domain and client ID for Auth0 so we can support stage/prod
  return (
    <React.StrictMode>
      <StateProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={Theme}>
            <BrowserRouter>
              <Auth0ProviderWithRedirectCallback>
                <QueryClientProvider client={queryClient}>
                  <MixpanelContextProvider debug={false}>
                    <MainRouter />
                    <CustomToaster />
                  </MixpanelContextProvider>
                </QueryClientProvider>
              </Auth0ProviderWithRedirectCallback>
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </StateProvider>
    </React.StrictMode>
  );
};

export default App;
