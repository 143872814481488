import {Endpoint, PropublicaEndpoint} from '../Endpoint';
import {ICreateMySubscription, IOrganization} from '../Types';

interface ICreateOrgUser {
  email: string;
  user_type?: string;
  user_status?: string;
}

interface IUpdateOrgUser {
  id: string;
  email: string;
  user_type?: string;
  user_status?: string;
}

interface IRemoveOrgUser {
  id: string;
}

interface IProductMyOrganization {
  targetId: string;
  targetType: string;
}

interface IUpdatePaymentMethod {
  paymentMethodId: string;
}

interface ICancelMySubscription {
  reason?: string;
}

interface IFinalizeMySubscription {
  paymentIntent: any;
  primary_email: string;
}

export const updateMyOrganization = (params: any) => Endpoint.post('/updateMyOrganization', params).then((r) => r.data);

export const createMySubscription = (params: any) =>
  Endpoint.post('/createMySubscription', params).then((r: {data: ICreateMySubscription}) => r.data);

export const updateMySubscription = (params: any) => Endpoint.post('/updateMySubscription', params).then((r) => r.data);

export const createOrgUser = (params: ICreateOrgUser) => Endpoint.post('/createOrgUser', params).then((r) => r.data);

export const getOrgUsers = () => Endpoint.get('/getOrgUsers').then((r) => r.data);

export const removeOrgUser = (params: IRemoveOrgUser) => Endpoint.post('/removeOrgUser', params).then((r) => r.data);

export const updateOrgUser = (params: IUpdateOrgUser) => Endpoint.post('/updateOrgUser', params).then((r) => r.data);

export const addProductToMyOrganization = (params: IProductMyOrganization) =>
  Endpoint.post('/addProductToMyOrganization', params).then((r) => r.data);

export const removeProductFromMyOrganization = (params: IProductMyOrganization) =>
  Endpoint.post('/removeProductFromMyOrganization', params).then((r) => r.data);

export const clearMyOrganizationProducts = () => Endpoint.post('/clearMyOrganizationProducts').then((r) => r.data);

export const listSubscriptionInvoices = () => Endpoint.get('/listSubscriptionInvoices').then((r) => r.data?.data);

export const getSubscriptionPaymentMethod = () => Endpoint.get('/getSubscriptionPaymentMethod').then((r) => r.data);

export const cancelMySubscription = (params: ICancelMySubscription) => Endpoint.post('/cancelMySubscription', params).then((r) => r.data);
export const getSetupIntent = () => Endpoint.get('/getSetupIntent').then((r) => r.data);

export const updateMyPaymentMethod = (params: IUpdatePaymentMethod) => Endpoint.post('/updateMyPaymentMethod', params).then((r) => r.data);

export const finalizeMySubscription = (params: IFinalizeMySubscription) =>
  Endpoint.post('finalizeMySubscription', params).then((r) => r.data);

export const getPropublicaNonprofitSearch = (params: any) => PropublicaEndpoint.get(`/search.json`, {params}).then((r) => r.data);

export const getSearchPropublica = (params: any) => Endpoint.get(`/searchPropublica`, {params}).then((r) => r.data);

export const getPropublicaOrg = (params: any) => Endpoint.get(`/getPropublicaOrg`, {params}).then((r) => r.data);

export const checkForDuplicateOrg = (params: any) => Endpoint.get(`/checkForDuplicateOrg`, {params}).then((r) => r.data);

export const checkForDuplicateEmail = (params: any) => Endpoint.get(`/checkForDuplicateEmail`, {params}).then((r) => r.data);

export const checkForExistingEmailDomain = (params: any) =>
  Endpoint.get<{domain?: String; organization?: IOrganization}>(`/checkForExistingEmailDomain`, {params}).then((r) => r.data);
