// This is here rather than in Components because it's not reused

import {useAuth0} from '@auth0/auth0-react';
import {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import styled, {CSSProperties} from 'styled-components/macro';
import {setAccessToken} from '../../API';
import {useRoles} from '../../Hooks/useRoles';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {AvatarMenu, Body1, Logo, Subhead1, TAvatarMenuAction} from '../../Components';
import {routePaths} from '../../constants/routePaths';
import {resetPersist} from '../../store';

// This is a more accurate layout mechanism for edge-case browser widths and heights than trying to use a single container
const HeaderContainer = styled.div`
  justify-content: flex-start;
  flex: 0;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0px;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  flex-direction: row;
`;

export const OnboardingHeader: FC<{style?: CSSProperties}> = ({style}) => {
  const navigate = useNavigate();
  const {logout} = useAuth0();
  const {data: myProfile} = useMyProfile();
  const {isOrgAdmin, isAdmin} = useRoles();

  // TODO: If we had more time I'd refactor this to a common menu component. See AdminMenu.tsx as well.
  const handleSelectAction = (action: TAvatarMenuAction) => {
    switch (action) {
      case 'map':
        navigate(routePaths.map);
        break;

      case 'help':
        alert('TODO');
        break;

      case 'admin':
        if (isAdmin) {
          navigate(routePaths.admin);
        } else if (isOrgAdmin) {
          navigate(routePaths.orgAdminOrganization);
        }
        break;

      case 'logout':
        setAccessToken(null);
        logout({returnTo: window.location.origin + '/'});
        resetPersist();
        break;
    }
  };

  return (
    <HeaderContainer style={style}>
      <HeaderWrapper>
        <Logo />

        {!!myProfile && (
          <div style={{flex: 0, display: 'flex', flexDirection: 'row', gap: 8, alignItems: 'center'}}>
            <div style={{flex: 1, justifyContent: 'center', alignItems: 'end'}}>
              <Subhead1 style={{margin: 0, whiteSpace: 'nowrap'}}>{myProfile?.user?.firstName + ' ' + myProfile?.user?.lastName}</Subhead1>
              <Body1 style={{margin: 0}}>{myProfile?.user?.email}</Body1>
            </div>
            <AvatarMenu onSelectAction={handleSelectAction} />
          </div>
        )}
      </HeaderWrapper>
    </HeaderContainer>
  );
};
