import {Row} from 'react-table';
import {FC} from 'react';
import {FlexRow, PillBadge} from '../../Components';

export const UserNameCell: FC<{value: string; row: Row}> = ({value, row}) => {
  return (
    <FlexRow style={{flexWrap: 'nowrap'}}>
      {row.values.user_status === 'inactive' && <PillBadge>New</PillBadge>}
      <span style={{alignSelf: 'center', whiteSpace: 'nowrap'}}>{value}</span>
    </FlexRow>
  );
};
