// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {MinusIcon, PlusIcon} from '@heroicons/react/outline';
import {FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {StandardButton} from '../../Components';
import {CENTER_POSITION} from '../../constants/constants';

mapboxgl.accessToken = 'pk.eyJ1IjoiY2xhcmt0dGVjaCIsImEiOiJjbDQ0cGMwYzkwM3FqM29uamIzaGlybWduIn0.5OxK78QHrXhlnys48Hx-bw';
mapboxgl.prewarm();

export interface IPosition {
  initialLon?: number | null;
  initialLat?: number | null;
  initialZoom?: number | null;
}
export interface ILocation {
  id: string;
  name: string;
  initialLon: number;
  initialLat: number;
  initialZoom: number;
}

interface DefaultMapProps {
  position?: IPosition | null;
  onPositionChange: ({lat, lng, zoom}: {lat: number; lng: number; zoom: number}) => void;
  currentSelectedLocation?: ILocation | null;
}

export const DefaultMap: FC<DefaultMapProps> = ({currentSelectedLocation, position, onPositionChange}) => {
  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  const lng = Number(position?.initialLon) || CENTER_POSITION.initialLon;
  const lat = Number(position?.initialLat) || CENTER_POSITION.initialLat;
  const zoom = Number(position?.initialZoom) || CENTER_POSITION.initialZoom;
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [layersLoaded, setLayersLoaded] = useState(false);

  const handlePositionChange = () => {
    onPositionChange({
      lat: map.current?.getCenter().lat.toFixed(4),
      lng: map.current?.getCenter().lng.toFixed(4),
      zoom: map.current?.getZoom().toFixed(2),
    });
  };

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/clarkttech/cllwa0sdf018101qigpyqb4ok',
      center: [lng, lat],
      zoom: zoom,
      maxZoom: 18,
      minZoom: 3,
      preserveDrawingBuffer: true,
      attributionControl: false,
      dragRotate: false,
      boxZoom: false,
      keyboard: false,
      pitchWithRotate: false,
      touchPitch: false,
    });

    map.current.on('move', () => {
      handlePositionChange();
    });

    map.current.on('style.load', () => setStyleLoaded(true));
  });

  useEffect(() => {
    if (!map.current || !lat) return;
    map.current.jumpTo({
      center: [lng, lat],
      zoom: zoom,
    });
  }, [lat, lng, zoom]);

  useEffect(() => {
    if (!map.current || !currentSelectedLocation || !layersLoaded) return;

    const [targetType, targetId] = currentSelectedLocation.id.split(':');
    // form file name/key based on the target type by the known form of the file names based off of ids
    const fileKey = targetType === 'state' ? `${targetId}-counties.geojson` : `${targetId.substring(0, 2)}-${targetId.substring(2)}.json`;
    map.current.getSource('tracts').setData(`https://storage.googleapis.com/greenlink-prod-web-resources/geojson-files/${fileKey}`);

    map.current.jumpTo({
      center: [currentSelectedLocation.initialLon, currentSelectedLocation.initialLat],
      // zoom: currentSelectedLocation.initialZoom,
    });
  }, [currentSelectedLocation, layersLoaded]);

  useEffect(() => {
    if (!map.current || !styleLoaded) return;

    const layers = map.current.getStyle().layers;
    const firstSymbolLayer = layers.find((layer: {type: string}) => layer.type === 'symbol');
    const firstSymbolId = firstSymbolLayer?.id;

    if (!map.current.getSource('tracts')) {
      map.current.addSource('tracts', {
        type: 'geojson',
        data: {type: 'FeatureCollection', features: []},
      });

      map.current.addLayer(
        {
          id: 'tract-fills',
          type: 'fill',
          source: 'tracts',
          layout: {},
          paint: {
            'fill-color': 'rgb(255,145,38)',
            'fill-opacity': 0.5,
          },
        },
        firstSymbolId,
      );

      map.current.addLayer(
        {
          id: 'tract-borders',
          type: 'line',
          source: 'tracts',
          layout: {},
          paint: {
            'line-color': '#ffffff',
            'line-width': 2,
          },
        },
        firstSymbolId,
      );
    }
    setLayersLoaded(true);
  }, [styleLoaded]);

  const handleZoomIn = () => {
    map.current.zoomIn();
  };

  const handleZoomOut = () => {
    map.current.zoomOut();
  };

  return (
    <div>
      <div style={{borderRadius: 20}} ref={mapContainer} />

      <ZoomButton onClick={handleZoomIn} outline style={{bottom: 60}} type="button">
        <PlusIcon style={{width: 20, height: 20}} />
      </ZoomButton>
      <ZoomButton onClick={handleZoomOut} outline style={{bottom: 16}} type="button">
        <MinusIcon style={{width: 20, height: 20}} />
      </ZoomButton>
    </div>
  );
};

const ZoomButton = styled(StandardButton)`
  display: flex;
  position: absolute;
  right: 16px;
  width: 36px;
  padding: 0;
  height: 36px;
  margin: 0;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
`;
