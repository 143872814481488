import styled from 'styled-components/macro';

export const StandardButton = styled.button<{outline?: boolean}>`
  color: ${(props) => (props.outline === true ? props.theme.Colors.Oranges._000 : 'white')};
  height: 52px;
  flex: 0 0 52px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 16px;
  font-family: 'Poppins', sans-serif;
  border: 2px solid ${(props) => props.theme.Colors.Oranges._000};
  background-color: ${(props) => (props.outline === true ? 'white' : props.theme.Colors.Oranges._000)};

  &:hover {
    cursor: pointer;
    background-color: ${(props) => (props.outline === true ? props.theme.Colors.Oranges._500 : props.theme.Colors.Oranges._100)};
  }
  &:disabled,
  &:disabled:hover {
    border: 2px solid ${(props) => props.theme.Colors.Oranges._300};
    background-color: ${(props) => (props.outline === true ? 'white' : props.theme.Colors.Oranges._300)};
    color: ${(props) => (props.outline === true ? props.theme.Colors.Oranges._300 : 'white')};
    cursor: default;
  }
`;

export const LegendButton = styled(StandardButton)`
  width: 217px;
`;

export const TextButton = styled.button`
  border: none;
  background-color: inherit;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 20px;
  flex: 0;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.color || props.theme.Colors.Oranges._100};
  }
`;
