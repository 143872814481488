import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';

const getValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  const subsets = [...(config?.subsets ?? [])];
  if (subsets.length < 1) {
    subsets.push('water', 'electric', 'gas');
  }

  const includesWater = subsets.includes('water');
  const includesGas = subsets.includes('gas');
  const includesElectric = subsets.includes('electric');

  if (includesWater && !includesGas && !includesElectric) {
    return +row.above10PercentWaterBurden;
  } else if (includesGas && !includesWater && !includesElectric) {
    return +row.above10PercentGasBurden;
  } else if (includesElectric && !includesWater && !includesGas) {
    return +row.above10PercentElectricBurden;
  } else if (includesGas && includesWater && !includesElectric) {
    return +row.above10PercentGasWaterBurden;
  } else if (includesElectric && includesWater && !includesGas) {
    return +row.above10PercentElecWaterBurden;
  } else if (includesElectric && includesGas && !includesWater) {
    return +row.above10PercentEnergyBurden;
  } else {
    return +row.above10PercentUtilityBurden;
  }
};

export const Above10EnergyBurden: IDatasetOptions<number> = {
  id: 'above10EnergyBurden',
  title: 'Utility Burden: Households with Above Average Energy Burden',
  downloadTitle: 'Energy Burden Above 10 Percent',
  combinable: true,
  cutoffDots: [],
  subsetType: 'checkbox',
  subsets: [
    {
      id: 'electric',
      title: 'Electricity',
      description: 'Electricity Burden shows the percent of median yearly income that households pay for electricity bills.',
      type: 'Both',
    },
    {
      id: 'gas',
      title: 'Gas',
      description: 'Gas Burden shows the percent of median yearly income that households pay for natural gas bills.',
      type: 'Both',
    },
    {
      id: 'water',
      title: 'Water',
      description: 'Water Burden shows the percent of median yearly income that households pay for water bills.',
      type: 'Both',
    },
  ],
  defaultSubsets: ['electric', 'gas'],
  description:
    'Energy Burden is the percent of median yearly income that households pay for electricity and gas bills. Households nationally on average pay about 3% of their income on energy bills. A household that pays more than 6% of their income on energy bills is considered to have high energy burden, while a household that pays more than 10% is considered to have severe energy burden. These indicators show the number of households with energy burdens above the 3% national average, the 6% threshold for high energy burden, or the 10% threshold for severe energy burden across different census tracts.',

  getValue,

  getEquityIndexValue: (config: IDatasetConfig, row: ITractDataValue) => getValue(config, row) / row.over200k,

  formatValue: (config: IDatasetConfig, value: number) => `${value}`,

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) =>
    getMinMax(getAllValues(config, rows, getValue, Above10EnergyBurden.downloadTitle)),

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = getAllValues(config, rows, getValue, Above10EnergyBurden.downloadTitle);
    const [min, max] = getMinMax(values);

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      map.fills.push(String(tractId));
      map.fills.push(fillBuckets[getValueBucket(value, config.cutoff1 || min, max, opacityBuckets.length)]);
      map.opacities.push(String(tractId));
      map.opacities.push(1);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    const values = getAllValues(config, rows, getValue, Above10EnergyBurden.downloadTitle);
    const [min, max] = getMinMax(values);
    const floor = config.cutoff1 || min;
    const lCutoff = floor + (max - min) * 0.2;
    const hCutoff = floor + (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (value <= lCutoff) {
        map.values[tractId] = 'L';
      } else if (value >= hCutoff) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
