import * as yup from 'yup';

interface IOrganizationSchema {
  nameFound?: boolean;
  isAdmin?: boolean;
}

export const organizationSchema = ({nameFound, isAdmin}: IOrganizationSchema) =>
  yup.object({
    name: yup
      .string()
      .trim()
      .test('nameFound', 'Organization name already exists.', () => {
        return !nameFound;
      })
      .required('Please enter organization name.'),
    address1: yup.string().required('Please enter organization address.'),
    // only required if not admin
    primary_email: isAdmin
      ? yup.string().nullable().email('Please enter a valid email.')
      : yup.string().required('Please enter an email address.').email('Please enter a valid email.'),
    address2: yup.string().nullable(),
    primary_contact: yup.string().nullable(),
    phone_number: yup.string().nullable(),
    city: yup.string().required('Please enter organization city.'),
    state: yup.string().required('Please enter organization state.'),
    zip: yup.string().required('Please enter organization zip code.'),
    initialLat: yup.number().nullable(),
    initialLon: yup.number().nullable(),
    initialZoom: yup.number().nullable(),
  });
