import {useAuth0} from '@auth0/auth0-react';
import {getConfig} from '../getConfig';

export const useRoles = () => {
  const ROLE_TYPES = {
    ADMIN: 'admin',
    ORGANIZATION_ADMIN: 'org-admin',
  };

  const {isLoading, user} = useAuth0();
  const config = getConfig();
  const roles = user?.[config.auth0RolesNamespace] || [];

  const isOrgAdmin = roles?.includes(ROLE_TYPES.ORGANIZATION_ADMIN);
  const isAdmin = roles?.includes(ROLE_TYPES.ADMIN);

  return {isOrgAdmin, isAdmin, isLoading};
};
