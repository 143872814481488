// This is a relatively small file to compile into the client code, and saves a server lookup
module.exports = [
  {stateFips: '01', code: '', name: 'Alabama', longitude: '-86.791130', latitude: '32.806671'},
  {stateFips: '02', code: '', name: 'Alaska', longitude: '-152.404419', latitude: '61.370716'},
  {stateFips: '04', code: '', name: 'Arizona', longitude: '-111.431221', latitude: '33.729759'},
  {stateFips: '05', code: '', name: 'Arkansas', longitude: '-92.373123', latitude: '34.969704'},
  {stateFips: '06', code: '', name: 'California', longitude: '-119.417931', latitude: '36.778259'},
  {stateFips: '08', code: '', name: 'Colorado', longitude: '-105.358887', latitude: '39.113014'},
  {stateFips: '09', code: '', name: 'Connecticut', longitude: '-72.755371', latitude: '41.597782'},
  {stateFips: '10', code: '', name: 'Delaware', longitude: '-75.500000', latitude: '39.318523'},
  {stateFips: '11', code: '', name: 'District of Columbia', longitude: '-77.0369', latitude: '38.9072'},
  {stateFips: '12', code: '', name: 'Florida', longitude: '-81.760254', latitude: '27.994402'},
  {stateFips: '13', code: '', name: 'Georgia', longitude: '-83.441162', latitude: '33.247875'},
  {stateFips: '15', code: '', name: 'Hawaii', longitude: '-155.844437', latitude: '19.741755'},
  {stateFips: '16', code: '', name: 'Idaho', longitude: '-114.742043', latitude: '44.068203'},
  {stateFips: '17', code: '', name: 'Illinois', longitude: '-88.986137', latitude: '40.349457'},
  {stateFips: '18', code: '', name: 'Indiana', longitude: '-86.258278', latitude: '39.849426'},
  {stateFips: '19', code: '', name: 'Iowa', longitude: '-93.210526', latitude: '42.011539'},
  {stateFips: '20', code: '', name: 'Kansas', longitude: '-96.726486', latitude: '38.526600'},
  {stateFips: '21', code: '', name: 'Kentucky', longitude: '-84.670067', latitude: '37.668140'},
  {stateFips: '22', code: '', name: 'Louisiana', longitude: '-91.867805', latitude: '31.169546'},
  {stateFips: '23', code: '', name: 'Maine', longitude: '-69.381927', latitude: '44.693947'},
  {stateFips: '24', code: '', name: 'Maryland', longitude: '-76.802101', latitude: '39.063946'},
  {stateFips: '25', code: '', name: 'Massachusetts', longitude: '-71.530106', latitude: '42.230171'},
  {stateFips: '26', code: '', name: 'Michigan', longitude: '-84.536095', latitude: '43.326618'},
  {stateFips: '27', code: '', name: 'Minnesota', longitude: '-93.900192', latitude: '45.694454'},
  {stateFips: '28', code: '', name: 'Mississippi', longitude: '-89.678696', latitude: '32.741646'},
  {stateFips: '29', code: '', name: 'Missouri', longitude: '-92.288368', latitude: '38.456085'},
  {stateFips: '30', code: '', name: 'Montana', longitude: '-110.454353', latitude: '46.921925'},
  {stateFips: '31', code: '', name: 'Nebraska', longitude: '-98.268082', latitude: '41.125370'},
  {stateFips: '32', code: '', name: 'Nevada', longitude: '-117.055374', latitude: '38.313515'},
  {stateFips: '33', code: '', name: 'New Hampshire', longitude: '-71.563896', latitude: '43.452492'},
  {stateFips: '34', code: '', name: 'New Jersey', longitude: '-74.521011', latitude: '40.298904'},
  {stateFips: '35', code: '', name: 'New Mexico', longitude: '-106.248482', latitude: '34.840515'},
  {stateFips: '36', code: '', name: 'New York', longitude: '-74.948051', latitude: '42.165726'},
  {stateFips: '37', code: '', name: 'North Carolina', longitude: '-79.806419', latitude: '35.630066'},
  {stateFips: '38', code: '', name: 'North Dakota', longitude: '-99.784012', latitude: '47.528912'},
  {stateFips: '39', code: '', name: 'Ohio', longitude: '-82.764915', latitude: '40.388783'},
  {stateFips: '40', code: '', name: 'Oklahoma', longitude: '-96.928917', latitude: '35.565342'},
  {stateFips: '41', code: '', name: 'Oregon', longitude: '-122.070938', latitude: '44.572021'},
  {stateFips: '42', code: '', name: 'Pennsylvania', longitude: '-77.209755', latitude: '40.590752'},
  {stateFips: '44', code: '', name: 'Rhode Island', longitude: '-71.511780', latitude: '41.680893'},
  {stateFips: '45', code: '', name: 'South Carolina', longitude: '-80.945007', latitude: '33.856892'},
  {stateFips: '46', code: '', name: 'South Dakota', longitude: '-99.438828', latitude: '44.299782'},
  {stateFips: '47', code: '', name: 'Tennessee', longitude: '-86.692345', latitude: '35.747845'},
  {stateFips: '48', code: '', name: 'Texas', longitude: '-97.563461', latitude: '31.054487'},
  {stateFips: '49', code: '', name: 'Utah', longitude: '-111.862434', latitude: '40.150032'},
  {stateFips: '50', code: '', name: 'Vermont', longitude: '-72.710686', latitude: '44.045876'},
  {stateFips: '51', code: '', name: 'Virginia', longitude: '-78.169968', latitude: '37.769337'},
  {stateFips: '53', code: '', name: 'Washington', longitude: '-121.490494', latitude: '47.400902'},
  {stateFips: '54', code: '', name: 'West Virginia', longitude: '-80.954453', latitude: '38.491226'},
  {stateFips: '55', code: '', name: 'Wisconsin', longitude: '-89.616508', latitude: '44.268543'},
  {stateFips: '56', code: '', name: 'Wyoming', longitude: '-107.302490', latitude: '42.755966'},
  {stateFips: '72', code: '', name: 'Puerto Rico', longitude: '-66.5901', latitude: '18.2208'},
];
