import {FC} from 'react';
import styled from 'styled-components/macro';
import {Body3} from '../Atoms';

const Wrapper = styled.div`
  flex-direction: row;
  margin: 0 0 12px;
`;

const Bar = styled.div`
  position: relative;
  height: 16px;
  margin: 0 8px;
  flex: 0 0 100px;
  overflow: hidden;
  border-radius: 4px;
  background-color: ${(props) => props.theme.Colors.Neutrals._500};
`;

const Highlight = styled.div`
  position: absolute;
  height: 16px;
  top: 0;
  left: 0;
`;

export interface IndicatorBarProps {
  formatter: (value: number) => string;
  value: number;
  min: number;
  max: number;
  label: string;
  color: string;
}

export const IndicatorBar: FC<IndicatorBarProps> = ({formatter, value, min, max, label, color}) => {
  const position = Math.max(4, (100 * (value - min)) / (max - min));

  return (
    <Wrapper>
      <Body3 style={{flex: '0 0 50px', color, margin: 0}}>{formatter(value)}</Body3>

      <Bar>
        <Highlight style={{width: `${position.toFixed(2)}%`, backgroundColor: color}} />
      </Bar>

      <Body3 style={{flex: '0 0 50px', whiteSpace: 'nowrap', margin: 0}}>{label}</Body3>
    </Wrapper>
  );
};
