import {FC, useEffect} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useIndicatorOptions} from '../../../API/Queries/useIndicatorOptions';
import {FilterSlider, FlexFill, H4, IndicatorPicker, Subhead2} from '../../../Components';
import {EquityIndex, PhillyBuildings, SolarEligibility} from '../../../Managers/Datasets';
import {AllDatasets} from '../../../Managers/MapManager';
import {setLegendOpen} from '../../../Redux/Slices/Map';
import {setDataset1, setDataset2} from '../../../Redux/Slices/User';
import {useAppDispatch, useAppSelector} from '../../../store';
import {IndicatorSearch} from './IndicatorSearch';
import {Subsets} from './Subsets';
import {useMyProfile} from '../../../API/Queries/useMyProfile';

// returns true if array1 contains at least 1 element from array2
const containsAny = (array1: string[], array2: string[]) => {
  return array1.some((item) => array2.includes(item));
};

const Wrapper = styled.div``;

const Indicator = styled.div`
  flex: 0;
  margin: 8px 0 20px;
`;

const NoDataWrapper = styled(Subhead2)`
  text-align: center;
  margin-top: 1em;
`;

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const FilterIndicators: FC = () => {
  const {indicators, indicatorOptions} = useIndicatorOptions();
  const {data: myProfile} = useMyProfile();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const mapData = useAppSelector((state) => state.map.mapData);
  const {dataset1, dataset2} = useAppSelector((state) => state.user);

  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);

  const serializedSubset = dataset1.subsets?.toString();
  const serializedSubset2 = dataset2.subsets?.toString();

  const options1 = searchParams.get('options1');
  const options1List = options1 && (searchParams.get('options1')?.split(',') || []);
  const options2 = searchParams.get('options2');
  const options2List = options2 && (searchParams.get('options2')?.split(',') || []);

  useEffect(() => {
    const showPhillyBuildings = myProfile?.user?.organization?.showPhillyBuildings;
    const showSolarEligibility = myProfile?.user?.organization?.showSolarEligibility;

    const restrictedDatasets: string[] = [];
    if (!showPhillyBuildings) {
      restrictedDatasets.push(PhillyBuildings.id);
    }
    if (!showSolarEligibility) {
      restrictedDatasets.push(SolarEligibility.id);
    }
    // Load the initial datasets from the URL
    let in1 = searchParams.get('in1');
    let in2 = searchParams.get('in2');

    if (in1 && restrictedDatasets.includes(in1)) {
      in1 = null;
    }
    if (in2 && restrictedDatasets.includes(in2)) {
      in2 = null;
    }

    if (in1 !== dataset1.id && in2 !== dataset2.id) {
      if (in2 && in1 !== in2 && in2 !== EquityIndex.id && in1 !== EquityIndex.id) {
        selectDataset2(in2);
      } else {
        selectDataset2(null);
      }
    }

    // Currently the equity index requires additional data to be loaded
    if (in1 !== dataset1.id) {
      if (in1) {
        selectDataset1(in1);
        if (in1 === EquityIndex.id) {
          selectDataset2(null);
        }
      } else {
        selectDataset1(null);
        selectDataset2(null);
      }
    }
  }, []);

  useEffect(() => {
    const newParams = createSearchParams(searchParams);
    if (dataset1.subsets?.length > 0) {
      newParams.set('options1', serializedSubset);
    } else {
      newParams.delete('options1');
    }

    if (dataset2.subsets?.length > 0) {
      newParams.set('options2', serializedSubset2);
    } else {
      newParams.delete('options2');
    }

    setSearchParams(newParams);
  }, [serializedSubset, serializedSubset2]);

  useEffect(() => {
    if (options1List && options1List.length > 0) {
      dispatch(
        setDataset1({
          subsets: [...options1List],
        }),
      );
    }
    if (options2List && options2List.length > 0) {
      dispatch(
        setDataset2({
          subsets: [...options2List],
        }),
      );
    }
  }, []);

  const selectDataset1 = (value: string | null) => {
    const selectedDataset = AllDatasets.find((ds) => ds.id === value);

    dispatch(
      setDataset1({
        id: value,
        cutoff1: 0,
        cutoff2: 0,
        subsets: [
          ...((containsAny(options1List || [], selectedDataset?.defaultSubsets || []) && options1List) ||
            selectedDataset?.defaultSubsets ||
            []),
        ],
        //TODO:  Convert the equityIndexWeights list to a hash-map
        // so we don't have to worry about order
        equityIndexWeights: EquityIndex?.subsets?.map((subset) => indicatorOptions[subset.id] || 0) || [],
        radioSliderValues: [],
      }),
    );
    dispatch(setLegendOpen(true));

    if (value === EquityIndex.id || !value || !selectedDataset?.combinable) {
      selectDataset2(null);
    }

    setDataset1Cutoff(0);
  };

  useEffect(() => {
    // set equity index weights when map data changes
    dispatch(
      setDataset1({
        equityIndexWeights: EquityIndex?.subsets?.map((subset) => indicatorOptions[subset.id] || 0) || [],
      }),
    );
  }, [mapData]);

  const setDataset1Cutoff = (value: number) => {
    dispatch(
      setDataset1({
        cutoff1: value,
      }),
    );
  };

  const selectDataset2 = (value: string | null) => {
    const selectedDataset = AllDatasets.find((ds) => ds.id === value);

    dispatch(
      setDataset2({
        id: value,
        cutoff1: 0,
        cutoff2: 0,
        subsets: [
          ...((containsAny(options2List || [], selectedDataset?.defaultSubsets || []) && options2List) ||
            selectedDataset?.defaultSubsets ||
            []),
        ],
        radioSliderValues: [],
      }),
    );
    dispatch(setLegendOpen(true));
  };

  const setDataset2Cutoff = (value: number) => {
    dispatch(
      setDataset2({
        cutoff1: value,
      }),
    );
  };

  const minMax1 = ds1?.getMinMax(dataset1, mapData?.values || []) || [0, 0];
  const minMax2 = ds2?.getMinMax(dataset2, mapData?.values || []) || [0, 0];
  const hasData = Boolean(mapData?.values?.length);
  const tooltip1Label = ds1?.isInverted ? `Shows lowest % of ${ds1?.title}` : `Shows highest % of ${ds1?.title}`;
  const tooltip2Label = ds2?.isInverted ? `Shows lowest % of ${ds2?.title}` : `Shows highest % of ${ds2?.title}`;

  return (
    <Wrapper>
      <H4>Indicators</H4>

      <Indicator>
        <Subhead2 style={{marginBottom: 8}}>Equity Indicator 1</Subhead2>
        <IndicatorSearch index={1} onSelectDataset={selectDataset1} otherSelectedId={dataset2.id} value={dataset1.id} />

        {ds1 !== undefined && hasData ? (
          <>
            {ds1.subsetType !== 'equitySlider' && ds1.subsetType !== 'radio' && !ds1.hideCutoffs ? (
              <FilterSlider
                formatter={(value: any) => ds1.formatValue(dataset1, value)}
                min={minMax1[0]}
                max={minMax1[1]}
                isInverted={ds1?.isInverted}
                onChange={setDataset1Cutoff}
                tooltip={tooltip1Label}
                value={dataset1.cutoff1}
              />
            ) : (
              <></>
            )}
            {ds1.id === EquityIndex.id && (
              <div style={{marginTop: '2rem'}}>
                <IndicatorPicker indicators={indicators} />
              </div>
            )}
            <Subsets dataset={dataset1} onUpdateDataset={(dataset) => dispatch(setDataset1(dataset))} />
          </>
        ) : (
          <> {!hasData && <NoDataWrapper>No Data</NoDataWrapper>} </>
        )}
      </Indicator>

      {ds1 && ds1.combinable ? (
        <Indicator>
          <Subhead2 style={{marginBottom: 8}}>Equity Indicator 2</Subhead2>
          <IndicatorSearch
            disabled={!hasData}
            index={2}
            onSelectDataset={selectDataset2}
            otherSelectedId={dataset1.id}
            value={dataset2.id}
          />
          {ds2 !== undefined && hasData ? (
            <>
              {ds2.subsetType !== 'equitySlider' && ds2.subsetType !== 'radio' && !ds2.hideCutoffs ? (
                <FilterSlider
                  isInverted={ds2?.isInverted}
                  formatter={(value: any) => ds2.formatValue(dataset2, value)}
                  min={minMax2[0]}
                  tooltip={tooltip2Label}
                  max={minMax2[1]}
                  onChange={setDataset2Cutoff}
                  value={dataset2.cutoff1}
                />
              ) : (
                <></>
              )}
              <Subsets dataset={dataset2} onUpdateDataset={(dataset) => dispatch(setDataset2(dataset))} />
            </>
          ) : (
            <> {!hasData && <NoDataWrapper>No Data</NoDataWrapper>}</>
          )}
        </Indicator>
      ) : (
        <></>
      )}

      <FlexFill />
    </Wrapper>
  );
};
