import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../../../Managers/Datasets/Types';
import {BasicCheckbox, TooltipTrigger} from '../../../../Components';

interface Props {
  dataset: IDatasetConfig;
  onUpdateDataset: (dataset: IDatasetConfig) => void;
  options: IDatasetOptions<number | IDemographicValue | IRedliningValue | IBuildingValue | ITribeValue>;
}

export const SubsetCheckbox: FC<Props> = ({dataset, options, onUpdateDataset}) => {
  const handleToggleSubset = (id: string, on: boolean) => {
    const datasetCopy = {...dataset};
    if (on) {
      datasetCopy.subsets = [...datasetCopy.subsets, id];
    } else {
      datasetCopy.subsets = datasetCopy.subsets.filter((e) => e !== id);
    }
    onUpdateDataset(datasetCopy);
  };

  return (
    <>
      {options.subsets?.map((subset, index) => (
        <TooltipTrigger data-tip={subset.description || ''} data-for="checkbox-tooltips" key={index}>
          <BasicCheckbox
            key={subset.id}
            name={subset.id}
            label={subset.title}
            style={{marginBottom: 16}}
            onChange={(v) => handleToggleSubset(subset.id, v)}
          />
        </TooltipTrigger>
      ))}
      <ReactTooltip
        id="checkbox-tooltips"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 8, left: 120}}
        wrapper="span"
        className="info-tooltip"
      />
    </>
  );
};
