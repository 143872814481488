import {useMutation} from 'react-query';
import {updateMyPaymentMethod} from '../Calls/OrgAdmin';

export const useUpdateMyPaymentMethod = () => {
  return useMutation(updateMyPaymentMethod, {
    onSuccess: () => {},

    onError: (err: Error) => {
      console.log('Error updating payment method', err.message);
    },
  });
};
