import {useMutation, useQueryClient} from 'react-query';
import {updateMySubscription} from '../Calls/OrgAdmin';

interface UseUpdateMySubscriptionOptions {
  skipInvalidations?: boolean;
}

export const useUpdateMySubscription = (options?: UseUpdateMySubscriptionOptions) => {
  const qc = useQueryClient();

  return useMutation(updateMySubscription, {
    onSuccess: (organization) => {
      if (!options?.skipInvalidations) {
        qc.invalidateQueries(['ONBOARDING', 'ORGANIZATION']).catch(() => {});
      }
    },
    onError: (err: Error) => {
      console.log('Error updating my subscription', err.message);
    },
    onSettled: () => {
      if (!options?.skipInvalidations) {
        qc.invalidateQueries(['ONBOARDING', 'SUBSCRIPTION']);
      }
    },
  });
};
