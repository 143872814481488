import {FC} from 'react';
import {Dropdown} from './Dropdown';
import counties from '../../Views/Admin/counties';

export const countyOptions = counties
  .map((row) => ({value: (row[2] as string) + row[3], label: row[4] + ', ' + row[0]}))
  .sort((a, b) => a.label.localeCompare(b.label));

const filterOptions = (inputValue: string) => {
  return countyOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 50);
};

export const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
  callback(filterOptions(inputValue));
};

export const defaultOptions = countyOptions.slice(0, 50);

export const CountyField: FC<{name: string; value?: string | null; onChange: (value: any) => void}> = ({name, value, onChange}) => {
  return (
    <Dropdown
      defaultValue={countyOptions.find((option) => option.value === value)}
      isSearchable={true}
      name={name}
      onChange={onChange}
      isAsync
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
    />
  );
};
