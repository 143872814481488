import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useRoles} from '../../Hooks/useRoles';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {routePaths} from '../../constants/routePaths';
import {Filters} from './Filters';
import {Header} from './Header';
import {LegendContainer} from './Legend';
import {MapBox} from './MapBox';
import {RollOver} from './RollOver';

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const MapComponent: FC = () => {
  // *** Coming here before being set to active causes a server error in Overlay.tsx, which then sends the user to the root.  Maybe we could get around this with a setTimeOut to allow profile to be loaded, but that's hacky. ***

  const {data: myProfile, isFetching: isFetchingProfile} = useMyProfile();
  const {isAdmin} = useRoles();

  const navigate = useNavigate();

  const isActive =
    myProfile?.user?.organization?.subscription?.status === 'active' || myProfile?.user?.organization?.subscription?.status === 'trialing';

  useEffect(() => {
    if (!!myProfile && !isActive && !isFetchingProfile && !isAdmin) {
      // TODO: get myProfile to reload after updating payment is complete
      navigate(routePaths.onBoardingConfirm);
    }
  }, [myProfile, isActive, navigate, isFetchingProfile, isAdmin]);

  return (
    <Wrapper>
      <MapBox />
      <Header />
      <Filters />
      <RollOver />
      <LegendContainer />
    </Wrapper>
  );
};

export const Map: FC = withAuthenticationRequired(MapComponent);
