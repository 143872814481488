import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../../../Managers/Datasets/Types';
import {EquityIndexSlider, TooltipTrigger} from '../../../../Components';
import {EquityIndex} from '../../../../Managers/Datasets';
import {useUpdateMyProfile} from '../../../../API';
import {useIndicatorOptions} from '../../../../API/Queries/useIndicatorOptions';
import {byId} from './Utils';

interface Props {
  dataset: IDatasetConfig;
  onUpdateDataset: (dataset: IDatasetConfig) => void;
  options: IDatasetOptions<number | IDemographicValue | IRedliningValue | IBuildingValue | ITribeValue>;
}

const findIndex = (id: string) => EquityIndex.subsets?.findIndex((subset) => subset.id === id) ?? -1;

export const SubsetEquitySlider: FC<Props> = ({dataset, options, onUpdateDataset}) => {
  const updateMyProfile = useUpdateMyProfile();
  const {indicatorOptions: queriedOptions} = useIndicatorOptions();
  const indicatorOptions = {...queriedOptions} ?? {};
  const handleSetIndexValue = (value: number, id: string) => {
    const index = findIndex(id);
    const tempWeights = [...dataset.equityIndexWeights];
    if (index > -1) {
      tempWeights[index] = value;

      indicatorOptions[id] = value;
      onUpdateDataset({...dataset, equityIndexWeights: tempWeights});
      updateMyProfile.mutateAsync({user_options: JSON.stringify({indicators: indicatorOptions})});
    }
  };

  return (
    <>
      {options.subsets?.sort(byId)?.map((subset, index) => {
        return (
          <TooltipTrigger data-tip={subset.description || ''} data-for="checkbox-tooltips" key={subset.id}>
            <EquityIndexSlider
              value={indicatorOptions[subset.id] || 0}
              label={subset.title}
              onChange={(val) => handleSetIndexValue(val, subset.id)}
            />
          </TooltipTrigger>
        );
      })}

      <ReactTooltip
        id="checkbox-tooltips"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: -14, left: -6}}
        wrapper="span"
        className="info-tooltip"
      />
    </>
  );
};
