import {combineReducers, configureStore} from '@reduxjs/toolkit';
import type {TypedUseSelectorHook} from 'react-redux';
import {useDispatch, useSelector} from 'react-redux';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mapReducer, {mapSlice} from './Redux/Slices/Map';
import onboardingReducer, {onboardingSlice} from './Redux/Slices/Onboarding';
import userReducer, {userSlice} from './Redux/Slices/User';
import cartReducer, {cartSlice} from './Redux/Slices/Cart';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [userSlice.name],
};

const rootReducer = combineReducers({
  [mapSlice.name]: mapReducer,
  [userSlice.name]: userReducer,
  [cartSlice.name]: cartReducer,
  [onboardingSlice.name]: onboardingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

export const resetPersist = async () => {
  //  await persistor.purge();
};


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
