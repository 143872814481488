import {FC} from 'react';
import styled from 'styled-components/macro';
import {IChange} from '../../API';
import {Subhead3} from '../Atoms';
import {ReactComponent as ChevronDown} from './img/chevron-down.svg';

const getChangeColor = (change: number, theme: any) =>
  change < 0 ? theme.Colors.Reds._000 : change === 0 ? theme.Colors.Blues._000 : theme.Colors.Greens._000;

const GroupedWrapper = styled(Subhead3)`
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  font-weight: 600;
  border-radius: 100%;
  border: 2px solid #84cc16;
  align-items: center;
  justify-content: center;

  & svg {
    top: -2px;
    left: -2px;
    z-index: 10;
    position: absolute;
  }
`;

const DetailedWrapper = styled.div<{change: IChange}>`
  display: inline-flex;
  flex-direction: row;
  flex: 0 0 44px;
  color: #fff;
  background-color: ${({change, theme}) => getChangeColor(change.change, theme)};
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;

  & svg {
    margin-right: 6px;
  }

  & span {
    margin-left: 8px;
  }
`;

const SummaryWrapper = styled.div<{change: IChange}>`
  display: inline-flex;
  flex-direction: row;
  flex: 0 0 24px;
  color: #fff;
  background-color: ${({change, theme}) => getChangeColor(change.change, theme)};
  border-radius: 24px;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;

  & svg {
    margin-right: 6px;
  }

  & span {
    margin-left: 8px;
  }
`;
const PrimaryBadgeWrapper = styled.div`
  color: white;
  background-color: ${(props) => props.theme.Colors.Oranges._000};
  border-radius: 3rem;
  display: flex;
  padding: 4px 12px;
  margin-right: 6px;
  flex: 0;
  display: flex;
  height: 2.4rem;
  align-items: center;
  justify-content: center;
  align-self: center;
`;

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
}

/**
 * The circular badge with a number and an outside border
 */
export const BadgeGrouped: FC<{changes: IChange[]}> = ({changes}) => {
  const changeCount = changes.length;
  const downCount = changes.filter((change) => change.change < 0).length;

  const downDegrees = changeCount > 0 ? 360 * (downCount / changeCount) : 0;
  const start = 90;
  const end = Math.min(downDegrees + start, 449.9);
  console.log({downDegrees, start, end});

  return (
    <GroupedWrapper>
      <svg viewBox="0 0 32 32" width="32">
        <path d={describeArc(14, 14, 13, start, end)} id="arc" fill="none" stroke="red" strokeWidth={2} />
      </svg>
      {changes.length}
    </GroupedWrapper>
  );
};

// The slightly more detailed "chip" with a change-caret, percentage, and background color
export const BadgeSummary: FC<{change: IChange}> = ({change}) => (
  <SummaryWrapper change={change}>
    <ChevronDown /> {(change.change || 0).toFixed(0)}%
  </SummaryWrapper>
);

// The full details with the year range
export const BadgeDetailed: FC<{change: IChange}> = ({change}) => (
  <DetailedWrapper change={change}>
    <ChevronDown /> {(change.change || 0).toFixed(0)}% <span>0000 - 0000</span>
  </DetailedWrapper>
);

export const BadgePin: FC<{change: IChange}> = ({change}) => (
  <SummaryWrapper change={change}>
    <ChevronDown /> {(change.change || 0).toFixed(0)}%
  </SummaryWrapper>
);

export const PillBadge: FC = ({children}) => <PrimaryBadgeWrapper>{children}</PrimaryBadgeWrapper>;
