import axios from 'axios';
import {getConfig} from '../getConfig';

const config = getConfig();
const baseURL = config.apiUrl;

// TODO: Discuss whether we want to include some kind of client/app ID. If we do, we need to add it to our CORS authorized headers...
// const clientID = '1234';

export const Endpoint = axios.create({
  baseURL,
  timeout: 20000,
  // headers: {'X-Client-ID': clientID},
});

export const AuthEndpoint = axios.create({
  baseURL: config.authBaseURL,
  timeout: 8000,
});

export const PropublicaEndpoint = axios.create({
  baseURL: 'https://projects.propublica.org/nonprofits/api/v2',
  timeout: 8000,
});

export const setAccessToken = (accessToken: string | null) => {
  Endpoint.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};
