import React, {FC} from 'react';
import {useAdminOrganizations} from '../../../API';
import {SimpleSelect} from './TableLayout';

interface UserTypeCellProps {
  value: any;
  row: any;
  onChange: (value: string, row: any) => void;
  disabled?: boolean;
}

export const UserTypeCell: FC<UserTypeCellProps> = ({value: initialValue, row, onChange, disabled = false}) => {
  const {data: orgs} = useAdminOrganizations();
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (e: any) => {
    onChange(e.target.value, row);
  };

  const greenlinkOrgId = orgs?.find((org) => org.name === 'Greenlink Analytics')?.id;

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (row.original.stripe_customer_id) return <>Stripe Customer</>;

  if ((row.original.organizationId || !['user', 'admin'].includes(value)) && row.original.organizationId !== greenlinkOrgId) {
    return <>{value}</>;
  }

  return (
    <SimpleSelect value={value || ''} onChange={handleChange} disabled={disabled}>
      <option value="admin">Greenlink Staff</option>
      {row.original.organizationId === greenlinkOrgId && <option value="org-admin">Organization Admin</option>}
      <option value="user">User</option>
    </SimpleSelect>
  );
};
