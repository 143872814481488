import {QuestionMarkCircleIcon} from '@heroicons/react/outline';
import {XIcon} from '@heroicons/react/solid';
import {FC} from 'react';
import styled from 'styled-components/macro';
import {Body4, FlexFill, FlexRow as FlexRowWrap, H6, LegendButton, Subhead3, Theme} from '../../../Components';
import {AllDatasets, Colors2d as ColorMap} from '../../../Managers/MapManager';
import {setLegendOpen} from '../../../Redux/Slices/Map';
import {useAppDispatch, useAppSelector} from '../../../store';
import {ReactComponent as ArrowRight} from './img/arrow-right.svg';
import {ReactComponent as ArrowUp} from './img/arrow-up.svg';

const LegendWrapper = styled.div`
  align-items: flex-end;
  position: absolute;
  right: 20px;
  top: 120px;
  bottom: 30px;
  overflow: auto;
  border-radius: 16px;
  width: 300px;
  z-index: 5;
`;

const Panel = styled.div`
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
  left: 0;
  padding: 28px;
  position: absolute;
  top: 0;
  width: 300px;
  z-index: 20;
`;

const PanelHeader = styled.div`
  flex-direction: row;
  flex: 0;
  margin-bottom: 30px;
`;

const PanelTopButton = styled.button`
  align-items: center;
  background: #ffffff;
  border: none;
  display: flex;
  flex-direction: row;
  height: 24px;
  justify-content: center;
  margin: 0;
  width: 24px;
  z-index: 10;
`;

const ColorBox = styled.div`
  border-radius: 8px;
  flex: 0 0 32px;
  height: 32px;
  margin: 0 8px 8px 0;
  width: 32px;
`;

const GridWrapper = styled.div`
  flex: 0 0 150px;
  height: 150px;
  margin: 20px 0 20px 32px;
  width: 150px;
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Grid = styled.div``;

const YAxisWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const YAxisLabel = styled(Body4)`
  display: flex;
  flex: 0;
  justify-content: center;
  min-width: 114px;
  min-height: 64px;
  margin-right: -16px;
  align-items: flex-end;
  transform: rotate(270deg);
`;

const FlexRow = styled(FlexRowWrap)`
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: flex-start;
`;

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const TwoDimensionLegend: FC = () => {
  const dispatch = useAppDispatch();
  const legendOpen = useAppSelector((state) => state.map.legendOpen);
  const {dataset1, dataset2} = useAppSelector((state) => state.user);
  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);
  // const [selectedSubset1] = dataset1.subsets;
  // const [selectedSubset2] = dataset2.subsets;

  const subsets1 = ds1?.subsets?.filter((s) => dataset1.subsets.includes(s.id));
  const subsets2 = ds2?.subsets?.filter((s) => dataset2.subsets.includes(s.id));

  const subsets1Title = subsets1?.map((s) => s.title).join(', ');
  const subsets2Title = subsets2?.map((s) => s.title).join(', ');

  const Colors2d = ColorMap;
  const shouldInvert = false;

  const OpenButton = () =>
    dataset1.id || dataset2.id ? (
      <LegendButton
        id="open-legend"
        onClick={() => {
          dispatch(setLegendOpen(true));
        }}>
        <QuestionMarkCircleIcon width={20} style={{marginBottom: -5, marginRight: 12}} />
        Open Legend
      </LegendButton>
    ) : (
      <></>
    );

  return (
    <LegendWrapper id="map-legend">
      {legendOpen ? (
        <Panel id="map-panel">
          <PanelHeader>
            <H6 style={{color: Theme.Colors.Oranges._000}}>Legend</H6>
            <FlexFill />
            <PanelTopButton
              id="close-legend"
              data-html2canvas-ignore
              onClick={() => {
                dispatch(setLegendOpen(false));
              }}>
              <XIcon />
            </PanelTopButton>
          </PanelHeader>

          <Subhead3 style={{color: Theme.Colors.Neutrals._200, marginBottom: 8}}>{ds1?.title}</Subhead3>
          <Body4>{ds1?.description}</Body4>
          {!!ds1?.note && (
            <Body4 style={{fontStyle: 'italic'}}>
              {ds1?.note}
              {!!ds1?.link && (
                <a target="_blank" rel="noreferrer" href={ds1?.link} style={{fontStyle: 'italic'}}>
                  {' '}
                  {ds1?.linkLabel}
                </a>
              )}
            </Body4>
          )}

          <Subhead3 style={{color: Theme.Colors.Neutrals._200, marginBottom: 8}}>{ds2?.title}</Subhead3>
          <Body4>{ds2?.description}</Body4>
          {!!ds2?.note && <Body4 style={{fontStyle: 'italic'}}>{ds2?.note}</Body4>}
          {shouldInvert && (
            <Body4>
              Note: The legend color configuration was reordered due to the inversion of the {ds1?.isInverted ? ds1?.title : ds2?.title}{' '}
              dataset.
            </Body4>
          )}
          <GridWrapper>
            <FlexRow style={{gap: 12, justifyContent: 'center', marginLeft: 16}}>
              <FlexRow style={{height: ds2?.isBinary ? 80 : '100%', paddingBottom: ds2?.isBinary ? 0 : 68}}>
                <YAxisWrapper style={{alignItems: 'flex-end', height: '100%', marginRight: 0, width: 0, marginTop: ds1?.isBinary ? 0 : 8}}>
                  <YAxisLabel>
                    <span>{ds2?.downloadTitle + (subsets2Title ? ': ' + subsets2Title : '')}</span>
                  </YAxisLabel>
                </YAxisWrapper>
                <YAxisWrapper style={{height: ds2?.isBinary ? 80 : '114px'}}>
                  <ArrowUp />
                </YAxisWrapper>
              </FlexRow>
              <div style={{flexDirection: 'column'}}>
                <Grid>
                  <FlexRow>
                    <ColorBox style={{backgroundColor: Colors2d['L']['H'], opacity: 1}} />

                    {!ds1?.isBinary && ( // if the first dataset is binary, we don't need to show the middle column
                      <ColorBox style={{backgroundColor: Colors2d['M']['H'], opacity: 1}} />
                    )}
                    <ColorBox style={{backgroundColor: Colors2d['H']['H'], opacity: 1}} />
                  </FlexRow>
                  {!ds2?.isBinary && ( // if the second dataset is binary, we don't need to show the middle row
                    <FlexRow>
                      <ColorBox style={{backgroundColor: Colors2d['L']['M'], opacity: 1}} />

                      {!ds1?.isBinary && <ColorBox style={{backgroundColor: Colors2d['M']['M'], opacity: 1}} />}
                      <ColorBox style={{backgroundColor: Colors2d['H']['M'], opacity: 1}} />
                    </FlexRow>
                  )}
                  <FlexRow>
                    <ColorBox style={{backgroundColor: Colors2d['L']['L'], opacity: 1}} />

                    {!ds1?.isBinary && <ColorBox style={{backgroundColor: Colors2d['M']['L'], opacity: 1}} />}
                    <ColorBox style={{backgroundColor: Colors2d['H']['L'], opacity: 1}} />
                  </FlexRow>
                </Grid>
                <div style={{width: ds1?.isBinary ? 70 : '100%'}}>
                  <FlexRow>
                    <ArrowRight />
                  </FlexRow>
                  <FlexRow style={{marginTop: 8}}>
                    <Body4 style={{textAlign: 'center', flex: 1}}>{ds1?.downloadTitle + (subsets1Title ? ': ' + subsets1Title : '')}</Body4>
                  </FlexRow>
                </div>
              </div>
            </FlexRow>
          </GridWrapper>
        </Panel>
      ) : (
        <OpenButton />
      )}
    </LegendWrapper>
  );
};
