import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IDatasetConfig} from '../../Managers/Datasets/Types';

export interface IUserState {
  // myProfile: IUserProfile | null;
  dataset1: IDatasetConfig;
  dataset2: IDatasetConfig;
}

const initialState: IUserState = {
  // The profile for the logged-in user. This is "our" profile for the user, from the Greenlink database, not Auth0's simplified form.
  // myProfile: null,
  // The data set configurations as selected by the user
  dataset1: {id: null, cutoff1: 0, cutoff2: 100} as IDatasetConfig,
  dataset2: {id: null, cutoff1: 0, cutoff2: 100} as IDatasetConfig,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // setMyProfile: (state, action: PayloadAction<IUserProfile | null>) => {
    //   state.myProfile = action.payload;
    // },
    setDataset1: (state, action: PayloadAction<Record<string, any>>) => {
      state.dataset1 = {...state.dataset1, ...action.payload};
    },
    setDataset2: (state, action: PayloadAction<Record<string, any>>) => {
      state.dataset2 = {...state.dataset2, ...action.payload};
    },
  },
});

export const {setDataset1, setDataset2} = userSlice.actions;
export default userSlice.reducer;
