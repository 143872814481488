import {User, withAuthenticationRequired} from '@auth0/auth0-react';
import {ComponentType} from 'react';
import {requireAdmin, requireOrgAdmin} from '../Managers/AuthUtils';
import {FullPageLoader} from './Molecules';

export const AuthenticatedComponent = ({component, claimCheck}: {component: ComponentType; claimCheck?: (claims?: User) => boolean}) => {
  const Component = withAuthenticationRequired(component, {
    claimCheck: claimCheck || (() => true),
    onRedirecting: () => <FullPageLoader />,
  });

  return <Component />;
};

export const AuthenticatedOrgAdmin = ({component}: {component: ComponentType}) => {
  return <AuthenticatedComponent component={component} claimCheck={requireOrgAdmin} />;
};

export const AuthenticatedAdmin = ({component}: {component: ComponentType}) => {
  return <AuthenticatedComponent component={component} claimCheck={requireAdmin} />;
};
