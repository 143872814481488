import {FC, useEffect, useState} from 'react';
import {IOrganization, useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {OrganizationHeader} from '../../Components/Molecules/OrganizationHeader';
import {AdminPage, AdminPageWrapper} from '../Admin/Layout';
import {Header} from '../Map/Header';
import {OrganizationMembersPanels} from '../Onboarding/OrganizationMembersPanels';
import {AdminMenu} from './AdminMenu';

export const MyMembers: FC = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const [updatedOrganization, setUpdatedOrganization] = useState<IOrganization | null>(null);

  const {data: myProfile} = useMyProfile();
  const {data: organization, isFetching} = useGetMyOrganization(!!myProfile?.user?.organizationId, refetchInterval);
  const {data: subscriptionPricing, isLoading: isLoadingPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const handleSubmit = async (values: any) => {
    setUpdatedOrganization(values);
  };

  const hasOrganizationChanged =
    updatedOrganization && organization && JSON.stringify(updatedOrganization) !== JSON.stringify(organization);

  console.log({orgDataIsDifferent: hasOrganizationChanged});

  useEffect(() => {
    if (hasOrganizationChanged) {
      setRefetchInterval(2000);
    } else {
      setRefetchInterval(false);
    }
  }, [hasOrganizationChanged]);

  return (
    <AdminPageWrapper>
      <AdminMenu />

      <Header />

      <AdminPage style={{maxWidth: '100%'}}>
        <OrganizationHeader organization={organization as IOrganization} title="My Members" />

        <div style={{display: 'flex', flexDirection: 'row', gap: 24}}>
          <OrganizationMembersPanels
            hideTitle
            onSubmit={handleSubmit}
            organization={organization as IOrganization}
            readOnly={isFetching || isLoadingPricing || organization?.subscription_type === subscriptionTypes?.local?.value}
            updateSubscriptionOnly
          />
        </div>
      </AdminPage>
    </AdminPageWrapper>
  );
};
