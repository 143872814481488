import {useMutation} from 'react-query';
import {addToMailchimpList} from '../Calls/Session';

export const useAddToMailchimp = () => {
  return useMutation(addToMailchimpList, {
    onSuccess: () => {},

    onError: (err: Error, _) => {
      console.log('Error creating my subscription', err.message);
    },
  });
};
