import {QuestionMarkCircleIcon} from '@heroicons/react/outline';
import {XIcon} from '@heroicons/react/solid';
import {FC} from 'react';
import styled from 'styled-components/macro';
import {Body2, Body4, FlexFill, H6, LegendButton, Subhead3, Theme} from '../../../Components';
import {fillBuckets, opacityBuckets} from '../../../Managers/ColorUtils';
import {IndigenousBoundaries, Redlining, redliningSubsets} from '../../../Managers/Datasets';
import {AllDatasets} from '../../../Managers/MapManager';
import {setLegendOpen} from '../../../Redux/Slices/Map';
import {useAppDispatch, useAppSelector} from '../../../store';

const LegendWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 120px;
  right: 20px;
  width: 300px;
  align-items: flex-end;
  background: transparent;
  bottom: 30px;
  overflow: auto;
  border-radius: 16px;
`;

const Panel = styled.div`
  top: 0;
  left: 0;
  width: 300px;
  z-index: 20;
  padding: 28px;
  background: #fff;
  position: absolute;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

const PanelHeader = styled.div`
  flex-direction: row;
  margin-bottom: 30px;
  flex: 0;
`;

const PanelTopButton = styled.button`
  width: 24px;
  height: 24px;
  //padding: 16px;
  display: flex;
  border: none;
  z-index: 10;
  margin: 0;
  background: #ffffff;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const ColorRow = styled.div`
  flex-direction: row;
  margin: 0 0 8px;
  align-items: center;
`;

const ColorBox = styled.div`
  margin: 0 8px 0 0;
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

interface ISection {
  selectedSubset: string;
  fills: string[];
}

const RedliningSection = ({selectedSubset, fills}: ISection) => {
  return (
    <>
      <ColorRow>
        <ColorBox style={{backgroundColor: fills[0]}} />
        <Body2 style={{marginBottom: 0}}>Grade {redliningSubsets[selectedSubset]?.title}</Body2>
      </ColorRow>
      <ColorRow>
        <ColorBox style={{backgroundColor: fills[fills.length - 1]}} />
        <Body2 style={{marginBottom: 0}}>
          Not Grade{' '}
          {redliningSubsets[selectedSubset]?.title === redliningSubsets.HOLCD.title
            ? 'D: "Hazardous" (not redlined)'
            : `${redliningSubsets[selectedSubset]?.title}`}
        </Body2>
      </ColorRow>
    </>
  );
};

const IndigenousBoundariesSection = ({selectedSubset, fills}: ISection) => {
  return (
    <>
      <ColorRow>
        <ColorBox style={{backgroundColor: fills[0]}} />
        <Body2 style={{marginBottom: 0}}>Federally Recognized</Body2>
      </ColorRow>
      <ColorRow>
        <ColorBox style={{backgroundColor: fills[fills.length - 1]}} />
        <Body2 style={{marginBottom: 0}}>Not Federally Recognized</Body2>
      </ColorRow>
    </>
  );
};

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const OneDimensionStandardLegend: FC = () => {
  const dispatch = useAppDispatch();
  const {legendOpen, mapData} = useAppSelector((state) => state.map);
  const {dataset1: dsConfig1, dataset2: dsConfig2} = useAppSelector((state) => state.user);
  const ds1 = AllDatasets.find((ds) => ds.id === dsConfig1.id);

  const selectedSubset = dsConfig1.subsets[0];
  const subset = ds1?.subsets?.find((s) => s.id === selectedSubset);

  const opacities = [...opacityBuckets].reverse();
  const fills = [...fillBuckets].reverse();
  const [min1, max1] = ds1?.getMinMax(dsConfig1, mapData?.values || [], dsConfig1.subsets[0]) || [0, 0];

  const subsetIndex = ds1?.subsets?.findIndex((subset) => subset.id === dsConfig1.subsets[0]);

  // the slider is the source of truth for the cutoffs
  let cutOff2 = dsConfig1.cutoff2 || max1;
  let cutOff1 =
    subsetIndex !== undefined ? dsConfig1.cutoff1 || dsConfig1.radioSliderValues?.[subsetIndex] || min1 : dsConfig1.cutoff1 || min1;

  if (ds1?.isInverted) {
    cutOff2 = dsConfig1.cutoff1 || max1;
    cutOff1 = min1;
  }

  if (subset?.isInvertedHigh) {
    cutOff2 = subsetIndex ? dsConfig1.radioSliderValues?.[subsetIndex] || max1 : max1;
    cutOff1 = min1;
  }

  const formattedMax1 = ds1?.formatValue(dsConfig1, cutOff2) || '-';
  const formattedMin1 = ds1?.formatValue(dsConfig1, cutOff1) || '-';

  const OpenButton = () =>
    dsConfig1.id || dsConfig2.id ? (
      <LegendButton
        id="open-legend"
        onClick={() => {
          dispatch(setLegendOpen(true));
        }}>
        <QuestionMarkCircleIcon width={20} style={{marginBottom: -5, marginRight: 12}} />
        Open Legend
      </LegendButton>
    ) : (
      <></>
    );

  const highestValue = !['-Infinity', 'Infinity'].includes(formattedMax1) ? `(${formattedMax1})` : '---';
  const lowestValue = !['-Infinity', 'Infinity'].includes(formattedMin1) ? `(${formattedMin1})` : '---';
  // We'll need this if we decide have min and max respond
  // to the slider
  // const cutoff1Value = ds1?.formatValue(AppState.dataset1, AppState.dataset1.cutoff1);

  const shouldInvert = ds1?.isInverted || subset?.isInverted;

  return (
    <LegendWrapper id="map-legend">
      {legendOpen ? (
        <Panel id="map-panel">
          <PanelHeader>
            <H6 style={{color: Theme.Colors.Oranges._000}}>Legend</H6>
            <FlexFill />
            <PanelTopButton
              id="close-legend"
              data-html2canvas-ignore
              onClick={() => {
                dispatch(setLegendOpen(false));
              }}>
              <XIcon />
            </PanelTopButton>
          </PanelHeader>

          <Subhead3 style={{color: Theme.Colors.Neutrals._200}}>{ds1?.legendName || ds1?.downloadTitle} </Subhead3>
          <Body4>{ds1?.description}</Body4>
          {!!ds1?.note && (
            <Body4 style={{fontStyle: 'italic'}}>
              {ds1?.note}
              {!!ds1?.link && (
                <a target="_blank" rel="noreferrer" href={ds1?.link} style={{fontStyle: 'italic'}}>
                  {' '}
                  {ds1?.linkLabel}
                </a>
              )}
            </Body4>
          )}

          <Subhead3 style={{color: Theme.Colors.Neutrals._200}}>Visual Indicator</Subhead3>

          {ds1?.id === Redlining.id && <RedliningSection selectedSubset={selectedSubset} fills={fills} />}

          {ds1?.id === IndigenousBoundaries.id && <IndigenousBoundariesSection selectedSubset={selectedSubset} fills={fills} />}

          {ds1?.id !== Redlining.id && ds1?.id !== IndigenousBoundaries.id && (
            <>
              {fills.map((fill, index) => (
                <ColorRow key={fill}>
                  <ColorBox style={{backgroundColor: fill}} />
                  {index === 0 ? (
                    <Body2 style={{marginBottom: 0}}>
                      {ds1?.opacityTopLabel || subset?.topLabel || 'Highest'} {shouldInvert ? lowestValue : highestValue}
                    </Body2>
                  ) : (
                    <></>
                  )}
                  {index === opacities.length - 1 ? (
                    <Body2 style={{marginBottom: 0}}>
                      {ds1?.opacityBottomLabel || subset?.bottomLabel || 'Lowest'} {shouldInvert ? highestValue : lowestValue}
                      {/* {shouldInvert ? ' and above' : ' and below'} */}
                    </Body2>
                  ) : (
                    <></>
                  )}
                </ColorRow>
              ))}
            </>
          )}
          <FlexFill />
        </Panel>
      ) : (
        <OpenButton />
      )}
    </LegendWrapper>
  );
};
