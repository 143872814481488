export const ONBOARDING_MESSAGES = {
  accountCreated: {
    title: 'Account Created',
    text: 'Your account is now active. You are now subscribed to the Greenlink Equity Map.',
    button: 'Finish and Go to Map',
  },
  accountInactive: {
    title: 'Inactive Account',
    text: 'Your account is not active. Please contact your organization admin to activate your account.',
    button: 'OK',
  },
  emailDomainAlreadyExists: {
    title: 'Email Domain Already Exists',
    text: 'This email domain is already in use by another organization.  Please contact the organization admin to request an invitation.',
    button: 'OK',
  },
  profileError: {
    title: 'Profile Error',
    text: 'There was an error loading your profile. Please try again.',
    button: 'OK',
  },
  loading: {
    title: 'Loading...',
    text: 'Please wait while your account is loaded. ',
    button: null,
  },
  localAccountCreated: {
    title: 'Organization Created Successfully',
    text: "Your account is not active. You've set up your organization, and we are working on activating your account. You should receive an email when your account is ready.",
    text2: 'If you still need to, head over to the Not For Profit form to get approved for access.',
    button: 'Start the Application',
  },

  missingRole: {
    title: 'Missing Role',
    text: 'Your account role type is missing or invalid. Please contact Greenlink support to activate your account.',
    button: 'OK',
  },
  noOrganization: {
    title: 'Missing Organization',
    text: "We couldn't find your organization.  If this email is incorrect or you prefer to use a different email for your GEM account, please reach out to your organization's admin to request a new invitation.",
    button: 'OK',
  },
  noSubscription: {
    title: 'Problem with your account',
    text: "We couldn't find your subscription.  If this email is incorrect or you prefer to use a different email for your GEM account, please contact your organization's admin to request a new invitation.",
    button: 'OK',
  },
};
