import {useField, useFormikContext} from 'formik';
import {useEffect} from 'react';
import {useCheckForDuplicateEmail, useCheckForExistingEmailDomain} from '../../API';
import {TextInput} from '../../Components';

export const EmailField = ({
  disabled,
  label,
  name,
  organizationId,
}: {
  disabled: boolean;
  label: string;
  name: string;
  organizationId: string;
}) => {
  const {setFieldError} = useFormikContext();
  const [field, meta] = useField(name);

  const {data: emailCheckResult} = useCheckForDuplicateEmail(
    !meta.error && field.value !== meta.initialValue ? field.value : null,
    organizationId,
  );

  const {data: duplicateEmailDomainCheck} = useCheckForExistingEmailDomain(
    !meta.error && field.value !== meta.initialValue ? field.value : null,
  );

  useEffect(() => {
    if (emailCheckResult?.found && emailCheckResult?.hasOrganization) {
      setFieldError(name, 'Email is already in use');
    }
  }, [emailCheckResult?.found]);

  useEffect(() => {
    if (duplicateEmailDomainCheck?.organization && organizationId !== duplicateEmailDomainCheck?.organization?.id) {
      setFieldError(name, 'Email domain is already in use by another organization');
    }
  }, [duplicateEmailDomainCheck?.organization]);

  return <TextInput disabled={disabled} label={label} name={name} placeholder="Enter Email" required type="email" />;
};
