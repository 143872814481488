import {CheckCircleIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {FlexFill, Loader, StandardButton, Theme} from '../../Components';
import {ONBOARDING_MESSAGES} from './ONBOARDING_MESSAGES';
import {CardContent, ErrorTitle, OnboardingPage, OnboardingPageWrapper, OnboardingPanel, SuccessTitle, Text} from './Layout';

export const MessageCard: FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  message: (typeof ONBOARDING_MESSAGES)[keyof typeof ONBOARDING_MESSAGES];
  onClick?: () => void;
}> = ({isLoading, isSuccess, message, onClick}) => {
  return (
    <OnboardingPageWrapper hideLoader={isLoading}>
      <OnboardingPage>
        <OnboardingPanel style={{gap: 16, alignItems: 'center'}}>
          <CardContent>
            {isSuccess && (
              <>
                <CheckCircleIcon color={Theme.Colors.Greens._000} style={{width: 112, marginBottom: 16}} />

                <SuccessTitle>{message.title}</SuccessTitle>
              </>
            )}

            {!isSuccess && <ErrorTitle>{message.title}</ErrorTitle>}

            <Text>{message.text}</Text>

            {!!onClick && (
              <StandardButton type="button" style={{width: '100%'}} onClick={onClick}>
                {message.button}
              </StandardButton>
            )}
          </CardContent>
        </OnboardingPanel>
      </OnboardingPage>

      {isLoading && (
        <>
          <Loader style={{position: 'relative', top: 0, left: 0, margin: 0, flex: 'none'}} />

          <FlexFill style={{flex: 0}} />
        </>
      )}
    </OnboardingPageWrapper>
  );
};
