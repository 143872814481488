import styled from 'styled-components';
import * as yup from 'yup';
import {Body2, FlexRow, Modal, StandardButton, Subhead1} from '..';
import {ISubscriptionPricing, SubscriptionType, useSubscriptionPricing} from '../../API';
import {SUBSCRIPTION_IMAGES} from '../../constants/constants';

const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 8px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
  cursor: pointer;
  transition: all 0.3s;
`;

export const newsletterSchema = yup.object({
  firstName: yup.string().required('Please enter first name.'),
  lastName: yup.string().required('Please enter last name.'),
});

export const OrgTypeDescriptionsModal = ({isOpen = true, onClose = () => {}}) => {
  const {data: subscriptionPricing} = useSubscriptionPricing();

  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const typesForForm = {
    standard: subscriptionTypes?.standard,
    discounted: subscriptionTypes?.discounted,
  } as ISubscriptionPricing['subscriptionTypes'];

  const copyForTypes: {
    [key: string]: {
      label: string;
      description1: string;
      description2?: string;
      value: string;
    };
  } = {
    standard: {
      label: 'Standard',
      description1:
        'For Profit Organizations, such as government agencies, institutions, consulting firms, utility companies, etc, can purchase GEM. The cost is based off of the geography and number of members desired.',
      value: 'standard',
    },
    discounted: {
      label: 'Discounted',
      description1:
        'Not-for-Profit Organizations, such nongovernmental and grassroots organizations, can purchase GEM. The cost is based off of the geography and number of members desired. All not-for-profit organizations receive a 10% discount of their overall purchase. Some organizations may be eligible for free access.*',
      description2:
        ' *Organizations eligible for free GEM access must be a certified nongovernmental organization that is community-centered, works locally (city/county level), and earns less that $5 million per year.',
      value: 'discounted',
    },
  };

  return (
    <Modal title="Organization Types" isOpen={isOpen} hideButtons width={800}>
      <FlexRow style={{gap: 16, flex: 1}} id="org-cards">
        {!!subscriptionTypes &&
          Object.values(typesForForm).map((type) => (
            <Card key={type?.value}>
              <img alt={type?.value} width="120" height="88" src={SUBSCRIPTION_IMAGES[type?.value as SubscriptionType]}></img>
              <Subhead1 style={{margin: 0}}>{type?.label}</Subhead1>
              <Body2>{copyForTypes[type.value].description1}</Body2>
              <Body2>{copyForTypes[type.value].description2}</Body2>
            </Card>
          ))}
      </FlexRow>

      <FlexRow style={{justifyContent: 'center', marginTop: 16}}>
        <StandardButton style={{minWidth: 240}} outline onClick={onClose}>
          Close
        </StandardButton>
      </FlexRow>
    </Modal>
  );
};
