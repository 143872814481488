import {Endpoint} from '../Endpoint';
import {ITractData} from '../Types';

export const getMapData = (
  authorizationId: number | null,
  lat: number | null,
  lon: number | null,
  year: number | null,
  zoom: number | null,
) => {
  const params = [];
  if (authorizationId) {
    params.push(`authorizationId=${authorizationId}`);
  }

  if (lat && lon) {
    params.push(`lat=${lat}&lon=${lon}`);
  }

  if (zoom) {
    params.push(`zoom=${zoom}`);
  }

  if (year) {
    params.push(`year=${year}`);
  }

  return Endpoint.get<ITractData>(`/getMapData?${params.join('&')}`).then((r) => r.data);
  // return Endpoint.get<ITractData>('/map-data?lat=28.29579151169257&lon=-83.54004415134665&zoom=6.728847669593828').then((r) => r.data);
};
