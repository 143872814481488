import {useMutation} from 'react-query';
import {cancelMySubscription} from '../Calls/OrgAdmin';

export const useCancelMySubscription = () => {
  return useMutation(cancelMySubscription, {
    onSuccess: () => {},

    onError: (err: Error) => {
      console.log('Error cancelling subscription', err.message);
    },
  });
};
