import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IBuildingProperties, ITractData} from '../../API';
import {ITractProperties} from '../../Managers/Datasets/Types';

export interface IMapState {
  hoveredTract: ITractProperties | null;
  hoveredPin: IBuildingProperties | null;
  mapData: ITractData | null;
  showBuildingPins: boolean;
  showCities: boolean;
  showRoads: boolean;
  showPOI: boolean;
  legendOpen: boolean;
}

const initialState: IMapState = {
  hoveredTract: null,
  hoveredPin: null,
  mapData: null,
  showBuildingPins: false,
  showCities: false,
  showRoads: false,
  showPOI: false,
  legendOpen: false,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setHoveredTract: (state, action: PayloadAction<ITractProperties | null>) => {
      state.hoveredTract = action.payload;
    },
    setHoveredPin: (state, action: PayloadAction<IBuildingProperties | null>) => {
      state.hoveredPin = action.payload;
    },
    setMapData: (state, action: PayloadAction<ITractData | null>) => {
      state.mapData = action.payload;
    },
    setShowBuildingPins: (state, action: PayloadAction<boolean>) => {
      state.showBuildingPins = action.payload;
    },
    setShowCities: (state, action: PayloadAction<boolean>) => {
      state.showCities = action.payload;
    },
    setShowRoads: (state, action: PayloadAction<boolean>) => {
      state.showRoads = action.payload;
    },
    setShowPOI: (state, action: PayloadAction<boolean>) => {
      state.showPOI = action.payload;
    },
    setLegendOpen: (state, action: PayloadAction<boolean>) => {
      state.legendOpen = action.payload;
    },
  },
});

export const {setHoveredTract, setHoveredPin, setMapData, setShowBuildingPins, setShowCities, setShowRoads, setShowPOI, setLegendOpen} =
  mapSlice.actions;
export default mapSlice.reducer;
