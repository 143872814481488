import {FC} from 'react';
import {FormField, H5, Subhead2} from '../../Components';
import {useAdminProducts} from '../../API';
import styled from 'styled-components/macro';
import {AuthorizationCard} from './AuthorizationCard';
import {Authorization} from './AuthorizationCard';
import {IOrganization, IProduct} from '../../API';
import {Dropdown, TValidOption} from '../../Components/Molecules/Dropdown';
import {completeProductList, productLessProductList} from './adminUtils';

const AuthorizationsBox = styled.div`
  align-items: center;
  background-color: #f3f3f4;
  border-radius: 16px;
  border: 1px solid #f3f3f4;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  min-height: 52px;
  padding: 6px;
`;

interface IAuthorizationsSelect {
  addAuths: Authorization[];
  handleAddAuth: (value: string) => void;
  handleRemoveAuth: (authorization: Authorization) => void;
  product?: IProduct;
  organization?: IOrganization;
  removeAuths: Authorization[];
  includeProducts?: boolean;
  includeTracts?: boolean;
}

export const Authorizations: FC<IAuthorizationsSelect> = ({
  addAuths,
  handleAddAuth,
  handleRemoveAuth,
  includeProducts = false,
  includeTracts = false,
  organization = {},
  product = {},
  removeAuths,
}) => {
  const products = useAdminProducts();
  const authorizations = product?.authorizations ?? organization?.authorizations;
  const allProducts = completeProductList({products: products?.data});
  const availableProducts = includeProducts ? allProducts : productLessProductList;
  const filteredAuths =
    authorizations?.filter(
      (auth: any) => removeAuths.findIndex((ra) => ra.targetId === auth.targetId && ra.targetType === auth.targetType) === -1,
    ) || [];
  const authorizationsToList = [...filteredAuths, ...addAuths];
  const options = availableProducts.map((auth) => ({
    value: auth.id,
    label: auth.name,
  }));

  const handleCreateOption = (inputValue: any) => {
    handleAddAuth(`tract:${inputValue}`);
  };

  const handleValidateOption: TValidOption = (inputValue) => {
    return /^\d{11}$/.test(inputValue);
  };

  options.unshift({label: 'Add a location...', value: ''});

  return (
    <div style={{margin: '0  0 40px'}}>
      <H5>Authorizations</H5>
      <FormField>
        <Dropdown
          defaultValue={options[0]}
          formatCreateLabel={(value) => <>Tract: {value}</>}
          id="authorization-select"
          isCreatable={includeTracts}
          isValidNewOption={handleValidateOption}
          onChange={(value) => handleAddAuth(value.value)}
          onCreateOption={handleCreateOption}
          options={options}
        />
      </FormField>
      <AuthorizationsBox>
        {authorizationsToList.length ? (
          authorizationsToList?.map((authorization: any, index) => (
            <AuthorizationCard key={index} authorization={authorization} onRemove={handleRemoveAuth} products={allProducts} />
          ))
        ) : (
          <Subhead2 style={{margin: 'auto 1em'}}>No locations added</Subhead2>
        )}
      </AuthorizationsBox>
    </div>
  );
};
