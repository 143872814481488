import {FC} from 'react';
import {Field, useField} from 'formik';
import styled from 'styled-components/macro';
import {Subhead2} from '../Atoms';

const FieldWrapper = styled.div`
  flex: 0;
  margin: 0 0 8px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex-direction: row;
`;

const FieldLabel = styled(Subhead2)`
  margin: 0;
`;

const Radio = styled(Field)`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  -webkit-appearance: none;
  appearance: none;
  margin: 0 8px 0 0;
  font: inherit;
  color: ${(props) => props.theme.Colors.Oranges._100};
  border: 1px solid ${(props) => props.theme.Colors.Oranges._100};
  //background-color: ${(props) => props.theme.Colors.Oranges._100};
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    color: ${(props) => props.theme.Colors.Oranges._500};
    background-color: ${(props) => props.theme.Colors.Oranges._000};
    box-shadow: inset 1em 1em ${(props) => props.theme.Colors.Oranges._500};
  }

  &:checked {
    background-color: ${(props) => props.theme.Colors.Oranges._100};

    &::before {
      transform: scale(1);
    }
  }
`;

export interface IRadioButtonProps {
  name: string;
  value: string;
  label?: string;
  style?: any;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const RadioButton: FC<IRadioButtonProps> = ({name, value, label, style, disabled, onChange}) => {
  const [field] = useField(name);

  return (
    <FieldWrapper style={style}>
      <InputWrapper
        onClick={() => {
          if (onChange) {
            onChange(value);
          }
        }}>
        <Radio name={name} type="radio" disabled={disabled} value={value} checked={field.value === value} />
        {label ? <FieldLabel>{label}</FieldLabel> : <></>}
      </InputWrapper>
    </FieldWrapper>
  );
};
