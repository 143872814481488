import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {XIcon} from '@heroicons/react/solid';
import {QuestionMarkCircleIcon} from '@heroicons/react/outline';
import {Body2, Body4, FlexFill, H6, LegendButton, Subhead3, Theme} from '../../../Components';
import {AllDatasets} from '../../../Managers/MapManager';
import {useAppDispatch, useAppSelector} from '../../../store';
import {setLegendOpen} from '../../../Redux/Slices/Map';

const LegendWrapper = styled.div`
  position: absolute;
  z-index: 5;
  top: 120px;
  right: 20px;
  width: 300px;
  align-items: flex-end;
`;

const Panel = styled.div`
  top: 0;
  left: 0;
  width: 300px;
  z-index: 20;
  padding: 28px;
  background: #fff;
  position: absolute;
  border-radius: 16px;

  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

const PanelHeader = styled.div`
  flex-direction: row;
  margin-bottom: 30px;
  flex: 0;
`;

const PanelTopButton = styled.button`
  width: 24px;
  height: 24px;
  //padding: 16px;
  display: flex;
  border: none;
  z-index: 10;
  margin: 0;
  background: #ffffff;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

const ColorRow = styled.div`
  flex-direction: row;
  margin: 0 0 8px;
  align-items: center;
`;

const ColorBox = styled.div`
  margin: 0 8px 0 0;
  flex: 0 0 32px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const OneDimensionDemographicLegend: FC = () => {
  const dispatch = useAppDispatch();
  const legendOpen = useAppSelector((state) => state.map.legendOpen);
  const {dataset1, dataset2} = useAppSelector((state) => state.user);
  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);

  const OpenButton = () =>
    dataset1.id || dataset2.id ? (
      <LegendButton
        id="open-legend"
        onClick={() => {
          dispatch(setLegendOpen(true));
        }}>
        <QuestionMarkCircleIcon width={20} style={{marginBottom: -5, marginRight: 12}} />
        Open Legend
      </LegendButton>
    ) : (
      <></>
    );

  return (
    <LegendWrapper id="map-legend">
      {legendOpen ? (
        <Panel id="map-panel">
          <PanelHeader>
            <H6 style={{color: Theme.Colors.Oranges._000}}>Legend</H6>
            <FlexFill />
            <PanelTopButton
              data-html2canvas-ignore
              onClick={() => {
                dispatch(setLegendOpen(false));
              }}>
              <XIcon />
            </PanelTopButton>
          </PanelHeader>

          <Subhead3 style={{color: Theme.Colors.Neutrals._200}}>{ds1?.downloadTitle}</Subhead3>
          <Body4>{ds1?.description}</Body4>

          <Subhead3 style={{color: Theme.Colors.Neutrals._200}}>Visual Indicator</Subhead3>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Oranges._000}} />
            <Body2 style={{marginBottom: 0}}>Asian</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Yellows._000}} />
            <Body2 style={{marginBottom: 0}}>Black</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Teals._000}} />
            <Body2 style={{marginBottom: 0}}>Latinx/Hispanic</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Cyans._000}} />
            <Body2 style={{marginBottom: 0}}>Native American</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Blues._000}} />
            <Body2 style={{marginBottom: 0}}>Pacific Islander</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Purples._000}} />
            <Body2 style={{marginBottom: 0}}>White</Body2>
          </ColorRow>

          <ColorRow>
            <ColorBox style={{backgroundColor: Theme.Colors.Pinks._000}} />
            <Body2 style={{marginBottom: 0}}>Other</Body2>
          </ColorRow>

          <FlexFill />
        </Panel>
      ) : (
        <OpenButton />
      )}
    </LegendWrapper>
  );
};
