import React, {FC} from 'react';
import {useAdminOrganizations} from '../../../API';
import {truncateString} from '../adminUtils';
import {SimpleSelect} from './TableLayout';

interface UserOrganizationCellProps {
  value: any;
  row: any;
  onChange: (value: string, row: any) => void;
  disabled?: boolean;
}

export const UserOrganizationCell: FC<UserOrganizationCellProps> = ({value: initialValue, row, onChange, disabled = false}) => {
  const [value, setValue] = React.useState(initialValue);
  const organizations = useAdminOrganizations();

  const handleChange = (e: any) => {
    onChange(e.target.value, row);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const orgName = organizations?.data?.find((org) => org.id === value)?.name;

  if (disabled || !(row.original.user_type === 'admin')) return <>{orgName}</>;

  return (
    <SimpleSelect value={value || ''} onChange={handleChange} disabled={disabled}>
      <option>None</option>
      {organizations?.data?.map((org) => (
        <option value={org.id} key={org.id}>
          {truncateString(org.name, 30)}
        </option>
      ))}
    </SimpleSelect>
  );
};
