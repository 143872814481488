import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {formatPercent, getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {fillBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';

const getValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  return row?.treeCanopy;
};

export const UrbanTreeCanopy: IDatasetOptions<number> = {
  id: 'utc',
  title: 'Urban Tree Canopy Coverage',
  downloadTitle: 'Urban Tree Canopy',
  description: 'Shows the percentage of a census tract that is covered by trees. Data available for all metro areas.',
  combinable: true,
  opacityTopLabel: 'Least Cover',
  opacityBottomLabel: 'Most Cover',
  isInverted: true,
  getValue,

  formatValue: (config: IDatasetConfig, value: number | null) => {
    return value === 0 || value ? formatPercent(value.toFixed(2)) : '---';
  },

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) =>
    getMinMax(getAllValues(config, rows, getValue, UrbanTreeCanopy.downloadTitle), true),

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = getAllValues(config, rows, getValue, UrbanTreeCanopy.downloadTitle);
    const [min, max] = getMinMax(values);
    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      map.fills.push(String(tractId));
      // reverse the buckets so that the lowest values are the darkest
      map.fills.push(
        [...fillBuckets].reverse()[getValueBucket(Math.round(100 * value) / 100, min, config.cutoff1 || max, fillBuckets.length)],
      );
      map.opacities.push(String(tractId));
      map.opacities.push(value || value === 0 ? 1 : 0);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    const values = getAllValues(config, rows, getValue, UrbanTreeCanopy.downloadTitle);
    const [min, max] = getMinMax(values);
    const floor = config.cutoff2 || min;
    const lCutoff = floor + (max - min) * 0.2;
    const hCutoff = floor + (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (Math.round(100 * value) / 100 <= lCutoff) {
        map.values[tractId] = 'H';
      } else if (Math.round(100 * value) / 100 >= hCutoff) {
        map.values[tractId] = 'L';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
