export const routePaths = {
  map: '/indicators',
  admin: '/admin/',
  adminOrganizations: '/admin/organizations',
  adminOrganizationsById: '/admin/organizations/:organizationId',
  adminOrganizationCreate: '/admin/organization/create',
  adminProducts: '/admin/products',
  adminProductsById: '/admin/products/:productId',
  adminProductCreate: '/admin/product/create',
  adminUsers: '/admin/users',
  login: '/login',
  onboarding: '/onboarding',
  onBoardingConfirm: '/',
  onboardingLocal: '/onboarding/local',
  onboardingLocations: '/onboarding/locations',
  onboardingDefaultLocation: '/onboarding/default-location',
  onboardingMembers: '/onboarding/members',
  onboardingOrganization: '/onboarding/organization',
  onboardingPayment: '/onboarding/payment',
  onboardingPaymentConfirmed: '/onboarding/payment/confirmed',
  orgAdminOrganization: '/admin/my-organization',
  orgAdminMembers: '/admin/my-members',
  orgAdminLocations: '/admin/my-locations',
  orgAdminSubscription: '/admin/my-subscription',
  settings: '/admin/settings',
};
