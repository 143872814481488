import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {FC, useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {ICreateMySubscription, useCreateMySubscription} from '../../../API';
import {useCartOrganization} from '../../../Hooks/useCartOrganization';
import {useMyProfile} from '../../../API/Queries/useMyProfile';
import {Body2, FlexFill, FlexRow, StandardButton} from '../../../Components';
import {routePaths} from '../../../constants/routePaths';
import {getConfig} from '../../../getConfig';
import {ErrorTitle, OnboardingPage, OnboardingPageWrapper, OnboardingPanel} from '../Layout';
import {StripePaymentForm} from './StripePaymentForm';

export const Divider = styled.hr`
  flex-shrink: 0;
  height: 2px;
  width: 100%;
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #f3f3f4;
  border-radius: 9999px;
`;

const Text = styled(Body2)`
  margin: 0;
  text-align: center;
`;

export type TSubscription = {type: string; stripe_client_secret: string};

const stripePromise = loadStripe(getConfig().stripePublishableKey);

export const SetupPayment: FC = () => {
  const navigate = useNavigate();
  const createMySubscription = useCreateMySubscription();
  const {organization} = useCartOrganization();
  const {data: myProfile} = useMyProfile();

  const [subscriptionCreationResults, setSubscriptionCreationResults] = useState<ICreateMySubscription | null>(null);

  const createSubscription = useCallback(async () => {
    try {
      const subscriptionCreationResults = await createMySubscription.mutateAsync({
        ...organization,
        id: myProfile?.user?.organization?.id,
        primary_email: myProfile?.user?.organization?.primary_email || myProfile?.user?.email,
      });

      setSubscriptionCreationResults(subscriptionCreationResults);
    } catch (error) {
      console.log('Error while creating my subscription');
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (
      !createMySubscription.isLoading &&
      !subscriptionCreationResults?.stripeSubscription?.latest_invoice?.payment_intent?.client_secret
    ) {
      if (!organization) {
        navigate(routePaths.onboardingOrganization);
      }

      createSubscription();
    }
  }, []);

  useEffect(() => {
    if (
      subscriptionCreationResults?.stripeSubscription?.status === 'active' ||
      subscriptionCreationResults?.stripeSubscription?.status === 'trialing'
    ) {
      navigate(routePaths.onboardingPaymentConfirmed);
    }
  }, [subscriptionCreationResults]);

  if (createMySubscription.isLoading) {
    return (
      <OnboardingPageWrapper>
        <OnboardingPage style={{maxWidth: '1000px', justifyContent: 'center', flexDirection: 'column'}}>
          <OnboardingPanel style={{gap: 28, marginBottom: 300}}>
            <ErrorTitle>{'Setting Up Subscription'}</ErrorTitle>
            <Text>We're preparing your subscription details.</Text>
          </OnboardingPanel>
        </OnboardingPage>
        <FlexFill style={{flex: 0}} />
      </OnboardingPageWrapper>
    );
  }

  if (createMySubscription.isError || !subscriptionCreationResults?.stripeSubscription?.latest_invoice?.payment_intent?.client_secret) {
    return (
      <OnboardingPageWrapper>
        <OnboardingPage style={{maxWidth: 500}}>
          <OnboardingPanel style={{gap: 28}}>
            <ErrorTitle>{'Problem Setting Up Subscription'}</ErrorTitle>
            <Text>There was a problem setting up the subscription. If the problem persists, contact Greenlink Analytics for support.</Text>
            <FlexRow style={{gap: 16}}>
              <StandardButton style={{flex: 1}} outline onClick={() => navigate(routePaths.onboardingLocations)}>
                Go Back
              </StandardButton>
              <StandardButton type="button" style={{flex: 1}} onClick={createSubscription}>
                Retry
              </StandardButton>
            </FlexRow>
          </OnboardingPanel>
        </OnboardingPage>
        <FlexFill style={{flex: 0}} />
      </OnboardingPageWrapper>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: subscriptionCreationResults?.stripeSubscription?.latest_invoice?.payment_intent?.client_secret,
        appearance: {
          theme: 'flat',
        },
      }}>
      <StripePaymentForm
        organization={organization}
        subscription={subscriptionCreationResults?.subscription}
        stripeSubscription={subscriptionCreationResults?.stripeSubscription}
      />
    </Elements>
  );
};
