import React, {FC} from 'react';
import {useField} from 'formik';
import styled from 'styled-components/macro';
import {CheckIcon} from '@heroicons/react/solid';
import {Body2} from '../Atoms';

type CheckboxProps = {
  name: string;
  label?: string;
  checked?: boolean;
  style?: any;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onChange?: (value: boolean) => void;
  disabled?: boolean;
  wrap?: boolean;
};

const FieldLabel = styled(Body2)`
  margin: 0 0 0 8px;
  white-space: nowrap;
`;

const FieldWrapper = styled.div`
  flex-direction: row;
  margin: 0 0 24px 0;
  flex: 0;
  justify-content: flex-start;
  // This was causing an error for me, space-between isn't a valid option for align-items, it's for align-content (and not broadly
  // supported)
  //align-items: space-between;
`;

const CheckboxContainer = styled.div`
  vertical-align: middle;
  flex-grow: 0;
`;

const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{checked?: boolean}>`
  flex: 0;
  align-self: flex-start;
  width: 20px;
  min-height: 20px;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.checked ? props.theme.Colors.Oranges._000 : 'white')};
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.Colors.Oranges._000};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`;

export const BasicCheckbox: FC<CheckboxProps> = ({name, label = '', onChange, style, disabled = false, wrap, ...props}) => {
  const [field, meta, helpers] = useField({name, type: 'checkbox'});
  const {value} = meta;
  const {setValue} = helpers;

  return (
    <FieldWrapper
      style={style}
      onClick={() => {
        if (!disabled) {
          setValue(!value);
          if (onChange) {
            onChange(!value);
          }
        }
      }}
      {...props}>
      <CheckboxContainer>
        <HiddenCheckbox {...field} />
        <StyledCheckbox {...field}>{value && <CheckIcon style={{color: 'white', width: 14}} />}</StyledCheckbox>
      </CheckboxContainer>
      {label ? <FieldLabel style={{whiteSpace: wrap ? 'normal' : 'nowrap'}}> {label} </FieldLabel> : <></>}
    </FieldWrapper>
  );
};
