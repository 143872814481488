import {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import {RadioButton, TooltipTrigger} from '../../../../Components';
import {
  IBuildingValue,
  IDatasetConfig,
  IDatasetOptions,
  IDemographicValue,
  IRedliningValue,
  ITribeValue,
} from '../../../../Managers/Datasets/Types';

interface Props {
  dataset: IDatasetConfig;
  options: IDatasetOptions<number | IDemographicValue | IRedliningValue | IBuildingValue | ITribeValue>;
  onUpdateDataset: (dataset: IDatasetConfig) => void;
}

export const SubsetRadio: FC<Props> = ({dataset, options, onUpdateDataset}) => {
  const handleSetSubset = (subset: string) => {
    const datasetCopy = {...dataset};
    datasetCopy.subsets = subset ? [subset] : [];
    onUpdateDataset(datasetCopy);
  };

  return (
    <>
      {options.subsets?.map((subset, index) => (
        <TooltipTrigger data-tip={subset.description || ''} data-for="radio-tooltips" key={index}>
          <RadioButton
            key={subset.id}
            name={dataset.id + '-subset'}
            value={subset.id}
            label={subset.title}
            onChange={handleSetSubset}
            style={{marginBottom: 16}}
          />
        </TooltipTrigger>
      ))}
      <ReactTooltip
        id="radio-tooltips"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 8, left: 100}}
        wrapper="span"
        className="info-tooltip"
      />
    </>
  );
};
