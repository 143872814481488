import {format} from 'date-fns';
import React, {FC} from 'react';
import styled from 'styled-components/macro';
import {DownloadIcon} from '@heroicons/react/outline';
import {downloadImage, downloadMapData} from '../../../Managers/DownloadManager';
import {Body3, FlexFill, H4, H6, StandardButton} from '../../../Components';
import {AllDatasets} from '../../../Managers/MapManager';
import ReactTooltip from 'react-tooltip';
import {useAppSelector} from '../../../store';
import {useMixpanel} from '../../../Managers/Mixpanel/useMixpanel';

const Wrapper = styled.div``;

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const FilterDownload: FC = () => {
  const {dataset1, dataset2} = useAppSelector((state) => state.user);
  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);

  const {mixpanel} = useMixpanel();

  const downloadCSV = () => {
    mixpanel.track('Download CSV');

    downloadMapData(`Greenlink-Equity-Map-${format(new Date(), 'MM-dd-yyyy')}.csv`);

    // TODO: MixPanel and Bugfender
    // Bugfender.log(`Download CSV`);
    // mixpanel.track('Download CSV', {dataset1: ds1?.title || 'Not Selected', dataset2: ds2?.title || 'Not Selected'});
  };

  const downloadMap = () => {
    mixpanel.track('Download Map');

    const prefix = [ds1, ds2]
      .map((ds) => ds?.downloadTitle.replace(/ /g, '-') || null)
      .filter((a) => a)
      .join('-');
    downloadImage(`Greenlink-Equity-Map-${prefix}-${format(new Date(), 'MM-dd-yyyy')}.png`);

    // TODO: MixPanel and Bugfender
    // Bugfender.log(`Download Map`);
    // mixpanel.track('Download Map', {dataset1: ds1?.title || 'Not Selected', dataset2: ds2?.title || 'Not Selected'});
  };

  return (
    <Wrapper>
      <H4>Download</H4>

      <H6 style={{marginTop: 16}}>Data</H6>
      <Body3>Download a CSV file for each of the indicators in GEM by census tract.</Body3>
      <StandardButton
        onClick={() => downloadCSV()}
        data-tip=" Downloaded data values may be slightly different than displayed due to rounding."
        data-for="button-tip">
        <DownloadIcon style={{display: 'inline', marginRight: 16, width: 20, verticalAlign: 'bottom'}} />
        Download Data
      </StandardButton>

      <H6 style={{marginTop: 32}}>Map</H6>
      <Body3>Download a PNG screenshot of the GEM map to include in your next presentation.</Body3>
      <StandardButton onClick={() => downloadMap()}>
        <DownloadIcon style={{display: 'inline', marginRight: 16, width: 20, verticalAlign: 'bottom'}} />
        Download Map
      </StandardButton>

      <FlexFill />
      <ReactTooltip
        id="button-tip"
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 2, left: -5}}
        wrapper="span"
        className="info-tooltip"
      />
    </Wrapper>
  );
};
