import {IStripeSubscription} from '../../../API';
import {Theme} from '../../../Components';
import {SubscriptionDetailRow} from '../../../Components/Molecules/SubscriptionDetail';
import {TotalRow} from '../../../Components/Molecules/TotalRow';
import {Divider} from './SetupPayment';

// This assumes no sales tax when computing changes, although it's possible that some states will require it
export const TotalsFromInvoice = ({
  amount_due = 0,
  hideItems,
  newCost = 0,
  newPercentOff = 0,
  stripeSubscription,
  previewLines,
  previewSubtotal = 0,
  previewEndingBalance,
}: {
  amount_due?: number;
  hideItems?: boolean;
  newCost?: number;
  newPercentOff?: number;
  stripeSubscription?: IStripeSubscription;
  previewLines?: any;
  previewSubtotal?: number;
  previewEndingBalance?: number;
}) => {
  const {
    discount,
    lines,
    ending_balance,
    starting_balance,
    subtotal_excluding_tax,
    subtotal,
    total_discount_amounts,
    total_tax_amounts,
    total,
  } = stripeSubscription?.latest_invoice || {};

  const savings = total - subtotal;
  const discountAmount = -1 * total_discount_amounts?.reduce((acc: number, curr: any) => acc + curr.amount, 0) || 0;
  const percentOff = discount?.coupon?.percent_off || 0;

  const newSavings = newCost ? ((newCost * (newPercentOff || 0)) / 100) * -1 : savings;
  const newDiscountAmount = newCost ? newCost * ((newPercentOff || 0) / 100) * -1 : discountAmount;
  const newTotal = newCost ? newCost + newSavings : total;

  const tax = total_tax_amounts?.reduce((acc: number, curr: any) => acc + curr.amount, 0) || 0;

  return (
    <div style={{gap: 16}}>
      {!!starting_balance && <SubscriptionDetailRow label="Starting balance" cost={starting_balance / 100} />}

      {!hideItems ||
        (!!ending_balance &&
          lines?.data?.map((item: any) => {
            const amount = item.amount / 100;
            // exclude text between parentheses
            const description = item.description.replace(/\(.*?\)/g, '').trim();
            return <SubscriptionDetailRow key={item.id} label={description} cost={amount} />;
          }))}

      <div>
        {!!tax && (
          <>
            <SubscriptionDetailRow label="Subtotal (excluding tax)" cost={subtotal_excluding_tax / 100} />
            <SubscriptionDetailRow label="Taxes" cost={tax / 100} />
          </>
        )}

        <SubscriptionDetailRow label="Subtotal" cost={(newCost || subtotal || 0) / 100} />

        {!!percentOff && (
          <SubscriptionDetailRow
            label={`Discount (${(percentOff / 100)?.toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 0})})`}
            cost={discountAmount / 100}
          />
        )}
      </div>

      <TotalRow label="Total" cost={newTotal / 100} />

      {!!ending_balance && (
        <SubscriptionDetailRow label="Ending balance (to be applied at next billing period)" cost={ending_balance / 100} />
      )}

      {!hideItems && (
        <>
          <div>
            <Divider />

            {!!newPercentOff && (
              <SubscriptionDetailRow
                label={`Discount (${(newPercentOff / 100)?.toLocaleString('en-US', {style: 'percent', minimumFractionDigits: 0})})`}
                cost={newDiscountAmount / 100}
              />
            )}
          </div>

          {!hideItems &&
            previewLines?.data?.map((item: any) => {
              const amount = item.amount / 100;
              // exclude text between parentheses
              const description = item.description.replace(/\(.*?\)/g, '').trim();

              return <SubscriptionDetailRow key={item.id} label={description} cost={amount} />;
            })}

          <SubscriptionDetailRow label="New subtotal" cost={previewSubtotal / 100 || 0} />

          <TotalRow label="After Update" cost={newCost / 100 || 0} color={Theme.Colors.Neutrals._200} />

          <TotalRow hideInterval label="Amount Due" cost={amount_due / 100 || 0} color={Theme.Colors.Neutrals._200} />

          {!!previewEndingBalance && (
            <SubscriptionDetailRow label="Ending balance (to be applied at next billing period)" cost={previewEndingBalance / 100} />
          )}
        </>
      )}
    </div>
  );
};
