import {useMutation, useQueryClient} from 'react-query';
import {updatePrices} from '../Calls/Admin';

// NOTE: This returns a full "login response" to save the front-end a few calls
export const useUpdatePrices = () => {
  const qc = useQueryClient();

  return useMutation(updatePrices, {
    onError: (err: Error, _newProfile, context) => {
      console.log('Error updating profile', err.message);
    },

    onSettled: () => {
      qc.invalidateQueries(['SUBSCRIPTION', 'PRICING']);
    },
  });
};
