import {useMutation, useQueryClient} from 'react-query';
import {updateAdminOrganization} from '../Calls/Admin';

interface UseUpdateAdminOrganizationOptions {
  skipInvalidations?: boolean;
}

export const useUpdateAdminOrganization = (options?: UseUpdateAdminOrganizationOptions) => {
  const qc = useQueryClient();

  return useMutation(updateAdminOrganization, {
    onSuccess: () => {
      if (!options?.skipInvalidations) {
        // We COULD use the result to update the one organization we edited. But we invalidate the query anyway because this is a good time
        // to make sure another admin isn't doing something (or the user doesn't accidentally have multiple tabs open).
        qc.invalidateQueries(['ADMIN', 'ORGANIZATIONS']).catch(() => {});
        qc.invalidateQueries(['ADMIN', 'ORGANIZATION']).catch(() => {});
        qc.invalidateQueries(['PROFILE']).catch(() => {});
      }
    },
    onError: (err: Error) => {
      console.log('Error updating organization', err.message);
    },
  });
};
