import {FC} from 'react';
import {IOrganization, useSubscriptionPricing} from '../../API';
import {BoldSubhead1} from '..';
import {SubscriptionDetailRow} from './SubscriptionDetail';
import {calcSingleLocationCost, formatTractCount, getTractCount} from '../../Managers/MathUtils';
import {formatAuthorizationTitle, productLessProductList} from '../../Views/Admin/adminUtils';
import {IAuthCondensed} from '../../Views/Onboarding/OrganizationLocationsPanels';

export const LocationDetailsSection: FC<{
  authorizations: IAuthCondensed[];
  disabled?: boolean;
  hideActionButtons?: boolean;
  organization: IOrganization;
  onRemoveAuth?: (auth: IAuthCondensed) => void;
}> = ({authorizations, disabled, organization, onRemoveAuth, hideActionButtons}) => {
  const {data: subscriptionPricing} = useSubscriptionPricing();

  return (
    <div style={{gap: 8}}>
      <BoldSubhead1>Locations</BoldSubhead1>

      {authorizations?.length === 0 && <span style={{marginTop: '8px', fontSize: '14px', color: '#6A6F7B'}}>No locations selected</span>}

      {[...(authorizations || [])]
        .sort((a, b) => {
          if (a.targetType === b.targetType) {
            return a.targetId.localeCompare(b.targetId);
          } else {
            return a.targetType.localeCompare(b.targetType);
          }
        })
        .map((auth) => {
          const authPrice = calcSingleLocationCost(auth, subscriptionPricing, organization?.subscription_type) || 0;

          return (
            <SubscriptionDetailRow
              key={`${auth.targetType}:${auth.targetId}`}
              cost={authPrice}
              disabled={disabled}
              header={formatAuthorizationTitle(auth, productLessProductList)}
              onDelete={() => onRemoveAuth && onRemoveAuth(auth)}
              showDelete={!hideActionButtons && !!onRemoveAuth}
              label={auth.targetType === 'nationwide' ? 'All tracts' : formatTractCount(getTractCount(auth, subscriptionPricing))}
            />
          );
        })}
    </div>
  );
};
