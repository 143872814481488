import {FC} from 'react';
import styled from 'styled-components/macro';

type StepsProps = {
  current?: number;
  completed?: number;
  total?: number;
  onClick?: (step: number) => void;
  ignoreCompleted?: boolean;
};

const Container = styled.div`
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex: 0;
`;

const Step = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 4px;
  background-color: ${(props) => props.theme.Colors.Neutrals._400};
`;

const CompletedStep = styled(Step)`
  background-color: ${(props) => props.theme.Colors.Oranges._000};
`;

const CurrentStep = styled(Step)`
  width: 20px;
  background-color: ${(props) => props.theme.Colors.Oranges._000};
`;

const StepMap = ({completed = 0, step = 1, current = 1, onClick = (step: number) => {}}) => {
  if (step === current) return <CurrentStep onClick={() => onClick(step)} />;
  else if (step > completed) return <Step onClick={() => {}} />;
  else return <CompletedStep onClick={() => onClick(step)} />;
};

export const Steps: FC<StepsProps> = ({current = 1, total = 4, completed = 1, onClick, ignoreCompleted}) => {
  return (
    <Container>
      {[...new Array(total)].map((_, i) => (
        <StepMap key={i} step={i} current={current} onClick={onClick} completed={ignoreCompleted ? current : completed} />
      ))}
    </Container>
  );
};
