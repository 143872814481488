import {AddressElement, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {PaymentIntentResult} from '@stripe/stripe-js';
import {FC, useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {
  IOrganization,
  IStripeSubscription,
  ISubscription,
  useCreateMySubscription,
  useSubscriptionPricing,
  useUpdateMyProfile,
} from '../../../API';
import {useMyProfile} from '../../../API/Queries/useMyProfile';
import {Body2, FlexFill, FlexRow, FullPageLoader, H4, StandardButton} from '../../../Components';
import {routePaths} from '../../../constants/routePaths';
import {ErrorTitle, OnboardingPage, OnboardingPageWrapper, OnboardingPanel} from '../Layout';
import {OnboardingSteps} from '../OnboardingSteps';
import {PaymentDetailsPanel} from './PaymentDetailsPanel';
import {useMixpanel} from '../../../Managers/Mixpanel/useMixpanel';

export const VerticalDivider = styled.div`
  flex: 0;
  height: 100%;
  width: 2px;
  border: 1px solid #f3f3f4;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #f3f3f4;
  border-radius: 9999px;
`;

const Text = styled(Body2)`
  margin: 0;
  text-align: center;
`;

export const StripePaymentForm: FC<{
  organization?: IOrganization;
  subscription?: ISubscription;
  stripeSubscription: IStripeSubscription;
}> = ({organization, subscription, stripeSubscription}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [stripeReady, setStripeReady] = useState(false);
  const [stripeComplete, setStripeComplete] = useState(false);

  const {data: myProfile} = useMyProfile();
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const createMySubscription = useCreateMySubscription();
  const updateMyProfile = useUpdateMyProfile();
  const {mixpanel} = useMixpanel();

  const navigate = useNavigate();

  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;
  const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}: PaymentIntentResult) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe, clientSecret]);

  const handleSubmit = async (e: any) => {
    try {
      setSubmitting(true);
      e.preventDefault();

      if (stripe && elements) {
        const result = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${window.location.protocol}//${window.location.host}${routePaths.onboardingPayment}`,
          },
        });

        if (result?.error) {
          toast.error(result?.error?.message || 'There was a problem processing your payment.');
          console.error('Stripe error: ' + result?.error.message, result?.error);
        } else {
          await updateMyProfile.mutateAsync({onboarding_completed: new Date().toUTCString()});

          // await finalizeSubscription.mutateAsync({primary_email: organization?.primary_email || '', paymentIntent: result?.paymentIntent});

          console.log('Subscription setup succeeded.', result?.paymentIntent);
          // await queryClient.invalidateQueries(['PROFILE']).catch(() => {});
          mixpanel.track('Onboarding Completed');
          navigate(routePaths.onboardingPaymentConfirmed);
        }
      } else if (organization?.subscription) {
        console.log('Updating subscription!');
        await createMySubscription.mutateAsync(organization);
        toast.success('Subscription has been successfully created!');

        navigate(routePaths.onboardingPaymentConfirmed);
      }
    } catch (error) {
      console.log('Error saving organization', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFinish = () => {
    navigate(routePaths.onboardingDefaultLocation);
  };

  if (message === 'Payment succeeded!') {
    return (
      <OnboardingPageWrapper>
        <OnboardingPage style={{maxWidth: 1000}}>
          <OnboardingPanel style={{alignItems: 'center', justifyContent: 'center'}}>
            <img alt="Check mark" width="111" height="111" src="/check-mark.svg"></img>
            <H4 style={{color: '#84CC16', marginTop: '20px'}}>
              {organization?.subscription_type === subscriptionTypes?.local?.value ? 'Subscription Finished!' : 'Payment Confirmed!'}
            </H4>
            <p style={{fontSize: '16px', maxWidth: '400px', textAlign: 'center', marginTop: '0px', marginBottom: '20px'}}>
              {organization?.subscription_type === subscriptionTypes?.local?.value
                ? 'We have received your application as a 501(c)(3) organization and will review it shortly.'
                : 'Your payment has been confirmed. You are now subscribed to the Greenlink Equity Map.'}
            </p>
            <StandardButton style={{width: '400px'}} onClick={handleFinish}>
              Finish
            </StandardButton>
          </OnboardingPanel>
        </OnboardingPage>
        <FlexFill style={{flex: 0}} />
      </OnboardingPageWrapper>
    );
  }
  if (message)
    return (
      <OnboardingPageWrapper>
        <OnboardingPage style={{maxWidth: 500}}>
          <OnboardingPanel style={{gap: 28}}>
            <ErrorTitle>{message}</ErrorTitle>
            <Text>
              There was a problem setting up the subscription and it was not created. If the problem persists, contact Greenlink Analytics
              for support.
            </Text>
            <FlexRow style={{gap: 16}}>
              <StandardButton style={{flex: 1}} outline onClick={() => navigate(routePaths.onboardingLocations)}>
                Go Back and Retry
              </StandardButton>
            </FlexRow>
          </OnboardingPanel>
        </OnboardingPage>
        <FlexFill style={{flex: 0}} />
      </OnboardingPageWrapper>
    );

  return (
    <OnboardingPageWrapper>
      {(submitting || !stripeReady) && <FullPageLoader />}
      <OnboardingPage style={{maxWidth: '1000px', justifyContent: 'center', flexDirection: 'column'}}>
        <OnboardingPanel style={{flex: 1, padding: '28px', background: 'white', maxWidth: '100%', gap: 28, overflowY: 'visible'}}>
          <H4 style={{padding: 0, marginBottom: '0px', textAlign: 'center'}}>Subscribe</H4>
          <div style={{flexDirection: 'row', flexGrow: 1, overflowY: 'visible', gap: 28, display: stripeReady ? 'flex' : 'none'}}>
            {stripe && elements ? (
              <>
                <div style={{flex: 3, maxWidth: '680px'}}>
                  <form id="onboarding-payment-form" onSubmit={handleSubmit}>
                    <div style={{marginBottom: 20}}>
                      <AddressElement
                        options={{
                          mode: 'billing',
                          defaultValues: {
                            address: {
                              line1: organization?.address1,
                              line2: organization?.address2,
                              city: organization?.city,
                              state: organization?.state,
                              postal_code: organization?.zip,
                              country: 'US',
                            },
                            name: myProfile?.user?.firstName + ' ' + myProfile?.user?.lastName,
                          },
                        }}
                      />
                    </div>
                    <PaymentElement
                      onReady={(el) => {
                        // console.log('Payment element ready', el);
                        setStripeReady(true);
                      }}
                      onChange={(event) => {
                        // console.log('Payment element change', event);
                        setStripeComplete(event.complete);
                      }}
                      options={{
                        layout: {
                          type: 'tabs',
                        },
                      }}
                    />
                  </form>
                </div>
              </>
            ) : (
              ''
            )}
            {stripeReady && <VerticalDivider />}

            <PaymentDetailsPanel
              organization={organization}
              subscription={subscription}
              stripeSubscription={stripeSubscription}
              onSubmit={handleSubmit}
              isReady={stripeComplete}
              submitting={submitting}
            />
          </div>
        </OnboardingPanel>
      </OnboardingPage>
      <OnboardingSteps />
    </OnboardingPageWrapper>
  );
};
