import React from 'react';
import styled from 'styled-components';
import {authorizationColors, formatAuthorizationTitle} from './adminUtils';
import {XCircleIcon} from '@heroicons/react/outline';

const Wrapper = styled.div<{color: string}>`
  background-color: ${(props) => props.theme.Colors[props.color]._000};
  color: white;
  z-index: 80;
  height: 30px;
  padding: 2px 6px 2px 10px;
  font-size: 14px;
  display: flex;
  flex: 0;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  font-family: Poppins;
  border-radius: 18px;
  font-weight: 400;
`;

const RemoveIcon = styled(XCircleIcon)`
  color: white;
  margin-left: 6px;
  width: 1.5rem;

  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.Colors.Neutrals._500};
  }
`;

export interface Authorization {
  targetType: 'nationwide' | 'product' | 'state' | 'county' | 'tract';
  targetId: string;
}

interface AuthorizationCardProps {
  authorization: Authorization;
  onRemove: (authorization: Authorization) => void;
  products: any[];
}

export const AuthorizationCard: React.FunctionComponent<AuthorizationCardProps> = ({authorization, onRemove, products}) => {
  // For giggles I timed how long useSelector() takes. It was 0.017ms on average. So calling it over and over is just fine.
  const label = formatAuthorizationTitle(authorization, products);
  return (
    <Wrapper color={authorizationColors[authorization.targetType]}>
      <span>{label}</span>
      <RemoveIcon onClick={() => onRemove(authorization)} />
    </Wrapper>
  );
};
