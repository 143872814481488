import {useMutation, useQueryClient} from 'react-query';
import {updateProduct} from '../Calls/Admin';

export const useUpdateProduct = () => {
  const qc = useQueryClient();

  return useMutation(updateProduct, {
    onSuccess: () => {
      // We COULD use the result to update the one product we edited. But we invalidate the query anyway because this is a good time to
      // make sure another admin isn't doing something (or the user doesn't accidentally have multiple tabs open).
      qc.invalidateQueries(['ADMIN', 'PRODUCTS']).catch(() => {});
    },

    onError: (err: Error) => {
      console.log('Error updating product', err.message);
    },
  });
};
