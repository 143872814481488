import React, {FC} from 'react';
import {SimpleSelect} from './TableLayout';

interface UserStatusCellProps {
  value: any;
  row: any;
  onChange: (value: string, row: any) => void;
  disabled?: boolean;
}

export const UserStatusCell: FC<UserStatusCellProps> = ({value: initialValue, row, onChange, disabled}) => {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (e: any) => {
    onChange(e.target.value, row);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (disabled) return <>{value}</>;

  return (
    <SimpleSelect value={value} onChange={handleChange} disabled={disabled}>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
    </SimpleSelect>
  );
};
