import {useMutation, useQueryClient} from 'react-query';
import {createOrganization} from '../Calls/Admin';

export const useCreateOrganization = () => {
  const qc = useQueryClient();

  return useMutation(createOrganization, {
    onSuccess: (params) => {
      console.log('Creating organization', params);
      qc.invalidateQueries(['ADMIN', 'ORGANIZATIONS']).catch(() => {});
    },

    onError: (err: Error) => {
      console.log('Error creating organization', err.message);
    },
  });
};
