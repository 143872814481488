import {formatPercent, getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';

const getValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  const subsets = [...(config?.subsets ?? [])];
  if (subsets.length < 1) {
    subsets.push('water', 'electric', 'gas');
  }

  // TODO: Track down raw subsets like "above N%" sets and bring down that data
  const water = subsets.includes('water') ? +row.waterBurden : 0;
  const electric = subsets.includes('electric') ? +row.electricBurden : 0;
  const gas = subsets.includes('gas') ? +row.gasBurden : 0;

  return water + electric + gas;
};

export const EnergyBurden: IDatasetOptions<number> = {
  id: 'energyBurden',
  title: 'Utility Burden: Neighborhood Average',
  downloadTitle: 'Energy Burden',
  combinable: true,
  cutoffDots: [6, 10],
  subsetType: 'checkbox',
  subsets: [
    {
      id: 'electric',
      title: 'Electricity',
      description: 'Electricity Burden shows the percent of median yearly income that households pay for electricity bills.',
      type: 'Both',
    },
    {
      id: 'gas',
      title: 'Gas',
      description: 'Gas Burden shows the percent of median yearly income that households pay for natural gas bills.',
      type: 'Both',
    },
    {
      id: 'water',
      title: 'Water',
      description: 'Water Burden shows the percent of median yearly income that households pay for water bills.',
      type: 'Both',
    },
  ],
  defaultSubsets: ['electric', 'gas', 'water'],
  description:
    'Utility Burden: Neighborhood Average shows the percent of median yearly income that households pay for utility bills (electricity, gas, and/or water).',

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => {
    return formatPercent(value);
  },

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) =>
    getMinMax(getAllValues(config, rows, getValue, EnergyBurden.downloadTitle)),

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const values = getAllValues(config, rows, getValue, EnergyBurden.downloadTitle);
    const [min, max] = getMinMax(values);

    const map: ITractColorMap = {fills: [], opacities: []};

    Object.entries(values).forEach(([tractId, value]) => {
      map.fills.push(String(tractId));
      map.fills.push(fillBuckets[getValueBucket(value, config.cutoff1 || min, max, opacityBuckets.length)]);

      map.opacities.push(String(tractId));
      map.opacities.push(1);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    // 10/12/2023 need to find out why this was done:
    // const values = getAllHouseCountsAsPercentages(config, rows, getValue, EnergyBurden.downloadTitle);
    const values = getAllValues(config, rows, getValue, EnergyBurden.downloadTitle);
    const [min, max] = getMinMax(values);
    const floor = config.cutoff1 || min;
    const lCutoff = floor + (max - min) * 0.2;
    const hCutoff = floor + (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (value <= lCutoff) {
        map.values[tractId] = 'L';
      } else if (value >= hCutoff) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
