import {ExclamationIcon} from '@heroicons/react/outline';
import {FC, useState} from 'react';
import styled from 'styled-components';
import {Modal, StandardButton} from '../../Components';
import {SUBSCRIPTION_INTERVAL} from '../../constants/constants';

const UpdateSubscriptionButtonComponent = styled(StandardButton)`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const ModalText = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`;

const ModalSubHead = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ModalPriceContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const ModalPrice = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

const ModalPerText = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 3px;
`;

const ModalDisclaimerText = styled.span`
  font-size: 14px;
  font-weight: 400;
  margin: 18px 0px;
`;

interface IUpdateSubscriptionButtonProps {
  onSubmit: () => void;
  newPrice: number;
  amountDue?: number;
  disabled?: boolean;
}

export const UpdateSubscriptionButton: FC<IUpdateSubscriptionButtonProps> = ({onSubmit, newPrice, amountDue = 0, disabled}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
    setShowModal(false);
  };

  return (
    <>
      <UpdateSubscriptionButtonComponent onClick={() => setShowModal(true)} disabled={disabled}>
        <ExclamationIcon style={{width: '24px'}} />
        Update Subscription
      </UpdateSubscriptionButtonComponent>
      <Modal
        title="Update Subscription"
        isOpen={showModal}
        button1Outline
        button1Text="Wait, I want to verify"
        button2Outline={false}
        button2Text="Update Subscription"
        button1Action={() => setShowModal(false)}
        button2Action={handleSubmit}
        button1Disabled={isSubmitting}
        button2Disabled={isSubmitting}>
        <ModalContainer style={{gap: 16}}>
          {/* {isSubmitting && <FullPageLoader />} */}
          <ModalText>You are about to update your total subscription price.</ModalText>

          <ModalContainer>
            <ModalSubHead>Subscription Price After Update</ModalSubHead>
            <ModalPriceContainer>
              <ModalPrice>{newPrice.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</ModalPrice>
              <ModalPerText>/{SUBSCRIPTION_INTERVAL}</ModalPerText>
            </ModalPriceContainer>
          </ModalContainer>
          <ModalContainer>
            <ModalSubHead>Amount Due</ModalSubHead>
            <ModalPriceContainer>
              <ModalPrice>{amountDue.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</ModalPrice>
            </ModalPriceContainer>
          </ModalContainer>
          <ModalDisclaimerText>Note: Invoice will be issued once subscription is updated.</ModalDisclaimerText>
        </ModalContainer>
      </Modal>
    </>
  );
};
