import {FC} from 'react';
import {Form, Formik} from 'formik';
import styled from 'styled-components/macro';
import {useNavigate} from 'react-router-dom';
import {FlexFill, FlexRow, FormField, FullPageLoader, H4, StandardButton, TextInput} from '../../Components';
import {AdminPage, AdminPageWrapper, AdminPanel} from './Layout';
import {IProduct, useAdminProducts} from '../../API';
import useAuthorizations from './useAuthorizations';
import {Authorizations} from './Authorizations';
import {productSchema} from './productSchema';
import {AdminMenu} from './AdminMenu';
import {Header} from '../Map/Header';
import {routePaths} from '../../constants/routePaths';

export const FormContainer = styled.div`
  flex: 1;
  align-self: center;
  padding: 20px;
  border-radius: 16px;
  background: white;
`;

export interface IProductForm {
  onSubmit: (values: {}) => Promise<void>;
  isLoading?: boolean;
  product?: IProduct;
  title: string;
}

export const ProductForm: FC<IProductForm> = ({onSubmit, isLoading = false, product, title}) => {
  const navigate = useNavigate();
  const products = useAdminProducts();
  const {addAuths, handleAddAuth, handleRemoveAuth, removeAuths, resetAuths, saveable} = useAuthorizations(product?.authorizations);

  const handleSubmit = async (values: IProduct, actions: any) => {
    try {
      await onSubmit({...values, addAuths, removeAuths});
      resetAuths();
    } catch (error) {
      console.log('Error saving product', error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const formSchema = productSchema({products: products?.data, product});

  return (
    <AdminPageWrapper>
      <AdminMenu />
      <Header />

      <AdminPage>
        <H4>{title}</H4>
        <AdminPanel>
          <FormContainer>
            <Formik
              initialValues={product || ({} as IProduct)}
              onSubmit={(values, actions) => handleSubmit(formSchema.cast(values) as IProduct, actions)}
              validationSchema={formSchema}
              enableReinitialize>
              {({isSubmitting, isValid, validateForm, dirty, values}) => (
                <Form>
                  {(isLoading || isSubmitting) && <FullPageLoader />}
                  <FlexRow style={{marginBottom: 18}}>
                    <FlexFill style={{flex: 3}} />
                    <StandardButton
                      type="button"
                      style={{flex: 1, marginRight: 30}}
                      outline
                      onClick={() => navigate(routePaths.adminProducts)}>
                      Cancel
                    </StandardButton>
                    <StandardButton type="submit" style={{flex: 1}} disabled={isSubmitting || !isValid || (!dirty && !saveable)}>
                      Save
                    </StandardButton>
                  </FlexRow>
                  <FormField>
                    <TextInput required type="text" name="name" placeholder="Product Name..." label="Name" />
                  </FormField>
                  <FlexRow>
                    <FormField style={{flex: 1, marginRight: 30}}>
                      <TextInput required type="text" name="initialLat" placeholder="Latitude..." label="Center Latitude" />
                    </FormField>
                    <FormField style={{flex: 1, marginRight: 30}}>
                      <TextInput required type="text" name="initialLon" placeholder="Longitude..." label="Center Longitude" />
                    </FormField>
                    <FormField style={{flex: 1}}>
                      <TextInput required type="text" name="initialZoom" placeholder="Initial Zoom..." label="Initial Zoom" />
                    </FormField>
                  </FlexRow>
                  <div style={{marginTop: 30}}>
                    <Authorizations
                      product={product}
                      addAuths={addAuths}
                      removeAuths={removeAuths}
                      handleAddAuth={(value) => validateForm().then(() => handleAddAuth(value))}
                      handleRemoveAuth={handleRemoveAuth}
                      includeTracts
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </FormContainer>
        </AdminPanel>
      </AdminPage>
    </AdminPageWrapper>
  );
};
