import {useAuth0} from '@auth0/auth0-react';
import {FC} from 'react';
import {useIsFetching, useIsMutating} from 'react-query';
import styled from 'styled-components/macro';
import {Body1, FullPageLoader, H4, H6, Theme} from '../../Components';
import {OnboardingHeader} from './OnboardingHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  justify-content: flex-start;
  overflow: visible;
  background: ${(props) => props.theme.Colors.Neutrals._500};
`;

export const OnboardingPage = styled.div`
  margin-bottom: 30px;
  max-width: 800px;
  min-height: fit-content;
  flex-grow: 0;
  flex-direction: row;
  gap: 24px;
  overflow: visible;
`;

export const OnboardingPanel = styled.div`
  padding: 28px;
  background: white;
  border-radius: 16px;
  overflow-y: auto;
  min-width: 296px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

export const OnboardingSidePanel = styled.div`
  padding: 0px !important;
  background: white;
  border-radius: 8px;
  min-width: 336px;
  max-width: 336px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

export const Divider = styled.hr`
  flex-shrink: 0;
  height: 2px;
  width: 80%;
  border: none;
  margin-top: 0px;
  margin-bottom: 0px;
  background: #f3f3f4;
  border-radius: 9999px;
`;

export const CardContainer = styled.div`
  flex: 1;
  max-width: 80%;
  height: 80%;
  align-self: center;
  padding: 40px;
  gap: 28px;
  border-radius: 16px;
  align-items: center;
  background: white;
  box-shadow: 0px 20px 25px -5px rgba(106, 111, 123, 0.1), 0px 10px 10px -5px rgba(106, 111, 123, 0.04);
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 400px;
`;

export const ErrorTitle = styled(H6)`
  color: ${Theme.Colors.Oranges._000};
  text-align: center;
  margin: 0;
`;

export const SuccessTitle = styled(H4)`
  color: ${Theme.Colors.Greens._000};
  text-align: center;
  margin: 0;
  line-height: 40px;
`;

export const Text = styled(Body1)`
  margin: 0;
  text-align: center;
`;

export const OnboardingPageWrapper: FC<{hideLoader?: boolean}> = ({children, hideLoader}) => {
  const {isLoading} = useAuth0();
  const fetchCount = useIsFetching();

  const isFetchingPropublica = useIsFetching({queryKey: ['ONBOARDING', 'NONPROFITS']});

  const isFetchingEmailChecks = useIsFetching({queryKey: ['DUPEEMAIL']});

  const isFetchingDomainChecks = useIsFetching({queryKey: ['DUPEEMAIL']});

  const isMutating = useIsMutating();

  return (
    <Container style={{padding: 16, gap: 16, flex: 1, justifyContent: 'space-between'}}>
      {(!!fetchCount || isLoading || !!isMutating) &&
        !isFetchingPropublica &&
        !isFetchingEmailChecks &&
        !isFetchingDomainChecks &&
        !hideLoader && <FullPageLoader />}

      <OnboardingHeader />

      <div
        style={{margin: 0, padding: 0, flexDirection: 'column', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        {children}
      </div>

      <div style={{flex: 0}} />
    </Container>
  );
};
