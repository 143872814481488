import {IProduct} from '../../API';
import {CENTER_POSITION} from '../../constants/constants';
import counties from './counties';
import states from './states';

// Tried a partial refactor here but kind of boxed in by Redux.
export const formatAuthorizationTitle = (authorization: any, products: any[]) => {
  // NOTE: We do this type:id complication because Autocomplete needs a simple id:name pair for each entry.
  switch (authorization.targetType) {
    case 'nationwide':
      return 'Nationwide';

    case 'product':
      const product = products.find((product: any) => product.id === `product:${authorization.targetId}`);
      return product?.name || 'Product: UNKNOWN';

    case 'state':
      // {stateFips: '01', code: '', name: 'Alabama'},
      const state = states.find((state) => state.stateFips === authorization.targetId);
      return state ? `State: ${state.name}` : 'State: UNKNOWN';

    case 'county':
      // ['Alabama', 'AL', '01', '001', 'Autauga', 32.536382, -86.64449],
      const county = counties.find((county) => (((county[2] as string) + county[3]) as string) === authorization.targetId);
      return county ? `County: ${county[4]}, ${county[1]}` : 'County: UNKNOWN';

    case 'tract':
      return `Tract: ${authorization.targetId}`;
  }

  return 'Unknown';
};

export const authorizationColors = {
  nationwide: 'Oranges',
  product: 'Pinks',
  state: 'Yellows',
  county: 'Purples',
  tract: 'Cyans',
  Unknown: 'Neutral',
};

export const sortedProducts = (products: IProduct[]) =>
  (products || [])
    .map((p: any) => ({...p, id: `product:${p.id}`, name: `Product: ${p.name}`}))
    .sort((a: any, b: any) => (a?.name || '').toLowerCase().localeCompare((b?.name || '').toLowerCase()));

export const truncateString = (str: string, num: number = 20) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

const nationwideProduct = {
  id: 'nationwide:N01',
  name: 'Nationwide',
  initialLon: +CENTER_POSITION.initialLon,
  initialLat: +CENTER_POSITION.initialLat,
  initialZoom: +CENTER_POSITION.initialZoom,
};

const stateProducts = states.map((state) => ({
  id: `state:${state.stateFips}`,
  name: `${state.name} State`,
  initialLon: state.longitude,
  initialLat: state.latitude,
  initialZoom: 7,
}));

const countiesProducts = counties.map((county) => ({
  id: `county:${county[2]}${county[3]}`,
  name: `${county[4]} County, ${county[1]}`,
  initialLat: `${county[5]}`,
  initialLon: `${county[6]}`,
  initialZoom: 9,
}));

export const completeProductList = ({products}: {products: IProduct[]}) => {
  return [nationwideProduct, ...sortedProducts(products), ...stateProducts, ...countiesProducts];
};

export const productLessProductList = [nationwideProduct, ...stateProducts, ...countiesProducts];
