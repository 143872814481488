import {FC} from 'react';
import {ISubscriptionTierDetails, IUser} from '../../API';
import {BoldSubhead1} from '..';
import {SubscriptionDetailRow} from './SubscriptionDetail';
import {calculateUserCost} from '../../Managers/MathUtils';

export const userOrUsers = (numberOfUsers: number) => {
  return numberOfUsers === 1 ? 'user' : 'users';
};

export const MemberDetailsSection: FC<{users: IUser[]; subscriptionTierPricing: ISubscriptionTierDetails | undefined}> = ({
  users,
  subscriptionTierPricing,
}) => {
  const numberOfAdmins = users?.filter((user) => user.user_type === 'org-admin').length || 0;

  const numberOfStaff = users?.filter((user) => user.user_type === 'admin').length || 0;

  const numberOfDefaultUsers = users?.length - numberOfAdmins - numberOfStaff || 0;

  return (
    <div style={{gap: 8, flex: 0}}>
      <BoldSubhead1>Members</BoldSubhead1>
      <SubscriptionDetailRow
        label={numberOfAdmins + ' Admin ' + userOrUsers(numberOfAdmins)}
        cost={calculateUserCost(numberOfAdmins, subscriptionTierPricing)}
      />
      {!!numberOfDefaultUsers && (
        <SubscriptionDetailRow
          label={numberOfDefaultUsers + ' Default ' + userOrUsers(numberOfDefaultUsers)}
          cost={calculateUserCost(users?.length - numberOfAdmins, subscriptionTierPricing)}
        />
      )}
      {!!numberOfStaff && <SubscriptionDetailRow label={numberOfStaff + ' Staff ' + userOrUsers(numberOfStaff)} cost={0} />}
    </div>
  );
};
