import {useAuth0} from '@auth0/auth0-react';
import {FC, useCallback, useEffect} from 'react';
import toast from 'react-hot-toast';
import {useNavigate} from 'react-router-dom';
import {IOrganization} from '../../API';
import {useCartOrganization} from '../../Hooks/useCartOrganization';
import {H4} from '../../Components';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper, OnboardingPanel} from './Layout';
import {OnboardingSteps} from './OnboardingSteps';
import {OrganizationForm} from './OrganizationForm';
import {useMixpanel} from '../../Managers/Mixpanel/useMixpanel';

export const SetupOrganization: FC = () => {
  const navigate = useNavigate();
  const {organization: cartOrganization = {} as IOrganization, updateCartOrganization} = useCartOrganization();
  const {user} = useAuth0();
  const {mixpanel} = useMixpanel();

  const handleUpdateOrg = useCallback(
    async (values: any) => {
      if (values?.subscription_type === 'local') {
        updateCartOrganization(values as IOrganization);
        navigate(routePaths.onboardingLocal);
        return;
      }

      const usersExcludingCurrentUser = cartOrganization?.users?.filter((u) => u.email !== user?.email);

      const currentUsers = [...(usersExcludingCurrentUser || []), {email: user?.email, user_type: 'org-admin'}];

      // only use unique emails
      const users = currentUsers.filter((user, index, self) => self.findIndex((u) => u.email === user.email) === index);

      const organizationForUpdate = {
        ...values,
        users,
        primary_email: user?.email,
      };

      try {
        updateCartOrganization(organizationForUpdate as IOrganization);
        toast.success('Organization has been successfully updated!');
        mixpanel.track('Onboarding Organization Added');
        navigate(routePaths.onboardingMembers);
      } catch (error: any) {
        console.log('error saving organization', error);
      }
    },
    [navigate, cartOrganization],
  );

  const resetSubscriptionType = () => {
    updateCartOrganization({...cartOrganization, subscription_type: 'standard'});
  };

  useEffect(() => {
    if (!cartOrganization?.subscription_type || cartOrganization?.subscription_type === 'local') {
      resetSubscriptionType();
    }
  }, [cartOrganization?.subscription_type]);

  return (
    <OnboardingPageWrapper>
      <OnboardingPage>
        <OnboardingPanel>
          <H4 style={{textAlign: 'center'}}>Setup Your Organization</H4>
          <OrganizationForm
            organization={{...cartOrganization, primary_email: user?.email}}
            handleSaveOrg={handleUpdateOrg}
            submitType="next"
            enableTypeSelection
          />
        </OnboardingPanel>
      </OnboardingPage>
      <OnboardingSteps />
    </OnboardingPageWrapper>
  );
};
