import {CurrencyDollarIcon, GlobeIcon, OfficeBuildingIcon, UserGroupIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components/macro';
import {useGetMyOrganization} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {H6, Modal, Sheet} from '../../Components';
import {STRIPE_STATUS_MESSAGES} from '../../constants/constants';
import {routePaths} from '../../constants/routePaths';
import {OrganizationSummary} from './OrganizationSummary';

const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 120px;
  left: 20px;
  width: 356px;
  gap: 28px;
`;

export const AdminMenu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {data: myProfile} = useMyProfile();
  const {data: organization, isLoading} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  const handleSelectAction = (id: string) => {
    navigate(`/admin/${id}`);
  };

  let activeItem = 'my-organization';
  if (location.pathname.includes('my-members')) {
    activeItem = 'my-members';
  } else if (location.pathname.includes('my-locations')) {
    activeItem = 'my-locations';
  } else if (location.pathname.includes('my-subscription')) {
    activeItem = 'my-subscription';
  }

  const onSubscriptionPage = activeItem === 'my-subscription';

  const sub = organization?.subscription;
  // find an active subscription
  const orgHasSubscriptions = (sub?.status === 'active' || sub?.status === 'trialing') && !isLoading;

  return (
    <Wrapper>
      <Sheet
        items={[
          {id: 'my-organization', label: 'My Organization', icon: <OfficeBuildingIcon />, active: activeItem === 'my-organization'},
          {id: 'my-members', label: 'My Members', icon: <UserGroupIcon />, active: activeItem === 'my-members'},
          {id: 'my-locations', label: 'My Locations', icon: <GlobeIcon />, active: activeItem === 'my-locations'},
          {id: 'my-subscription', label: 'My Subscription', icon: <CurrencyDollarIcon />, active: activeItem === 'my-subscription'},
        ]}
        onClick={(item) => handleSelectAction(item.id)}
        itemStyle={{marginBottom: 32}}
      />
      <OrganizationSummary />
      <Modal
        title={STRIPE_STATUS_MESSAGES[sub?.status || 'not_found'].message}
        isOpen={!orgHasSubscriptions && !onSubscriptionPage && !isLoading}
        button1Outline={true}
        button1Text="OK"
        button1Action={() => {
          navigate(routePaths.orgAdminSubscription);
        }}
        button2Outline={false}
        // button2Text="Update Subscription"
        // button2Action={() => navigate(routePaths.onboardingOrganization)}
      >
        <div style={{flexDirection: 'column', alignItems: 'center'}}>
          <img alt="Person shopping" width="250" height="250" src="/person-shopping.png"></img>
          <H6 style={{marginTop: '20px'}}>Update Your Subscription</H6>
          <p style={{marginTop: 0, marginBottom: '20px', maxWidth: '400px', textAlign: 'center'}}>
            We couldn't find an active subscription for your organization. Please update your payment method to continue using the Greenlink
            Equity Map.
          </p>
        </div>
      </Modal>
    </Wrapper>
  );
};
