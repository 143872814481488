import {LibraryIcon, LocationMarkerIcon, MapIcon, MenuAlt2Icon, TruckIcon} from '@heroicons/react/outline';
import {FC, useEffect, useState} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';
import {useMyProfile} from '../../../API/Queries/useMyProfile';
import {FlexFill, ToggleButtons} from '../../../Components';
import {useMixpanel} from '../../../Managers/Mixpanel/useMixpanel';
import {setShowBuildingPins, setShowCities, setShowPOI, setShowRoads} from '../../../Redux/Slices/Map';
import {useAppDispatch, useAppSelector} from '../../../store';
import {FilterDownload} from './FilterDownload';
import {FilterIndicators} from './FilterIndicators';

const FiltersWrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 120px;
  left: 20px;
  width: 356px;
  bottom: 30px;
`;

const FiltersButton = styled.button`
  width: 76px;
  height: 76px;
  padding: 16px;
  display: flex;
  border: none;
  z-index: 10;
  background: #ffffff;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
  border-radius: 16px;

  svg {
    stroke: black;
    width: 24px;
    height: 24px;
  }

  &:hover,
  &.active {
    cursor: pointer;

    svg {
      stroke: ${(props) => props.theme.Colors.Oranges._000};
    }
  }
`;

const Panel = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  flex: 0;
  width: 356px;
  overflow-y: scroll;
  z-index: 20;
  padding: 28px;
  background: #fff;
  position: absolute;
  border-radius: 16px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

const PanelHeader = styled.div`
  flex-direction: row;
  margin-bottom: 30px;
  flex: 0;
  gap: 15px;
  justify-content: space-between;
`;

const PanelTopButton = styled.button`
  width: 36px;
  height: 36px;
  // display: flex;
  border: none;
  z-index: 10;
  margin: 0;
  background: #ffffff;
  // align-items: center;
  flex-direction: row;
  // justify-content: center;
  cursor: pointer;

  svg {
    stroke: grey;
  }

  // This looked weird. You couldn't tell you had turned the button "on" until you moused out.
  // &:hover,
  &.active {
    svg {
      stroke: ${(props) => props.theme.Colors.Oranges._000};
    }
  }
`;

const BottomButtons = styled.div`
  padding: 28px 0 0 0;
  flex: 0;
`;

/**
 * Filters "drives" the value/onChange cycles so we can use common controls between the filters and apply them to the 1/2 datasets.
 */
export const Filters: FC = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [showFilters, setShowFilters] = useState(true);
  const dispatch = useAppDispatch();
  const showBuildingPins = useAppSelector((state) => state.map.showBuildingPins);
  const showCities = useAppSelector((state) => state.map.showCities);
  const {data: myProfile} = useMyProfile();
  const showRoads = useAppSelector((state) => state.map.showRoads);
  const showPOI = useAppSelector((state) => state.map.showPOI);

  const {mixpanel} = useMixpanel();

  const handlePOIClick = () => {
    dispatch(setShowPOI(!showPOI));
    mixpanel.track('Show Points of Interest', {state: !showPOI ? 'on' : 'off'});
  };

  const handleRoadsClick = () => {
    dispatch(setShowRoads(!showRoads));
    mixpanel.track('Show Roads', {state: !showRoads ? 'on' : 'off'});
  };

  const handleBuildPinsClick = () => {
    dispatch(setShowBuildingPins(!showBuildingPins));
    mixpanel.track('Show Building Pins', {state: !showBuildingPins ? 'on' : 'off'});
  };

  const handleCityBoundsClick = () => {
    dispatch(setShowCities(!showCities));
    mixpanel.track('Enable City Boundaries', {state: !showCities ? 'on' : 'off'});
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [myProfile?.user?.organization?.showBuildingPins, myProfile?.user?.organization?.showCityBoundaries]);

  return (
    <FiltersWrapper>
      <FiltersButton onClick={() => setShowFilters(!showFilters)}>
        <MenuAlt2Icon />
      </FiltersButton>
      {showFilters ? (
        <Panel>
          <PanelHeader>
            <PanelTopButton onClick={() => setShowFilters(!showFilters)}>
              <MenuAlt2Icon />
            </PanelTopButton>

            <FlexFill />

            <PanelTopButton data-tip="" data-for="poi-tooltip" onClick={handlePOIClick} className={showPOI ? 'active' : ''}>
              <LibraryIcon />
            </PanelTopButton>

            <PanelTopButton data-tip="" data-for="roads-tooltip" onClick={handleRoadsClick} className={showRoads ? 'active' : ''}>
              <TruckIcon />
            </PanelTopButton>

            {myProfile?.user?.organization?.showBuildingPins ? (
              <PanelTopButton
                data-tip=""
                data-for="building-pins-tooltip"
                onClick={handleBuildPinsClick}
                className={showBuildingPins ? 'active' : ''}>
                <LocationMarkerIcon />
              </PanelTopButton>
            ) : (
              <></>
            )}

            {myProfile?.user?.organization?.showCityBoundaries ? (
              <PanelTopButton
                data-tip=""
                data-for="city-boundaries-tooltip"
                onClick={handleCityBoundsClick}
                className={showCities ? 'active' : ''}>
                <MapIcon />
              </PanelTopButton>
            ) : (
              <></>
            )}
          </PanelHeader>

          {currentTab === 0 ? <FilterIndicators /> : <FilterDownload />}

          <FlexFill />

          <BottomButtons>
            <ToggleButtons buttons={['Indicators', 'Download']} selected={currentTab} onClick={setCurrentTab} showBorder />
          </BottomButtons>
        </Panel>
      ) : (
        <></>
      )}
      <ReactTooltip
        getContent={() => {
          return showPOI ? 'Hide points of interest.' : 'Show points of interest.';
        }}
        id="poi-tooltip"
        place="top"
        type="dark"
        effect="solid"
        wrapper="span"
        className="info-tooltip"
      />

      <ReactTooltip
        getContent={() => {
          return showRoads ? 'Hide roads.' : 'Show roads.';
        }}
        id="roads-tooltip"
        place="top"
        type="dark"
        effect="solid"
        wrapper="span"
        className="info-tooltip"
      />

      <ReactTooltip
        getContent={() => {
          return showBuildingPins ? 'Hide building pins.' : 'Show building pins.';
        }}
        id="building-pins-tooltip"
        place="top"
        type="dark"
        effect="solid"
        wrapper="span"
        className="info-tooltip"
      />

      <ReactTooltip
        getContent={() => {
          return showCities ? 'Disable city boundaries.' : 'Enable city boundaries.';
        }}
        id="city-boundaries-tooltip"
        place="top"
        type="dark"
        effect="solid"
        wrapper="span"
        className="info-tooltip"
      />

      <ReactTooltip
        id={'indicator-search'}
        place="right"
        type="dark"
        effect="solid"
        offset={{top: 0, left: 0}}
        wrapper="div"
        className="info-tooltip"
      />
    </FiltersWrapper>
  );
};
