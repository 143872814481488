import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Form, Formik} from 'formik';
import {FC, useState} from 'react';
import toast from 'react-hot-toast';
import {ISubscriptionPricing, ISubscriptionTierDetails, useSubscriptionPricing} from '../../API';
import {useUpdatePrices} from '../../API/Mutations/useUpdatePrices';
import {FlexRow, FormField, H4, H5, StandardButton, TextInput} from '../../Components';
import {requireAdmin} from '../../Managers/AuthUtils';
import {Header} from '../Map/Header';
import {AdminMenu} from './AdminMenu';
import {AdminPage, AdminPageWrapper, AdminPanel} from './Layout';

const SettingsComponent: FC = () => {
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;
  const [loading, setLoading] = useState(false);
  const updatePrices = useUpdatePrices();

  const handleSubmit = async (values: ISubscriptionPricing['subscriptionTypes'], actions: any) => {
    setLoading(true);

    try {
      const result = await updatePrices.mutateAsync(values);
      if (!result.error) {
        toast.success('Pricing has been successfully updated!');
      }
    } catch (error) {
      console.log('save error', error);
    } finally {
      actions.setSubmitting(false);
      setLoading(false);
    }
  };

  // assign the default value of 'local' to subscriptionTypes.local.value
  // if it is not already set
  if (subscriptionTypes && !subscriptionTypes.local.value) {
    subscriptionTypes.local.value = 'local';
  }

  const initialValues = {
    standard: subscriptionTypes?.standard,
    discounted: subscriptionTypes?.discounted,
    local: subscriptionTypes?.local,
  } as ISubscriptionPricing['subscriptionTypes'];

  return (
    <AdminPageWrapper style={{maxWidth: '100%'}}>
      <AdminMenu />

      <Header />

      <AdminPage style={{maxWidth: '100%'}}>
        <H4>Subscription Pricing</H4>

        <AdminPanel>
          <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
            {({isSubmitting, dirty, values}) => (
              <Form>
                <div style={{flexDirection: 'row', display: 'flex', gap: 32, flexWrap: 'wrap', justifyContent: 'space-around'}}>
                  {!!subscriptionTypes &&
                    Object.values(values).map((type = {} as ISubscriptionTierDetails, i) => {
                      const isLocal = type.value === subscriptionTypes?.local?.value;

                      return (
                        <div style={{width: 520, flex: 1}} key={i}>
                          <H5>{type.label}</H5>

                          <FormField>
                            <TextInput
                              type="text"
                              name={`${type.value}.label`}
                              placeholder="Enter name for type"
                              label="Organization Type Name"
                            />
                          </FormField>

                          <FormField>
                            <TextInput
                              type="text"
                              as="textarea"
                              name={`${type.value}.subtitle`}
                              placeholder="Enter description for type"
                              label="Description"
                              style={{height: 100, width: 'auto'}}
                            />
                          </FormField>

                          <FormField>
                            <TextInput
                              type="text"
                              name={`${type.value}.perUserPricing`}
                              placeholder="Enter price"
                              label="Price per user (dollars)"
                            />
                          </FormField>

                          {!isLocal && (
                            <FormField>
                              <TextInput
                                type="text"
                                name={`${type.value}.perLocationPricing.stateTract`}
                                placeholder="Enter price"
                                label="Price per state tract (dollars)"
                              />
                            </FormField>
                          )}

                          <FormField>
                            <TextInput
                              type="text"
                              name={`${type.value}.perLocationPricing.countyTract`}
                              placeholder="Enter price"
                              label="Price per county tract (dollars)"
                            />
                          </FormField>

                          <FormField>
                            <TextInput
                              type="text"
                              name={`${type.value}.perLocationPricing.nationwide`}
                              placeholder="Enter price"
                              label="Nationwide Price (dollars)"
                            />
                          </FormField>

                          <FormField>
                            <TextInput
                              type="text"
                              name={`${type.value}.discount`}
                              placeholder="Enter percent, e.g. 10"
                              label="Discount Percentage"
                              disabled={isLocal}
                            />
                          </FormField>
                        </div>
                      );
                    })}
                </div>

                <FlexRow>
                  <StandardButton type="submit" style={{flex: 1, marginRight: 30}} disabled={loading || !dirty || isSubmitting}>
                    Save
                  </StandardButton>
                </FlexRow>
              </Form>
            )}
          </Formik>
        </AdminPanel>
      </AdminPage>
    </AdminPageWrapper>
  );
};

export const PricingSettings: FC = withAuthenticationRequired(SettingsComponent, {
  claimCheck: requireAdmin,
});
