import {TrashIcon} from '@heroicons/react/outline';
import styled from 'styled-components';
import {Body2, Body3} from '../Atoms';
import {FlexRow} from '../Layout';
import {StandardButton} from './Buttons';
import {Dropdown, DropdownOption} from './Dropdown';

const FieldHeader = styled(Body2)`
  margin-bottom: 0.5rem;
`;

const FieldRow = styled(FlexRow)`
  margin-bottom: 1rem;
  gap: 1rem;
  padding: 0 0 0 0.5rem;
  width: 100%;
`;

const Button = styled(StandardButton)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
`;

const ErrorBody = styled(Body3)`
  margin: 0 0 8px;
  color: ${(props) => props.theme.Colors.Reds._000};
`;

export const ArrayFieldInput = ({
  disabledDelete = false,
  error,
  index,
  onChange = () => null,
  onDelete = () => null,
  options = [],
  value,
}: {
  disabledDelete?: boolean;
  error?: string;
  index: number;
  value: DropdownOption;
  onChange: (value: DropdownOption['value']) => void;
  onDelete: (value: number) => void;
  options: DropdownOption[];
}) => {
  const handleChange = (value: DropdownOption) => {
    onChange(value.value);
  };

  return (
    <>
      <FieldHeader>Indicator {index + 1}</FieldHeader>
      <FieldRow>
        <div style={{flex: 1}}>
          <Dropdown options={options} onChange={handleChange} value={value} />
        </div>
        <Button type="button" onClick={() => onDelete(index)} disabled={disabledDelete}>
          <TrashIcon style={{width: 24}} />
        </Button>
      </FieldRow>
      {error && <ErrorBody>{error}</ErrorBody>}
    </>
  );
};
