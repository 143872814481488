import {useAuth0} from '@auth0/auth0-react';
import {FC, useEffect} from 'react';
import {FullPageLoader} from '../Components';

export const LoginRedirect: FC = () => {
  const {loginWithRedirect} = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return <FullPageLoader />;
};
