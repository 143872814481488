export const Theme = {
  Colors: {
    Neutrals: {
      _000: '#0f172a',
      _100: '#354353',
      _200: '#6a6f7b',
      _300: '#989ba3',
      _400: '#c5c7cc',
      _500: '#F3F3F4',
    },

    Reds: {
      _000: '#EF4444',
      _100: '#F26868',
      _200: '#F58B8B',
      _300: '#F8AFAF',
      _400: '#FBD2D2',
      _500: '#FEF6F6',
    },

    Oranges: {
      _000: '#FF9126',
      _100: '#FFA64F',
      _200: '#FFBB79',
      _300: '#FFD0A2',
      _400: '#FFE5CB',
      _500: '#FFFAF4',
    },

    Yellows: {
      _000: '#FACC15',
      _100: '#FBD642',
      _200: '#FCDF6E',
      _300: '#FDE99A',
      _400: '#FEF3C7',
      _500: '#FFFDF3',
    },

    Greens: {
      _000: '#84CC16',
      _100: '#9BD642',
      _200: '#B3DF6F',
      _300: '#CAE99B',
      _400: '#E2F3C7',
      _500: '#F9FDF3',
    },

    Teals: {
      _000: '#34D399',
      _100: '#5BDBAC',
      _200: '#81E4C0',
      _300: '#A8ECD3',
      _400: '#CEF4E7',
      _500: '#F5FDFA',
    },

    Cyans: {
      _000: '#22D3EE',
      _100: '#4CDBF1',
      _200: '#76E4F5',
      _300: '#A0ECF8',
      _400: '#CAF4FB',
      _500: '#F4FDFE',
    },

    Blues: {
      _000: '#4CA6FF',
      _100: '#6FB7FF',
      _200: '#91C8FF',
      _300: '#B3D9FF',
      _400: '#D4EAFF',
      _500: '#F6FBFF',
    },

    Purples: {
      _000: '#8B5CF6',
      _100: '#A17BF8',
      _200: '#B79AF9',
      _300: '#CDB9FB',
      _400: '#E3D8FD',
      _500: '#F9F7FF',
    },

    Pinks: {
      _000: '#EC4899',
      _100: '#F06BAC',
      _200: '#F38EC0',
      _300: '#F7B0D3',
      _400: '#FAD3E7',
      _500: '#FEF6FA',
    },
    Transparents: {
      _000: '#0f172a80',
      _400: '#c5c7cc80',
    }
  },
};
