import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC, useEffect} from 'react';
import toast from 'react-hot-toast';
import {useMapData} from '../../API';
import {FullPageLoader} from '../../Components';
import {setMapData} from '../../Managers/MapManager';
import {setMapData as setMapStateData} from '../../Redux/Slices/Map';
import {useAppDispatch, useAppSelector} from '../../store';
import {mapComponent} from './MapBox';

interface OverlaysProps {
  authorizationId: number | null;
  lat: number;
  lng: number;
  year: number;
  zoom: number;
}

export const Overlays: FC<OverlaysProps> = withAuthenticationRequired(({lat, lng, zoom, year, authorizationId}) => {
  const {data, status, isLoading, error} = useMapData(authorizationId, lat, lng, year, zoom);

  const dispatch = useAppDispatch();

  const {dataset1, dataset2} = useAppSelector((state) => state.user);

  // TODO: Refine how this works. You can test this by looking for non-verified users
  const serverError = status === 'error' ? (error as any)?.response?.data || '' : '';
  if (serverError) {
    toast.error(serverError);
  }

  const ds1Settings = JSON.stringify(dataset1);
  const ds2Settings = JSON.stringify(dataset2);

  useEffect(() => {
    if (status === 'success' && mapComponent?.getSource('tracts') && data?.tractsUrl) {
      mapComponent?.getSource('tracts').setData(data?.tractsUrl);
    }
  }, [data?.tractsUrl]);

  useEffect(() => {
    if (status === 'success') {
      dispatch(setMapStateData(data));
      setMapData(mapComponent, data, dataset1, dataset2).catch((e) => console.log('Error setting map data', e));
    }
  }, [data, status, ds1Settings, ds2Settings, dataset1, dataset2]);

  return isLoading ? <FullPageLoader /> : <></>;
});
