import {useEffect, useState} from 'react';
import {IOrganization, IStripeSubscription, useSubscriptionPricing} from '../../API';
import {calcTotalCost, calculateUserCost} from '../../Managers/MathUtils';
import {DEFAULT_SUBSCRIPTION_TYPE} from '../../constants/constants';
import {getUniqueAuthorizations} from './getUniqueAuthorizations';
import {usePreviewUpcomingInvoice} from '../../API/Mutations/usePreviewUpcomingInvoice';

export const useSubscriptionTotals = ({
  organization,
  updatedOrganization,
  newCost,
}: {
  organization?: IOrganization;
  updatedOrganization?: IOrganization;
  newCost?: number;
}) => {
  const [upcomingInvoice, setUpcomingInvoice] = useState<any>({});
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const SUBSCRIPTION_TYPES = subscriptionPricing?.subscriptionTypes;
  const organizationAuthorizations = getUniqueAuthorizations(organization?.authorizations || []);
  const previewInvoice = usePreviewUpcomingInvoice();

  const allUsers = organization?.users || [];
  const users = allUsers?.filter((user) => user.user_type !== 'admin');

  const stripeSubscription = organization?.stripeSubscription || ({} as IStripeSubscription);

  const {
    amount_due,
    total: previewTotal,
    lines: previewLines,
    subtotal: previewSubtotal,
    ending_balance: previewEndingBalance,
  } = upcomingInvoice || {};

  const getUpcomingInvoice = async () => {
    try {
      const res = await previewInvoice.mutateAsync(updatedOrganization as IOrganization);
      setUpcomingInvoice(res?.invoice);
    } catch (error) {
      console.log('Error while getting upcoming invoice');
      console.log(error);
    }
  };

  const subscriptionTierPricing = SUBSCRIPTION_TYPES?.[organization?.subscription_type || DEFAULT_SUBSCRIPTION_TYPE];

  const subTotal = calcTotalCost(
    organizationAuthorizations,
    users?.length,
    subscriptionPricing,
    organization?.subscription_type as IOrganization['subscription_type'],
  );

  const {total_discount_amounts, discount} = stripeSubscription?.latest_invoice || {};

  const discountAmount = total_discount_amounts?.[0]?.amount || 0;

  const selectedPercentOff = subscriptionTierPricing?.discount || 0 || 0;

  const totalCost = (subTotal / 100) * (1 - discount);

  useEffect(() => {
    if (newCost !== totalCost * 100 && !!updatedOrganization?.id && !!organization?.subscription) {
      getUpcomingInvoice();
    }
  }, [newCost, totalCost]);

  const savings = totalCost - subTotal;

  const userCost = calculateUserCost(users?.length, subscriptionTierPricing);

  return {
    amount_due: amount_due || 0,
    discount,
    discountAmount,
    isLoading: previewInvoice.isLoading,
    previewEndingBalance: previewEndingBalance || 0,
    previewLines,
    previewSubtotal: previewSubtotal || 0,
    previewTotal: previewTotal || 0,
    savings,
    selectedPercentOff,
    subTotal,
    totalCost,
    userCost,
  };
};
