import {IDatasetConfig, IDatasetOptions, ITract2DBucketMap, ITractColorMap} from './Types';
import {
  cacheDownloadTractData,
  cacheDownloadValue,
  cacheSingleDownloadValue,
  cacheValueByKey,
  clearCachedValues,
  getCachedValue,
} from '../DownloadManager';
import {getAllValues, getMinMax, getValueBucket, higher, lower} from '../MathUtils';
import {HighLivingCost} from './HighLivingCost';
import {Above10EnergyBurden} from './Above10EnergyBurden';
import {Above3EnergyBurden} from './Above3EnergyBurden';
import {Above6EnergyBurden} from './Above6EnergyBurden';
import {InsuranceStress} from './InsuranceStress';
import {LackOfInternet} from './LackOfInternet';
import {fillBuckets, opacityBuckets} from '../ColorUtils';
import {getBipoc} from './RacialComposition';
import {IncomeStress} from './IncomeStress';
import {EvictionRate} from './EvictionRate';
import {ITractDataValue} from '../../API';
import {Asthma} from './Asthma';
import {EnergyBurden} from './EnergyBurden';
import {UrbanTreeCanopy} from './UrbanTreeCanopy';
import {UrbanHeatIndex} from './UrbanHeatIndex';

// type rowTypes = 'energyBurden' | 'utilityBurden' | 'above3PercentElectricBurden';
type rowTypes = keyof Omit<ITractDataValue, 'stateCountyId' | 'countyName' | 'tractName' | 'tractId' | 'stateName'>;

const generateGetValue = (key: rowTypes) => {
  return (config: IDatasetConfig, row: ITractDataValue): number => {
    return Number(row?.[key]) || 0;
  };
};

export const EquityIndex: IDatasetOptions<number> = {
  id: 'equityIndex',
  title: 'Community Equity Index',
  downloadTitle: 'Equity Index',
  legendName: 'Community Equity Index',
  description: 'The equity index displays a score (0-100) associated with community weighted indicators.',
  subsetType: 'equitySlider',
  combinable: false,
  opacityTopLabel: 'Least Equitable',
  opacityBottomLabel: 'Most Equitable',
  isInverted: true,
  defaultSubsets: ['electric', 'gas'],
  // Don't change the order of these subsets
  // unless you use an object instead of an array
  subsets: [
    {
      id: 'incomeStress',
      title: 'Income Stress',
      description: 'Income Stress',
      field: 'incomeStress',
    },
    {
      id: 'livingCost',
      title: 'Living Cost as a Percentage of Income',
      description: 'Living Cost as a Percentage of Income',
      field: 'medianLivingCostPercent',
    },
    {
      id: 'utilityBurden',
      title: 'Utility Burden',
      description: 'Utility Burden',
      field: 'utilityBurden',
    },
    {
      id: 'elecGasBurden03',
      title: 'Electric-Gas Burden Above 3%',
      description: 'Electric-Gas Burden Above 3%',
      field: 'above3PercentEnergyBurden',
    },
    {
      id: 'elecGasBurden06',
      title: 'Electric-Gas Burden Above 6%',
      description: 'Electric-Gas Burden Above 6%',
      field: 'above6PercentEnergyBurden',
    },
    {
      id: 'elecGasBurden10',
      title: 'Electric-Gas Burden Above 10%',
      description: 'Electric-Gas Burden Above 10%',
      field: 'above10PercentEnergyBurden',
    },
    {
      id: 'evictionRate',
      title: 'Eviction Rate',
      description: 'Eviction Rate',
      field: 'evictionRate',
    },
    {
      id: 'internetAccess',
      title: 'Lack of Internet Access',
      description: 'Lack of Internet Access',
      field: 'internetAccessibility',
    },
    {
      id: 'healthCare',
      title: 'Lack of Access to Health Care',
      description: 'Lack of Access to Health Care',
      field: 'healthInsuranceStress',
    },
    {
      id: 'asthmaRates',
      title: 'Asthma Rates',
      description: 'Asthma Rates',
      field: 'asthma',
    },
    {
      id: 'bipoc',
      title: 'BIPOC',
      description: 'BIPOC',
      field: 'asthma',
    },
    {
      id: 'energyBurden',
      title: 'Energy Burden',
      description: 'Energy Burden',
      field: 'energyBurden',
    },
    {
      id: 'elecWaterBurden',
      title: 'Electric Water Burden',
      description: 'Electric Water Burden',
      field: 'energyBurden',
    },
    {
      id: 'elecGasBurden',
      title: 'Elec Gas Burden',
      description: 'Electric Gas Burden',
      field: 'energyBurden',
    },
    {
      id: 'gasWaterBurden',
      title: 'Gas Water Burden',
      description: 'Gas Water Burden',
      field: 'energyBurden',
    },
    {
      id: 'electricBurden',
      title: 'Electric Burden',
      description: 'ElectricBurden',
      field: 'energyBurden',
    },
    {
      id: 'gasBurden',
      title: 'Gas Burden',
      description: 'Gas Burden',
      field: 'energyBurden',
    },
    {
      id: 'waterBurden',
      title: 'Water Burden',
      description: 'Water Burden',
      field: 'energyBurden',
    },
    {
      id: 'utilityBurden03',
      title: 'Utility Burden Above 3%',
      description: 'Utility Burden Above 3%',
      field: 'above3PercentUtilityBurden',
    },
    {
      id: 'utilityBurden06',
      title: 'Utility Burden Above 6%',
      description: 'Utility Burden Above 6%',
      field: 'above6PercentUtilityBurden',
    },
    {
      id: 'utilityBurden10',
      title: 'Utility Burden Above 10%',
      description: 'Utility Burden Above 10%',
      field: 'above10PercentUtilityBurden',
    },
    {
      id: 'gasBurden03',
      title: 'Gas Burden Above 3%',
      description: 'Gas Burden Above 3%',
      field: 'above3PercentGasBurden',
    },
    {
      id: 'gasBurden06',
      title: 'Gas Burden Above 6%',
      description: 'Gas Burden Above 6%',
      field: 'above6PercentGasBurden',
    },
    {
      id: 'gasBurden10',
      title: 'Gas Burden Above 10%',
      description: 'Gas Burden Above 10%',
      field: 'above10PercentGasBurden',
    },
    {
      id: 'waterBurden03',
      title: 'Water Burden Above 3%',
      description: 'Water Burden Above 3%',
      field: 'above3PercentWaterBurden',
    },
    {
      id: 'waterBurden06',
      title: 'Water Burden Above 6%',
      description: 'Water Burden Above 6%',
      field: 'above6PercentWaterBurden',
    },
    {
      id: 'waterBurden10',
      title: 'Water Burden Above 10%',
      description: 'Water Burden Above 10%',
      field: 'above10PercentWaterBurden',
    },
    {
      id: 'electricBurden03',
      title: 'Electric Burden Above 3%',
      description: 'Electric Burden Above 3%',
      field: 'above3PercentElectricBurden',
    },
    {
      id: 'electricBurden06',
      title: 'Electric Burden Above 6%',
      description: 'Electric Burden Above 6%',
      field: 'above6PercentElectricBurden',
    },
    {
      id: 'electricBurden10',
      title: 'Electric Burden Above 10%',
      description: 'Electric Burden Above 10%',
      field: 'above10PercentElectricBurden',
    },
    {
      id: 'elecWaterBurden03',
      title: 'Electric Water Burden Above 3%',
      description: 'Electric Water Above 3%',
      field: 'above3PercentElecWaterBurden',
    },
    {
      id: 'elecWaterBurden06',
      title: 'Electric Water Burden Above 6%',
      description: 'Electric Water Above 6%',
      field: 'above6PercentElecWaterBurden',
    },
    {
      id: 'elecWaterBurden10',
      title: 'Electric Water Burden Above 10%',
      description: 'Electric Water Above 10%',
      field: 'above3PercentElecWaterBurden',
    },
    {
      id: 'gasWaterBurden03',
      title: 'Gas Water Burden Above 3%',
      description: 'Gas Water Above 3%',
      field: 'above3PercentGasWaterBurden',
    },
    {
      id: 'gasWaterBurden06',
      title: 'Gas Water Burden Above 6%',
      description: 'Gas Water Above 6%',
      field: 'above6PercentGasWaterBurden',
    },
    {
      id: 'gasWaterBurden10',
      title: 'Gas Water Burden Above 10%',
      description: 'Gas Water Above 10%',
      field: 'above3PercentGasWaterBurden',
    },
    {
      id: 'singleFamily',
      title: 'Single-Family Units',
      description: 'Single-Family Units',
      field: 'singleFamily',
    },
    {
      id: 'multiFamily',
      title: 'Multi-Family Units',
      description: 'Multi-Family Units',
      field: 'multiFamily',
    },
    {
      id: 'renterOccupied',
      title: 'Renter-Occupied Units',
      description: 'Renter-Occupied Units',
      field: 'renterOccupied',
    },
    {
      id: 'ownerOccupied',
      title: 'Owner-Occupied Units',
      description: 'Owner-Occupied Units',
      field: 'ownerOccupied',
    },
    {
      id: 'urbanHeatIndex',
      title: 'Urban Heat Index',
      description: 'Urban Heat Index',
      field: 'heatIndex',
    },
    {
      id: 'utc',
      title: 'Urban Tree Canopy',
      description: 'Urban Tree Canopy',
      field: 'treeCanopy',
    },
    {
      id: 'highLivingCostHouseholds',
      title: 'Households with High Housing Burden',
      description: 'Households with High Housing Burden',
      field: 'highLivingCostHouseholds',
    },
  ],

  getValue: getCachedValue,

  formatValue: (config: IDatasetConfig, value: number) => `${value.toFixed(2)}`,

  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const values = rows.map((row) => getCachedValue(config, row));
    const [min, max] = values.reduce(([min, max], value) => [lower(min, value), higher(max, value)], [Infinity, -Infinity]);
    return [min, max];
  },

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    clearCachedValues();

    // A little inefficient but easier to debug later
    const sumOfWeights = config?.equityIndexWeights?.reduce((prev, val) => prev + val, 0);

    // const incomeStressValues = getAllValues(config, rows, IncomeStress.getValue, null);
    const livingCostvalues = getAllValues(config, rows, HighLivingCost.getValue, null);
    const above3EnergyBurdenValues = getAllValues(
      config,
      rows,
      Above3EnergyBurden?.getEquityIndexValue || Above3EnergyBurden?.getValue,
      null,
    );
    const above6EnergyBurdenValues = getAllValues(
      config,
      rows,
      Above6EnergyBurden?.getEquityIndexValue || Above6EnergyBurden?.getValue,
      null,
    );
    const above10EnergyBurdenValues = getAllValues(
      config,
      rows,
      Above10EnergyBurden?.getEquityIndexValue || Above10EnergyBurden?.getValue,
      null,
    );
    const evictionRateValues = getAllValues(config, rows, EvictionRate.getValue, null);
    const internetAccessValues = getAllValues(config, rows, LackOfInternet.getValue, null);
    const healthCareValues = getAllValues(config, rows, InsuranceStress.getValue, null);
    const asthmaValues = getAllValues(config, rows, Asthma.getValue, null);
    const uhiValues = getAllValues(config, rows, UrbanHeatIndex.getValue, null);
    const utcValues = getAllValues(config, rows, UrbanTreeCanopy.getValue, null);
    const bipocValues = getAllValues(config, rows, getBipoc, null);
    const highLivingCostValues = getAllValues(config, rows, HighLivingCost.getValue, null);

    // const [, incomeStressMax] = getMinMax(incomeStressValues);
    const [, livingCostMax] = getMinMax(livingCostvalues);
    const [, above3Max] = getMinMax(above3EnergyBurdenValues);
    const [, above6Max] = getMinMax(above6EnergyBurdenValues);
    const [, above10Max] = getMinMax(above10EnergyBurdenValues);
    const [, evictionRateMax] = getMinMax(evictionRateValues);
    const [, internetAccessMax] = getMinMax(internetAccessValues);
    const [, healthCareMax] = getMinMax(healthCareValues);
    const [, asthmaMax] = getMinMax(asthmaValues);
    const [, uhiMax] = getMinMax(uhiValues);
    const [, utcMax] = getMinMax(utcValues);
    const [, bipocMax] = getMinMax(bipocValues);
    const [, hightLivingCostMax] = getMinMax(highLivingCostValues);

    const [
      incomeStressWeight,
      livingCostWeight,
      utilityBurdenWeight,
      above3Weight,
      above6Weight,
      above10Weight,
      evictionRateWeight,
      internetAccessWeight,
      healthCareWeight,
      asthmaWeight,
      bipocWeight,
      energyBurdenWeight,
      elecWaterBurdenWeight,
      elecGasBurdenWeight,
      gasWaterBurdenWeight,
      electricBurdenWeight,
      gasBurdenWeight,
      waterBurdenWeight,
      above3UtilityWeight,
      above6UtilityWeight,
      above10UtilityWeight,
      above3GasWeight,
      above6GasWeight,
      above10GasWeight,
      above3WaterWeight,
      above6WaterWeight,
      above10WaterWeight,
      above3ElectricWeight,
      above6ElectricWeight,
      above10ElectricWeight,
      above3ElecWaterWeight,
      above6ElecWaterWeight,
      above10ElecWaterWeight,
      above3GasWaterWeight,
      above6GasWaterWeight,
      above10GasWaterWeight,
      singleFamilyWeight,
      multiFamilyWeight,
      renterOccupiedWeight,
      ownerOccupiedWeight,
      uhiWeight,
      utcWeight,
      householdCostWeight,
    ] = config.equityIndexWeights;

    const getRelativeValue = ({
      getValue,
      key,
      notInverted,
      subsets,
    }: {
      getValue: (config: IDatasetConfig, row: ITractDataValue) => number;
      key: string;
      notInverted?: boolean;
      subsets?: ('gas' | 'water' | 'electric')[];
    }) => {
      const configCopy = {...config};

      if (subsets) {
        configCopy.subsets = subsets;
      }
      const values = getAllValues(configCopy, rows, getValue, null);
      const [, indicatorMax] = getMinMax(values);
      const quotient = notInverted ? (values[key] || 0) / indicatorMax : 1 - (values[key] || 0) / indicatorMax;
      const relativeValue = indicatorMax > 0 ? Math.max(quotient) : 0;
      return relativeValue;
    };

    const weightedValues = {} as Record<string, number>;
    rows.forEach((row) => {
      const key = row.tractId || row.stateCountyId || '';

      // const incomeStressRelative = incomeStressMax > 0 ? Math.max(1 - (incomeStressValues[key] || 0) / incomeStressMax, 0) : 0;
      const incomeStressRelative = getRelativeValue({getValue: IncomeStress.getValue, key});
      const bipocRelative = bipocMax > 0 ? Math.max((bipocValues[key] || 0) / bipocMax, 0) : 0;
      const livingCostRelative = livingCostMax > 0 ? Math.max(1 - (livingCostvalues[key] || 0) / livingCostMax, 0) : 0;
      const above3Relative = above3Max > 0 ? Math.max(1 - (above3EnergyBurdenValues[key] || 0) / above3Max, 0) : 0;
      const above6Relative = above6Max > 0 ? Math.max(1 - (above6EnergyBurdenValues[key] || 0) / above6Max, 0) : 0;
      const above10Relative = above10Max > 0 ? Math.max(1 - (above10EnergyBurdenValues[key] || 0) / above10Max, 0) : 0;
      const evictionRelative = evictionRateMax > 0 ? Math.max(1 - (evictionRateValues[key] || 0) / evictionRateMax, 0) : 0;
      const internetRelative = internetAccessMax > 0 ? Math.max(1 - (internetAccessValues[key] || 0) / internetAccessMax, 0) : 0;
      const healthcareRelative = healthCareMax > 0 ? Math.max(1 - (healthCareValues[key] || 0) / healthCareMax, 0) : 0;
      const asthmaRelative = asthmaMax > 0 ? Math.max(1 - (asthmaValues[key] || 0) / asthmaMax, 0) : 0;
      const uhiRelative = uhiMax > 0 ? Math.max(1 - (uhiValues[key] || 0) / uhiMax, 0) : 0;
      const utcRelative = utcMax > 0 ? Math.max(1 - (utcValues[key] || 0) / utcMax, 0) : 0;
      const elecGasBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['electric', 'gas']});
      const elecWaterBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['electric', 'water']});
      const gasWaterBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['gas', 'water']});
      const energyBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key});
      const electricBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['electric']});
      const gasBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['gas']});
      const waterBurdenRelative = getRelativeValue({getValue: EnergyBurden.getValue, key, subsets: ['water']});
      const utilityBurdenRelative = getRelativeValue({getValue: generateGetValue('utilityBurden'), key});
      const above3UtilityRelative = getRelativeValue({getValue: generateGetValue('above3PercentUtilityBurden'), key});
      const above6UtilityRelative = getRelativeValue({getValue: generateGetValue('above6PercentUtilityBurden'), key});
      const above10UtilityRelative = getRelativeValue({getValue: generateGetValue('above10PercentUtilityBurden'), key});
      const above3GasRelative = getRelativeValue({getValue: generateGetValue('above3PercentGasBurden'), key});
      const above6GasRelative = getRelativeValue({getValue: generateGetValue('above6PercentGasBurden'), key});
      const above10GasRelative = getRelativeValue({getValue: generateGetValue('above10PercentGasBurden'), key});
      const above3WaterRelative = getRelativeValue({getValue: generateGetValue('above3PercentWaterBurden'), key});
      const above6WaterRelative = getRelativeValue({getValue: generateGetValue('above6PercentWaterBurden'), key});
      const above10WaterRelative = getRelativeValue({getValue: generateGetValue('above10PercentWaterBurden'), key});
      const above3ElectricRelative = getRelativeValue({getValue: generateGetValue('above3PercentElectricBurden'), key});
      const above6ElectricRelative = getRelativeValue({getValue: generateGetValue('above6PercentElectricBurden'), key});
      const above10ElectricRelative = getRelativeValue({getValue: generateGetValue('above10PercentElectricBurden'), key});
      const above3ElecWaterRelative = getRelativeValue({getValue: generateGetValue('above3PercentElecWaterBurden'), key});
      const above6ElecWaterRelative = getRelativeValue({getValue: generateGetValue('above6PercentElecWaterBurden'), key});
      const above10ElecWaterRelative = getRelativeValue({getValue: generateGetValue('above10PercentElecWaterBurden'), key});
      const above3GasWaterRelative = getRelativeValue({getValue: generateGetValue('above3PercentGasWaterBurden'), key});
      const above6GasWaterRelative = getRelativeValue({getValue: generateGetValue('above6PercentGasWaterBurden'), key});
      const above10GasWaterRelative = getRelativeValue({getValue: generateGetValue('above10PercentGasWaterBurden'), key});
      const singleFamilyRelative = getRelativeValue({getValue: generateGetValue('above10PercentGasWaterBurden'), key, notInverted: true});
      const multiFamilyRelative = getRelativeValue({getValue: generateGetValue('above10PercentGasWaterBurden'), key});
      const renterOccupiedRelative = getRelativeValue({getValue: generateGetValue('renterOccupied'), key});
      const ownerOccupiedRelative = getRelativeValue({getValue: generateGetValue('ownerOccupied'), key, notInverted: true});
      const householdCostRelative = hightLivingCostMax > 0 ? Math.max(1 - (highLivingCostValues[key] || 0) / hightLivingCostMax, 0) : 0;

      // if (['08031000102', '08031000201', '08031000202', '08031000301', '08031000302', '08031000303', '8031000904'].includes(key)) {
      //   console.log({key, val: incomeStressValues[key], incomeStressRelative});
      // }

      const weightedValue =
        incomeStressRelative * incomeStressWeight +
        livingCostRelative * livingCostWeight +
        utilityBurdenRelative * utilityBurdenWeight +
        above3Relative * above3Weight +
        above6Relative * above6Weight +
        above10Relative * above10Weight +
        evictionRelative * evictionRateWeight +
        internetRelative * internetAccessWeight +
        healthcareRelative * healthCareWeight +
        asthmaRelative * asthmaWeight +
        uhiRelative * uhiWeight +
        utcRelative * utcWeight +
        bipocRelative * bipocWeight +
        elecGasBurdenRelative * elecGasBurdenWeight +
        elecWaterBurdenRelative * elecWaterBurdenWeight +
        gasWaterBurdenRelative * gasWaterBurdenWeight +
        electricBurdenRelative * electricBurdenWeight +
        gasBurdenRelative * gasBurdenWeight +
        waterBurdenRelative * waterBurdenWeight +
        above3UtilityRelative * above3UtilityWeight +
        above6UtilityRelative * above6UtilityWeight +
        above10UtilityRelative * above10UtilityWeight +
        above3GasRelative * above3GasWeight +
        above6GasRelative * above6GasWeight +
        above10GasRelative * above10GasWeight +
        above3WaterRelative * above3WaterWeight +
        above6WaterRelative * above6WaterWeight +
        above10WaterRelative * above10WaterWeight +
        above3ElectricRelative * above3ElectricWeight +
        above6ElectricRelative * above6ElectricWeight +
        above10ElectricRelative * above10ElectricWeight +
        above3ElecWaterRelative * above3ElecWaterWeight +
        above6ElecWaterRelative * above6ElecWaterWeight +
        above10ElecWaterRelative * above10ElecWaterWeight +
        above3GasWaterRelative * above3GasWaterWeight +
        above6GasWaterRelative * above6GasWaterWeight +
        above10GasWaterRelative * above10GasWaterWeight +
        singleFamilyRelative * singleFamilyWeight +
        multiFamilyRelative * multiFamilyWeight +
        renterOccupiedRelative * renterOccupiedWeight +
        ownerOccupiedRelative * ownerOccupiedWeight +
        energyBurdenRelative * energyBurdenWeight +
        householdCostRelative * householdCostWeight;

      weightedValues[key] = sumOfWeights ? Math.round((weightedValue / sumOfWeights) * 100) : 0;

      cacheDownloadTractData(row);
      cacheDownloadValue(key, 'Weighted Value', weightedValue, row);
      cacheDownloadValue(key, 'Income Stress', incomeStressRelative, row);
      cacheDownloadValue(key, 'BIPOC', bipocRelative, row);
      cacheDownloadValue(key, 'Living Cost', livingCostRelative, row);
      cacheDownloadValue(key, 'Above 3% Energy Burden', above3Relative, row);
      cacheDownloadValue(key, 'Above 6% Energy Burden', above6Relative, row);
      cacheDownloadValue(key, 'Above 10% Energy Burden', above10Relative, row);
      cacheDownloadValue(key, 'Eviction Rate', evictionRelative, row);
      cacheDownloadValue(key, 'Internet Access', internetRelative, row);
      cacheDownloadValue(key, 'Healthcare Access', healthcareRelative, row);
      cacheDownloadValue(key, 'Asthma Rate', asthmaRelative, row);
      cacheDownloadValue(key, 'Urban Heat Index', uhiRelative, row);
      cacheDownloadValue(key, 'Urban Tree Canopy', utcRelative, row);
      cacheDownloadValue(key, 'Energy Burden', energyBurdenRelative, row);
      cacheDownloadValue(key, 'Utility Burden', utilityBurdenRelative, row);
      cacheDownloadValue(key, 'Electric Gas Burden', elecGasBurdenRelative, row);
      cacheDownloadValue(key, 'Electric Water Burden', elecWaterBurdenRelative, row);
      cacheDownloadValue(key, 'Gas Water Burden', gasWaterBurdenRelative, row);
      cacheDownloadValue(key, 'Electric Burden', electricBurdenRelative, row);
      cacheDownloadValue(key, 'Gas Burden', gasBurdenRelative, row);
      cacheDownloadValue(key, 'Water Burden', waterBurdenRelative, row);
      cacheDownloadValue(key, 'Above 3% Utility Burden', above3UtilityRelative, row);
      cacheDownloadValue(key, 'Above 6% Utility Burden', above6UtilityRelative, row);
      cacheDownloadValue(key, 'Above 10% Utility Burden', above10UtilityRelative, row);
      cacheDownloadValue(key, 'Above 3% Gas Burden', above3GasRelative, row);
      cacheDownloadValue(key, 'Above 6% Gas Burden', above6GasRelative, row);
      cacheDownloadValue(key, 'Above 10% Gas Burden', above10GasRelative, row);
      cacheDownloadValue(key, 'Above 3% Water Burden', above3WaterRelative, row);
      cacheDownloadValue(key, 'Above 6% Water Burden', above6WaterRelative, row);
      cacheDownloadValue(key, 'Above 10% Water Burden', above10WaterRelative, row);
      cacheDownloadValue(key, 'Above 3% Electric Burden', above3ElectricRelative, row);
      cacheDownloadValue(key, 'Above 6% Electric Burden', above6ElectricRelative, row);
      cacheDownloadValue(key, 'Above 10% Electric Burden', above10ElectricRelative, row);
      cacheDownloadValue(key, 'Above 3% Electric Water Burden', above3ElecWaterRelative, row);
      cacheDownloadValue(key, 'Above 6% Electric Water Burden', above6ElecWaterRelative, row);
      cacheDownloadValue(key, 'Above 10% Electric Water Burden', above10ElecWaterRelative, row);
      cacheDownloadValue(key, 'Above 3% Gas Water Burden', above3GasWaterRelative, row);
      cacheDownloadValue(key, 'Above 6% Gas Water Burden', above6GasWaterRelative, row);
      cacheDownloadValue(key, 'Above 10% Gas Water Burden', above10GasWaterRelative, row);
      cacheDownloadValue(key, 'Single-Family Units', singleFamilyRelative, row);
      cacheDownloadValue(key, 'Multi-Family Units', multiFamilyRelative, row);
      cacheDownloadValue(key, 'Renter-Occupied Units', renterOccupiedRelative, row);
      cacheDownloadValue(key, 'Owner-Occupied Units', ownerOccupiedRelative, row);
    });

    const [weightedMin, weightedMax] = getMinMax(weightedValues);
    const weightedRange = weightedMax - weightedMin;

    Object.entries(weightedValues).forEach(([key, value]) => {
      const extrapolatedValue = (100 * (value - weightedMin)) / weightedRange;
      weightedValues[key] = Number.isNaN(extrapolatedValue) ? 0 : extrapolatedValue;
      cacheValueByKey(key, extrapolatedValue);
      cacheSingleDownloadValue(key, 'Weighted Value', extrapolatedValue);

      // if (['08031000102', '08031000201', '08031000202', '08031000301', '08031000302', '08031000303', '08031000904'].includes(key)) {
      //   console.log('Extrapolated equity index row', key, extrapolatedValue);
      // }
    });

    const map: ITractColorMap = {fills: [], opacities: []};

    const [overallMin, overallMax] = getMinMax(weightedValues);

    Object.entries(weightedValues).forEach(([tractId, weightedValue]) => {
      const valueBucket = getValueBucket(overallMax - weightedValue, overallMin, overallMax, opacityBuckets.length);
      map.fills.push(String(tractId));
      map.fills.push(fillBuckets[valueBucket]);
      map.opacities.push(String(tractId));
      map.opacities.push(valueBucket ? 1 : 0);
    });

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    const values = getAllValues(config, rows, getCachedValue, '');
    const [min, max] = getMinMax(values);
    const lCutoff = (max - min) * 0.2;
    const hCutoff = (max - min) * 0.8;

    Object.entries(values).forEach(([tractId, value]) => {
      if (value <= lCutoff) {
        map.values[tractId] = 'L';
      } else if (value >= hCutoff) {
        map.values[tractId] = 'H';
      } else {
        map.values[tractId] = 'M';
      }
    });

    return map;
  },
};
