import {SearchIcon} from '@heroicons/react/outline';
import {ChangeEvent, FC, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {FieldLabel, RequiredSpan} from './Inputs';
import {EllipsisLoader} from './Loading';

const Control = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  height: 52px;
  width: 100%;
  padding: 0 48px 0 48px;
  font-size: 14px;
  border-radius: 16px;
  margin: 0;
  color: ${(props) => props.theme.Colors.Neutrals._100};
  border: 1px solid ${(props) => props.theme.Colors.Neutrals._500};
  background-color: ${(props) => props.theme.Colors.Neutrals._500};

  &.error {
    border: 1px solid ${(props) => props.theme.Colors.Reds._000};
  }

  &:focus {
    border: 1px solid ${(props) => props.theme.Colors.Oranges._100};
    outline: 5px solid ${(props) => props.theme.Colors.Oranges._400};
  }
`;

const Dropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  left: 0;
  top: 45px;
  z-index: 100;

  padding: 12px;

  width: 100%;
  background: #ffffff;
  box-shadow: 0 20px 25px -5px rgba(106, 111, 123, 0.1), 0px 10px 10px -5px rgba(106, 111, 123, 0.04);
  border-radius: 16px;
`;

const Suggestion = styled.div`
  padding: 12px;
  font-size: 16px;
  background-color: #fff;

  &:hover {
    background-color: ${(props) => props.theme.Colors.Oranges._000};
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
  }
`;

const Loader = styled(EllipsisLoader)`
  position: absolute;
  right: 0px;
  top: -5px;
`;

export const DropdownWithSuggestions: FC<{
  label?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder: string;
  onSearchChange: (value: any) => Promise<void>;
  value: string;
  onSelect: (value: any) => void;
  options: {label: string; value: any}[];
  maxOptions?: number;
  isLoading?: boolean;
  name?: string;
  onBlur?: (e: any) => void;
}> = ({placeholder, onSearchChange, disabled, required, value, onSelect, options, maxOptions, label, isLoading, name, onBlur}) => {
  const [q, setQ] = useState(value || '');
  const [showDropdown, setShowDropdown] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: any) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('pointerdown', handleClickOutside);

    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [inputRef]);

  const handleSearchChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsSearching(true);
    setQ(e.target.value);
    onSearchChange && (await onSearchChange(e.target.value));
    setIsSearching(false);
  };

  useEffect(() => {
    setQ(value || '');
  }, [value]);

  const handleSelect = (option: any) => {
    setQ(option.label);
    onSelect && onSelect(option.value);
    setShowDropdown(false);
  };

  return (
    <div>
      {label ? (
        <FieldLabel>
          {label} {required && !disabled && <RequiredSpan>*</RequiredSpan>}
        </FieldLabel>
      ) : (
        <></>
      )}
      <Control>
        <SearchIcon style={{top: 16, left: 16, position: 'absolute', width: 20}} color="#989ba3" />
        <Input
          name={name}
          // required={required}
          disabled={disabled}
          type="text"
          placeholder={placeholder || 'Search Places...'}
          value={q}
          onChange={handleSearchChange}
          onFocus={() => setShowDropdown(true)}
          onBlur={onBlur}
        />
        {(isLoading || isSearching) && <Loader />}
        {q && showDropdown && !!options?.length && (
          <Dropdown ref={inputRef}>
            {(options || []).slice(0, maxOptions || 5).map((option, i) => (
              <Suggestion key={i} onClick={() => handleSelect(option)}>
                {option.label}
              </Suggestion>
            ))}
          </Dropdown>
        )}
      </Control>
    </div>
  );
};
