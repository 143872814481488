import {FC, useState, useEffect} from 'react';
import {Elements, useElements, useStripe, AddressElement, PaymentElement} from '@stripe/react-stripe-js';
import {FullPageLoader, Modal, StandardButton} from '../../Components';
import {loadStripe} from '@stripe/stripe-js';
import {getConfig} from '../../getConfig';
import {useGetSetupIntent} from '../../API';
import {routePaths} from '../../constants/routePaths';
import {toast} from 'react-hot-toast';
import {useUpdateMyPaymentMethod} from '../../API/Mutations/useUpdateMyPaymentMethod';
import {useMyProfile} from '../../API/Queries/useMyProfile';

interface IPaymentMethodModalProps {
  isOpen: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

interface IPaymentMethodProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const PaymentMethod: FC<IPaymentMethodProps> = ({onSubmit, onCancel}) => {
  const stripe = useStripe();
  const elements = useElements();
  const updateMyPaymentMethod = useUpdateMyPaymentMethod();
  const {data: myProfile, isFetching} = useMyProfile();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (stripe && elements) {
      const setupIntent = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: `${window.location.protocol}//${window.location.host}${routePaths.orgAdminSubscription}`,
        },
      });

      if (setupIntent?.error || !setupIntent?.setupIntent?.payment_method) {
        toast.error('There was an error updating your payment method');
      } else {
        const paymentMethodId: string = setupIntent?.setupIntent?.payment_method as string;
        const subscription = await updateMyPaymentMethod.mutateAsync({paymentMethodId});
        if (subscription?.default_payment_method === paymentMethodId) {
          toast.success('Your payment method has been updated.');
        }
        onSubmit();
      }
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      {(isFetching || updateMyPaymentMethod.isLoading) && <FullPageLoader />}
      <div style={{marginBottom: 20}}>
        <AddressElement options={{mode: 'billing', defaultValues: {name: myProfile?.user?.firstName + ' ' + myProfile?.user?.lastName}}} />
      </div>
      <PaymentElement />
      <div style={{display: 'flex', flexDirection: 'row', gap: '18px', marginTop: '18px'}}>
        <StandardButton onClick={onCancel} style={{flex: 1}}>
          Cancel
        </StandardButton>
        <StandardButton type="submit" style={{flex: 1}}>
          Update Payment Method
        </StandardButton>
      </div>
    </form>
  );
};

export const PaymentMethodModal: FC<IPaymentMethodModalProps> = ({isOpen, onSubmit, onCancel}: IPaymentMethodModalProps) => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const {data: setupIntent, isLoading} = useGetSetupIntent();

  useEffect(() => {
    const handleLoadStripe = async () => {
      setStripePromise(await loadStripe(getConfig().stripePublishableKey));
    };
    handleLoadStripe();
  }, []);

  return (
    <Modal isOpen={isOpen} hideButtons button1Action={onCancel}>
      {isLoading && <FullPageLoader />}
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: setupIntent?.client_secret,
          appearance: {
            theme: 'flat',
          },
        }}>
        <PaymentMethod onSubmit={onSubmit} onCancel={onCancel} />
      </Elements>
    </Modal>
  );
};
