import {FC} from 'react';
import {Dropdown} from '../../Components';
import {productLessProductList} from '../Admin/adminUtils';
import {IProductCondensed, IAuthCondensed} from './SetupLocations';

const getTargetInfoFromProduct = (product: IProductCondensed): IAuthCondensed => {
  const [targetType, targetId] = product.id.split(':');
  return {
    targetId,
    targetType,
  };
};

export const LocationsSelect: FC<{locationFilter: string[]; onChange: (value: any) => void}> = ({locationFilter, onChange}) => {
  const locationOptions = productLessProductList
    .filter((product) => {
      // We always expect the Authorization's ID to be formatted like
      // targetType:targetId. Though perhaps it would be a good idea to
      // create an API around that rather than parsing the ID throughout
      // the code.
      const target = getTargetInfoFromProduct(product);
      // Only show locations of the desired type
      return locationFilter.indexOf(target.targetType) >= 0;
    })
    .map((auth) => ({
      value: auth.id,
      label: auth.name,
    }));

  const filterOptions = (inputValue: string) => {
    return locationOptions.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 50);
  };

  const loadOptions = (inputValue: string, callback: (options: any[]) => void) => {
    callback(filterOptions(inputValue));
  };

  const defaultOptions = locationOptions.slice(0, 50);

  return (
    <Dropdown hideSearchIcon isSearchable={true} onChange={onChange} isAsync loadOptions={loadOptions} defaultOptions={defaultOptions} />
  );
};
