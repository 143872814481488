import {useNavigate} from 'react-router-dom';
import {Steps} from '../../Components/Molecules/Steps';

import {useOnboardingSteps} from '../../Hooks/useOnboardingSteps';

export const OnboardingSteps = () => {
  const navigate = useNavigate();
  const {completedStep, stepMap, currentStep} = useOnboardingSteps();

  const handleNavigate = (step: number) => {
    const path = stepMap[step];
    navigate(path);
  };

  return <Steps completed={completedStep} ignoreCompleted current={currentStep} total={stepMap.length} onClick={handleNavigate} />;
};
