import {IProduct} from '..';
import {Endpoint} from '../Endpoint';
import {IOrganization, ISubscriptionPricing, IUser, SubscriptionType} from '../Types';

export const findProductComponent = (q: string) => Endpoint.post('/findProductComponent', {q}).then((r) => r.data);

export const getUsers = () => Endpoint.get<IUser[]>('/getUsers').then((r) => r.data);

export const getProducts = () => Endpoint.get('/getProducts').then((r) => r.data);

export const getProduct = (productId: string) => Endpoint.post<IProduct>('/getProduct', {productId}).then((r) => r.data);

export const getOrganizations = ({includeRelations = false}: {includeRelations?: boolean}) =>
  Endpoint.get<IOrganization[]>('/getOrganizations', {params: {includeRelations}}).then((r) => r.data);

export const getOrganization = (organizationId: string) =>
  Endpoint.post<IOrganization>('/getOrganization', {organizationId}).then((r) => r.data);

export const getOrganizationInvoices = (organizationId: string) =>
  Endpoint.post('/getOrganizationInvoices', {organizationId}).then((r) => r.data);

export const getOrganizationProducts = (organizationId: string) =>
  Endpoint.post('/getOrganizationProducts', {organizationId}).then((r) => r.data);

interface IAddProductToOrganizationRequest {
  productId: string;
  organizationId: string;
  targetType: string;
}

export const addProductToOrganization = (params: IAddProductToOrganizationRequest) =>
  Endpoint.post('/addProductToOrganization', params).then((r) => r.data);

export const createOrganization = (params: IOrganization) => Endpoint.post('/createOrganization', params).then((r) => r.data);

interface ICreateProductRequest {
  name: string;
  phone_number?: string;
  primary_contact?: string;
  primary_email?: string;
  street_address?: string;
}

export const createProduct = (params: ICreateProductRequest) => Endpoint.post('/createProduct', params).then((r) => r.data);

export const deleteUser = (userId: string) => Endpoint.post('/deleteUser', {userId}).then((r) => r.data);

export const deleteOrganization = (organizationId: string) => Endpoint.post('/deleteOrganization', {organizationId}).then((r) => r.data);

interface IRemoveProductFromOrganizationRequest {
  productId: string;
  organizationId: string;
}

export const removeProductFromOrganization = (params: IRemoveProductFromOrganizationRequest) =>
  Endpoint.post('/removeProductFromOrganization', params).then((r) => r.data);

export const removeExtraSubscriptions = () => Endpoint.post('/removeExtraSubscriptions').then((r) => r.data);

interface IResetUserPassworRequest {
  targetType: string;
  targetId: string;
}

export const resetUserPassword = (params: IResetUserPassworRequest) => Endpoint.post('/resetUserPassword', params).then((r) => r.data);

interface IAuthEntry {
  targetType: string;
  targetId: string;
}

interface IUpdateOrganizationRequest {
  organizationId: string;
  id: string;
  name?: string;
  street_address?: string;
  phone_number?: string;
  primary_email?: string;
  user_type?: string;
  primary_contact?: string;
  showProducts?: boolean;
  showCityBoundaries?: boolean;
  initialLat?: number;
  initialLon?: number;
  initialZoom?: number;
  initialAuthorizationId?: string;
  county?: string;
  revenue?: number;
  ein?: number;
  subscription_type?: SubscriptionType;
  addAuths?: IAuthEntry[];
  removeAuths?: IAuthEntry[];
}

export const updateOrganization = (params: IUpdateOrganizationRequest) => Endpoint.post('/updateOrganization', params).then((r) => r.data);

export const updateAdminOrganization = (params: IUpdateOrganizationRequest) =>
  Endpoint.post('/updateAdminOrganization', params).then((r) => r.data);

interface IUpdateProductRequest {
  productId: string;
  name?: string;
  initialLat?: number;
  initialLon?: number;
  initialZoom?: number;

  addAuths?: IAuthEntry[];
  removeAuths?: IAuthEntry[];
}

export const updateProduct = (params: IUpdateProductRequest) => Endpoint.post('/updateProduct', params).then((r) => r.data);

interface IUpdateUserRequest {
  userId: string;
  organizationId?: string;
  user_status?: string;
  firstName?: string;
  lastName?: string;
  user_type?: string;
  phone_number?: string;
  initialLat?: number;
  initialLon?: number;
  limitedView?: boolean;
}

export const updateUser = (params: IUpdateUserRequest) => Endpoint.post('/updateUser', params).then((r) => r.data);

export const updatePrices = (params: ISubscriptionPricing['subscriptionTypes']) =>
  Endpoint.post('/updatePrices', params).then((r) => r.data);

export const createTrialSubscription = (params: {organization: IOrganization; periodEnd?: number}) =>
  Endpoint.post('/createTrialSubscription', params).then((r) => r.data);

export const updateExistingOrganizations = () => Endpoint.post('/updateExistingOrganizations').then((r) => r.data);
