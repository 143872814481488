// Given two number-like values, return the higher as an actual number
import {IAuthorization, ISubscriptionPricing, ISubscriptionTierDetails, ITractDataValue, SubscriptionType} from '../API';
import {IAuthCondensed} from '../Views/Onboarding/SetupLocations';
import {IDatasetConfig} from './Datasets/Types';
import {cacheDownloadValue, cacheSingleDownloadValue} from './DownloadManager';

export const higher = (a: string | number = 0, b: string | number = 0): number => {
  const max = Math.max(+a, +b);
  return Number.isNaN(max) ? 0 : max;
};

// Given two number-like values, return the lower as an actual number
export const lower = (a: string | number, b: string | number): number => {
  const min = Math.min(+a, +b);
  return Number.isNaN(min) ? 0 : min;
};

// Given a set of {tractId: value, tractId2: value2, ...} values, return the lowest and highest values in the set
// If the set is empty, return [Infinity, -Infinity]
// Optionally, skips Falsy values
export const getMinMax = (values: Record<string, number>, skipFalsy = false): [number, number] =>
  Object.values(values).reduce(
    ([min, max], value) => (skipFalsy && !value ? [min, max] : [lower(min, value), higher(max, value)]),
    [Infinity, -Infinity],
  );

// Given a value in a known range, and a number of "buckets" to divide the values across, determine what "bucket" this value is in.
export const getValueBucket = (value: number, min: number, max: number, buckets: number) => {
  const minimum = max - min === 0 ? min * 0.99 : min;
  return Math.max(Math.min(Math.floor(((value - minimum) / (max - minimum)) * buckets), buckets - 1), 0);
};

// Format a income stress value with or without pennies. For the moment we only support the US locale but if we standardize all formatters to
// call this, we can enhance that later.
export const formatCurrency = (val: any) =>
  Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0}).format(val);

const formatIncomeStressCurrency = (val: any) =>
  Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(Math.round(100000 / +(val || 0.00001)));

export const formatIncomeStressCurrencyInt = (val: any) => (val ? formatIncomeStressCurrency(val).split('.')[0] : '--');

// Format a percentage value. We don't use toFixed() because it has some odd rounding behaviors.
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed#using_tofixed
// Note that this is a legacy function designed to format a NUMERIC percent (e.g. 0.50 => '50%')
export const formatPercent = (val: any) => Math.round(+val * 10000) / 100 + '%';

export const formatNumber = (val: any) => Intl.NumberFormat('en-US').format(val);

export const formatArea = (val: any) => {
  const area = +val;
  return formatNumber(Math.round(area * 100) / 100) + ' sqft';
};

// Convenience helper to extract row values into a {tractId: value, ...} map
export const getAllValues = (
  config: IDatasetConfig,
  rows: ITractDataValue[],
  valueGetter: (config: IDatasetConfig, row: ITractDataValue) => number,
  label: string | null,
) => {
  const values = {} as Record<string, number>;
  rows.forEach((row) => {
    const key = row.tractId || row.stateCountyId;
    values[key] = valueGetter(config, row);
    if (label) {
      cacheDownloadValue(key, label, values[key], row);
    }
  });

  return values;
};

// Some datasets are "houses in burden" (e.g. 1343 houses) rather than percentages. This returns a percentage of the SUM of all the houses
// in the data set.
// TODO: Discuss this. If what's desired is "percentage of max" we'd just need a min/max calculation
export const getAllHouseCountsAsPercentages = (
  config: IDatasetConfig,
  rows: ITractDataValue[],
  getValue: (config: IDatasetConfig, row: ITractDataValue) => number,
  label: string,
) => {
  const values = getAllValues(config, rows, getValue, label);
  const sum = Object.values(values).reduce((prev, val) => prev + val, 0);
  Object.entries(values).forEach(([tractId, value]) => {
    cacheSingleDownloadValue(tractId, label, value);
    values[tractId] = value / sum;
  });

  return values;
};

// Given a set of authorizations and a subscription tier, calculate the total cost of the authorizations
export const calculateLocationsCost = ({
  authorizations,
  subscriptionPricing,
}: {
  authorizations: IAuthorization[];
  subscriptionPricing: ISubscriptionTierDetails | undefined;
}) => {
  const price = authorizations
    ?.map((auth) => subscriptionPricing?.perLocationPricing?.[auth.targetType] || 0)
    ?.reduce((acc: number, price) => acc + (price || 0), 0);

  return price;
};

export const calculateUserCost = (numberOfUsers = 0, subscriptionPricing?: ISubscriptionTierDetails) => {
  return +(subscriptionPricing?.perUserPricing || 0) * (numberOfUsers || 0);
};

export const getTractCount = (auth: IAuthCondensed, subscriptionPricing: ISubscriptionPricing | undefined) => {
  if (auth.targetType === 'county') {
    const tractCount = subscriptionPricing?.countyTractCounts?.[auth.targetId] || 0;
    return tractCount;
  } else if (auth.targetType === 'state') {
    const tractCount = subscriptionPricing?.statesTractCounts?.[+auth.targetId] || 0;
    return tractCount;
  } else {
    return 0;
  }
};

const getPricePerTract = (auth: IAuthCondensed, subscriptionPricing: ISubscriptionPricing | undefined, type: SubscriptionType) => {
  const subscriptionTierPricing = subscriptionPricing?.subscriptionTypes[type];

  if (auth.targetType === 'county') {
    const pricePerTract = subscriptionTierPricing?.perLocationPricing?.countyTract || 0;
    return pricePerTract;
  } else if (auth.targetType === 'state') {
    const pricePerTract = subscriptionTierPricing?.perLocationPricing?.stateTract || 0;
    return pricePerTract;
  } else {
    return 0;
  }
};

export const calcSingleLocationCost = (
  auth: IAuthCondensed,
  subscriptionPricing: ISubscriptionPricing | undefined,
  type: SubscriptionType,
) => {
  const subscriptionTierPricing = subscriptionPricing?.subscriptionTypes[type];
  const tractCount = getTractCount(auth, subscriptionPricing);
  const pricePerTract = getPricePerTract(auth, subscriptionPricing, type);

  if (auth.targetType === 'nationwide') {
    return +(subscriptionTierPricing?.perLocationPricing?.nationwide || 0);
  } else if (['county', 'state'].includes(auth.targetType)) {
    return tractCount * pricePerTract;
  } else {
    return subscriptionTierPricing?.perLocationPricing?.nationwide;
  }
};

export const calcTotalCost = (
  authorizations: IAuthCondensed[],
  users: number,
  subscriptionPricing: ISubscriptionPricing | undefined,
  type: SubscriptionType,
) => {
  const subscriptionTierPricing = subscriptionPricing?.subscriptionTypes[type];
  const locationCost = authorizations
    .map((auth) => {
      return calcSingleLocationCost(auth, subscriptionPricing, type);
    })
    .reduce((acc: number, x) => acc + (x || 0), 0);

  const userCost = (subscriptionTierPricing?.perUserPricing || 0) * (users || 0);

  return userCost + locationCost;
};

export const formatTractCount = (tractCount: number) => {
  return tractCount.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits: 0}) + ' tracts';
};
