import {FC, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {FlexFill, FullPageLoader, H4, StandardButton} from '../../Components';
import {routePaths} from '../../constants/routePaths';
import {OnboardingPage, OnboardingPageWrapper, OnboardingPanel} from './Layout';
import {useMixpanel} from '../../Managers/Mixpanel/useMixpanel';

const ContentBlock = styled.p`
  font-size: 16px;
  max-width: 400px;
  text-align: 'center';
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const PaymentConfirmed: FC = () => {
  const [refetchInterval, setRefetchInterval] = useState<number | false>(false);
  const {mixpanel} = useMixpanel();

  const navigate = useNavigate();
  const {data: organization, isFetching: isFetchingOrganization} = useGetMyOrganization(true, refetchInterval);
  const {data: subscriptionPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const hasActiveSubscription = organization?.subscription?.status === 'active' || organization?.subscription?.status === 'trialing';
  const isLocal = organization?.subscription_type === subscriptionTypes?.local?.value;

  useEffect(() => {
    if (!hasActiveSubscription) {
      setRefetchInterval(2000);
    } else {
      setRefetchInterval(false);
    }
  }, [hasActiveSubscription]);

  const handleFinish = () => {
    mixpanel.track('Onboarding Completed');
    isLocal ? navigate(routePaths.map) : navigate(routePaths.onboardingDefaultLocation);
  };

  return (
    <OnboardingPageWrapper>
      {isFetchingOrganization && <FullPageLoader />}
      <OnboardingPage style={{maxWidth: '1000px', minHeight: '600px', height: '600px', maxHeight: '600px'}}>
        <OnboardingPanel style={{alignItems: 'center', justifyContent: 'center'}}>
          <img alt="Check mark" width="111" height="111" src="/check-mark.svg"></img>
          <H4 style={{color: '#84CC16', marginTop: '20px'}}>{isLocal ? 'Subscription Finished!' : 'Payment Confirmed!'}</H4>

          {isLocal ? (
            <ContentBlock>Your subscription has been confirmed. You are now subscribed to the Greenlink Equity Map.</ContentBlock>
          ) : (
            <>
              <ContentBlock>Your payment has been confirmed. You are now subscribed to the Greenlink Equity Map. </ContentBlock>
            </>
          )}

          <p>Subscription status: {organization?.subscription?.status || 'pending'} </p>
          <StandardButton style={{width: '400px'}} disabled={!hasActiveSubscription} onClick={handleFinish}>
            Finish
          </StandardButton>
        </OnboardingPanel>
      </OnboardingPage>
      <FlexFill style={{flex: 0}} />
    </OnboardingPageWrapper>
  );
};
