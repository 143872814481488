import {useAuth0} from '@auth0/auth0-react';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import 'yup-phone';
import {setAccessToken, useGetMyOrganization, useUpdateMyProfile} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {routePaths} from '../../constants/routePaths';
import {resetPersist} from '../../store';
import {MessageCard} from './MessageCard';
import {ONBOARDING_MESSAGES} from './ONBOARDING_MESSAGES';
import {useOnboardingRoute} from './useOnboardingRoute';

export const Root: FC = () => {
  const navigate = useNavigate();
  const {hasActiveSubscription, isLoading: pathIsLoading, routePath, userIsActive, isFirstTime} = useOnboardingRoute();

  const {data: myProfile, isError} = useMyProfile();

  const {logout} = useAuth0();

  const {data: accountOrganization} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  const updateMyProfile = useUpdateMyProfile();

  const isLoading = pathIsLoading;

  useEffect(() => {
    if (routePath) {
      navigate(routePath);
    }
  }, [routePath]);

  const handleSuccess = async () => {
    if (isFirstTime) {
      await updateMyProfile.mutateAsync({onboarding_completed: new Date().toUTCString()});
    }
    navigate(routePaths.map);
  };

  const handleLogout = () => {
    setAccessToken(null);
    logout({returnTo: window.location.origin + '/'});
    resetPersist();
  };

  if (isLoading) {
    return <MessageCard message={ONBOARDING_MESSAGES.loading} isLoading />;
  }

  if (isFirstTime) {
    return <MessageCard message={ONBOARDING_MESSAGES.accountCreated} isSuccess onClick={handleSuccess} />;
  }

  if (isError) {
    return <MessageCard message={ONBOARDING_MESSAGES.profileError} onClick={handleLogout} />;
  }

  if (!hasActiveSubscription && !!accountOrganization) {
    return <MessageCard message={ONBOARDING_MESSAGES.noOrganization} onClick={handleLogout} />;
  }

  if (!hasActiveSubscription && !!userIsActive) {
    return <MessageCard message={ONBOARDING_MESSAGES.accountInactive} onClick={handleLogout} />;
  }

  if (!hasActiveSubscription) {
    return <MessageCard message={ONBOARDING_MESSAGES.noSubscription} onClick={handleLogout} />;
  }
  return <></>;
};
