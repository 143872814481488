import {withAuthenticationRequired} from '@auth0/auth0-react';
import {FC} from 'react';
import toast from 'react-hot-toast';
import {useParams} from 'react-router-dom';
import {IProduct, useAdminProducts, useUpdateProduct} from '../../API';
import {requireAdmin} from '../../Managers/AuthUtils';
import {ProductForm} from './ProductForm';

const EditProductComponent: FC = () => {
  const params = useParams();
  const products = useAdminProducts();
  const product = products?.data?.find((product: IProduct) => product.id === params?.productId);
  const updateProduct = useUpdateProduct();

  const handleSave = async (values: any) => {
    await updateProduct
      .mutateAsync({...values, productId: values.id})
      .then((r) => {
        console.log('update result', r);
        toast.success('Product has been successfully updated!');
      })
      .catch((error) => {
        console.log('Error saving product', error);
        toast.error('There was a problem with the update!');
      });
  };

  return (
    <>
      <ProductForm onSubmit={handleSave} isLoading={products.isLoading} product={product} title="Edit Product" />
    </>
  );
};

export const EditProduct: FC = withAuthenticationRequired(EditProductComponent, {claimCheck: requireAdmin});
