import {TrashIcon} from '@heroicons/react/outline';
import {FC} from 'react';
import styled from 'styled-components';
import {FlexRow} from '../Layout';

const Button = styled.button`
  border: none;
  background-color: inherit;
  color: ${(props) => props.theme.Colors.Oranges._000};
  align-self: flex-end;
  margin: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.Colors.Oranges._100};
  }
  &:disabled {
    cursor: default;
    color: ${(props) => props.theme.Colors.Neutrals._400};
  }
`;

const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.Colors.Neutrals._200};
`;

const Subheader2 = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
`;

const Subheader3 = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) => props.theme.Colors.Neutrals._000};
  margin: 0;
`;

const Container = styled.div`
  margin: 0;
`;

export const SubscriptionDetailRow: FC<{
  cost: number;
  disabled?: boolean;
  header?: string;
  onDelete?: () => void;
  showDelete?: boolean;
  label?: string;
}> = ({cost, disabled, header, onDelete, showDelete, label}) => {
  return (
    <FlexRow style={{marginTop: '8px', alignItems: 'center', margin: 0, gap: 10}}>
      <Container>
        {!!header && <Subheader3>{header}</Subheader3>}
        
        <FlexRow style={{alignItems: 'center', margin: 0, gap: 12}}>
          <div>{!!label && <Text>{label}</Text>}</div>

          <Subheader2 style={{marginLeft: 'auto'}}> {cost.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}</Subheader2>
        </FlexRow>
      </Container>

      {showDelete && (
        <Button type="button" onClick={onDelete} disabled={disabled}>
          <TrashIcon style={{width: 24}} />
        </Button>
      )}
    </FlexRow>
  );
};
