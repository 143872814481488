import {IDatasetConfig, IDatasetOptions, IDemographicValue, ITract2DBucketMap, ITractColorMap} from './Types';
import {formatPercent, getAllValues, getMinMax, getValueBucket} from '../MathUtils';
import {opacityBuckets} from '../ColorUtils';
import {ITractDataValue} from '../../API';
import {Theme} from '../../Components';
import {cacheDownloadTractData, cacheDownloadValue} from '../DownloadManager';

// @ts-ignore
const highestKey = (value: IDemographicValue) => Object.keys(value).reduce((prev, key) => (value[key] > value[prev] ? key : prev), 'white');

const getSingleValue = (config: IDatasetConfig, row: ITractDataValue): number => {
  const key = config.subsets[0] as keyof ITractDataValue;
  return +(row[key] || 0);
};

const getValue = (config: IDatasetConfig, row: ITractDataValue): IDemographicValue => {
  const white = +row.percentWhite;
  const black = +row.percentBlack;
  const asian = +row.percentAsian;
  const hispanic = +row.percentHispanic;
  const amIndian = +row.percentAmIndian;
  const islander = +row.percentIslander;
  const other = Math.max(1 - (white + black + asian + hispanic + amIndian + islander), 0);

  return {white, black, asian, hispanic, amIndian, islander, other};
};

export const getBipoc = (config: IDatasetConfig, row: ITractDataValue): number => {
  const {black, asian, hispanic, amIndian, islander, other} = getValue(config, row);
  return black + asian + hispanic + amIndian + islander + other;
};

const cacheValue = (demographicValue: IDemographicValue, row: ITractDataValue) => {
  const {white, black, asian, hispanic, amIndian, islander, other} = demographicValue;

  const key = row.tractId || row.stateCountyId;
  cacheDownloadTractData(row);
  cacheDownloadValue(key, `Percent White`, white, row);
  cacheDownloadValue(key, `Percent Black`, black, row);
  cacheDownloadValue(key, `Percent Asian`, asian, row);
  cacheDownloadValue(key, `Percent Latinx/Hispanic`, hispanic, row);
  cacheDownloadValue(key, `Percent American Indian`, amIndian, row);
  cacheDownloadValue(key, `Percent Islander`, islander, row);
  cacheDownloadValue(key, `Percent Other`, other, row);

  return {white, black, asian, hispanic, amIndian, islander, other};
};

const getCutoffs = (values: Record<string, number>) => {
  const [min, max] = getMinMax(values);
  return {
    lCutoff: (max - min) * 0.2,
    hCutoff: (max - min) * 0.8,
  };
};

const getBucketPosition = (value: number, lCutoff: number, hCutoff: number) => {
  if (value <= lCutoff) {
    return 'L';
  } else if (value >= hCutoff) {
    return 'H';
  } else {
    return 'M';
  }
};

const colorsHex: any = {
  asian: Theme.Colors.Oranges._000,
  black: Theme.Colors.Yellows._000,
  hispanic: Theme.Colors.Teals._000,
  amIndian: Theme.Colors.Cyans._000,
  islander: Theme.Colors.Blues._000,
  white: Theme.Colors.Purples._000,
  other: Theme.Colors.Pinks._000,
};

export const RacialComposition: IDatasetOptions<IDemographicValue> = {
  id: 'racialComposition',
  downloadTitle: 'Racial Composition',
  title: 'Demographics: Racial Composition',
  combinable: true,
  cutoffDots: [],
  subsetType: 'radio',
  subsets: [
    {
      id: 'all',
      title: 'All',
      description: 'Highlights the majority racial group for each area.',
      type: '1D',
    },
    {
      id: 'percentAsian',
      title: 'Asian',
      description: 'Shows the relative minority/majority of Asian residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentBlack',
      title: 'Black',
      description: 'Shows the relative minority/majority of Black residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentHispanic',
      title: 'Latinx/Hispanic',
      description: 'Shows the relative minority/majority of Latinx/Hispanic residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentAmIndian',
      title: 'Native American',
      description: 'Shows the relative minority/majority of Native American and Alaskan Native residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentIslander',
      title: 'Pacific Islander',
      description: 'Shows the relative minority/majority of Hawaiian Pacific Islander residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentWhite',
      title: 'White',
      description: 'Shows the relative minority/majority of White residents as compared to the entire region.',
      type: 'Both',
    },
    {
      id: 'percentOther',
      title: 'Other',
      description: 'Shows the relative minority/majority of Other residents as compared to the entire region.',
      type: 'Both',
    },
  ],
  defaultSubsets: ['all'],
  defaultSubsets2D: ['percentAsian'],
  description: 'Shows the median percentage of all racial groups.',

  getValue,

  formatValue: (config: IDatasetConfig, value: number) => formatPercent(value),

  // NOTE: This dataset is an example of why even though getMinMax "normally" seems like duplicate work (energyBurden) it's more powerful
  // to split out the call. This dataset has seven components used for coloration, but on the filter bar we show percent-non-white here.
  getMinMax: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const [subset] = config.subsets;
    if (subset && subset !== 'all') {
      const subsetValues = {} as Record<string, number>;
      console.log('returning single set', subset);
      rows.forEach((row) => {
        subsetValues[row.tractId || row.stateCountyId] = getSingleValue(config, row);
      });

      return getMinMax(subsetValues);
    }

    const values = {} as Record<string, number>;
    rows.forEach((row) => {
      values[row.tractId || row.stateCountyId] = getBipoc(config, row);
    });

    return getMinMax(values);
  },

  // This is a single-set color range. All cells are orange just with a different opacity
  getSingleSetColors: (config: IDatasetConfig, rows: ITractDataValue[]): ITractColorMap => {
    const map: ITractColorMap = {fills: [], opacities: []};

    // Get the demographic subset instead of all of them
    const [subset] = config.subsets;
    if (subset && subset !== 'all') {
      const subsetTitle = RacialComposition.subsets?.find((s) => s.id === subset)?.title || '';
      const subsetValues = {} as Record<string, number>;
      console.log('returning single set', subsetTitle);
      rows.forEach((row) => {
        const key = row.tractId || row.stateCountyId;
        subsetValues[key] = getSingleValue(config, row);
        cacheDownloadValue(key, `Percent ${subsetTitle}`, subsetValues[key], row);
      });

      const [min, max] = getMinMax(subsetValues);

      Object.entries(subsetValues).forEach(([tractId, value]) => {
        map.fills.push(String(tractId));
        map.fills.push('rgb(255, 145, 38)');
        map.opacities.push(String(tractId));
        map.opacities.push(opacityBuckets[getValueBucket(value, config.cutoff1 || min, max, opacityBuckets.length)]);
      });
    } else {
      const values = {} as Record<string, IDemographicValue>;
      rows.forEach((row) => {
        const key = row.tractId || row.stateCountyId;
        const demographicValue = getValue(config, row);
        values[key] = demographicValue;
        cacheValue(demographicValue, row);
      });

      Object.entries(values).forEach(([tractId, value]) => {
        map.fills.push(String(tractId));
        map.fills.push(colorsHex[highestKey(value)]);
        map.opacities.push(String(tractId));
        map.opacities.push(0.85);
        // map.opacities.push(opacityBuckets[getValueBucket(value, min, max, opacityBuckets.length)]);
      });
    }

    return map;
  },

  // For 2D cases, we only ask the dataset to identify each value as being in one of three buckets, L, M, or H
  getDoubleSetBuckets: (config: IDatasetConfig, rows: ITractDataValue[]) => {
    const map = {values: {}} as ITract2DBucketMap;

    // We aren't allowed to have 'all' selected in 2D mode so we're really just looking for one simple field
    const allValues = getAllValues(config, rows, getBipoc, '% BIPOC');

    const subsetValues = {} as Record<string, number>;
    const [subset] = config.subsets;
    if (subset && subset !== 'all') {
      const subsetTitle = RacialComposition.subsets?.find((s) => s.id === subset)?.title || '';
      console.log('returning single set', subsetTitle);
      rows.forEach((row) => {
        const key = row.tractId || row.stateCountyId;
        subsetValues[key] = getSingleValue(config, row);
        cacheDownloadValue(key, `Percent ${subsetTitle}`, subsetValues[key], row);
      });

      Object.entries(subsetValues).forEach(([tractId, value]) => {
        const {lCutoff, hCutoff} = getCutoffs(subsetValues);
        map.values[tractId] = getBucketPosition(value, lCutoff, hCutoff);
      });
    } else {
      Object.entries(allValues).forEach(([tractId, value]) => {
        const {lCutoff, hCutoff} = getCutoffs(allValues);
        map.values[tractId] = getBucketPosition(value, lCutoff, hCutoff);
      });

      const values = {} as Record<string, IDemographicValue>;
      rows.forEach((row) => {
        const key = row.tractId || row.stateCountyId;

        const demographicValue = getValue(config, row);

        values[key] = demographicValue;

        cacheValue(demographicValue, row);
      });
    }

    return map;
  },
};
