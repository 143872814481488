import {FC, useState} from 'react';
import {FieldArray, Form, Formik} from 'formik';
import styled from 'styled-components';
import * as yup from 'yup';
import {PencilIcon} from '@heroicons/react/outline';
import {PlusIcon} from '@heroicons/react/solid';
import {Modal as ModalComponent} from './Modal';
import {StandardButton} from '.';
import {DropdownOption} from './Dropdown';
import {EquityIndex} from '../../Managers/Datasets';
import {ArrayFieldInput} from './ArrayFieldInput';
import {useUpdateMyProfile} from '../../API';
import {byId} from '../../Views/Map/Filters/SubsetControls/Utils';
import {useMixpanel} from '../../Managers/Mixpanel/useMixpanel';
import {Loader} from './Loading';
import {useAppDispatch, useAppSelector} from '../../store';
import {setDataset1} from '../../Redux/Slices/User';

const schema = yup.object().shape({
  indicators: yup.array().of(yup.string().nullable().required('Required')).required('Indicator is required'),
});

const Button = styled(StandardButton)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
`;

const Container = styled.div`
  gap: 1rem;
`;

const emptyOption = {label: '', value: null};

const getRemainingOptions = ({options = [], values = []}: {options: DropdownOption[]; values: string[]}) =>
  options.filter((option) => !values.includes(option.value || ''));

export interface IIndicatorPrefs {
  indicators?: string[];
  minimum?: number;
  onSubmit?: (values: string[]) => Promise<void | null>;
}

export const IndicatorPrefs = ({indicators = [], minimum = 2, onSubmit = async () => null}: IIndicatorPrefs) => {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const {mixpanel} = useMixpanel();

  const openModal = () => {
    mixpanel.track('Configure Indicators');
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (values: any = {}) => {
    try {
      mixpanel.track('Configuration Saved');
      setSubmitting(true);
      closeModal();
      await onSubmit(values.indicators);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  for (let i = 0; i < minimum; i++) {
    if (indicators?.length < minimum) {
      indicators.push('');
    }
  }

  const options = EquityIndex?.subsets?.sort(byId)?.map((item) => ({label: item.title, value: item.id})) || [];

  const getValueOption = (value: string) => {
    return options?.find((option) => option.value === value) || emptyOption;
  };

  return (
    <>
      {isSubmitting && <Loader />}
      <Button onClick={openModal} outline style={{justifyContent: 'center'}}>
        <PencilIcon style={{width: 24}} /> Configure Indicators
      </Button>

      <Formik enableReinitialize initialValues={{indicators}} onSubmit={handleSubmit} validationSchema={schema}>
        {({dirty, errors, handleReset, setFieldValue, submitForm, values}) => (
          <Form>
            <ModalComponent
              button1Action={() => {
                handleReset();
                closeModal();
              }}
              button1Outline={true}
              button1Text="Cancel"
              button2Action={submitForm}
              button2Disabled={!dirty || Boolean(Object.keys(errors).length)}
              button2Outline={false}
              button2Text="Confirm"
              isOpen={showModal}
              title="Configure Indicators">
              <FieldArray name={'indicators'}>
                {(arrayHelpers) => {
                  return (
                    <Container>
                      <div>
                        {values?.indicators?.map((value, index) => (
                          <ArrayFieldInput
                            disabledDelete={values?.indicators?.length <= minimum}
                            error={errors?.indicators?.[index]}
                            index={index}
                            key={index}
                            onChange={(value) => setFieldValue(`indicators.${index}`, value)}
                            onDelete={() => {
                              arrayHelpers.remove(index);
                            }}
                            options={getRemainingOptions({options, values: values?.indicators})}
                            value={getValueOption(value)}
                          />
                        ))}
                      </div>
                      <Button
                        disabled={
                          values?.indicators?.includes('') || !Boolean(getRemainingOptions({options, values: values?.indicators})?.length)
                        }
                        type="button"
                        onClick={() => arrayHelpers.push('')}
                        outline
                        style={{marginBottom: '3.5rem', justifyContent: 'center'}}>
                        <PlusIcon style={{width: 24}} />
                        Add Indicator
                      </Button>
                    </Container>
                  );
                }}
              </FieldArray>
            </ModalComponent>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const IndicatorPicker: FC<{indicators: string[]}> = ({indicators}) => {
  const updateMyProfile = useUpdateMyProfile();
  const dispatch = useAppDispatch();
  const dataset1 = useAppSelector((state) => state.user.dataset1);

  const handleSubmit = async (values: string[] = []) => {
    try {
      const indicators: Record<string, number> = {};
      const tempWeights = [...dataset1.equityIndexWeights];
      EquityIndex?.subsets?.forEach((subset, i) => {
        if (!values.includes(subset.id)) {
          tempWeights[i] = 0;
        } else {
          if (!dataset1.equityIndexWeights[i]) {
            const tempWeights = [...dataset1.equityIndexWeights];
            tempWeights[i] = 0;
          }
          indicators[subset.id] = dataset1.equityIndexWeights[i] || 1;
        }
      });
      dispatch(
        setDataset1({
          equityIndexWeights: tempWeights,
        }),
      );
      await updateMyProfile.mutateAsync({user_options: JSON.stringify({indicators})});
    } catch (error) {
      console.log(error);
    }
  };

  return <IndicatorPrefs indicators={indicators} onSubmit={handleSubmit} />;
};
