import React, {FC} from 'react';
import {OneDimensionDemographicLegend} from './OneDimensionDemographicLegend';
import {OneDimensionStandardLegend} from './OneDimensionStandardLegend';
import {AllDatasets} from '../../../Managers/MapManager';
import {TwoDimensionLegend} from './TwoDimensionLegend';
import {useAppSelector} from '../../../store';

/**
 * Hide the legend until a dataset is selected. Then show the 1-standard, 1-demographic, or 2-dimension legends as needed.
 */
export const LegendContainer: FC = () => {
  const {dataset1, dataset2} = useAppSelector((state) => state.user);
  const ds1 = AllDatasets.find((ds) => ds.id === dataset1.id);
  const ds2 = AllDatasets.find((ds) => ds.id === dataset2.id);

  if (!ds1) {
    return <></>;
  }

  if (ds2) {
    return <TwoDimensionLegend />;
  }

  return ds1.id === 'racialComposition' && dataset1.subsets?.[0] === 'all' ? (
    <OneDimensionDemographicLegend />
  ) : (
    <OneDimensionStandardLegend />
  );
};
