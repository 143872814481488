import {FC} from 'react';
import {IOrganization, useGetMyOrganization, useSubscriptionPricing} from '../../API';
import {FullPageLoader} from '../../Components';
import {OrganizationHeader} from '../../Components/Molecules/OrganizationHeader';
import {AdminPage, AdminPageWrapper} from '../Admin/Layout';
import {Header} from '../Map/Header';
import {OrganizationLocationsPanels} from '../Onboarding/OrganizationLocationsPanels';
import {AdminMenu} from './AdminMenu';
import {useMyProfile} from '../../API/Queries/useMyProfile';

export const MyLocations: FC = () => {
  const {data: myProfile} = useMyProfile();
  const {data: organization, isFetching} = useGetMyOrganization(!!myProfile?.user?.organizationId);

  const {data: subscriptionPricing, isLoading: isLoadingPricing} = useSubscriptionPricing();
  const subscriptionTypes = subscriptionPricing?.subscriptionTypes;

  const handleSubmitLocations = async (organization: IOrganization) => {};

  return (
    <AdminPageWrapper>
      {(isFetching || isLoadingPricing) && <FullPageLoader />}
      <AdminMenu />
      <Header />
      <AdminPage style={{maxWidth: '100%'}}>
        <OrganizationHeader organization={organization as IOrganization} title="My Locations" />
        <div style={{display: 'flex', flexDirection: 'row', gap: 24}}>
          <OrganizationLocationsPanels
            onSubmitLocations={handleSubmitLocations}
            organization={organization as IOrganization}
            hideTitle
            updateSubscriptionOnly
            readOnly={isFetching || isLoadingPricing || organization?.subscription_type === subscriptionTypes?.local?.value}
          />
        </div>
      </AdminPage>
    </AdminPageWrapper>
  );
};
