// This is a relatively small file to compile into the client code, and saves a server lookup
module.exports = [
  ['Alabama', 'AL', '01', '001', 'Autauga', 32.536382, -86.64449],
  ['Alabama', 'AL', '01', '003', 'Baldwin', 30.659218, -87.746067],
  ['Alabama', 'AL', '01', '005', 'Barbour', 31.87067, -85.405456],
  ['Alabama', 'AL', '01', '007', 'Bibb', 33.015893, -87.127148],
  ['Alabama', 'AL', '01', '009', 'Blount', 33.977448, -86.567246],
  ['Alabama', 'AL', '01', '011', 'Bullock', 32.101759, -85.717261],
  ['Alabama', 'AL', '01', '013', 'Butler', 31.751667, -86.681969],
  ['Alabama', 'AL', '01', '015', 'Calhoun', 33.771706, -85.822513],
  ['Alabama', 'AL', '01', '017', 'Chambers', 32.917943, -85.391812],
  ['Alabama', 'AL', '01', '019', 'Cherokee', 34.069515, -85.654242],
  ['Alabama', 'AL', '01', '021', 'Chilton', 32.85406, -86.726628],
  ['Alabama', 'AL', '01', '023', 'Choctaw', 31.991008, -88.248887],
  ['Alabama', 'AL', '01', '025', 'Clarke', 31.685521, -87.818624],
  ['Alabama', 'AL', '01', '027', 'Clay', 33.267809, -85.862051],
  ['Alabama', 'AL', '01', '029', 'Cleburne', 33.671981, -85.516109],
  ['Alabama', 'AL', '01', '031', 'Coffee', 31.402183, -85.989201],
  ['Alabama', 'AL', '01', '033', 'Colbert', 34.703112, -87.801457],
  ['Alabama', 'AL', '01', '035', 'Conecuh', 31.428293, -86.992029],
  ['Alabama', 'AL', '01', '037', 'Coosa', 32.931445, -86.243482],
  ['Alabama', 'AL', '01', '039', 'Covington', 31.243987, -86.448721],
  ['Alabama', 'AL', '01', '041', 'Crenshaw', 31.732826, -86.319222],
  ['Alabama', 'AL', '01', '043', 'Cullman', 34.131923, -86.869267],
  ['Alabama', 'AL', '01', '045', 'Dale', 31.430654, -85.609476],
  ['Alabama', 'AL', '01', '047', 'Dallas', 32.33354, -87.114356],
  ['Alabama', 'AL', '01', '049', 'DeKalb', 34.460929, -85.803992],
  ['Alabama', 'AL', '01', '051', 'Elmore', 32.597229, -86.142739],
  ['Alabama', 'AL', '01', '053', 'Escambia', 31.121748, -87.168429],
  ['Alabama', 'AL', '01', '055', 'Etowah', 34.047638, -86.03442],
  ['Alabama', 'AL', '01', '057', 'Fayette', 33.732249, -87.752049],
  ['Alabama', 'AL', '01', '059', 'Franklin', 34.441988, -87.842815],
  ['Alabama', 'AL', '01', '061', 'Geneva', 31.090866, -85.824346],
  ['Alabama', 'AL', '01', '063', 'Greene', 32.844497, -87.964201],
  ['Alabama', 'AL', '01', '065', 'Hale', 32.752796, -87.623061],
  ['Alabama', 'AL', '01', '067', 'Henry', 31.516978, -85.239971],
  ['Alabama', 'AL', '01', '069', 'Houston', 31.158193, -85.296398],
  ['Alabama', 'AL', '01', '071', 'Jackson', 34.763522, -85.9774],
  ['Alabama', 'AL', '01', '073', 'Jefferson', 33.553444, -86.896536],
  ['Alabama', 'AL', '01', '075', 'Lamar', 33.787085, -88.087431],
  ['Alabama', 'AL', '01', '077', 'Lauderdale', 34.904122, -87.650997],
  ['Alabama', 'AL', '01', '079', 'Lawrence', 34.529776, -87.321865],
  ['Alabama', 'AL', '01', '081', 'Lee', 32.604064, -85.353048],
  ['Alabama', 'AL', '01', '083', 'Limestone', 34.810239, -86.9814],
  ['Alabama', 'AL', '01', '085', 'Lowndes', 32.147888, -86.650586],
  ['Alabama', 'AL', '01', '087', 'Macon', 32.387027, -85.692887],
  ['Alabama', 'AL', '01', '089', 'Madison', 34.764238, -86.55108],
  ['Alabama', 'AL', '01', '091', 'Marengo', 32.247591, -87.791091],
  ['Alabama', 'AL', '01', '093', 'Marion', 34.138219, -87.881551],
  ['Alabama', 'AL', '01', '095', 'Marshall', 34.309564, -86.321668],
  ['Alabama', 'AL', '01', '097', 'Mobile', 30.684573, -88.196568],
  ['Alabama', 'AL', '01', '099', 'Monroe', 31.580332, -87.383266],
  ['Alabama', 'AL', '01', '101', 'Montgomery', 32.203651, -86.203831],
  ['Alabama', 'AL', '01', '103', 'Morgan', 34.454484, -86.846402],
  ['Alabama', 'AL', '01', '105', 'Perry', 32.639005, -87.293827],
  ['Alabama', 'AL', '01', '107', 'Pickens', 33.296809, -88.096878],
  ['Alabama', 'AL', '01', '109', 'Pike', 31.798726, -85.941997],
  ['Alabama', 'AL', '01', '111', 'Randolph', 33.296475, -85.464068],
  ['Alabama', 'AL', '01', '113', 'Russell', 32.289811, -85.18698],
  ['Alabama', 'AL', '01', '115', 'St. Clair', 33.712963, -86.315663],
  ['Alabama', 'AL', '01', '117', 'Shelby', 33.262937, -86.678104],
  ['Alabama', 'AL', '01', '119', 'Sumter', 32.597481, -88.200057],
  ['Alabama', 'AL', '01', '121', 'Talladega', 33.369277, -86.175805],
  ['Alabama', 'AL', '01', '123', 'Tallapoosa', 32.863369, -85.799553],
  ['Alabama', 'AL', '01', '125', 'Tuscaloosa', 33.290202, -87.52286],
  ['Alabama', 'AL', '01', '127', 'Walker', 33.791571, -87.301092],
  ['Alabama', 'AL', '01', '129', 'Washington', 31.406974, -88.202078],
  ['Alabama', 'AL', '01', '131', 'Wilcox', 31.99033, -87.302205],
  ['Alabama', 'AL', '01', '133', 'Winston', 34.155888, -87.364147],
  ['Alaska', 'AK', '02', '013', 'Aleutians East', 55.243722, -161.950749],
  ['Alaska', 'AK', '02', '016', 'Unorganized Borough."</ref>', 51.959447, -178.338813],
  ['Alaska', 'AK', '02', '020', 'Anchorage', 61.177549, -149.274354],
  ['Alaska', 'AK', '02', '050', 'Bethel', 60.928916, -160.15335],
  ['Alaska', 'AK', '02', '060', 'Bristol Bay', 58.731373, -156.986612],
  ['Alaska', 'AK', '02', '068', 'Denali', 63.682732, -150.026719],
  ['Alaska', 'AK', '02', '070', 'Dillingham', 59.824816, -158.602233],
  ['Alaska', 'AK', '02', '090', 'Fairbanks North Star', 64.690832, -146.599867],
  ['Alaska', 'AK', '02', '100', 'Haines', 59.099905, -135.578102],
  ['Alaska', 'AK', '02', '105', 'Hoonah-Angoon', 58.076434, -135.1851],
  ['Alaska', 'AK', '02', '110', 'Juneau', 58.3727, -134.178781],
  ['Alaska', 'AK', '02', '122', 'Kenai Peninsula', 60.366373, -152.321973],
  ['Alaska', 'AK', '02', '130', 'Ketchikan Gateway', 55.449938, -131.106685],
  ['Alaska', 'AK', '02', '150', 'Kodiak Island', 57.553611, -153.630911],
  ['Alaska', 'AK', '02', '164', 'Lake and Peninsula', 58.205065, -156.705188],
  ['Alaska', 'AK', '02', '170', 'Matanuska-Susitna', 62.182174, -149.407974],
  ['Alaska', 'AK', '02', '180', 'Nome', 64.783686, -164.188912],
  ['Alaska', 'AK', '02', '185', 'North Slope', 69.449343, -153.47283],
  ['Alaska', 'AK', '02', '188', 'Northwest Arctic', 67.005066, -160.021086],
  ['Alaska', 'AK', '02', '195', 'Petersburg', 56.639612, -133.527996],
  ['Alaska', 'AK', '02', '198', 'Prince of Wales-Hyder', 55.415683, -132.875734],
  ['Alaska', 'AK', '02', '220', 'Sitka', 57.142509, -135.332624],
  ['Alaska', 'AK', '02', '230', 'Skagway', 59.575097, -135.335418],
  ['Alaska', 'AK', '02', '240', 'Southeast Fairbanks', 63.864997, -143.218628],
  ['Alaska', 'AK', '02', '261', 'Valdez-Cordova', 61.34984, -145.023141],
  ['Alaska', 'AK', '02', '270', 'Wade Hampton', 62.283174, -163.19095],
  ['Alaska', 'AK', '02', '275', 'Wrangell', 56.279121, -132.040326],
  ['Alaska', 'AK', '02', '282', 'Yakutat', 59.999083, -140.239593],
  ['Alaska', 'AK', '02', '290', 'Yukon-Koyukuk', 65.376131, -151.576855],
  ['Arizona', 'AZ', '04', '001', 'Apache', 35.385845, -109.493747],
  ['Arizona', 'AZ', '04', '003', 'Cochise', 31.881793, -109.75412],
  ['Arizona', 'AZ', '04', '005', 'Coconino', 35.829692, -111.773728],
  ['Arizona', 'AZ', '04', '007', 'Gila', 33.789618, -110.81187],
  ['Arizona', 'AZ', '04', '009', 'Graham', 32.931828, -109.87831],
  ['Arizona', 'AZ', '04', '011', 'Greenlee', 33.238872, -109.242323],
  ['Arizona', 'AZ', '04', '012', 'La Paz', 33.727625, -114.038793],
  ['Arizona', 'AZ', '04', '013', 'Maricopa', 33.346541, -112.495534],
  ['Arizona', 'AZ', '04', '015', 'Mohave', 35.717705, -113.749689],
  ['Arizona', 'AZ', '04', '017', 'Navajo', 35.390934, -110.320908],
  ['Arizona', 'AZ', '04', '019', 'Pima', 32.128237, -111.783018],
  ['Arizona', 'AZ', '04', '021', 'Pinal', 32.91891, -111.367257],
  ['Arizona', 'AZ', '04', '023', 'Santa Cruz', 31.525904, -110.84519],
  ['Arizona', 'AZ', '04', '025', 'Yavapai', 34.630044, -112.573745],
  ['Arizona', 'AZ', '04', '027', 'Yuma', 32.773942, -113.910905],
  ['Arkansas', 'AR', '05', '001', 'Arkansas', 34.289574, -91.376547],
  ['Arkansas', 'AR', '05', '003', 'Ashley', 33.190835, -91.772267],
  ['Arkansas', 'AR', '05', '005', 'Baxter', 36.28071, -92.330438],
  ['Arkansas', 'AR', '05', '007', 'Benton', 36.337825, -94.256187],
  ['Arkansas', 'AR', '05', '009', 'Boone', 36.304308, -93.079204],
  ['Arkansas', 'AR', '05', '011', 'Bradley', 33.463819, -92.168164],
  ['Arkansas', 'AR', '05', '013', 'Calhoun', 33.56046, -92.513879],
  ['Arkansas', 'AR', '05', '015', 'Carroll', 36.337774, -93.541663],
  ['Arkansas', 'AR', '05', '017', 'Chicot', 33.267139, -91.297158],
  ['Arkansas', 'AR', '05', '019', 'Clark', 34.053312, -93.176205],
  ['Arkansas', 'AR', '05', '021', 'Clay', 36.367302, -90.418704],
  ['Arkansas', 'AR', '05', '023', 'Cleburne', 35.566288, -92.059974],
  ['Arkansas', 'AR', '05', '025', 'Cleveland', 33.893201, -92.188714],
  ['Arkansas', 'AR', '05', '027', 'Columbia', 33.21507, -93.226901],
  ['Arkansas', 'AR', '05', '029', 'Conway', 35.265702, -92.689248],
  ['Arkansas', 'AR', '05', '031', 'Craighead', 35.828268, -90.630411],
  ['Arkansas', 'AR', '05', '033', 'Crawford', 35.583041, -94.236224],
  ['Arkansas', 'AR', '05', '035', 'Crittenden', 35.211878, -90.315331],
  ['Arkansas', 'AR', '05', '037', 'Cross', 35.291259, -90.773894],
  ['Arkansas', 'AR', '05', '039', 'Dallas', 33.967823, -92.653999],
  ['Arkansas', 'AR', '05', '041', 'Desha', 33.828748, -91.244427],
  ['Arkansas', 'AR', '05', '043', 'Drew', 33.587242, -91.722778],
  ['Arkansas', 'AR', '05', '045', 'Faulkner', 35.146356, -92.324654],
  ['Arkansas', 'AR', '05', '047', 'Franklin', 35.508573, -93.887672],
  ['Arkansas', 'AR', '05', '049', 'Fulton', 36.383443, -91.819239],
  ['Arkansas', 'AR', '05', '051', 'Garland', 34.578861, -93.146915],
  ['Arkansas', 'AR', '05', '053', 'Grant', 34.288063, -92.423984],
  ['Arkansas', 'AR', '05', '055', 'Greene', 36.119922, -90.565241],
  ['Arkansas', 'AR', '05', '057', 'Hempstead', 33.728611, -93.665809],
  ['Arkansas', 'AR', '05', '059', 'Hot Spring', 34.315177, -92.944147],
  ['Arkansas', 'AR', '05', '061', 'Howard', 34.083179, -93.990988],
  ['Arkansas', 'AR', '05', '063', 'Independence', 35.737499, -91.559942],
  ['Arkansas', 'AR', '05', '065', 'Izard', 36.094879, -91.913625],
  ['Arkansas', 'AR', '05', '067', 'Jackson', 35.596605, -91.223178],
  ['Arkansas', 'AR', '05', '069', 'Jefferson', 34.277696, -91.930701],
  ['Arkansas', 'AR', '05', '071', 'Johnson', 35.573359, -93.466322],
  ['Arkansas', 'AR', '05', '073', 'Lafayette', 33.24066, -93.611643],
  ['Arkansas', 'AR', '05', '075', 'Lawrence', 36.041098, -91.101153],
  ['Arkansas', 'AR', '05', '077', 'Lee', 34.77975, -90.779288],
  ['Arkansas', 'AR', '05', '079', 'Lincoln', 33.957665, -91.727624],
  ['Arkansas', 'AR', '05', '081', 'Little River', 33.699497, -94.229774],
  ['Arkansas', 'AR', '05', '083', 'Logan', 35.21855, -93.720563],
  ['Arkansas', 'AR', '05', '085', 'Lonoke', 34.755114, -91.894132],
  ['Arkansas', 'AR', '05', '087', 'Madison', 36.012545, -93.724053],
  ['Arkansas', 'AR', '05', '089', 'Marion', 36.266656, -92.678588],
  ['Arkansas', 'AR', '05', '091', 'Miller', 33.305505, -93.901509],
  ['Arkansas', 'AR', '05', '093', 'Mississippi', 35.766943, -90.052209],
  ['Arkansas', 'AR', '05', '095', 'Monroe', 34.679513, -91.203314],
  ['Arkansas', 'AR', '05', '097', 'Montgomery', 34.545652, -93.664147],
  ['Arkansas', 'AR', '05', '099', 'Nevada', 33.666699, -93.305072],
  ['Arkansas', 'AR', '05', '101', 'Newton', 35.910063, -93.215084],
  ['Arkansas', 'AR', '05', '103', 'Ouachita', 33.591158, -92.878418],
  ['Arkansas', 'AR', '05', '105', 'Perry', 34.946363, -92.926877],
  ['Arkansas', 'AR', '05', '107', 'Phillips', 34.425842, -90.848386],
  ['Arkansas', 'AR', '05', '109', 'Pike', 34.158191, -93.658659],
  ['Arkansas', 'AR', '05', '111', 'Poinsett', 35.568981, -90.680595],
  ['Arkansas', 'AR', '05', '113', 'Polk', 34.490915, -94.230884],
  ['Arkansas', 'AR', '05', '115', 'Pope', 35.455297, -93.031535],
  ['Arkansas', 'AR', '05', '117', 'Prairie', 34.828225, -91.5572],
  ['Arkansas', 'AR', '05', '119', 'Pulaski', 34.773988, -92.316515],
  ['Arkansas', 'AR', '05', '121', 'Randolph', 36.341299, -91.028441],
  ['Arkansas', 'AR', '05', '123', 'St. Francis', 35.014438, -90.742199],
  ['Arkansas', 'AR', '05', '125', 'Saline', 34.648525, -92.674463],
  ['Arkansas', 'AR', '05', '127', 'Scott', 34.858869, -94.063641],
  ['Arkansas', 'AR', '05', '129', 'Searcy', 35.90966, -92.699351],
  ['Arkansas', 'AR', '05', '131', 'Sebastian', 35.196981, -94.274989],
  ['Arkansas', 'AR', '05', '133', 'Sevier', 33.994608, -94.24329],
  ['Arkansas', 'AR', '05', '135', 'Sharp', 36.173399, -91.471069],
  ['Arkansas', 'AR', '05', '137', 'Stone', 35.856989, -92.140494],
  ['Arkansas', 'AR', '05', '139', 'Union', 33.168219, -92.598145],
  ['Arkansas', 'AR', '05', '141', 'Van Buren', 35.582959, -92.515977],
  ['Arkansas', 'AR', '05', '143', 'Washington', 35.971209, -94.218417],
  ['Arkansas', 'AR', '05', '145', 'White', 35.254722, -91.753158],
  ['Arkansas', 'AR', '05', '147', 'Woodruff', 35.189071, -91.244418],
  ['Arkansas', 'AR', '05', '149', 'Yell', 34.997713, -93.408303],
  ['California', 'CA', '06', '001', 'Alameda', 37.648081, -121.913304],
  ['California', 'CA', '06', '003', 'Alpine', 38.61761, -119.798999],
  ['California', 'CA', '06', '005', 'Amador', 38.44355, -120.653856],
  ['California', 'CA', '06', '007', 'Butte', 39.665959, -121.601919],
  ['California', 'CA', '06', '009', 'Calaveras', 38.187844, -120.555115],
  ['California', 'CA', '06', '011', 'Colusa', 39.177739, -122.237563],
  ['California', 'CA', '06', '013', 'Contra Costa', 37.919479, -121.951543],
  ['California', 'CA', '06', '015', 'Del Norte', 41.749903, -123.980998],
  ['California', 'CA', '06', '017', 'El Dorado', 38.785532, -120.534398],
  ['California', 'CA', '06', '019', 'Fresno', 36.761006, -119.655019],
  ['California', 'CA', '06', '021', 'Glenn', 39.602546, -122.4017],
  ['California', 'CA', '06', '023', 'Humboldt', 40.706673, -123.925818],
  ['California', 'CA', '06', '025', 'Imperial', 33.040816, -115.355395],
  ['California', 'CA', '06', '027', 'Inyo', 36.561977, -117.403927],
  ['California', 'CA', '06', '029', 'Kern', 35.346629, -118.729506],
  ['California', 'CA', '06', '031', 'Kings', 36.072478, -119.81553],
  ['California', 'CA', '06', '033', 'Lake', 39.094802, -122.746757],
  ['California', 'CA', '06', '035', 'Lassen', 40.721089, -120.629931],
  ['California', 'CA', '06', '037', 'Los Angeles', 34.196398, -118.261862],
  ['California', 'CA', '06', '039', 'Madera', 37.210039, -119.749852],
  ['California', 'CA', '06', '041', 'Marin', 38.051817, -122.745974],
  ['California', 'CA', '06', '043', 'Mariposa', 37.570034, -119.91286],
  ['California', 'CA', '06', '045', 'Mendocino', 39.432388, -123.442881],
  ['California', 'CA', '06', '047', 'Merced', 37.194806, -120.722802],
  ['California', 'CA', '06', '049', 'Modoc', 41.592919, -120.71837],
  ['California', 'CA', '06', '051', 'Mono', 37.915836, -118.875167],
  ['California', 'CA', '06', '053', 'Monterey', 36.240107, -121.315573],
  ['California', 'CA', '06', '055', 'Napa', 38.507351, -122.325995],
  ['California', 'CA', '06', '057', 'Nevada', 39.295191, -120.773446],
  ['California', 'CA', '06', '059', 'Orange', 33.675687, -117.777207],
  ['California', 'CA', '06', '061', 'Placer', 39.062032, -120.722718],
  ['California', 'CA', '06', '063', 'Plumas', 39.99517, -120.829516],
  ['California', 'CA', '06', '065', 'Riverside', 33.729828, -116.002239],
  ['California', 'CA', '06', '067', 'Sacramento', 38.450011, -121.340441],
  ['California', 'CA', '06', '069', 'San Benito', 36.610702, -121.085296],
  ['California', 'CA', '06', '071', 'San Bernardino', 34.85722, -116.181197],
  ['California', 'CA', '06', '073', 'San Diego', 33.023604, -116.776117],
  ['California', 'CA', '06', '075', 'San Francisco', 37.727239, -123.032229],
  ['California', 'CA', '06', '077', 'San Joaquin', 37.935034, -121.272237],
  ['California', 'CA', '06', '079', 'San Luis Obispo', 35.385227, -120.44754],
  ['California', 'CA', '06', '081', 'San Mateo', 37.414664, -122.371542],
  ['California', 'CA', '06', '083', 'Santa Barbara', 34.537378, -120.038485],
  ['California', 'CA', '06', '085', 'Santa Clara', 37.220777, -121.690622],
  ['California', 'CA', '06', '087', 'Santa Cruz', 37.012488, -122.007205],
  ['California', 'CA', '06', '089', 'Shasta', 40.760522, -122.04355],
  ['California', 'CA', '06', '091', 'Sierra', 39.576925, -120.521993],
  ['California', 'CA', '06', '093', 'Siskiyou', 41.587986, -122.533287],
  ['California', 'CA', '06', '095', 'Solano', 38.267226, -121.939594],
  ['California', 'CA', '06', '097', 'Sonoma', 38.532574, -122.945194],
  ['California', 'CA', '06', '099', 'Stanislaus', 37.562384, -121.002656],
  ['California', 'CA', '06', '101', 'Sutter', 39.035257, -121.702758],
  ['California', 'CA', '06', '103', 'Tehama', 40.126156, -122.232276],
  ['California', 'CA', '06', '105', 'Trinity', 40.647724, -123.114404],
  ['California', 'CA', '06', '107', 'Tulare', 36.230453, -118.780542],
  ['California', 'CA', '06', '109', 'Tuolumne', 38.021451, -119.964708],
  ['California', 'CA', '06', '111', 'Ventura', 34.358742, -119.133143],
  ['California', 'CA', '06', '113', 'Yolo', 38.679268, -121.903178],
  ['California', 'CA', '06', '115', 'Yuba', 39.270026, -121.34428],
  ['Colorado', 'CO', '08', '001', 'Adams', 39.874325, -104.331872],
  ['Colorado', 'CO', '08', '003', 'Alamosa', 37.568442, -105.788041],
  ['Colorado', 'CO', '08', '005', 'Arapahoe', 39.644632, -104.331733],
  ['Colorado', 'CO', '08', '007', 'Archuleta', 37.202395, -107.050863],
  ['Colorado', 'CO', '08', '009', 'Baca', 37.303144, -102.535457],
  ['Colorado', 'CO', '08', '011', 'Bent', 37.931891, -103.077584],
  ['Colorado', 'CO', '08', '013', 'Boulder', 40.094826, -105.398382],
  ['Colorado', 'CO', '08', '014', 'Broomfield', 39.953383, -105.052125],
  ['Colorado', 'CO', '08', '015', 'Chaffee', 38.738246, -106.316972],
  ['Colorado', 'CO', '08', '017', 'Cheyenne', 38.835387, -102.604585],
  ['Colorado', 'CO', '08', '019', 'Clear Creek', 39.689403, -105.670791],
  ['Colorado', 'CO', '08', '021', 'Conejos', 37.213407, -106.176447],
  ['Colorado', 'CO', '08', '023', 'Costilla', 37.277547, -105.42894],
  ['Colorado', 'CO', '08', '025', 'Crowley', 38.321956, -103.787562],
  ['Colorado', 'CO', '08', '027', 'Custer', 38.101994, -105.373515],
  ['Colorado', 'CO', '08', '029', 'Delta', 38.861756, -107.864757],
  ['Colorado', 'CO', '08', '031', 'Denver', 39.761849, -104.880625],
  ['Colorado', 'CO', '08', '033', 'Dolores', 37.747602, -108.530383],
  ['Colorado', 'CO', '08', '035', 'Douglas', 39.326435, -104.926199],
  ['Colorado', 'CO', '08', '037', 'Eagle', 39.630638, -106.692944],
  ['Colorado', 'CO', '08', '039', 'Elbert', 39.310817, -104.117928],
  ['Colorado', 'CO', '08', '041', 'El Paso', 38.827383, -104.527472],
  ['Colorado', 'CO', '08', '043', 'Fremont', 38.455658, -105.421438],
  ['Colorado', 'CO', '08', '045', 'Garfield', 39.599352, -107.90978],
  ['Colorado', 'CO', '08', '047', 'Gilpin', 39.861082, -105.528947],
  ['Colorado', 'CO', '08', '049', 'Grand', 40.123289, -106.095876],
  ['Colorado', 'CO', '08', '051', 'Gunnison', 38.669679, -107.078108],
  ['Colorado', 'CO', '08', '053', 'Hinsdale', 37.811625, -107.383405],
  ['Colorado', 'CO', '08', '055', 'Huerfano', 37.687815, -104.959928],
  ['Colorado', 'CO', '08', '057', 'Jackson', 40.663432, -106.329248],
  ['Colorado', 'CO', '08', '059', 'Jefferson', 39.58646, -105.245601],
  ['Colorado', 'CO', '08', '061', 'Kiowa', 38.388466, -102.75621],
  ['Colorado', 'CO', '08', '063', 'Kit Carson', 39.30534, -102.603023],
  ['Colorado', 'CO', '08', '065', 'Lake', 39.204316, -106.349696],
  ['Colorado', 'CO', '08', '067', 'La Plata', 37.287367, -107.839718],
  ['Colorado', 'CO', '08', '069', 'Larimer', 40.663091, -105.482131],
  ['Colorado', 'CO', '08', '071', 'Las Animas', 37.318831, -104.04411],
  ['Colorado', 'CO', '08', '073', 'Lincoln', 38.99374, -103.507555],
  ['Colorado', 'CO', '08', '075', 'Logan', 40.728091, -103.090464],
  ['Colorado', 'CO', '08', '077', 'Mesa', 39.019492, -108.461837],
  ['Colorado', 'CO', '08', '079', 'Mineral', 37.651478, -106.9323],
  ['Colorado', 'CO', '08', '081', 'Moffat', 40.573984, -108.204521],
  ['Colorado', 'CO', '08', '083', 'Montezuma', 37.338025, -108.595786],
  ['Colorado', 'CO', '08', '085', 'Montrose', 38.413427, -108.263042],
  ['Colorado', 'CO', '08', '087', 'Morgan', 40.262354, -103.807092],
  ['Colorado', 'CO', '08', '089', 'Otero', 37.88417, -103.72126],
  ['Colorado', 'CO', '08', '091', 'Ouray', 38.1506, -107.767133],
  ['Colorado', 'CO', '08', '093', 'Park', 39.118914, -105.717648],
  ['Colorado', 'CO', '08', '095', 'Phillips', 40.594712, -102.345105],
  ['Colorado', 'CO', '08', '097', 'Pitkin', 39.217533, -106.915943],
  ['Colorado', 'CO', '08', '099', 'Prowers', 37.958181, -102.392161],
  ['Colorado', 'CO', '08', '101', 'Pueblo', 38.170658, -104.489893],
  ['Colorado', 'CO', '08', '103', 'Rio Blanco', 39.972606, -108.200685],
  ['Colorado', 'CO', '08', '105', 'Rio Grande', 37.485763, -106.453214],
  ['Colorado', 'CO', '08', '107', 'Routt', 40.48316, -106.985289],
  ['Colorado', 'CO', '08', '109', 'Saguache', 38.033952, -106.246675],
  ['Colorado', 'CO', '08', '111', 'San Juan', 37.781075, -107.670257],
  ['Colorado', 'CO', '08', '113', 'San Miguel', 38.009374, -108.427326],
  ['Colorado', 'CO', '08', '115', 'Sedgwick', 40.871568, -102.355358],
  ['Colorado', 'CO', '08', '117', 'Summit', 39.621023, -106.137555],
  ['Colorado', 'CO', '08', '119', 'Teller', 38.871994, -105.182552],
  ['Colorado', 'CO', '08', '121', 'Washington', 39.965413, -103.209605],
  ['Colorado', 'CO', '08', '123', 'Weld', 40.555794, -104.383649],
  ['Colorado', 'CO', '08', '125', 'Yuma', 40.000631, -102.422649],
  ['Connecticut', 'CT', '09', '001', 'Fairfield', 41.228103, -73.366757],
  ['Connecticut', 'CT', '09', '003', 'Hartford', 41.806053, -72.732916],
  ['Connecticut', 'CT', '09', '005', 'Litchfield', 41.791897, -73.235428],
  ['Connecticut', 'CT', '09', '007', 'Middlesex', 41.434525, -72.524227],
  ['Connecticut', 'CT', '09', '009', 'New Haven', 41.349717, -72.900204],
  ['Connecticut', 'CT', '09', '011', 'New London', 41.47863, -72.103452],
  ['Connecticut', 'CT', '09', '013', 'Tolland', 41.858076, -72.340977],
  ['Connecticut', 'CT', '09', '015', 'Windham', 41.824999, -71.990702],
  ['Delaware', 'DE', '10', '001', 'Kent', 39.097088, -75.502982],
  ['Delaware', 'DE', '10', '003', 'New Castle', 39.575915, -75.644132],
  ['Delaware', 'DE', '10', '005', 'Sussex', 38.677511, -75.335495],
  ['District of Columbia', 'DC', '11', '001', 'District of Columbia', 38.904149, -77.017094],
  ['Florida', 'FL', '12', '001', 'Alachua', 29.67574, -82.357221],
  ['Florida', 'FL', '12', '003', 'Baker', 30.324442, -82.302284],
  ['Florida', 'FL', '12', '005', 'Bay', 30.237563, -85.631348],
  ['Florida', 'FL', '12', '007', 'Bradford', 29.946934, -82.166796],
  ['Florida', 'FL', '12', '009', 'Brevard', 28.298276, -80.700384],
  ['Florida', 'FL', '12', '011', 'Broward', 26.19352, -80.476658],
  ['Florida', 'FL', '12', '013', 'Calhoun', 30.388801, -85.197916],
  ['Florida', 'FL', '12', '015', 'Charlotte', 26.868826, -81.940858],
  ['Florida', 'FL', '12', '017', 'Citrus', 28.843628, -82.524796],
  ['Florida', 'FL', '12', '019', 'Clay', 29.987116, -81.858147],
  ['Florida', 'FL', '12', '021', 'Collier', 26.118713, -81.400884],
  ['Florida', 'FL', '12', '023', 'Columbia', 30.221305, -82.623127],
  ['Florida', 'FL', '12', '027', 'DeSoto', 27.190581, -81.806253],
  ['Florida', 'FL', '12', '029', 'Dixie', 29.580899, -83.195666],
  ['Florida', 'FL', '12', '031', 'Duval', 30.335245, -81.648113],
  ['Florida', 'FL', '12', '033', 'Escambia', 30.611664, -87.33904],
  ['Florida', 'FL', '12', '035', 'Flagler', 29.474894, -81.286362],
  ['Florida', 'FL', '12', '037', 'Franklin', 29.810176, -84.799174],
  ['Florida', 'FL', '12', '039', 'Gadsden', 30.57917, -84.612783],
  ['Florida', 'FL', '12', '041', 'Gilchrist', 29.723456, -82.795801],
  ['Florida', 'FL', '12', '043', 'Glades', 26.95481, -81.19082],
  ['Florida', 'FL', '12', '045', 'Gulf', 29.907257, -85.256537],
  ['Florida', 'FL', '12', '047', 'Hamilton', 30.491102, -82.951049],
  ['Florida', 'FL', '12', '049', 'Hardee', 27.492846, -81.82158],
  ['Florida', 'FL', '12', '051', 'Hendry', 26.53934, -81.151584],
  ['Florida', 'FL', '12', '053', 'Hernando', 28.567911, -82.464835],
  ['Florida', 'FL', '12', '055', 'Highlands', 27.342627, -81.340921],
  ['Florida', 'FL', '12', '057', 'Hillsborough', 27.90659, -82.349568],
  ['Florida', 'FL', '12', '059', 'Holmes', 30.866222, -85.812959],
  ['Florida', 'FL', '12', '061', 'Indian River', 27.700638, -80.574803],
  ['Florida', 'FL', '12', '063', 'Jackson', 30.787812, -85.210374],
  ['Florida', 'FL', '12', '065', 'Jefferson', 30.424558, -83.890859],
  ['Florida', 'FL', '12', '067', 'Lafayette', 29.990066, -83.17851],
  ['Florida', 'FL', '12', '069', 'Lake', 28.764113, -81.712282],
  ['Florida', 'FL', '12', '071', 'Lee', 26.552134, -81.89225],
  ['Florida', 'FL', '12', '073', 'Leon', 30.45931, -84.2778],
  ['Florida', 'FL', '12', '075', 'Levy', 29.284409, -82.783483],
  ['Florida', 'FL', '12', '077', 'Liberty', 30.259849, -84.868581],
  ['Florida', 'FL', '12', '079', 'Madison', 30.447228, -83.470437],
  ['Florida', 'FL', '12', '081', 'Manatee', 27.481386, -82.365784],
  ['Florida', 'FL', '12', '083', 'Marion', 29.202805, -82.0431],
  ['Florida', 'FL', '12', '085', 'Martin', 27.079954, -80.398211],
  ['Florida', 'FL', '12', '086', 'Miami-Dade', 25.610494, -80.499045],
  ['Florida', 'FL', '12', '087', 'Monroe', 25.601043, -81.206777],
  ['Florida', 'FL', '12', '089', 'Nassau', 30.605926, -81.764929],
  ['Florida', 'FL', '12', '091', 'Okaloosa', 30.665858, -86.594194],
  ['Florida', 'FL', '12', '093', 'Okeechobee', 27.385592, -80.887388],
  ['Florida', 'FL', '12', '095', 'Orange', 28.514435, -81.323295],
  ['Florida', 'FL', '12', '097', 'Osceola', 28.059027, -81.139312],
  ['Florida', 'FL', '12', '099', 'Palm Beach', 26.645763, -80.448673],
  ['Florida', 'FL', '12', '101', 'Pasco', 28.302024, -82.455707],
  ['Florida', 'FL', '12', '103', 'Pinellas', 27.903122, -82.739518],
  ['Florida', 'FL', '12', '105', 'Polk', 27.953115, -81.692783],
  ['Florida', 'FL', '12', '107', 'Putnam', 29.606006, -81.740894],
  ['Florida', 'FL', '12', '109', 'St. Johns', 29.890593, -81.383914],
  ['Florida', 'FL', '12', '111', 'St. Lucie', 27.380775, -80.443364],
  ['Florida', 'FL', '12', '113', 'Santa Rosa', 30.703633, -87.014255],
  ['Florida', 'FL', '12', '115', 'Sarasota', 27.184386, -82.365835],
  ['Florida', 'FL', '12', '117', 'Seminole', 28.690079, -81.13198],
  ['Florida', 'FL', '12', '119', 'Sumter', 28.714294, -82.074715],
  ['Florida', 'FL', '12', '121', 'Suwannee', 30.189244, -82.992754],
  ['Florida', 'FL', '12', '123', 'Taylor', 30.016943, -83.616417],
  ['Florida', 'FL', '12', '125', 'Union', 30.05428, -82.366918],
  ['Florida', 'FL', '12', '127', 'Volusia', 29.057617, -81.161813],
  ['Florida', 'FL', '12', '129', 'Wakulla', 30.140378, -84.375136],
  ['Florida', 'FL', '12', '131', 'Walton', 30.631211, -86.176614],
  ['Florida', 'FL', '12', '133', 'Washington', 30.602217, -85.662797],
  ['Georgia (U.S. state)', 'GA', '13', '001', 'Appling', 31.739712, -82.290103],
  ['Georgia (U.S. state)', 'GA', '13', '003', 'Atkinson', 31.300562, -82.883614],
  ['Georgia (U.S. state)', 'GA', '13', '005', 'Bacon', 31.563327, -82.387859],
  ['Georgia (U.S. state)', 'GA', '13', '007', 'Baker', 31.319622, -84.454857],
  ['Georgia (U.S. state)', 'GA', '13', '009', 'Baldwin', 33.059532, -83.255362],
  ['Georgia (U.S. state)', 'GA', '13', '011', 'Banks', 34.351922, -83.498441],
  ['Georgia (U.S. state)', 'GA', '13', '013', 'Barrow', 33.992009, -83.712303],
  ['Georgia (U.S. state)', 'GA', '13', '015', 'Bartow', 34.240918, -84.838188],
  ['Georgia (U.S. state)', 'GA', '13', '017', 'Ben Hill', 31.740776, -83.14719],
  ['Georgia (U.S. state)', 'GA', '13', '019', 'Berrien', 31.274308, -83.231906],
  ['Georgia (U.S. state)', 'GA', '13', '021', 'Bibb', 32.808844, -83.694193],
  ['Georgia (U.S. state)', 'GA', '13', '023', 'Bleckley', 32.435403, -83.331717],
  ['Georgia (U.S. state)', 'GA', '13', '025', 'Brantley', 31.197334, -81.982978],
  ['Georgia (U.S. state)', 'GA', '13', '027', 'Brooks', 30.833696, -83.588971],
  ['Georgia (U.S. state)', 'GA', '13', '029', 'Bryan', 32.017969, -81.438543],
  ['Georgia (U.S. state)', 'GA', '13', '031', 'Bulloch', 32.393408, -81.74381],
  ['Georgia (U.S. state)', 'GA', '13', '033', 'Burke', 33.063737, -82.00389],
  ['Georgia (U.S. state)', 'GA', '13', '035', 'Butts', 33.290355, -83.958221],
  ['Georgia (U.S. state)', 'GA', '13', '037', 'Calhoun', 31.521279, -84.62629],
  ['Georgia (U.S. state)', 'GA', '13', '039', 'Camden', 30.916358, -81.636516],
  ['Georgia (U.S. state)', 'GA', '13', '043', 'Candler', 32.403986, -82.071446],
  ['Georgia (U.S. state)', 'GA', '13', '045', 'Carroll', 33.582237, -85.080527],
  ['Georgia (U.S. state)', 'GA', '13', '047', 'Catoosa', 34.899393, -85.137353],
  ['Georgia (U.S. state)', 'GA', '13', '049', 'Charlton', 30.779904, -82.139644],
  ['Georgia (U.S. state)', 'GA', '13', '051', 'Chatham', 31.974756, -81.091768],
  ['Georgia (U.S. state)', 'GA', '13', '053', 'Chattahoochee', 32.347445, -84.788021],
  ['Georgia (U.S. state)', 'GA', '13', '055', 'Chattooga', 34.474179, -85.345289],
  ['Georgia (U.S. state)', 'GA', '13', '057', 'Cherokee', 34.244317, -84.475057],
  ['Georgia (U.S. state)', 'GA', '13', '059', 'Clarke', 33.952234, -83.36713],
  ['Georgia (U.S. state)', 'GA', '13', '061', 'Clay', 31.619831, -84.992583],
  ['Georgia (U.S. state)', 'GA', '13', '063', 'Clayton', 33.552242, -84.412977],
  ['Georgia (U.S. state)', 'GA', '13', '065', 'Clinch', 30.917653, -82.702614],
  ['Georgia (U.S. state)', 'GA', '13', '067', 'Cobb', 33.93994, -84.574166],
  ['Georgia (U.S. state)', 'GA', '13', '069', 'Coffee', 31.549245, -82.844938],
  ['Georgia (U.S. state)', 'GA', '13', '071', 'Colquitt', 31.189758, -83.769741],
  ['Georgia (U.S. state)', 'GA', '13', '073', 'Columbia', 33.550556, -82.251342],
  ['Georgia (U.S. state)', 'GA', '13', '075', 'Cook', 31.152935, -83.429552],
  ['Georgia (U.S. state)', 'GA', '13', '077', 'Coweta', 33.352897, -84.762138],
  ['Georgia (U.S. state)', 'GA', '13', '079', 'Crawford', 32.709446, -83.979182],
  ['Georgia (U.S. state)', 'GA', '13', '081', 'Crisp', 31.914753, -83.753338],
  ['Georgia (U.S. state)', 'GA', '13', '083', 'Dade', 34.852424, -85.506201],
  ['Georgia (U.S. state)', 'GA', '13', '085', 'Dawson', 34.442842, -84.173284],
  ['Georgia (U.S. state)', 'GA', '13', '087', 'Decatur', 30.877961, -84.577744],
  ['Georgia (U.S. state)', 'GA', '13', '089', 'DeKalb', 33.770661, -84.226343],
  ['Georgia (U.S. state)', 'GA', '13', '091', 'Dodge', 32.160656, -83.156818],
  ['Georgia (U.S. state)', 'GA', '13', '093', 'Dooly', 32.151995, -83.807167],
  ['Georgia (U.S. state)', 'GA', '13', '095', 'Dougherty', 31.535068, -84.214444],
  ['Georgia (U.S. state)', 'GA', '13', '097', 'Douglas', 33.699317, -84.765944],
  ['Georgia (U.S. state)', 'GA', '13', '099', 'Early', 31.324191, -84.906723],
  ['Georgia (U.S. state)', 'GA', '13', '101', 'Echols', 30.7085, -82.836132],
  ['Georgia (U.S. state)', 'GA', '13', '103', 'Effingham', 32.361711, -81.343337],
  ['Georgia (U.S. state)', 'GA', '13', '105', 'Elbert', 34.115017, -82.842057],
  ['Georgia (U.S. state)', 'GA', '13', '107', 'Emanuel', 32.5911, -82.299763],
  ['Georgia (U.S. state)', 'GA', '13', '109', 'Evans', 32.151902, -81.887618],
  ['Georgia (U.S. state)', 'GA', '13', '111', 'Fannin', 34.863837, -84.319287],
  ['Georgia (U.S. state)', 'GA', '13', '113', 'Fayette', 33.412717, -84.493941],
  ['Georgia (U.S. state)', 'GA', '13', '115', 'Floyd', 34.263677, -85.21373],
  ['Georgia (U.S. state)', 'GA', '13', '117', 'Forsyth', 34.225143, -84.127336],
  ['Georgia (U.S. state)', 'GA', '13', '119', 'Franklin', 34.375155, -83.227291],
  ['Georgia (U.S. state)', 'GA', '13', '121', 'Fulton', 33.790034, -84.468182],
  ['Georgia (U.S. state)', 'GA', '13', '123', 'Gilmer', 34.690541, -84.453984],
  ['Georgia (U.S. state)', 'GA', '13', '125', 'Glascock', 33.227491, -82.606913],
  ['Georgia (U.S. state)', 'GA', '13', '127', 'Glynn', 31.212747, -81.496517],
  ['Georgia (U.S. state)', 'GA', '13', '129', 'Gordon', 34.509667, -84.873862],
  ['Georgia (U.S. state)', 'GA', '13', '131', 'Grady', 30.875863, -84.244772],
  ['Georgia (U.S. state)', 'GA', '13', '133', 'Greene', 33.576836, -83.167103],
  ['Georgia (U.S. state)', 'GA', '13', '135', 'Gwinnett', 33.959101, -84.022938],
  ['Georgia (U.S. state)', 'GA', '13', '137', 'Habersham', 34.635108, -83.526406],
  ['Georgia (U.S. state)', 'GA', '13', '139', 'Hall', 34.317588, -83.818497],
  ['Georgia (U.S. state)', 'GA', '13', '141', 'Hancock', 33.26922, -83.000465],
  ['Georgia (U.S. state)', 'GA', '13', '143', 'Haralson', 33.795165, -85.220062],
  ['Georgia (U.S. state)', 'GA', '13', '145', 'Harris', 32.731549, -84.912432],
  ['Georgia (U.S. state)', 'GA', '13', '147', 'Hart', 34.348733, -82.96329],
  ['Georgia (U.S. state)', 'GA', '13', '149', 'Heard', 33.290968, -85.139594],
  ['Georgia (U.S. state)', 'GA', '13', '151', 'Henry', 33.452881, -84.15444],
  ['Georgia (U.S. state)', 'GA', '13', '153', 'Houston', 32.458381, -83.662856],
  ['Georgia (U.S. state)', 'GA', '13', '155', 'Irwin', 31.604306, -83.277037],
  ['Georgia (U.S. state)', 'GA', '13', '157', 'Jackson', 34.134157, -83.565133],
  ['Georgia (U.S. state)', 'GA', '13', '159', 'Jasper', 33.314905, -83.687892],
  ['Georgia (U.S. state)', 'GA', '13', '161', 'Jeff Davis', 31.811615, -82.636825],
  ['Georgia (U.S. state)', 'GA', '13', '163', 'Jefferson', 33.051874, -82.41905],
  ['Georgia (U.S. state)', 'GA', '13', '165', 'Jenkins', 32.794563, -81.971524],
  ['Georgia (U.S. state)', 'GA', '13', '167', 'Johnson', 32.689831, -82.661354],
  ['Georgia (U.S. state)', 'GA', '13', '169', 'Jones', 33.020226, -83.562339],
  ['Georgia (U.S. state)', 'GA', '13', '171', 'Lamar', 33.07446, -84.146721],
  ['Georgia (U.S. state)', 'GA', '13', '173', 'Lanier', 31.038197, -83.063164],
  ['Georgia (U.S. state)', 'GA', '13', '175', 'Laurens', 32.39322, -82.926317],
  ['Georgia (U.S. state)', 'GA', '13', '177', 'Lee', 31.818419, -84.146681],
  ['Georgia (U.S. state)', 'GA', '13', '179', 'Liberty', 31.807245, -81.457969],
  ['Georgia (U.S. state)', 'GA', '13', '181', 'Lincoln', 33.792151, -82.448299],
  ['Georgia (U.S. state)', 'GA', '13', '183', 'Long', 31.749563, -81.74287],
  ['Georgia (U.S. state)', 'GA', '13', '185', 'Lowndes', 30.83368, -83.268967],
  ['Georgia (U.S. state)', 'GA', '13', '187', 'Lumpkin', 34.568149, -83.998828],
  ['Georgia (U.S. state)', 'GA', '13', '189', 'McDuffie', 33.482464, -82.473188],
  ['Georgia (U.S. state)', 'GA', '13', '191', 'McIntosh', 31.482978, -81.370199],
  ['Georgia (U.S. state)', 'GA', '13', '193', 'Macon', 32.366216, -84.052162],
  ['Georgia (U.S. state)', 'GA', '13', '195', 'Madison', 34.128486, -83.203637],
  ['Georgia (U.S. state)', 'GA', '13', '197', 'Marion', 32.359538, -84.529561],
  ['Georgia (U.S. state)', 'GA', '13', '199', 'Meriwether', 33.03022, -84.663279],
  ['Georgia (U.S. state)', 'GA', '13', '201', 'Miller', 31.162908, -84.730386],
  ['Georgia (U.S. state)', 'GA', '13', '205', 'Mitchell', 31.22894, -84.192407],
  ['Georgia (U.S. state)', 'GA', '13', '207', 'Monroe', 33.017435, -83.922938],
  ['Georgia (U.S. state)', 'GA', '13', '209', 'Montgomery', 32.172108, -82.533349],
  ['Georgia (U.S. state)', 'GA', '13', '211', 'Morgan', 33.593732, -83.492324],
  ['Georgia (U.S. state)', 'GA', '13', '213', 'Murray', 34.797097, -84.73799],
  ['Georgia (U.S. state)', 'GA', '13', '215', 'Muscogee', 32.510197, -84.874946],
  ['Georgia (U.S. state)', 'GA', '13', '217', 'Newton', 33.544046, -83.855189],
  ['Georgia (U.S. state)', 'GA', '13', '219', 'Oconee', 33.834125, -83.437728],
  ['Georgia (U.S. state)', 'GA', '13', '221', 'Oglethorpe', 33.866806, -83.074081],
  ['Georgia (U.S. state)', 'GA', '13', '223', 'Paulding', 33.920903, -84.866979],
  ['Georgia (U.S. state)', 'GA', '13', '225', 'Peach', 32.571324, -83.831978],
  ['Georgia (U.S. state)', 'GA', '13', '227', 'Pickens', 34.456621, -84.490256],
  ['Georgia (U.S. state)', 'GA', '13', '229', 'Pierce', 31.353988, -82.210427],
  ['Georgia (U.S. state)', 'GA', '13', '231', 'Pike', 33.090769, -84.386627],
  ['Georgia (U.S. state)', 'GA', '13', '233', 'Polk', 33.995961, -85.186826],
  ['Georgia (U.S. state)', 'GA', '13', '235', 'Pulaski', 32.238794, -83.481855],
  ['Georgia (U.S. state)', 'GA', '13', '237', 'Putnam', 33.321061, -83.37179],
  ['Georgia (U.S. state)', 'GA', '13', '239', 'Quitman', 31.861487, -85.009317],
  ['Georgia (U.S. state)', 'GA', '13', '241', 'Rabun', 34.883026, -83.404735],
  ['Georgia (U.S. state)', 'GA', '13', '243', 'Randolph', 31.762651, -84.752311],
  ['Georgia (U.S. state)', 'GA', '13', '245', 'Richmond', 33.361487, -82.074998],
  ['Georgia (U.S. state)', 'GA', '13', '247', 'Rockdale', 33.652081, -84.02637],
  ['Georgia (U.S. state)', 'GA', '13', '249', 'Schley', 32.263441, -84.322724],
  ['Georgia (U.S. state)', 'GA', '13', '251', 'Screven', 32.744751, -81.617585],
  ['Georgia (U.S. state)', 'GA', '13', '253', 'Seminole', 30.933894, -84.867592],
  ['Georgia (U.S. state)', 'GA', '13', '255', 'Spalding', 33.262389, -84.286067],
  ['Georgia (U.S. state)', 'GA', '13', '257', 'Stephens', 34.552914, -83.290216],
  ['Georgia (U.S. state)', 'GA', '13', '259', 'Stewart', 32.073225, -84.834912],
  ['Georgia (U.S. state)', 'GA', '13', '261', 'Sumter', 32.042203, -84.204283],
  ['Georgia (U.S. state)', 'GA', '13', '263', 'Talbot', 32.704603, -84.530029],
  ['Georgia (U.S. state)', 'GA', '13', '265', 'Taliaferro', 33.559314, -82.875208],
  ['Georgia (U.S. state)', 'GA', '13', '267', 'Tattnall', 32.043768, -82.059208],
  ['Georgia (U.S. state)', 'GA', '13', '269', 'Taylor', 32.554667, -84.251426],
  ['Georgia (U.S. state)', 'GA', '13', '271', 'Telfair', 31.913639, -82.931062],
  ['Georgia (U.S. state)', 'GA', '13', '273', 'Terrell', 31.777191, -84.439446],
  ['Georgia (U.S. state)', 'GA', '13', '275', 'Thomas', 30.864616, -83.919783],
  ['Georgia (U.S. state)', 'GA', '13', '277', 'Tift', 31.457003, -83.525931],
  ['Georgia (U.S. state)', 'GA', '13', '279', 'Toombs', 32.126698, -82.332071],
  ['Georgia (U.S. state)', 'GA', '13', '281', 'Towns', 34.90265, -83.732158],
  ['Georgia (U.S. state)', 'GA', '13', '283', 'Treutlen', 32.409586, -82.570882],
  ['Georgia (U.S. state)', 'GA', '13', '285', 'Troup', 33.034482, -85.02836],
  ['Georgia (U.S. state)', 'GA', '13', '287', 'Turner', 31.718232, -83.628567],
  ['Georgia (U.S. state)', 'GA', '13', '289', 'Twiggs', 32.665847, -83.425879],
  ['Georgia (U.S. state)', 'GA', '13', '291', 'Union', 34.83377, -83.989573],
  ['Georgia (U.S. state)', 'GA', '13', '293', 'Upson', 32.881837, -84.292281],
  ['Georgia (U.S. state)', 'GA', '13', '295', 'Walker', 34.735827, -85.305385],
  ['Georgia (U.S. state)', 'GA', '13', '297', 'Walton', 33.782649, -83.734215],
  ['Georgia (U.S. state)', 'GA', '13', '299', 'Ware', 31.050881, -82.421507],
  ['Georgia (U.S. state)', 'GA', '13', '301', 'Warren', 33.419169, -82.688012],
  ['Georgia (U.S. state)', 'GA', '13', '303', 'Washington', 32.971848, -82.798112],
  ['Georgia (U.S. state)', 'GA', '13', '305', 'Wayne', 31.547845, -81.912376],
  ['Georgia (U.S. state)', 'GA', '13', '307', 'Webster', 32.046554, -84.553218],
  ['Georgia (U.S. state)', 'GA', '13', '309', 'Wheeler', 32.109276, -82.738701],
  ['Georgia (U.S. state)', 'GA', '13', '311', 'White', 34.643677, -83.743713],
  ['Georgia (U.S. state)', 'GA', '13', '313', 'Whitfield', 34.801726, -84.968541],
  ['Georgia (U.S. state)', 'GA', '13', '315', 'Wilcox', 31.962717, -83.438262],
  ['Georgia (U.S. state)', 'GA', '13', '317', 'Wilkes', 33.77904, -82.747922],
  ['Georgia (U.S. state)', 'GA', '13', '319', 'Wilkinson', 32.804321, -83.175587],
  ['Georgia (U.S. state)', 'GA', '13', '321', 'Worth', 31.551773, -83.84996],
  ['Hawaii', 'HI', '15', '001', 'Hawai{{okina}}i', 19.597764, -155.502443],
  ['Hawaii', 'HI', '15', '003', 'Honolulu', 21.461364, -158.201976],
  ['Hawaii', 'HI', '15', '005', 'Kalawao', 21.218764, -156.97401],
  ['Hawaii', 'HI', '15', '007', 'Kauaíi', 22.012038, -159.705965],
  ['Hawaii', 'HI', '15', '009', 'Maui', 20.855931, -156.60155],
  ['Idaho', 'ID', '16', '001', 'Ada', 43.447861, -116.244456],
  ['Idaho', 'ID', '16', '003', 'Adams', 44.884583, -116.431873],
  ['Idaho', 'ID', '16', '005', 'Bannock', 42.692939, -112.228986],
  ['Idaho', 'ID', '16', '007', 'Bear Lake', 42.285833, -111.327445],
  ['Idaho', 'ID', '16', '009', 'Benewah', 47.218451, -116.633541],
  ['Idaho', 'ID', '16', '011', 'Bingham', 43.216357, -112.399206],
  ['Idaho', 'ID', '16', '013', 'Blaine', 43.394482, -113.95529],
  ['Idaho', 'ID', '16', '015', 'Boise', 43.987275, -115.715111],
  ['Idaho', 'ID', '16', '017', 'Bonner', 48.312512, -116.59694],
  ['Idaho', 'ID', '16', '019', 'Bonneville', 43.395171, -111.621878],
  ['Idaho', 'ID', '16', '021', 'Boundary', 48.773065, -116.524619],
  ['Idaho', 'ID', '16', '023', 'Butte', 43.6851, -113.177627],
  ['Idaho', 'ID', '16', '025', 'Camas', 43.463396, -114.804427],
  ['Idaho', 'ID', '16', '027', 'Canyon', 43.623051, -116.708527],
  ['Idaho', 'ID', '16', '029', 'Caribou', 42.786273, -111.544172],
  ['Idaho', 'ID', '16', '031', 'Cassia', 42.288635, -113.605498],
  ['Idaho', 'ID', '16', '033', 'Clark', 44.286251, -112.36112],
  ['Idaho', 'ID', '16', '035', 'Clearwater', 46.67257, -115.6535],
  ['Idaho', 'ID', '16', '037', 'Custer', 44.273341, -114.252251],
  ['Idaho', 'ID', '16', '039', 'Elmore', 43.394826, -115.470751],
  ['Idaho', 'ID', '16', '041', 'Franklin', 42.173589, -111.822955],
  ['Idaho', 'ID', '16', '043', 'Fremont', 44.218091, -111.484429],
  ['Idaho', 'ID', '16', '045', 'Gem', 44.061473, -116.398784],
  ['Idaho', 'ID', '16', '047', 'Gooding', 42.973185, -114.82142],
  ['Idaho', 'ID', '16', '049', 'Idaho', 45.849237, -115.467376],
  ['Idaho', 'ID', '16', '051', 'Jefferson', 43.796965, -112.318588],
  ['Idaho', 'ID', '16', '053', 'Jerome', 42.691172, -114.263009],
  ['Idaho', 'ID', '16', '055', 'Kootenai', 47.677113, -116.694918],
  ['Idaho', 'ID', '16', '057', 'Latah', 46.81892, -116.730974],
  ['Idaho', 'ID', '16', '059', 'Lemhi', 44.928789, -113.887841],
  ['Idaho', 'ID', '16', '061', 'Lewis', 46.236328, -116.42376],
  ['Idaho', 'ID', '16', '063', 'Lincoln', 42.986181, -114.153899],
  ['Idaho', 'ID', '16', '065', 'Madison', 43.789709, -111.65655],
  ['Idaho', 'ID', '16', '067', 'Minidoka', 42.856937, -113.64001],
  ['Idaho', 'ID', '16', '069', 'Nez Perce', 46.333766, -116.760903],
  ['Idaho', 'ID', '16', '071', 'Oneida', 42.18389, -112.520465],
  ['Idaho', 'ID', '16', '073', 'Owyhee', 42.593063, -116.142655],
  ['Idaho', 'ID', '16', '075', 'Payette', 44.00274, -116.748655],
  ['Idaho', 'ID', '16', '077', 'Power', 42.694126, -112.844407],
  ['Idaho', 'ID', '16', '079', 'Shoshone', 47.347694, -115.885092],
  ['Idaho', 'ID', '16', '081', 'Teton', 43.760994, -111.211765],
  ['Idaho', 'ID', '16', '083', 'Twin Falls', 42.352309, -114.665639],
  ['Idaho', 'ID', '16', '085', 'Valley', 44.771532, -115.637875],
  ['Idaho', 'ID', '16', '087', 'Washington', 44.448213, -116.79783],
  ['Illinois', 'IL', '17', '001', 'Adams', 39.986053, -91.194961],
  ['Illinois', 'IL', '17', '003', 'Alexander', 37.183683, -89.349506],
  ['Illinois', 'IL', '17', '005', 'Bond', 38.885924, -89.436592],
  ['Illinois', 'IL', '17', '007', 'Boone', 42.318983, -88.824295],
  ['Illinois', 'IL', '17', '009', 'Brown', 39.962069, -90.75031],
  ['Illinois', 'IL', '17', '011', 'Bureau', 41.401304, -89.528377],
  ['Illinois', 'IL', '17', '013', 'Calhoun', 39.164262, -90.666295],
  ['Illinois', 'IL', '17', '015', 'Carroll', 42.059084, -89.926485],
  ['Illinois', 'IL', '17', '017', 'Cass', 39.969202, -90.245705],
  ['Illinois', 'IL', '17', '019', 'Champaign', 40.13915, -88.197201],
  ['Illinois', 'IL', '17', '021', 'Christian', 39.545524, -89.279593],
  ['Illinois', 'IL', '17', '023', 'Clark', 39.332364, -87.791687],
  ['Illinois', 'IL', '17', '025', 'Clay', 38.747312, -88.483789],
  ['Illinois', 'IL', '17', '027', 'Clinton', 38.606423, -89.423136],
  ['Illinois', 'IL', '17', '029', 'Coles', 39.51368, -88.220782],
  ['Illinois', 'IL', '17', '031', 'Cook', 41.894294, -87.645455],
  ['Illinois', 'IL', '17', '033', 'Crawford', 39.00373, -87.757172],
  ['Illinois', 'IL', '17', '035', 'Cumberland', 39.273121, -88.240619],
  ['Illinois', 'IL', '17', '037', 'DeKalb', 41.894613, -88.768991],
  ['Illinois', 'IL', '17', '039', 'De Witt', 40.181499, -88.901853],
  ['Illinois', 'IL', '17', '041', 'Douglas', 39.766078, -88.222866],
  ['Illinois', 'IL', '17', '043', 'DuPage', 41.852058, -88.086038],
  ['Illinois', 'IL', '17', '045', 'Edgar', 39.679037, -87.74711],
  ['Illinois', 'IL', '17', '047', 'Edwards', 38.417095, -88.047941],
  ['Illinois', 'IL', '17', '049', 'Effingham', 39.047694, -88.592786],
  ['Illinois', 'IL', '17', '051', 'Fayette', 39.001125, -89.017923],
  ['Illinois', 'IL', '17', '053', 'Ford', 40.594423, -88.224746],
  ['Illinois', 'IL', '17', '055', 'Franklin', 37.991848, -88.926246],
  ['Illinois', 'IL', '17', '057', 'Fulton', 40.465688, -90.206793],
  ['Illinois', 'IL', '17', '059', 'Gallatin', 37.768677, -88.227964],
  ['Illinois', 'IL', '17', '061', 'Greene', 39.355444, -90.387757],
  ['Illinois', 'IL', '17', '063', 'Grundy', 41.29241, -88.401055],
  ['Illinois', 'IL', '17', '065', 'Hamilton', 38.085226, -88.539005],
  ['Illinois', 'IL', '17', '067', 'Hancock', 40.405792, -91.167988],
  ['Illinois', 'IL', '17', '069', 'Hardin', 37.517852, -88.266148],
  ['Illinois', 'IL', '17', '071', 'Henderson', 40.815141, -90.93848],
  ['Illinois', 'IL', '17', '073', 'Henry', 41.350021, -90.130838],
  ['Illinois', 'IL', '17', '075', 'Iroquois', 40.748867, -87.833601],
  ['Illinois', 'IL', '17', '077', 'Jackson', 37.786096, -89.381212],
  ['Illinois', 'IL', '17', '079', 'Jasper', 39.004874, -88.150763],
  ['Illinois', 'IL', '17', '081', 'Jefferson', 38.30078, -88.92421],
  ['Illinois', 'IL', '17', '083', 'Jersey', 39.080192, -90.361365],
  ['Illinois', 'IL', '17', '085', 'Jo Daviess', 42.362391, -90.211471],
  ['Illinois', 'IL', '17', '087', 'Johnson', 37.460815, -88.882962],
  ['Illinois', 'IL', '17', '089', 'Kane', 41.939594, -88.42804],
  ['Illinois', 'IL', '17', '091', 'Kankakee', 41.139494, -87.861125],
  ['Illinois', 'IL', '17', '093', 'Kendall', 41.58814, -88.430626],
  ['Illinois', 'IL', '17', '095', 'Knox', 40.930941, -90.213761],
  ['Illinois', 'IL', '17', '097', 'Lake', 42.326444, -87.436118],
  ['Illinois', 'IL', '17', '099', 'La Salle', 41.343341, -88.885931],
  ['Illinois', 'IL', '17', '101', 'Lawrence', 38.718954, -87.730221],
  ['Illinois', 'IL', '17', '103', 'Lee', 41.747442, -89.299351],
  ['Illinois', 'IL', '17', '105', 'Livingston', 40.894376, -88.552852],
  ['Illinois', 'IL', '17', '107', 'Logan', 40.12907, -89.365308],
  ['Illinois', 'IL', '17', '109', 'McDonough', 40.455789, -90.677579],
  ['Illinois', 'IL', '17', '111', 'McHenry', 42.324298, -88.452245],
  ['Illinois', 'IL', '17', '113', 'McLean', 40.494559, -88.844539],
  ['Illinois', 'IL', '17', '115', 'Macon', 39.860237, -88.961529],
  ['Illinois', 'IL', '17', '117', 'Macoupin', 39.2659, -89.926344],
  ['Illinois', 'IL', '17', '119', 'Madison', 38.827082, -89.900195],
  ['Illinois', 'IL', '17', '121', 'Marion', 38.648396, -88.920221],
  ['Illinois', 'IL', '17', '123', 'Marshall', 41.031119, -89.342371],
  ['Illinois', 'IL', '17', '125', 'Mason', 40.236993, -89.913575],
  ['Illinois', 'IL', '17', '127', 'Massac', 37.216119, -88.705658],
  ['Illinois', 'IL', '17', '129', 'Menard', 40.022569, -89.794133],
  ['Illinois', 'IL', '17', '131', 'Mercer', 41.204791, -90.741433],
  ['Illinois', 'IL', '17', '133', 'Monroe', 38.277983, -90.179078],
  ['Illinois', 'IL', '17', '135', 'Montgomery', 39.228092, -89.478007],
  ['Illinois', 'IL', '17', '137', 'Morgan', 39.716806, -90.202277],
  ['Illinois', 'IL', '17', '139', 'Moultrie', 39.636896, -88.625726],
  ['Illinois', 'IL', '17', '141', 'Ogle', 42.041884, -89.320176],
  ['Illinois', 'IL', '17', '143', 'Peoria', 40.785999, -89.767358],
  ['Illinois', 'IL', '17', '145', 'Perry', 38.084385, -89.368487],
  ['Illinois', 'IL', '17', '147', 'Piatt', 40.009056, -88.592328],
  ['Illinois', 'IL', '17', '149', 'Pike', 39.625106, -90.889034],
  ['Illinois', 'IL', '17', '151', 'Pope', 37.417169, -88.542374],
  ['Illinois', 'IL', '17', '153', 'Pulaski', 37.215615, -89.127755],
  ['Illinois', 'IL', '17', '155', 'Putnam', 41.19894, -89.298386],
  ['Illinois', 'IL', '17', '157', 'Randolph', 38.056515, -89.82121],
  ['Illinois', 'IL', '17', '159', 'Richland', 38.71155, -88.085698],
  ['Illinois', 'IL', '17', '161', 'Rock Island', 41.468404, -90.572203],
  ['Illinois', 'IL', '17', '163', 'St. Clair', 38.470198, -89.928546],
  ['Illinois', 'IL', '17', '165', 'Saline', 37.751653, -88.545031],
  ['Illinois', 'IL', '17', '167', 'Sangamon', 39.756378, -89.662311],
  ['Illinois', 'IL', '17', '169', 'Schuyler', 40.156905, -90.613464],
  ['Illinois', 'IL', '17', '171', 'Scott', 39.63698, -90.477759],
  ['Illinois', 'IL', '17', '173', 'Shelby', 39.384926, -88.798862],
  ['Illinois', 'IL', '17', '175', 'Stark', 41.096908, -89.797411],
  ['Illinois', 'IL', '17', '177', 'Stephenson', 42.349726, -89.665994],
  ['Illinois', 'IL', '17', '179', 'Tazewell', 40.508074, -89.51626],
  ['Illinois', 'IL', '17', '181', 'Union', 37.475104, -89.252875],
  ['Illinois', 'IL', '17', '183', 'Vermilion', 40.18674, -87.726772],
  ['Illinois', 'IL', '17', '185', 'Wabash', 38.445821, -87.839167],
  ['Illinois', 'IL', '17', '187', 'Warren', 40.850441, -90.620223],
  ['Illinois', 'IL', '17', '189', 'Washington', 38.353141, -89.417187],
  ['Illinois', 'IL', '17', '191', 'Wayne', 38.431948, -88.432129],
  ['Illinois', 'IL', '17', '193', 'White', 38.087372, -88.178585],
  ['Illinois', 'IL', '17', '195', 'Whiteside', 41.750571, -89.910957],
  ['Illinois', 'IL', '17', '197', 'Will', 41.448474, -87.978456],
  ['Illinois', 'IL', '17', '199', 'Williamson', 37.730353, -88.930018],
  ['Illinois', 'IL', '17', '201', 'Winnebago', 42.337396, -89.161205],
  ['Illinois', 'IL', '17', '203', 'Woodford', 40.789596, -89.210301],
  ['Indiana', 'IN', '18', '001', 'Adams', 40.745733, -84.936131],
  ['Indiana', 'IN', '18', '003', 'Allen', 41.091855, -85.07223],
  ['Indiana', 'IN', '18', '005', 'Bartholomew', 39.205843, -85.897999],
  ['Indiana', 'IN', '18', '007', 'Benton', 40.608253, -87.315479],
  ['Indiana', 'IN', '18', '009', 'Blackford', 40.472672, -85.32373],
  ['Indiana', 'IN', '18', '011', 'Boone', 40.050892, -86.469014],
  ['Indiana', 'IN', '18', '013', 'Brown', 39.192585, -86.23941],
  ['Indiana', 'IN', '18', '015', 'Carroll', 40.58498, -86.565141],
  ['Indiana', 'IN', '18', '017', 'Cass', 40.753799, -86.355169],
  ['Indiana', 'IN', '18', '019', 'Clark', 38.476217, -85.711122],
  ['Indiana', 'IN', '18', '021', 'Clay', 39.393951, -87.115837],
  ['Indiana', 'IN', '18', '023', 'Clinton', 40.305944, -86.477567],
  ['Indiana', 'IN', '18', '025', 'Crawford', 38.289433, -86.440871],
  ['Indiana', 'IN', '18', '027', 'Daviess', 38.696155, -87.076988],
  ['Indiana', 'IN', '18', '029', 'Dearborn', 39.151491, -84.97346],
  ['Indiana', 'IN', '18', '031', 'Decatur', 39.30598, -85.499831],
  ['Indiana', 'IN', '18', '033', 'DeKalb', 41.401189, -85.000185],
  ['Indiana', 'IN', '18', '035', 'Delaware', 40.227165, -85.398856],
  ['Indiana', 'IN', '18', '037', 'Dubois', 38.373344, -86.873385],
  ['Indiana', 'IN', '18', '039', 'Elkhart', 41.600693, -85.863986],
  ['Indiana', 'IN', '18', '041', 'Fayette', 39.639655, -85.185032],
  ['Indiana', 'IN', '18', '043', 'Floyd', 38.317937, -85.911474],
  ['Indiana', 'IN', '18', '045', 'Fountain', 40.121282, -87.234806],
  ['Indiana', 'IN', '18', '047', 'Franklin', 39.409762, -85.066964],
  ['Indiana', 'IN', '18', '049', 'Fulton', 41.050384, -86.265006],
  ['Indiana', 'IN', '18', '051', 'Gibson', 38.317413, -87.580566],
  ['Indiana', 'IN', '18', '053', 'Grant', 40.515758, -85.654946],
  ['Indiana', 'IN', '18', '055', 'Greene', 39.0486, -87.005245],
  ['Indiana', 'IN', '18', '057', 'Hamilton', 40.04987, -86.020586],
  ['Indiana', 'IN', '18', '059', 'Hancock', 39.822604, -85.772904],
  ['Indiana', 'IN', '18', '061', 'Harrison', 38.18644, -86.103681],
  ['Indiana', 'IN', '18', '063', 'Hendricks', 39.768749, -86.510287],
  ['Indiana', 'IN', '18', '065', 'Henry', 39.929576, -85.397338],
  ['Indiana', 'IN', '18', '067', 'Howard', 40.483537, -86.114118],
  ['Indiana', 'IN', '18', '069', 'Huntington', 40.826394, -85.478598],
  ['Indiana', 'IN', '18', '071', 'Jackson', 38.911957, -86.042516],
  ['Indiana', 'IN', '18', '073', 'Jasper', 41.017688, -87.118814],
  ['Indiana', 'IN', '18', '075', 'Jay', 40.434972, -85.00338],
  ['Indiana', 'IN', '18', '077', 'Jefferson', 38.783604, -85.44009],
  ['Indiana', 'IN', '18', '079', 'Jennings', 38.996234, -85.628111],
  ['Indiana', 'IN', '18', '081', 'Johnson', 39.495986, -86.0946],
  ['Indiana', 'IN', '18', '083', 'Knox', 38.688663, -87.420182],
  ['Indiana', 'IN', '18', '085', 'Kosciusko', 41.244293, -85.861575],
  ['Indiana', 'IN', '18', '087', 'LaGrange', 41.642468, -85.426302],
  ['Indiana', 'IN', '18', '089', 'Lake', 41.472239, -87.374337],
  ['Indiana', 'IN', '18', '091', 'LaPorte', 41.549011, -86.744729],
  ['Indiana', 'IN', '18', '093', 'Lawrence', 38.839815, -86.48782],
  ['Indiana', 'IN', '18', '095', 'Madison', 40.166203, -85.722454],
  ['Indiana', 'IN', '18', '097', 'Marion', 39.782976, -86.135794],
  ['Indiana', 'IN', '18', '099', 'Marshall', 41.325003, -86.269036],
  ['Indiana', 'IN', '18', '101', 'Martin', 38.705322, -86.801847],
  ['Indiana', 'IN', '18', '103', 'Miami', 40.772881, -86.044259],
  ['Indiana', 'IN', '18', '105', 'Monroe', 39.160751, -86.523325],
  ['Indiana', 'IN', '18', '107', 'Montgomery', 40.040296, -86.892715],
  ['Indiana', 'IN', '18', '109', 'Morgan', 39.482646, -86.447457],
  ['Indiana', 'IN', '18', '111', 'Newton', 40.962399, -87.402172],
  ['Indiana', 'IN', '18', '113', 'Noble', 41.400794, -85.41785],
  ['Indiana', 'IN', '18', '115', 'Ohio', 38.940527, -84.964299],
  ['Indiana', 'IN', '18', '117', 'Orange', 38.547381, -86.489257],
  ['Indiana', 'IN', '18', '119', 'Owen', 39.317339, -86.838845],
  ['Indiana', 'IN', '18', '121', 'Parke', 39.77425, -87.19695],
  ['Indiana', 'IN', '18', '123', 'Perry', 38.081436, -86.62654],
  ['Indiana', 'IN', '18', '125', 'Pike', 38.397898, -87.232532],
  ['Indiana', 'IN', '18', '127', 'Porter', 41.509922, -87.071308],
  ['Indiana', 'IN', '18', '129', 'Posey', 38.027614, -87.868653],
  ['Indiana', 'IN', '18', '131', 'Pulaski', 41.045272, -86.692538],
  ['Indiana', 'IN', '18', '133', 'Putnam', 39.665545, -86.853325],
  ['Indiana', 'IN', '18', '135', 'Randolph', 40.164414, -85.005004],
  ['Indiana', 'IN', '18', '137', 'Ripley', 39.10023, -85.260541],
  ['Indiana', 'IN', '18', '139', 'Rush', 39.622312, -85.466527],
  ['Indiana', 'IN', '18', '141', 'St. Joseph', 41.617699, -86.288159],
  ['Indiana', 'IN', '18', '143', 'Scott', 38.679431, -85.751898],
  ['Indiana', 'IN', '18', '145', 'Shelby', 39.524135, -85.792174],
  ['Indiana', 'IN', '18', '147', 'Spencer', 38.009789, -87.010645],
  ['Indiana', 'IN', '18', '149', 'Starke', 41.28322, -86.64757],
  ['Indiana', 'IN', '18', '151', 'Steuben', 41.643437, -85.002467],
  ['Indiana', 'IN', '18', '153', 'Sullivan', 39.089225, -87.415843],
  ['Indiana', 'IN', '18', '155', 'Switzerland', 38.825846, -85.029679],
  ['Indiana', 'IN', '18', '157', 'Tippecanoe', 40.38926, -86.893943],
  ['Indiana', 'IN', '18', '159', 'Tipton', 40.310229, -86.056207],
  ['Indiana', 'IN', '18', '161', 'Union', 39.623111, -84.925152],
  ['Indiana', 'IN', '18', '163', 'Vanderburgh', 38.02007, -87.586166],
  ['Indiana', 'IN', '18', '165', 'Vermillion', 39.854045, -87.462071],
  ['Indiana', 'IN', '18', '167', 'Vigo', 39.429143, -87.390375],
  ['Indiana', 'IN', '18', '169', 'Wabash', 40.843717, -85.795175],
  ['Indiana', 'IN', '18', '171', 'Warren', 40.352658, -87.375847],
  ['Indiana', 'IN', '18', '173', 'Warrick', 38.097764, -87.272023],
  ['Indiana', 'IN', '18', '175', 'Washington', 38.600613, -86.104751],
  ['Indiana', 'IN', '18', '177', 'Wayne', 39.863091, -85.006735],
  ['Indiana', 'IN', '18', '179', 'Wells', 40.735273, -85.212974],
  ['Indiana', 'IN', '18', '181', 'White', 40.75095, -86.864293],
  ['Indiana', 'IN', '18', '183', 'Whitley', 41.136426, -85.501892],
  ['Iowa', 'IA', '19', '001', 'Adair', 41.328528, -94.478164],
  ['Iowa', 'IA', '19', '003', 'Adams', 41.021656, -94.696906],
  ['Iowa', 'IA', '19', '005', 'Allamakee', 43.274964, -91.382751],
  ['Iowa', 'IA', '19', '007', 'Appanoose', 40.744683, -92.870345],
  ['Iowa', 'IA', '19', '009', 'Audubon', 41.679178, -94.904312],
  ['Iowa', 'IA', '19', '011', 'Benton', 42.092547, -92.05763],
  ['Iowa', 'IA', '19', '013', 'Black Hawk', 42.472888, -92.306059],
  ['Iowa', 'IA', '19', '015', 'Boone', 42.038601, -93.939138],
  ['Iowa', 'IA', '19', '017', 'Bremer', 42.78089, -92.327586],
  ['Iowa', 'IA', '19', '019', 'Buchanan', 42.470328, -91.838666],
  ['Iowa', 'IA', '19', '021', 'Buena Vista', 42.741522, -95.141433],
  ['Iowa', 'IA', '19', '023', 'Butler', 42.734708, -92.780066],
  ['Iowa', 'IA', '19', '025', 'Calhoun', 42.38617, -94.643683],
  ['Iowa', 'IA', '19', '027', 'Carroll', 42.039492, -94.867647],
  ['Iowa', 'IA', '19', '029', 'Cass', 41.333824, -94.933302],
  ['Iowa', 'IA', '19', '031', 'Cedar', 41.772355, -91.13219],
  ['Iowa', 'IA', '19', '033', 'Cerro Gordo', 43.075171, -93.251266],
  ['Iowa', 'IA', '19', '035', 'Cherokee', 42.742738, -95.633262],
  ['Iowa', 'IA', '19', '037', 'Chickasaw', 43.059741, -92.31721],
  ['Iowa', 'IA', '19', '039', 'Clarke', 41.029191, -93.784096],
  ['Iowa', 'IA', '19', '041', 'Clay', 43.079822, -95.149726],
  ['Iowa', 'IA', '19', '043', 'Clayton', 42.840998, -91.323511],
  ['Iowa', 'IA', '19', '045', 'Clinton', 41.898073, -90.534243],
  ['Iowa', 'IA', '19', '047', 'Crawford', 42.043119, -95.38909],
  ['Iowa', 'IA', '19', '049', 'Dallas', 41.685321, -94.040707],
  ['Iowa', 'IA', '19', '051', 'Davis', 40.748089, -92.410345],
  ['Iowa', 'IA', '19', '053', 'Decatur', 40.736379, -93.78458],
  ['Iowa', 'IA', '19', '055', 'Delaware', 42.473003, -91.359443],
  ['Iowa', 'IA', '19', '057', 'Des Moines', 40.915339, -91.186925],
  ['Iowa', 'IA', '19', '059', 'Dickinson', 43.389611, -95.196057],
  ['Iowa', 'IA', '19', '061', 'Dubuque', 42.463481, -90.878771],
  ['Iowa', 'IA', '19', '063', 'Emmet', 43.377984, -94.66937],
  ['Iowa', 'IA', '19', '065', 'Fayette', 42.86445, -91.839373],
  ['Iowa', 'IA', '19', '067', 'Floyd', 43.052741, -92.787367],
  ['Iowa', 'IA', '19', '069', 'Franklin', 42.736549, -93.271425],
  ['Iowa', 'IA', '19', '071', 'Fremont', 40.743726, -95.599516],
  ['Iowa', 'IA', '19', '073', 'Greene', 42.042494, -94.388703],
  ['Iowa', 'IA', '19', '075', 'Grundy', 42.403323, -92.790261],
  ['Iowa', 'IA', '19', '077', 'Guthrie', 41.683573, -94.501272],
  ['Iowa', 'IA', '19', '079', 'Hamilton', 42.390768, -93.709198],
  ['Iowa', 'IA', '19', '081', 'Hancock', 43.075411, -93.743697],
  ['Iowa', 'IA', '19', '083', 'Hardin', 42.389955, -93.241081],
  ['Iowa', 'IA', '19', '085', 'Harrison', 41.688584, -95.827149],
  ['Iowa', 'IA', '19', '087', 'Henry', 40.985864, -91.54427],
  ['Iowa', 'IA', '19', '089', 'Howard', 43.365313, -92.321908],
  ['Iowa', 'IA', '19', '091', 'Humboldt', 42.782221, -94.202775],
  ['Iowa', 'IA', '19', '093', 'Ida', 42.39186, -95.507421],
  ['Iowa', 'IA', '19', '095', 'Iowa', 41.683918, -92.059123],
  ['Iowa', 'IA', '19', '097', 'Jackson', 42.164281, -90.574597],
  ['Iowa', 'IA', '19', '099', 'Jasper', 41.685686, -93.052971],
  ['Iowa', 'IA', '19', '101', 'Jefferson', 41.00688, -91.967137],
  ['Iowa', 'IA', '19', '103', 'Johnson', 41.668737, -91.588812],
  ['Iowa', 'IA', '19', '105', 'Jones', 42.125118, -91.116914],
  ['Iowa', 'IA', '19', '107', 'Keokuk', 41.331182, -92.167721],
  ['Iowa', 'IA', '19', '109', 'Kossuth', 43.212478, -94.213983],
  ['Iowa', 'IA', '19', '111', 'Lee', 40.647588, -91.477157],
  ['Iowa', 'IA', '19', '113', 'Linn', 42.077951, -91.597674],
  ['Iowa', 'IA', '19', '115', 'Louisa', 41.218211, -91.256994],
  ['Iowa', 'IA', '19', '117', 'Lucas', 41.033344, -93.331467],
  ['Iowa', 'IA', '19', '119', 'Lyon', 43.38358, -96.207201],
  ['Iowa', 'IA', '19', '121', 'Madison', 41.330622, -94.015184],
  ['Iowa', 'IA', '19', '123', 'Mahaska', 41.330797, -92.636366],
  ['Iowa', 'IA', '19', '125', 'Marion', 41.331455, -93.093849],
  ['Iowa', 'IA', '19', '127', 'Marshall', 42.041691, -92.981452],
  ['Iowa', 'IA', '19', '129', 'Mills', 41.033703, -95.619101],
  ['Iowa', 'IA', '19', '131', 'Mitchell', 43.348564, -92.784466],
  ['Iowa', 'IA', '19', '133', 'Monona', 42.049432, -95.956566],
  ['Iowa', 'IA', '19', '135', 'Monroe', 41.028847, -92.869642],
  ['Iowa', 'IA', '19', '137', 'Montgomery', 41.021735, -95.15779],
  ['Iowa', 'IA', '19', '139', 'Muscatine', 41.483776, -91.118699],
  ['Iowa', 'IA', '19', '141', "O'Brien", 43.083746, -95.625624],
  ['Iowa', 'IA', '19', '143', 'Osceola', 43.378542, -95.633788],
  ['Iowa', 'IA', '19', '145', 'Page', 40.73909, -95.14429],
  ['Iowa', 'IA', '19', '147', 'Palo Alto', 43.075854, -94.667297],
  ['Iowa', 'IA', '19', '149', 'Plymouth', 42.737585, -96.215864],
  ['Iowa', 'IA', '19', '151', 'Pocahontas', 42.734033, -94.678279],
  ['Iowa', 'IA', '19', '153', 'Polk', 41.684281, -93.56972],
  ['Iowa', 'IA', '19', '155', 'Pottawattamie', 41.340184, -95.544905],
  ['Iowa', 'IA', '19', '157', 'Poweshiek', 41.684526, -92.522882],
  ['Iowa', 'IA', '19', '159', 'Ringgold', 40.735334, -94.244251],
  ['Iowa', 'IA', '19', '161', 'Sac', 42.387526, -95.105224],
  ['Iowa', 'IA', '19', '163', 'Scott', 41.641679, -90.62229],
  ['Iowa', 'IA', '19', '165', 'Shelby', 41.679014, -95.308917],
  ['Iowa', 'IA', '19', '167', 'Sioux', 43.082854, -96.177929],
  ['Iowa', 'IA', '19', '169', 'Story', 42.037538, -93.466093],
  ['Iowa', 'IA', '19', '171', 'Tama', 42.074848, -92.529412],
  ['Iowa', 'IA', '19', '173', 'Taylor', 40.737949, -94.697108],
  ['Iowa', 'IA', '19', '175', 'Union', 41.02855, -94.245091],
  ['Iowa', 'IA', '19', '177', 'Van Buren', 40.754117, -91.952943],
  ['Iowa', 'IA', '19', '179', 'Wapello', 41.031263, -92.409482],
  ['Iowa', 'IA', '19', '181', 'Warren', 41.336769, -93.564366],
  ['Iowa', 'IA', '19', '183', 'Washington', 41.329401, -91.725052],
  ['Iowa', 'IA', '19', '185', 'Wayne', 40.739983, -93.332613],
  ['Iowa', 'IA', '19', '187', 'Webster', 42.434397, -94.179157],
  ['Iowa', 'IA', '19', '189', 'Winnebago', 43.378124, -93.743488],
  ['Iowa', 'IA', '19', '191', 'Winneshiek', 43.292989, -91.850788],
  ['Iowa', 'IA', '19', '193', 'Woodbury', 42.39322, -96.053296],
  ['Iowa', 'IA', '19', '195', 'Worth', 43.373491, -93.248533],
  ['Iowa', 'IA', '19', '197', 'Wright', 42.733007, -93.734735],
  ['Kansas', 'KS', '20', '001', 'Allen', 37.884229, -95.300945],
  ['Kansas', 'KS', '20', '003', 'Anderson', 38.215114, -95.292046],
  ['Kansas', 'KS', '20', '005', 'Atchison', 39.532544, -95.313398],
  ['Kansas', 'KS', '20', '007', 'Barber', 37.222906, -98.685052],
  ['Kansas', 'KS', '20', '009', 'Barton', 38.481239, -98.767837],
  ['Kansas', 'KS', '20', '011', 'Bourbon', 37.8561, -94.850928],
  ['Kansas', 'KS', '20', '013', 'Brown', 39.825931, -95.569905],
  ['Kansas', 'KS', '20', '015', 'Butler', 37.773681, -96.838762],
  ['Kansas', 'KS', '20', '017', 'Chase', 38.298553, -96.594064],
  ['Kansas', 'KS', '20', '019', 'Chautauqua', 37.154259, -96.245396],
  ['Kansas', 'KS', '20', '021', 'Cherokee', 37.169392, -94.845698],
  ['Kansas', 'KS', '20', '023', 'Cheyenne', 39.789256, -101.727302],
  ['Kansas', 'KS', '20', '025', 'Clark', 37.233831, -99.813869],
  ['Kansas', 'KS', '20', '027', 'Clay', 39.344964, -97.168853],
  ['Kansas', 'KS', '20', '029', 'Cloud', 39.487329, -97.64139],
  ['Kansas', 'KS', '20', '031', 'Coffey', 38.23645, -95.729137],
  ['Kansas', 'KS', '20', '033', 'Comanche', 37.189071, -99.254089],
  ['Kansas', 'KS', '20', '035', 'Cowley', 37.234507, -96.837247],
  ['Kansas', 'KS', '20', '037', 'Crawford', 37.505628, -94.853941],
  ['Kansas', 'KS', '20', '039', 'Decatur', 39.783542, -100.459708],
  ['Kansas', 'KS', '20', '041', 'Dickinson', 38.867735, -97.157943],
  ['Kansas', 'KS', '20', '043', 'Doniphan', 39.788502, -95.147225],
  ['Kansas', 'KS', '20', '045', 'Douglas', 38.896573, -95.290529],
  ['Kansas', 'KS', '20', '047', 'Edwards', 37.883595, -99.304746],
  ['Kansas', 'KS', '20', '049', 'Elk', 37.456026, -96.244642],
  ['Kansas', 'KS', '20', '051', 'Ellis', 38.914596, -99.317329],
  ['Kansas', 'KS', '20', '053', 'Ellsworth', 38.700845, -98.205355],
  ['Kansas', 'KS', '20', '055', 'Finney', 38.049855, -100.739929],
  ['Kansas', 'KS', '20', '057', 'Ford', 37.688365, -99.884734],
  ['Kansas', 'KS', '20', '059', 'Franklin', 38.558019, -95.278962],
  ['Kansas', 'KS', '20', '061', 'Geary', 39.002155, -96.768038],
  ['Kansas', 'KS', '20', '063', 'Gove', 38.917239, -100.48736],
  ['Kansas', 'KS', '20', '065', 'Graham', 39.349445, -99.880952],
  ['Kansas', 'KS', '20', '067', 'Grant', 37.547537, -101.299362],
  ['Kansas', 'KS', '20', '069', 'Gray', 37.744513, -100.451716],
  ['Kansas', 'KS', '20', '071', 'Greeley', 38.480408, -101.805984],
  ['Kansas', 'KS', '20', '073', 'Greenwood', 37.878659, -96.242232],
  ['Kansas', 'KS', '20', '075', 'Hamilton', 37.995244, -101.793689],
  ['Kansas', 'KS', '20', '077', 'Harper', 37.188184, -98.06659],
  ['Kansas', 'KS', '20', '079', 'Harvey', 38.050144, -97.436707],
  ['Kansas', 'KS', '20', '081', 'Haskell', 37.55855, -100.876869],
  ['Kansas', 'KS', '20', '083', 'Hodgeman', 38.087493, -99.898407],
  ['Kansas', 'KS', '20', '085', 'Jackson', 39.410989, -95.794509],
  ['Kansas', 'KS', '20', '087', 'Jefferson', 39.239644, -95.375314],
  ['Kansas', 'KS', '20', '089', 'Jewell', 39.77701, -98.222584],
  ['Kansas', 'KS', '20', '091', 'Johnson', 38.883907, -94.82233],
  ['Kansas', 'KS', '20', '093', 'Kearny', 37.994461, -101.308136],
  ['Kansas', 'KS', '20', '095', 'Kingman', 37.552951, -98.144529],
  ['Kansas', 'KS', '20', '097', 'Kiowa', 37.561231, -99.286539],
  ['Kansas', 'KS', '20', '099', 'Labette', 37.191468, -95.297473],
  ['Kansas', 'KS', '20', '101', 'Lane', 38.481286, -100.466185],
  ['Kansas', 'KS', '20', '103', 'Leavenworth', 39.189511, -95.038977],
  ['Kansas', 'KS', '20', '105', 'Lincoln', 39.047276, -98.214265],
  ['Kansas', 'KS', '20', '107', 'Linn', 38.216549, -94.844932],
  ['Kansas', 'KS', '20', '109', 'Logan', 38.91327, -101.157407],
  ['Kansas', 'KS', '20', '111', 'Lyon', 38.455498, -96.161589],
  ['Kansas', 'KS', '20', '113', 'McPherson', 38.395812, -97.647489],
  ['Kansas', 'KS', '20', '115', 'Marion', 38.359647, -97.102771],
  ['Kansas', 'KS', '20', '117', 'Marshall', 39.782709, -96.521243],
  ['Kansas', 'KS', '20', '119', 'Meade', 37.243886, -100.360094],
  ['Kansas', 'KS', '20', '121', 'Miami', 38.566772, -94.832963],
  ['Kansas', 'KS', '20', '123', 'Mitchell', 39.393026, -98.207362],
  ['Kansas', 'KS', '20', '125', 'Montgomery', 37.189537, -95.742403],
  ['Kansas', 'KS', '20', '127', 'Morris', 38.687696, -96.644905],
  ['Kansas', 'KS', '20', '129', 'Morton', 37.18525, -101.809516],
  ['Kansas', 'KS', '20', '131', 'Nemaha', 39.791043, -96.005381],
  ['Kansas', 'KS', '20', '133', 'Neosho', 37.564283, -95.315683],
  ['Kansas', 'KS', '20', '135', 'Ness', 38.480437, -99.908745],
  ['Kansas', 'KS', '20', '137', 'Norton', 39.783867, -99.899235],
  ['Kansas', 'KS', '20', '139', 'Osage', 38.649706, -95.727275],
  ['Kansas', 'KS', '20', '141', 'Osborne', 39.348649, -98.767876],
  ['Kansas', 'KS', '20', '143', 'Ottawa', 39.137963, -97.654803],
  ['Kansas', 'KS', '20', '145', 'Pawnee', 38.182873, -99.232154],
  ['Kansas', 'KS', '20', '147', 'Phillips', 39.784506, -99.34215],
  ['Kansas', 'KS', '20', '149', 'Pottawatomie', 39.382187, -96.337113],
  ['Kansas', 'KS', '20', '151', 'Pratt', 37.647594, -98.74012],
  ['Kansas', 'KS', '20', '153', 'Rawlins', 39.786198, -101.076738],
  ['Kansas', 'KS', '20', '155', 'Reno', 37.948185, -98.078346],
  ['Kansas', 'KS', '20', '157', 'Republic', 39.82891, -97.650921],
  ['Kansas', 'KS', '20', '159', 'Rice', 38.347178, -98.201415],
  ['Kansas', 'KS', '20', '161', 'Riley', 39.291211, -96.727489],
  ['Kansas', 'KS', '20', '163', 'Rooks', 39.346006, -99.324492],
  ['Kansas', 'KS', '20', '165', 'Rush', 38.523592, -99.309183],
  ['Kansas', 'KS', '20', '167', 'Russell', 38.916839, -98.765638],
  ['Kansas', 'KS', '20', '169', 'Saline', 38.786327, -97.650153],
  ['Kansas', 'KS', '20', '171', 'Scott', 38.481877, -100.90636],
  ['Kansas', 'KS', '20', '173', 'Sedgwick', 37.683807, -97.459451],
  ['Kansas', 'KS', '20', '175', 'Seward', 37.180585, -100.854741],
  ['Kansas', 'KS', '20', '177', 'Shawnee', 39.041805, -95.755664],
  ['Kansas', 'KS', '20', '179', 'Sheridan', 39.350543, -100.441206],
  ['Kansas', 'KS', '20', '181', 'Sherman', 39.351352, -101.719859],
  ['Kansas', 'KS', '20', '183', 'Smith', 39.78466, -98.78543],
  ['Kansas', 'KS', '20', '185', 'Stafford', 38.03563, -98.719889],
  ['Kansas', 'KS', '20', '187', 'Stanton', 37.565932, -101.789383],
  ['Kansas', 'KS', '20', '189', 'Stevens', 37.202356, -101.315796],
  ['Kansas', 'KS', '20', '191', 'Sumner', 37.236663, -97.493349],
  ['Kansas', 'KS', '20', '193', 'Thomas', 39.357706, -101.083439],
  ['Kansas', 'KS', '20', '195', 'Trego', 38.921302, -99.865423],
  ['Kansas', 'KS', '20', '197', 'Wabaunsee', 38.955154, -96.201262],
  ['Kansas', 'KS', '20', '199', 'Wallace', 38.926626, -101.771103],
  ['Kansas', 'KS', '20', '201', 'Washington', 39.776714, -97.095611],
  ['Kansas', 'KS', '20', '203', 'Wichita', 38.481922, -101.347434],
  ['Kansas', 'KS', '20', '205', 'Wilson', 37.558515, -95.745175],
  ['Kansas', 'KS', '20', '207', 'Woodson', 37.888484, -95.757553],
  ['Kansas', 'KS', '20', '209', 'Wyandotte', 39.115384, -94.763087],
  ['Kentucky', 'KY', '21', '001', 'Adair', 37.105541, -85.281401],
  ['Kentucky', 'KY', '21', '003', 'Allen', 36.75077, -86.192458],
  ['Kentucky', 'KY', '21', '005', 'Anderson', 38.005396, -84.986417],
  ['Kentucky', 'KY', '21', '007', 'Ballard', 37.05112, -89.009178],
  ['Kentucky', 'KY', '21', '009', 'Barren', 36.963614, -85.92499],
  ['Kentucky', 'KY', '21', '011', 'Bath', 38.152249, -83.737641],
  ['Kentucky', 'KY', '21', '013', 'Bell', 36.72268, -83.681046],
  ['Kentucky', 'KY', '21', '015', 'Boone', 38.974595, -84.731444],
  ['Kentucky', 'KY', '21', '017', 'Bourbon', 38.202562, -84.20986],
  ['Kentucky', 'KY', '21', '019', 'Boyd', 38.360004, -82.681406],
  ['Kentucky', 'KY', '21', '021', 'Boyle', 37.6182, -84.873016],
  ['Kentucky', 'KY', '21', '023', 'Bracken', 38.678523, -84.100355],
  ['Kentucky', 'KY', '21', '025', 'Breathitt', 37.521178, -83.322401],
  ['Kentucky', 'KY', '21', '027', 'Breckinridge', 37.778109, -86.432829],
  ['Kentucky', 'KY', '21', '029', 'Bullitt', 37.969572, -85.703036],
  ['Kentucky', 'KY', '21', '031', 'Butler', 37.207013, -86.682471],
  ['Kentucky', 'KY', '21', '033', 'Caldwell', 37.148643, -87.87051],
  ['Kentucky', 'KY', '21', '035', 'Calloway', 36.620978, -88.274086],
  ['Kentucky', 'KY', '21', '037', 'Campbell', 38.946981, -84.379583],
  ['Kentucky', 'KY', '21', '039', 'Carlisle', 36.857726, -88.975757],
  ['Kentucky', 'KY', '21', '041', 'Carroll', 38.668284, -85.124083],
  ['Kentucky', 'KY', '21', '043', 'Carter', 38.309555, -83.048821],
  ['Kentucky', 'KY', '21', '045', 'Casey', 37.321962, -84.92822],
  ['Kentucky', 'KY', '21', '047', 'Christian', 36.893388, -87.493554],
  ['Kentucky', 'KY', '21', '049', 'Clark', 37.970133, -84.144974],
  ['Kentucky', 'KY', '21', '051', 'Clay', 37.164466, -83.710763],
  ['Kentucky', 'KY', '21', '053', 'Clinton', 36.729124, -85.153499],
  ['Kentucky', 'KY', '21', '055', 'Crittenden', 37.358149, -88.10501],
  ['Kentucky', 'KY', '21', '057', 'Cumberland', 36.784227, -85.393499],
  ['Kentucky', 'KY', '21', '059', 'Daviess', 37.731671, -87.087139],
  ['Kentucky', 'KY', '21', '061', 'Edmonson', 37.227856, -86.217778],
  ['Kentucky', 'KY', '21', '063', 'Elliott', 38.116425, -83.097541],
  ['Kentucky', 'KY', '21', '065', 'Estill', 37.69246, -83.963927],
  ['Kentucky', 'KY', '21', '067', 'Fayette', 38.040157, -84.458443],
  ['Kentucky', 'KY', '21', '069', 'Fleming', 38.368431, -83.706152],
  ['Kentucky', 'KY', '21', '071', 'Floyd', 37.558283, -82.740337],
  ['Kentucky', 'KY', '21', '073', 'Franklin', 38.234919, -84.868786],
  ['Kentucky', 'KY', '21', '075', 'Fulton', 36.55262, -89.187723],
  ['Kentucky', 'KY', '21', '077', 'Gallatin', 38.760184, -84.862114],
  ['Kentucky', 'KY', '21', '079', 'Garrard', 37.630162, -84.545856],
  ['Kentucky', 'KY', '21', '081', 'Grant', 38.64921, -84.625946],
  ['Kentucky', 'KY', '21', '083', 'Graves', 36.723344, -88.649897],
  ['Kentucky', 'KY', '21', '085', 'Grayson', 37.458577, -86.344011],
  ['Kentucky', 'KY', '21', '087', 'Green', 37.269637, -85.561403],
  ['Kentucky', 'KY', '21', '089', 'Greenup', 38.563596, -82.933833],
  ['Kentucky', 'KY', '21', '091', 'Hancock', 37.843389, -86.792773],
  ['Kentucky', 'KY', '21', '093', 'Hardin', 37.695836, -85.963183],
  ['Kentucky', 'KY', '21', '095', 'Harlan', 36.859223, -83.221497],
  ['Kentucky', 'KY', '21', '097', 'Harrison', 38.443489, -84.332796],
  ['Kentucky', 'KY', '21', '099', 'Hart', 37.313856, -85.881995],
  ['Kentucky', 'KY', '21', '101', 'Henderson', 37.792542, -87.572577],
  ['Kentucky', 'KY', '21', '103', 'Henry', 38.451561, -85.120079],
  ['Kentucky', 'KY', '21', '105', 'Hickman', 36.675916, -88.97202],
  ['Kentucky', 'KY', '21', '107', 'Hopkins', 37.31107, -87.542196],
  ['Kentucky', 'KY', '21', '109', 'Jackson', 37.40332, -84.020686],
  ['Kentucky', 'KY', '21', '111', 'Jefferson', 38.189533, -85.657624],
  ['Kentucky', 'KY', '21', '113', 'Jessamine', 37.873291, -84.58396],
  ['Kentucky', 'KY', '21', '115', 'Johnson', 37.84926, -82.830639],
  ['Kentucky', 'KY', '21', '117', 'Kenton', 38.930477, -84.533492],
  ['Kentucky', 'KY', '21', '119', 'Knott', 37.354703, -82.949138],
  ['Kentucky', 'KY', '21', '121', 'Knox', 36.887476, -83.85563],
  ['Kentucky', 'KY', '21', '123', 'Larue', 37.545518, -85.697209],
  ['Kentucky', 'KY', '21', '125', 'Laurel', 37.113268, -84.119395],
  ['Kentucky', 'KY', '21', '127', 'Lawrence', 38.074459, -82.738305],
  ['Kentucky', 'KY', '21', '129', 'Lee', 37.605383, -83.718497],
  ['Kentucky', 'KY', '21', '131', 'Leslie', 37.087846, -83.388616],
  ['Kentucky', 'KY', '21', '133', 'Letcher', 37.118503, -82.861175],
  ['Kentucky', 'KY', '21', '135', 'Lewis', 38.532051, -83.373303],
  ['Kentucky', 'KY', '21', '137', 'Lincoln', 37.457257, -84.658074],
  ['Kentucky', 'KY', '21', '139', 'Livingston', 37.209517, -88.363426],
  ['Kentucky', 'KY', '21', '141', 'Logan', 36.859829, -86.881436],
  ['Kentucky', 'KY', '21', '143', 'Lyon', 37.023976, -88.083391],
  ['Kentucky', 'KY', '21', '145', 'McCracken', 37.053688, -88.712378],
  ['Kentucky', 'KY', '21', '147', 'McCreary', 36.731136, -84.491052],
  ['Kentucky', 'KY', '21', '149', 'McLean', 37.530575, -87.262931],
  ['Kentucky', 'KY', '21', '151', 'Madison', 37.723528, -84.277008],
  ['Kentucky', 'KY', '21', '153', 'Magoffin', 37.698954, -83.069716],
  ['Kentucky', 'KY', '21', '155', 'Marion', 37.552247, -85.269242],
  ['Kentucky', 'KY', '21', '157', 'Marshall', 36.882017, -88.332752],
  ['Kentucky', 'KY', '21', '159', 'Martin', 37.796774, -82.506623],
  ['Kentucky', 'KY', '21', '161', 'Mason', 38.594068, -83.828052],
  ['Kentucky', 'KY', '21', '163', 'Meade', 37.967476, -86.200863],
  ['Kentucky', 'KY', '21', '165', 'Menifee', 37.950715, -83.597345],
  ['Kentucky', 'KY', '21', '167', 'Mercer', 37.812085, -84.879695],
  ['Kentucky', 'KY', '21', '169', 'Metcalfe', 36.990394, -85.629554],
  ['Kentucky', 'KY', '21', '171', 'Monroe', 36.714077, -85.713508],
  ['Kentucky', 'KY', '21', '173', 'Montgomery', 38.038138, -83.912338],
  ['Kentucky', 'KY', '21', '175', 'Morgan', 37.92294, -83.258944],
  ['Kentucky', 'KY', '21', '177', 'Muhlenberg', 37.213816, -87.134092],
  ['Kentucky', 'KY', '21', '179', 'Nelson', 37.803188, -85.465955],
  ['Kentucky', 'KY', '21', '181', 'Nicholas', 38.337132, -84.025554],
  ['Kentucky', 'KY', '21', '183', 'Ohio', 37.477859, -86.844871],
  ['Kentucky', 'KY', '21', '185', 'Oldham', 38.400046, -85.456059],
  ['Kentucky', 'KY', '21', '187', 'Owen', 38.499603, -84.841509],
  ['Kentucky', 'KY', '21', '189', 'Owsley', 37.423452, -83.691566],
  ['Kentucky', 'KY', '21', '191', 'Pendleton', 38.690765, -84.354041],
  ['Kentucky', 'KY', '21', '193', 'Perry', 37.241282, -83.217772],
  ['Kentucky', 'KY', '21', '195', 'Pike', 37.482067, -82.402869],
  ['Kentucky', 'KY', '21', '197', 'Powell', 37.810379, -83.826985],
  ['Kentucky', 'KY', '21', '199', 'Pulaski', 37.108312, -84.579986],
  ['Kentucky', 'KY', '21', '201', 'Robertson', 38.513826, -84.063757],
  ['Kentucky', 'KY', '21', '203', 'Rockcastle', 37.36122, -84.314419],
  ['Kentucky', 'KY', '21', '205', 'Rowan', 38.205894, -83.425969],
  ['Kentucky', 'KY', '21', '207', 'Russell', 36.989127, -85.058976],
  ['Kentucky', 'KY', '21', '209', 'Scott', 38.289882, -84.579376],
  ['Kentucky', 'KY', '21', '211', 'Shelby', 38.239426, -85.22836],
  ['Kentucky', 'KY', '21', '213', 'Simpson', 36.740863, -86.581464],
  ['Kentucky', 'KY', '21', '215', 'Spencer', 38.026976, -85.321525],
  ['Kentucky', 'KY', '21', '217', 'Taylor', 37.365106, -85.326698],
  ['Kentucky', 'KY', '21', '219', 'Todd', 36.840338, -87.183642],
  ['Kentucky', 'KY', '21', '221', 'Trigg', 36.807681, -87.858652],
  ['Kentucky', 'KY', '21', '223', 'Trimble', 38.618193, -85.355171],
  ['Kentucky', 'KY', '21', '225', 'Union', 37.658029, -87.95165],
  ['Kentucky', 'KY', '21', '227', 'Warren', 36.995634, -86.423579],
  ['Kentucky', 'KY', '21', '229', 'Washington', 37.754209, -85.175416],
  ['Kentucky', 'KY', '21', '231', 'Wayne', 36.802011, -84.83044],
  ['Kentucky', 'KY', '21', '233', 'Webster', 37.520021, -87.685095],
  ['Kentucky', 'KY', '21', '235', 'Whitley', 36.758021, -84.14455],
  ['Kentucky', 'KY', '21', '237', 'Wolfe', 37.743774, -83.495068],
  ['Kentucky', 'KY', '21', '239', 'Woodford', 38.042789, -84.748147],
  ['Louisiana', 'LA', '22', '001', 'Acadia', 30.291497, -92.411037],
  ['Louisiana', 'LA', '22', '003', 'Allen', 30.652744, -92.819605],
  ['Louisiana', 'LA', '22', '005', 'Ascension', 30.202946, -90.910023],
  ['Louisiana', 'LA', '22', '007', 'Assumption', 29.899884, -91.050528],
  ['Louisiana', 'LA', '22', '009', 'Avoyelles', 31.085094, -91.993798],
  ['Louisiana', 'LA', '22', '011', 'Beauregard', 30.645018, -93.340253],
  ['Louisiana', 'LA', '22', '013', 'Bienville', 32.344268, -93.061513],
  ['Louisiana', 'LA', '22', '015', 'Bossier', 32.696202, -93.617977],
  ['Louisiana', 'LA', '22', '017', 'Caddo', 32.577195, -93.882423],
  ['Louisiana', 'LA', '22', '019', 'Calcasieu', 30.229559, -93.358015],
  ['Louisiana', 'LA', '22', '021', 'Caldwell', 32.101244, -92.11418],
  ['Louisiana', 'LA', '22', '023', 'Cameron', 29.871989, -93.165437],
  ['Louisiana', 'LA', '22', '025', 'Catahoula', 31.666517, -91.846703],
  ['Louisiana', 'LA', '22', '027', 'Claiborne', 32.827585, -92.991125],
  ['Louisiana', 'LA', '22', '029', 'Concordia', 31.469806, -91.626314],
  ['Louisiana', 'LA', '22', '031', 'De Soto', 32.059248, -93.740797],
  ['Louisiana', 'LA', '22', '033', 'East Baton Rouge', 30.544002, -91.093174],
  ['Louisiana', 'LA', '22', '035', 'East Carroll', 32.73017, -91.234141],
  ['Louisiana', 'LA', '22', '037', 'East Feliciana', 30.839784, -91.043434],
  ['Louisiana', 'LA', '22', '039', 'Evangeline', 30.720693, -92.404086],
  ['Louisiana', 'LA', '22', '041', 'Franklin', 32.138279, -91.672101],
  ['Louisiana', 'LA', '22', '043', 'Grant', 31.597787, -92.561716],
  ['Louisiana', 'LA', '22', '045', 'Iberia', 29.606013, -91.842706],
  ['Louisiana', 'LA', '22', '047', 'Iberville', 30.288394, -91.36315],
  ['Louisiana', 'LA', '22', '049', 'Jackson', 32.30448, -92.556672],
  ['Louisiana', 'LA', '22', '051', 'Jefferson', 29.5033, -90.036231],
  ['Louisiana', 'LA', '22', '053', 'Jefferson Davis', 30.269529, -92.816221],
  ['Louisiana', 'LA', '22', '055', 'Lafayette', 30.206507, -92.06417],
  ['Louisiana', 'LA', '22', '057', 'Lafourche', 29.491993, -90.394849],
  ['Louisiana', 'LA', '22', '059', 'La Salle', 31.680836, -92.161809],
  ['Louisiana', 'LA', '22', '061', 'Lincoln', 32.601148, -92.662082],
  ['Louisiana', 'LA', '22', '063', 'Livingston', 30.440419, -90.727474],
  ['Louisiana', 'LA', '22', '065', 'Madison', 32.365824, -91.240729],
  ['Louisiana', 'LA', '22', '067', 'Morehouse', 32.820008, -91.800399],
  ['Louisiana', 'LA', '22', '069', 'Natchitoches', 31.734982, -93.086106],
  ['Louisiana', 'LA', '22', '071', 'Orleans', 30.068636, -89.939007],
  ['Louisiana', 'LA', '22', '073', 'Ouachita', 32.477495, -92.154798],
  ['Louisiana', 'LA', '22', '075', 'Plaquemines', 29.402337, -89.298489],
  ['Louisiana', 'LA', '22', '077', 'Pointe Coupee', 30.708319, -91.604621],
  ['Louisiana', 'LA', '22', '079', 'Rapides', 31.193204, -92.535953],
  ['Louisiana', 'LA', '22', '081', 'Red River', 32.101213, -93.34905],
  ['Louisiana', 'LA', '22', '083', 'Richland', 32.418121, -91.758375],
  ['Louisiana', 'LA', '22', '085', 'Sabine', 31.560209, -93.559228],
  ['Louisiana', 'LA', '22', '087', 'St. Bernard', 29.91811, -89.263494],
  ['Louisiana', 'LA', '22', '089', 'St. Charles', 29.913833, -90.359314],
  ['Louisiana', 'LA', '22', '091', 'St. Helena', 30.821423, -90.70935],
  ['Louisiana', 'LA', '22', '093', 'St. James', 30.02482, -90.793956],
  ['Louisiana', 'LA', '22', '095', 'St. John the Baptist', 30.117471, -90.504677],
  ['Louisiana', 'LA', '22', '097', 'St. Landry', 30.583441, -91.989275],
  ['Louisiana', 'LA', '22', '099', 'St. Martin', 30.121433, -91.611481],
  ['Louisiana', 'LA', '22', '101', 'St. Mary', 29.629349, -91.463804],
  ['Louisiana', 'LA', '22', '103', 'St. Tammany', 30.410022, -89.951962],
  ['Louisiana', 'LA', '22', '105', 'Tangipahoa', 30.621581, -90.406633],
  ['Louisiana', 'LA', '22', '107', 'Tensas', 32.001489, -91.342576],
  ['Louisiana', 'LA', '22', '109', 'Terrebonne', 29.333266, -90.844191],
  ['Louisiana', 'LA', '22', '111', 'Union', 32.829349, -92.37565],
  ['Louisiana', 'LA', '22', '113', 'Vermilion', 29.786872, -92.29009],
  ['Louisiana', 'LA', '22', '115', 'Vernon', 31.110563, -93.18152],
  ['Louisiana', 'LA', '22', '117', 'Washington', 30.852144, -90.046253],
  ['Louisiana', 'LA', '22', '119', 'Webster', 32.732152, -93.339825],
  ['Louisiana', 'LA', '22', '121', 'West Baton Rouge', 30.464052, -91.309808],
  ['Louisiana', 'LA', '22', '123', 'West Carroll', 32.79248, -91.451998],
  ['Louisiana', 'LA', '22', '125', 'West Feliciana', 30.872701, -91.421008],
  ['Louisiana', 'LA', '22', '127', 'Winn', 31.941187, -92.641269],
  ['Maine', 'ME', '23', '001', 'Androscoggin', 44.167681, -70.207435],
  ['Maine', 'ME', '23', '003', 'Aroostook', 46.727057, -68.64941],
  ['Maine', 'ME', '23', '005', 'Cumberland', 43.808348, -70.330375],
  ['Maine', 'ME', '23', '007', 'Franklin', 44.973012, -70.444727],
  ['Maine', 'ME', '23', '009', 'Hancock', 44.564906, -68.370703],
  ['Maine', 'ME', '23', '011', 'Kennebec', 44.417012, -69.765764],
  ['Maine', 'ME', '23', '013', 'Knox', 44.042045, -69.038515],
  ['Maine', 'ME', '23', '015', 'Lincoln', 43.994265, -69.514029],
  ['Maine', 'ME', '23', '017', 'Oxford', 44.494585, -70.734688],
  ['Maine', 'ME', '23', '019', 'Penobscot', 45.390602, -68.657487],
  ['Maine', 'ME', '23', '021', 'Piscataquis', 45.917678, -69.104548],
  ['Maine', 'ME', '23', '023', 'Sagadahoc', 43.916694, -69.843994],
  ['Maine', 'ME', '23', '025', 'Somerset', 45.507482, -69.97604],
  ['Maine', 'ME', '23', '027', 'Waldo', 44.505361, -69.139678],
  ['Maine', 'ME', '23', '029', 'Washington', 44.967009, -67.609354],
  ['Maine', 'ME', '23', '031', 'York', 43.427239, -70.670402],
  ['Maryland', 'MD', '24', '001', 'Allegany', 39.612309, -78.703108],
  ['Maryland', 'MD', '24', '003', 'Anne Arundel', 38.993374, -76.560511],
  ['Maryland', 'MD', '24', '005', 'Baltimore', 39.443167, -76.616569],
  ['Maryland', 'MD', '24', '009', 'Calvert', 38.521358, -76.525864],
  ['Maryland', 'MD', '24', '011', 'Caroline', 38.871531, -75.831631],
  ['Maryland', 'MD', '24', '013', 'Carroll', 39.563189, -77.015512],
  ['Maryland', 'MD', '24', '015', 'Cecil', 39.562352, -75.941584],
  ['Maryland', 'MD', '24', '017', 'Charles', 38.472853, -77.015427],
  ['Maryland', 'MD', '24', '019', 'Dorchester', 38.429196, -76.047433],
  ['Maryland', 'MD', '24', '021', 'Frederick', 39.470427, -77.397627],
  ['Maryland', 'MD', '24', '023', 'Garrett', 39.529871, -79.269416],
  ['Maryland', 'MD', '24', '025', 'Harford', 39.537429, -76.299789],
  ['Maryland', 'MD', '24', '027', 'Howard', 39.252264, -76.924406],
  ['Maryland', 'MD', '24', '029', 'Kent', 39.239177, -76.1242],
  ['Maryland', 'MD', '24', '031', 'Montgomery', 39.137382, -77.203063],
  ['Maryland', 'MD', '24', '033', "Prince George's", 38.82588, -76.847272],
  ['Maryland', 'MD', '24', '035', "Queen Anne's", 39.040693, -76.082405],
  ['Maryland', 'MD', '24', '037', "St. Mary's", 38.222666, -76.534271],
  ['Maryland', 'MD', '24', '039', 'Somerset', 38.07445, -75.853323],
  ['Maryland', 'MD', '24', '041', 'Talbot', 38.749427, -76.17913],
  ['Maryland', 'MD', '24', '043', 'Washington', 39.603621, -77.814671],
  ['Maryland', 'MD', '24', '045', 'Wicomico', 38.367389, -75.632206],
  ['Maryland', 'MD', '24', '047', 'Worcester', 38.222133, -75.309932],
  ['Maryland', 'MD', '24', '510', 'Baltimore', 39.300214, -76.610516],
  ['Massachusetts', 'MA', '25', '001', 'Barnstable', 41.798819, -70.211083],
  ['Massachusetts', 'MA', '25', '003', 'Berkshire', 42.375314, -73.213948],
  ['Massachusetts', 'MA', '25', '005', 'Bristol', 41.748576, -71.087062],
  ['Massachusetts', 'MA', '25', '007', 'Dukes', 41.380939, -70.701536],
  ['Massachusetts', 'MA', '25', '009', 'Essex', 42.642711, -70.865107],
  ['Massachusetts', 'MA', '25', '011', 'Franklin', 42.583791, -72.591655],
  ['Massachusetts', 'MA', '25', '013', 'Hampden', 42.136198, -72.635648],
  ['Massachusetts', 'MA', '25', '015', 'Hampshire', 42.339459, -72.663694],
  ['Massachusetts', 'MA', '25', '017', 'Middlesex', 42.479477, -71.396507],
  ['Massachusetts', 'MA', '25', '019', 'Nantucket', 41.305878, -70.14191],
  ['Massachusetts', 'MA', '25', '021', 'Norfolk', 42.169703, -71.179875],
  ['Massachusetts', 'MA', '25', '023', 'Plymouth', 41.987196, -70.741942],
  ['Massachusetts', 'MA', '25', '025', 'Suffolk', 42.33196, -71.020173],
  ['Massachusetts', 'MA', '25', '027', 'Worcester', 42.311693, -71.940282],
  ['Michigan', 'MI', '26', '001', 'Alcona', 44.682535, -82.83408],
  ['Michigan', 'MI', '26', '003', 'Alger', 47.080077, -86.564108],
  ['Michigan', 'MI', '26', '005', 'Allegan', 42.595788, -86.634745],
  ['Michigan', 'MI', '26', '007', 'Alpena', 44.894954, -83.426574],
  ['Michigan', 'MI', '26', '009', 'Antrim', 45.005457, -85.175625],
  ['Michigan', 'MI', '26', '011', 'Arenac', 44.03687, -83.740675],
  ['Michigan', 'MI', '26', '013', 'Baraga', 46.696068, -88.356022],
  ['Michigan', 'MI', '26', '015', 'Barry', 42.582811, -85.31455],
  ['Michigan', 'MI', '26', '017', 'Bay', 43.699711, -83.978701],
  ['Michigan', 'MI', '26', '019', 'Benzie', 44.648621, -86.494317],
  ['Michigan', 'MI', '26', '021', 'Berrien', 41.792639, -86.741822],
  ['Michigan', 'MI', '26', '023', 'Branch', 41.918585, -85.066523],
  ['Michigan', 'MI', '26', '025', 'Calhoun', 42.24299, -85.012385],
  ['Michigan', 'MI', '26', '027', 'Cass', 41.91624, -85.999457],
  ['Michigan', 'MI', '26', '029', 'Charlevoix', 45.513164, -85.450392],
  ['Michigan', 'MI', '26', '031', 'Cheboygan', 45.47612, -84.495271],
  ['Michigan', 'MI', '26', '033', 'Chippewa', 46.321819, -84.52063],
  ['Michigan', 'MI', '26', '035', 'Clare', 43.991137, -84.838325],
  ['Michigan', 'MI', '26', '037', 'Clinton', 42.950455, -84.591695],
  ['Michigan', 'MI', '26', '039', 'Crawford', 44.680208, -84.611132],
  ['Michigan', 'MI', '26', '041', 'Delta', 45.805101, -86.901373],
  ['Michigan', 'MI', '26', '043', 'Dickinson', 46.012823, -87.866119],
  ['Michigan', 'MI', '26', '045', 'Eaton', 42.589614, -84.846524],
  ['Michigan', 'MI', '26', '047', 'Emmet', 45.590094, -84.986822],
  ['Michigan', 'MI', '26', '049', 'Genesee', 43.021077, -83.706372],
  ['Michigan', 'MI', '26', '051', 'Gladwin', 43.98975, -84.389816],
  ['Michigan', 'MI', '26', '053', 'Gogebic', 46.488054, -89.788314],
  ['Michigan', 'MI', '26', '055', 'Grand Traverse', 44.718688, -85.553848],
  ['Michigan', 'MI', '26', '057', 'Gratiot', 43.292326, -84.60469],
  ['Michigan', 'MI', '26', '059', 'Hillsdale', 41.864475, -84.642409],
  ['Michigan', 'MI', '26', '061', 'Houghton', 46.998305, -88.652066],
  ['Michigan', 'MI', '26', '063', 'Huron', 43.907616, -82.857045],
  ['Michigan', 'MI', '26', '065', 'Ingham', 42.603534, -84.373811],
  ['Michigan', 'MI', '26', '067', 'Ionia', 42.94465, -85.073766],
  ['Michigan', 'MI', '26', '069', 'Iosco', 44.329482, -82.849447],
  ['Michigan', 'MI', '26', '071', 'Iron', 46.170249, -88.540409],
  ['Michigan', 'MI', '26', '073', 'Isabella', 43.645233, -84.839425],
  ['Michigan', 'MI', '26', '075', 'Jackson', 42.248474, -84.420868],
  ['Michigan', 'MI', '26', '077', 'Kalamazoo', 42.246266, -85.532854],
  ['Michigan', 'MI', '26', '079', 'Kalkaska', 44.678881, -85.088992],
  ['Michigan', 'MI', '26', '081', 'Kent', 43.032497, -85.547446],
  ['Michigan', 'MI', '26', '083', 'Keweenaw', 47.681981, -88.148802],
  ['Michigan', 'MI', '26', '085', 'Lake', 43.995187, -85.8114],
  ['Michigan', 'MI', '26', '087', 'Lapeer', 43.088633, -83.224325],
  ['Michigan', 'MI', '26', '089', 'Leelanau', 45.146182, -86.051574],
  ['Michigan', 'MI', '26', '091', 'Lenawee', 41.895915, -84.066853],
  ['Michigan', 'MI', '26', '093', 'Livingston', 42.602532, -83.911718],
  ['Michigan', 'MI', '26', '095', 'Luce', 46.940602, -85.582368],
  ['Michigan', 'MI', '26', '097', 'Mackinac', 46.167981, -85.303756],
  ['Michigan', 'MI', '26', '099', 'Macomb', 42.671467, -82.910869],
  ['Michigan', 'MI', '26', '101', 'Manistee', 44.350385, -86.602967],
  ['Michigan', 'MI', '26', '103', 'Marquette', 46.656597, -87.584028],
  ['Michigan', 'MI', '26', '105', 'Mason', 43.996636, -86.750814],
  ['Michigan', 'MI', '26', '107', 'Mecosta', 43.635295, -85.332751],
  ['Michigan', 'MI', '26', '109', 'Menominee', 45.544174, -87.509892],
  ['Michigan', 'MI', '26', '111', 'Midland', 43.648378, -84.37922],
  ['Michigan', 'MI', '26', '113', 'Missaukee', 44.325424, -85.085471],
  ['Michigan', 'MI', '26', '115', 'Monroe', 41.916097, -83.487106],
  ['Michigan', 'MI', '26', '117', 'Montcalm', 43.312782, -85.149468],
  ['Michigan', 'MI', '26', '119', 'Montmorency', 45.024134, -84.130107],
  ['Michigan', 'MI', '26', '121', 'Muskegon', 43.289258, -86.751892],
  ['Michigan', 'MI', '26', '123', 'Newaygo', 43.562709, -85.791423],
  ['Michigan', 'MI', '26', '125', 'Oakland', 42.660452, -83.38421],
  ['Michigan', 'MI', '26', '127', 'Oceana', 43.647255, -86.807575],
  ['Michigan', 'MI', '26', '129', 'Ogemaw', 44.33328, -84.128073],
  ['Michigan', 'MI', '26', '131', 'Ontonagon', 47.216604, -89.500461],
  ['Michigan', 'MI', '26', '133', 'Osceola', 43.997552, -85.322283],
  ['Michigan', 'MI', '26', '135', 'Oscoda', 44.685121, -84.124894],
  ['Michigan', 'MI', '26', '137', 'Otsego', 45.021794, -84.576597],
  ['Michigan', 'MI', '26', '139', 'Ottawa', 42.942346, -86.655342],
  ['Michigan', 'MI', '26', '141', 'Presque Isle', 45.489515, -83.384019],
  ['Michigan', 'MI', '26', '143', 'Roscommon', 44.339517, -84.611272],
  ['Michigan', 'MI', '26', '145', 'Saginaw', 43.328267, -84.05541],
  ['Michigan', 'MI', '26', '147', 'St. Clair', 42.928804, -82.668914],
  ['Michigan', 'MI', '26', '149', 'St. Joseph', 41.911488, -85.52287],
  ['Michigan', 'MI', '26', '151', 'Sanilac', 43.449155, -82.642815],
  ['Michigan', 'MI', '26', '153', 'Schoolcraft', 46.020758, -86.199352],
  ['Michigan', 'MI', '26', '155', 'Shiawassee', 42.951545, -84.146352],
  ['Michigan', 'MI', '26', '157', 'Tuscola', 43.487902, -83.436618],
  ['Michigan', 'MI', '26', '159', 'Van Buren', 42.283986, -86.305697],
  ['Michigan', 'MI', '26', '161', 'Washtenaw', 42.252327, -83.844634],
  ['Michigan', 'MI', '26', '163', 'Wayne', 42.284664, -83.261953],
  ['Michigan', 'MI', '26', '165', 'Wexford', 44.331375, -85.570046],
  ['Minnesota', 'MN', '27', '001', 'Aitkin', 46.602446, -93.41976],
  ['Minnesota', 'MN', '27', '003', 'Anoka', 45.27411, -93.242723],
  ['Minnesota', 'MN', '27', '005', 'Becker', 46.937629, -95.741757],
  ['Minnesota', 'MN', '27', '007', 'Beltrami', 47.878825, -94.986698],
  ['Minnesota', 'MN', '27', '009', 'Benton', 45.701227, -94.00144],
  ['Minnesota', 'MN', '27', '011', 'Big Stone', 45.419925, -96.402226],
  ['Minnesota', 'MN', '27', '013', 'Blue Earth', 44.038225, -94.064071],
  ['Minnesota', 'MN', '27', '015', 'Brown', 44.246542, -94.733647],
  ['Minnesota', 'MN', '27', '017', 'Carlton', 46.603818, -92.671044],
  ['Minnesota', 'MN', '27', '019', 'Carver', 44.821381, -93.800575],
  ['Minnesota', 'MN', '27', '021', 'Cass', 46.951427, -94.333773],
  ['Minnesota', 'MN', '27', '023', 'Chippewa', 45.028625, -95.564108],
  ['Minnesota', 'MN', '27', '025', 'Chisago', 45.505444, -92.903849],
  ['Minnesota', 'MN', '27', '027', 'Clay', 46.898377, -96.494901],
  ['Minnesota', 'MN', '27', '029', 'Clearwater', 47.575873, -95.371117],
  ['Minnesota', 'MN', '27', '031', 'Cook', 47.538571, -90.29019],
  ['Minnesota', 'MN', '27', '033', 'Cottonwood', 44.010711, -95.18313],
  ['Minnesota', 'MN', '27', '035', 'Crow Wing', 46.491114, -94.071213],
  ['Minnesota', 'MN', '27', '037', 'Dakota', 44.670893, -93.062481],
  ['Minnesota', 'MN', '27', '039', 'Dodge', 44.020706, -92.869353],
  ['Minnesota', 'MN', '27', '041', 'Douglas', 45.936429, -95.46061],
  ['Minnesota', 'MN', '27', '043', 'Faribault', 43.676522, -93.947234],
  ['Minnesota', 'MN', '27', '045', 'Fillmore', 43.67727, -92.093681],
  ['Minnesota', 'MN', '27', '047', 'Freeborn', 43.674202, -93.350289],
  ['Minnesota', 'MN', '27', '049', 'Goodhue', 44.406178, -92.716],
  ['Minnesota', 'MN', '27', '051', 'Grant', 45.930743, -96.010699],
  ['Minnesota', 'MN', '27', '053', 'Hennepin', 45.006064, -93.475185],
  ['Minnesota', 'MN', '27', '055', 'Houston', 43.66699, -91.501556],
  ['Minnesota', 'MN', '27', '057', 'Hubbard', 47.095551, -94.91329],
  ['Minnesota', 'MN', '27', '059', 'Isanti', 45.562431, -93.296339],
  ['Minnesota', 'MN', '27', '061', 'Itasca', 47.490843, -93.613128],
  ['Minnesota', 'MN', '27', '063', 'Jackson', 43.673025, -95.149704],
  ['Minnesota', 'MN', '27', '065', 'Kanabec', 45.94776, -93.297788],
  ['Minnesota', 'MN', '27', '067', 'Kandiyohi', 45.152714, -95.004981],
  ['Minnesota', 'MN', '27', '069', 'Kittson', 48.77604, -96.780349],
  ['Minnesota', 'MN', '27', '071', 'Koochiching', 48.24549, -93.782842],
  ['Minnesota', 'MN', '27', '073', 'Lac qui Parle', 44.999855, -96.176836],
  ['Minnesota', 'MN', '27', '075', 'Lake', 47.517111, -91.411704],
  ['Minnesota', 'MN', '27', '077', 'Lake of the Woods', 48.7681, -94.904634],
  ['Minnesota', 'MN', '27', '079', 'Le Sueur', 44.373397, -93.73018],
  ['Minnesota', 'MN', '27', '081', 'Lincoln', 44.408238, -96.272032],
  ['Minnesota', 'MN', '27', '083', 'Lyon', 44.409195, -95.847268],
  ['Minnesota', 'MN', '27', '085', 'McLeod', 44.821644, -94.27232],
  ['Minnesota', 'MN', '27', '087', 'Mahnomen', 47.325842, -95.810703],
  ['Minnesota', 'MN', '27', '089', 'Marshall', 48.362728, -96.357761],
  ['Minnesota', 'MN', '27', '091', 'Martin', 43.677118, -94.537198],
  ['Minnesota', 'MN', '27', '093', 'Meeker', 45.123156, -94.527346],
  ['Minnesota', 'MN', '27', '095', 'Mille Lacs', 45.929043, -93.632996],
  ['Minnesota', 'MN', '27', '097', 'Morrison', 46.020484, -94.266619],
  ['Minnesota', 'MN', '27', '099', 'Mower', 43.666249, -92.759514],
  ['Minnesota', 'MN', '27', '101', 'Murray', 44.015594, -95.761581],
  ['Minnesota', 'MN', '27', '103', 'Nicollet', 44.35882, -94.245685],
  ['Minnesota', 'MN', '27', '105', 'Nobles', 43.677686, -95.763132],
  ['Minnesota', 'MN', '27', '107', 'Norman', 47.32947, -96.463807],
  ['Minnesota', 'MN', '27', '109', 'Olmsted', 44.00343, -92.406722],
  ['Minnesota', 'MN', '27', '111', 'Otter Tail', 46.405725, -95.714578],
  ['Minnesota', 'MN', '27', '113', 'Pennington', 48.069247, -96.037725],
  ['Minnesota', 'MN', '27', '115', 'Pine', 46.10094, -92.763094],
  ['Minnesota', 'MN', '27', '117', 'Pipestone', 44.015361, -96.257015],
  ['Minnesota', 'MN', '27', '119', 'Polk', 47.774254, -96.400027],
  ['Minnesota', 'MN', '27', '121', 'Pope', 45.589623, -95.446705],
  ['Minnesota', 'MN', '27', '123', 'Ramsey', 45.01525, -93.100141],
  ['Minnesota', 'MN', '27', '125', 'Red Lake', 47.865487, -96.08718],
  ['Minnesota', 'MN', '27', '127', 'Redwood', 44.403536, -95.254242],
  ['Minnesota', 'MN', '27', '129', 'Renville', 44.723697, -94.955617],
  ['Minnesota', 'MN', '27', '131', 'Rice', 44.350943, -93.298503],
  ['Minnesota', 'MN', '27', '133', 'Rock', 43.669587, -96.263238],
  ['Minnesota', 'MN', '27', '135', 'Roseau', 48.761066, -95.82153],
  ['Minnesota', 'MN', '27', '137', 'St. Louis', 47.583852, -92.463645],
  ['Minnesota', 'MN', '27', '139', 'Scott', 44.651932, -93.534553],
  ['Minnesota', 'MN', '27', '141', 'Sherburne', 45.443171, -93.775092],
  ['Minnesota', 'MN', '27', '143', 'Sibley', 44.575734, -94.230123],
  ['Minnesota', 'MN', '27', '145', 'Stearns', 45.555235, -94.610482],
  ['Minnesota', 'MN', '27', '147', 'Steele', 44.015261, -93.220453],
  ['Minnesota', 'MN', '27', '149', 'Stevens', 45.593461, -95.992315],
  ['Minnesota', 'MN', '27', '151', 'Swift', 45.27545, -95.690398],
  ['Minnesota', 'MN', '27', '153', 'Todd', 46.066569, -94.900576],
  ['Minnesota', 'MN', '27', '155', 'Traverse', 45.76984, -96.475049],
  ['Minnesota', 'MN', '27', '157', 'Wabasha', 44.289693, -92.233341],
  ['Minnesota', 'MN', '27', '159', 'Wadena', 46.586784, -94.988331],
  ['Minnesota', 'MN', '27', '161', 'Waseca', 44.01846, -93.589844],
  ['Minnesota', 'MN', '27', '163', 'Washington', 45.037929, -92.890117],
  ['Minnesota', 'MN', '27', '165', 'Watonwan', 43.978366, -94.614128],
  ['Minnesota', 'MN', '27', '167', 'Wilkin', 46.362335, -96.476657],
  ['Minnesota', 'MN', '27', '169', 'Winona', 43.982268, -91.776708],
  ['Minnesota', 'MN', '27', '171', 'Wright', 45.175091, -93.966397],
  ['Minnesota', 'MN', '27', '173', 'Yellow Medicine', 44.715736, -95.862756],
  ['Mississippi', 'MS', '28', '001', 'Adams', 31.486218, -91.351781],
  ['Mississippi', 'MS', '28', '003', 'Alcorn', 34.886648, -88.581014],
  ['Mississippi', 'MS', '28', '005', 'Amite', 31.203933, -90.795542],
  ['Mississippi', 'MS', '28', '007', 'Attala', 33.09047, -89.588622],
  ['Mississippi', 'MS', '28', '009', 'Benton', 34.810773, -89.200029],
  ['Mississippi', 'MS', '28', '011', 'Bolivar', 33.799278, -90.884476],
  ['Mississippi', 'MS', '28', '013', 'Calhoun', 33.936634, -89.337114],
  ['Mississippi', 'MS', '28', '015', 'Carroll', 33.440795, -89.918887],
  ['Mississippi', 'MS', '28', '017', 'Chickasaw', 33.921654, -88.945808],
  ['Mississippi', 'MS', '28', '019', 'Choctaw', 33.345964, -89.251332],
  ['Mississippi', 'MS', '28', '021', 'Claiborne', 31.97281, -90.915424],
  ['Mississippi', 'MS', '28', '023', 'Clarke', 32.048448, -88.685964],
  ['Mississippi', 'MS', '28', '025', 'Clay', 33.661734, -88.783326],
  ['Mississippi', 'MS', '28', '027', 'Coahoma', 34.22867, -90.603165],
  ['Mississippi', 'MS', '28', '029', 'Copiah', 31.866915, -90.448758],
  ['Mississippi', 'MS', '28', '031', 'Covington', 31.633331, -89.548897],
  ['Mississippi', 'MS', '28', '033', 'DeSoto', 34.874266, -89.99324],
  ['Mississippi', 'MS', '28', '035', 'Forrest', 31.18858, -89.259447],
  ['Mississippi', 'MS', '28', '037', 'Franklin', 31.477798, -90.895884],
  ['Mississippi', 'MS', '28', '039', 'George', 30.855431, -88.642268],
  ['Mississippi', 'MS', '28', '041', 'Greene', 31.212846, -88.634811],
  ['Mississippi', 'MS', '28', '043', 'Grenada', 33.770031, -89.80274],
  ['Mississippi', 'MS', '28', '045', 'Hancock', 30.391437, -89.47985],
  ['Mississippi', 'MS', '28', '047', 'Harrison', 30.416536, -89.083376],
  ['Mississippi', 'MS', '28', '049', 'Hinds', 32.267924, -90.4659],
  ['Mississippi', 'MS', '28', '051', 'Holmes', 33.125942, -90.091197],
  ['Mississippi', 'MS', '28', '053', 'Humphreys', 33.132978, -90.523911],
  ['Mississippi', 'MS', '28', '055', 'Issaquena', 32.754977, -90.988552],
  ['Mississippi', 'MS', '28', '057', 'Itawamba', 34.281075, -88.363127],
  ['Mississippi', 'MS', '28', '059', 'Jackson', 30.458491, -88.619991],
  ['Mississippi', 'MS', '28', '061', 'Jasper', 32.016989, -89.11943],
  ['Mississippi', 'MS', '28', '063', 'Jefferson', 31.733633, -91.043878],
  ['Mississippi', 'MS', '28', '065', 'Jefferson Davis', 31.564734, -89.826626],
  ['Mississippi', 'MS', '28', '067', 'Jones', 31.621044, -89.167262],
  ['Mississippi', 'MS', '28', '069', 'Kemper', 32.750136, -88.625631],
  ['Mississippi', 'MS', '28', '071', 'Lafayette', 34.349298, -89.485903],
  ['Mississippi', 'MS', '28', '073', 'Lamar', 31.197135, -89.514952],
  ['Mississippi', 'MS', '28', '075', 'Lauderdale', 32.403998, -88.660449],
  ['Mississippi', 'MS', '28', '077', 'Lawrence', 31.550009, -90.10753],
  ['Mississippi', 'MS', '28', '079', 'Leake', 32.753268, -89.522568],
  ['Mississippi', 'MS', '28', '081', 'Lee', 34.288965, -88.680887],
  ['Mississippi', 'MS', '28', '083', 'Leflore', 33.54979, -90.294934],
  ['Mississippi', 'MS', '28', '085', 'Lincoln', 31.535216, -90.453566],
  ['Mississippi', 'MS', '28', '087', 'Lowndes', 33.471424, -88.439723],
  ['Mississippi', 'MS', '28', '089', 'Madison', 32.63437, -90.03416],
  ['Mississippi', 'MS', '28', '091', 'Marion', 31.230126, -89.821736],
  ['Mississippi', 'MS', '28', '093', 'Marshall', 34.76619, -89.504231],
  ['Mississippi', 'MS', '28', '095', 'Monroe', 33.89003, -88.485038],
  ['Mississippi', 'MS', '28', '097', 'Montgomery', 33.500714, -89.639625],
  ['Mississippi', 'MS', '28', '099', 'Neshoba', 32.752518, -89.119274],
  ['Mississippi', 'MS', '28', '101', 'Newton', 32.40197, -89.118412],
  ['Mississippi', 'MS', '28', '103', 'Noxubee', 33.10627, -88.565787],
  ['Mississippi', 'MS', '28', '105', 'Oktibbeha', 33.422313, -88.876151],
  ['Mississippi', 'MS', '28', '107', 'Panola', 34.365205, -89.963065],
  ['Mississippi', 'MS', '28', '109', 'Pearl River', 30.773791, -89.586832],
  ['Mississippi', 'MS', '28', '111', 'Perry', 31.169307, -88.988755],
  ['Mississippi', 'MS', '28', '113', 'Pike', 31.177517, -90.397725],
  ['Mississippi', 'MS', '28', '115', 'Pontotoc', 34.227081, -89.037239],
  ['Mississippi', 'MS', '28', '117', 'Prentiss', 34.620866, -88.52224],
  ['Mississippi', 'MS', '28', '119', 'Quitman', 34.25264, -90.290525],
  ['Mississippi', 'MS', '28', '121', 'Rankin', 32.262057, -89.946552],
  ['Mississippi', 'MS', '28', '123', 'Scott', 32.427127, -89.533209],
  ['Mississippi', 'MS', '28', '125', 'Sharkey', 32.89249, -90.827388],
  ['Mississippi', 'MS', '28', '127', 'Simpson', 31.902503, -89.917707],
  ['Mississippi', 'MS', '28', '129', 'Smith', 32.019034, -89.494973],
  ['Mississippi', 'MS', '28', '131', 'Stone', 30.790184, -89.112297],
  ['Mississippi', 'MS', '28', '133', 'Sunflower', 33.605529, -90.59509],
  ['Mississippi', 'MS', '28', '135', 'Tallahatchie', 33.955891, -90.172833],
  ['Mississippi', 'MS', '28', '137', 'Tate', 34.649553, -89.943105],
  ['Mississippi', 'MS', '28', '139', 'Tippah', 34.763618, -88.918819],
  ['Mississippi', 'MS', '28', '141', 'Tishomingo', 34.737699, -88.236067],
  ['Mississippi', 'MS', '28', '143', 'Tunica', 34.652201, -90.37177],
  ['Mississippi', 'MS', '28', '145', 'Union', 34.489101, -89.0023],
  ['Mississippi', 'MS', '28', '147', 'Walthall', 31.164492, -90.103431],
  ['Mississippi', 'MS', '28', '149', 'Warren', 32.357005, -90.851791],
  ['Mississippi', 'MS', '28', '151', 'Washington', 33.273131, -90.94443],
  ['Mississippi', 'MS', '28', '153', 'Wayne', 31.64476, -88.682057],
  ['Mississippi', 'MS', '28', '155', 'Webster', 33.612048, -89.283836],
  ['Mississippi', 'MS', '28', '157', 'Wilkinson', 31.160249, -91.325567],
  ['Mississippi', 'MS', '28', '159', 'Winston', 33.078724, -89.037391],
  ['Mississippi', 'MS', '28', '161', 'Yalobusha', 34.030669, -89.703793],
  ['Mississippi', 'MS', '28', '163', 'Yazoo', 32.765675, -90.387928],
  ['Missouri', 'MO', '29', '001', 'Adair', 40.190666, -92.603592],
  ['Missouri', 'MO', '29', '003', 'Andrew', 39.988863, -94.803551],
  ['Missouri', 'MO', '29', '005', 'Atchison', 40.431846, -95.437555],
  ['Missouri', 'MO', '29', '007', 'Audrain', 39.21448, -91.843415],
  ['Missouri', 'MO', '29', '009', 'Barry', 36.699378, -93.834326],
  ['Missouri', 'MO', '29', '011', 'Barton', 37.500799, -94.344089],
  ['Missouri', 'MO', '29', '013', 'Bates', 38.257217, -94.339246],
  ['Missouri', 'MO', '29', '015', 'Benton', 38.301036, -93.287942],
  ['Missouri', 'MO', '29', '017', 'Bollinger', 37.318349, -90.024601],
  ['Missouri', 'MO', '29', '019', 'Boone', 38.989657, -92.310779],
  ['Missouri', 'MO', '29', '021', 'Buchanan', 39.66037, -94.808173],
  ['Missouri', 'MO', '29', '023', 'Butler', 36.71518, -90.40313],
  ['Missouri', 'MO', '29', '025', 'Caldwell', 39.658998, -93.979179],
  ['Missouri', 'MO', '29', '027', 'Callaway', 38.835966, -91.924089],
  ['Missouri', 'MO', '29', '029', 'Camden', 38.031995, -92.765138],
  ['Missouri', 'MO', '29', '031', 'Cape Girardeau', 37.383882, -89.684908],
  ['Missouri', 'MO', '29', '033', 'Carroll', 39.427375, -93.500227],
  ['Missouri', 'MO', '29', '035', 'Carter', 36.944783, -90.945626],
  ['Missouri', 'MO', '29', '037', 'Cass', 38.647159, -94.354242],
  ['Missouri', 'MO', '29', '039', 'Cedar', 37.733655, -93.850014],
  ['Missouri', 'MO', '29', '041', 'Chariton', 39.517969, -92.961621],
  ['Missouri', 'MO', '29', '043', 'Christian', 36.969739, -93.187614],
  ['Missouri', 'MO', '29', '045', 'Clark', 40.407275, -91.729472],
  ['Missouri', 'MO', '29', '047', 'Clay', 39.315551, -94.421502],
  ['Missouri', 'MO', '29', '049', 'Clinton', 39.608723, -94.395803],
  ['Missouri', 'MO', '29', '051', 'Cole', 38.506847, -92.271404],
  ['Missouri', 'MO', '29', '053', 'Cooper', 38.845386, -92.812323],
  ['Missouri', 'MO', '29', '055', 'Crawford', 37.966561, -91.313933],
  ['Missouri', 'MO', '29', '057', 'Dade', 37.43235, -93.854878],
  ['Missouri', 'MO', '29', '059', 'Dallas', 37.683583, -93.033812],
  ['Missouri', 'MO', '29', '061', 'Daviess', 39.962839, -93.970053],
  ['Missouri', 'MO', '29', '063', 'DeKalb', 39.894665, -94.40719],
  ['Missouri', 'MO', '29', '065', 'Dent', 37.60325, -91.495916],
  ['Missouri', 'MO', '29', '067', 'Douglas', 36.946517, -92.515892],
  ['Missouri', 'MO', '29', '069', 'Dunklin', 36.153025, -90.062254],
  ['Missouri', 'MO', '29', '071', 'Franklin', 38.408313, -91.07341],
  ['Missouri', 'MO', '29', '073', 'Gasconade', 38.441183, -91.50578],
  ['Missouri', 'MO', '29', '075', 'Gentry', 40.209379, -94.40411],
  ['Missouri', 'MO', '29', '077', 'Greene', 37.258196, -93.340641],
  ['Missouri', 'MO', '29', '079', 'Grundy', 40.112541, -93.565054],
  ['Missouri', 'MO', '29', '081', 'Harrison', 40.34562, -93.992582],
  ['Missouri', 'MO', '29', '083', 'Henry', 38.386491, -93.792628],
  ['Missouri', 'MO', '29', '085', 'Hickory', 37.9367, -93.322835],
  ['Missouri', 'MO', '29', '087', 'Holt', 40.095724, -95.219072],
  ['Missouri', 'MO', '29', '089', 'Howard', 39.143365, -92.695926],
  ['Missouri', 'MO', '29', '091', 'Howell', 36.774369, -91.887368],
  ['Missouri', 'MO', '29', '093', 'Iron', 37.62596, -90.699627],
  ['Missouri', 'MO', '29', '095', 'Jackson', 39.007233, -94.342507],
  ['Missouri', 'MO', '29', '097', 'Jasper', 37.200865, -94.338869],
  ['Missouri', 'MO', '29', '099', 'Jefferson', 38.257414, -90.543138],
  ['Missouri', 'MO', '29', '101', 'Johnson', 38.74588, -93.805999],
  ['Missouri', 'MO', '29', '103', 'Knox', 40.136776, -92.146809],
  ['Missouri', 'MO', '29', '105', 'Laclede', 37.65969, -92.594832],
  ['Missouri', 'MO', '29', '107', 'Lafayette', 39.068705, -93.802639],
  ['Missouri', 'MO', '29', '109', 'Lawrence', 37.106135, -93.833262],
  ['Missouri', 'MO', '29', '111', 'Lewis', 40.084559, -91.728803],
  ['Missouri', 'MO', '29', '113', 'Lincoln', 39.058568, -90.957771],
  ['Missouri', 'MO', '29', '115', 'Linn', 39.86444, -93.108019],
  ['Missouri', 'MO', '29', '117', 'Livingston', 39.778587, -93.548201],
  ['Missouri', 'MO', '29', '119', 'McDonald', 36.630218, -94.343956],
  ['Missouri', 'MO', '29', '121', 'Macon', 39.829795, -92.56434],
  ['Missouri', 'MO', '29', '123', 'Madison', 37.473235, -90.345453],
  ['Missouri', 'MO', '29', '125', 'Maries', 38.162615, -91.923601],
  ['Missouri', 'MO', '29', '127', 'Marion', 39.807538, -91.635379],
  ['Missouri', 'MO', '29', '129', 'Mercer', 40.421414, -93.567631],
  ['Missouri', 'MO', '29', '131', 'Miller', 38.21672, -92.429871],
  ['Missouri', 'MO', '29', '133', 'Mississippi', 36.826264, -89.295929],
  ['Missouri', 'MO', '29', '135', 'Moniteau', 38.633037, -92.583642],
  ['Missouri', 'MO', '29', '137', 'Monroe', 39.49827, -92.006458],
  ['Missouri', 'MO', '29', '139', 'Montgomery', 38.935162, -91.465437],
  ['Missouri', 'MO', '29', '141', 'Morgan', 38.420807, -92.874835],
  ['Missouri', 'MO', '29', '143', 'New Madrid', 36.594261, -89.655949],
  ['Missouri', 'MO', '29', '145', 'Newton', 36.908017, -94.334741],
  ['Missouri', 'MO', '29', '147', 'Nodaway', 40.360484, -94.883281],
  ['Missouri', 'MO', '29', '149', 'Oregon', 36.684867, -91.402902],
  ['Missouri', 'MO', '29', '151', 'Osage', 38.464254, -91.859504],
  ['Missouri', 'MO', '29', '153', 'Ozark', 36.649643, -92.45858],
  ['Missouri', 'MO', '29', '155', 'Pemiscot', 36.209916, -89.785942],
  ['Missouri', 'MO', '29', '157', 'Perry', 37.71113, -89.802125],
  ['Missouri', 'MO', '29', '159', 'Pettis', 38.727367, -93.285207],
  ['Missouri', 'MO', '29', '161', 'Phelps', 37.866326, -91.790349],
  ['Missouri', 'MO', '29', '163', 'Pike', 39.344178, -91.171042],
  ['Missouri', 'MO', '29', '165', 'Platte', 39.378696, -94.761472],
  ['Missouri', 'MO', '29', '167', 'Polk', 37.616761, -93.400817],
  ['Missouri', 'MO', '29', '169', 'Pulaski', 37.824835, -92.207022],
  ['Missouri', 'MO', '29', '171', 'Putnam', 40.478606, -93.014531],
  ['Missouri', 'MO', '29', '173', 'Ralls', 39.553455, -91.524787],
  ['Missouri', 'MO', '29', '175', 'Randolph', 39.441601, -92.492725],
  ['Missouri', 'MO', '29', '177', 'Ray', 39.308401, -93.995746],
  ['Missouri', 'MO', '29', '179', 'Reynolds', 37.360857, -90.969516],
  ['Missouri', 'MO', '29', '181', 'Ripley', 36.648902, -90.86706],
  ['Missouri', 'MO', '29', '183', 'St. Charles', 38.781102, -90.674915],
  ['Missouri', 'MO', '29', '185', 'St. Clair', 38.039069, -93.773077],
  ['Missouri', 'MO', '29', '186', 'Ste. Genevieve', 37.89018, -90.18117],
  ['Missouri', 'MO', '29', '187', 'St. Francois', 37.810707, -90.473868],
  ['Missouri', 'MO', '29', '189', 'St. Louis', 38.640702, -90.445954],
  ['Missouri', 'MO', '29', '195', 'Saline', 39.13584, -93.204164],
  ['Missouri', 'MO', '29', '197', 'Schuyler', 40.469361, -92.519016],
  ['Missouri', 'MO', '29', '199', 'Scotland', 40.447686, -92.142824],
  ['Missouri', 'MO', '29', '201', 'Scott', 37.047793, -89.568098],
  ['Missouri', 'MO', '29', '203', 'Shannon', 37.15198, -91.398364],
  ['Missouri', 'MO', '29', '205', 'Shelby', 39.797531, -92.088719],
  ['Missouri', 'MO', '29', '207', 'Stoddard', 36.855428, -89.941735],
  ['Missouri', 'MO', '29', '209', 'Stone', 36.747857, -93.467782],
  ['Missouri', 'MO', '29', '211', 'Sullivan', 40.209587, -93.109783],
  ['Missouri', 'MO', '29', '213', 'Taney', 36.649827, -93.042819],
  ['Missouri', 'MO', '29', '215', 'Texas', 37.314257, -91.964478],
  ['Missouri', 'MO', '29', '217', 'Vernon', 37.850196, -94.341597],
  ['Missouri', 'MO', '29', '219', 'Warren', 38.761902, -91.159307],
  ['Missouri', 'MO', '29', '221', 'Washington', 37.942315, -90.897056],
  ['Missouri', 'MO', '29', '223', 'Wayne', 37.113825, -90.460868],
  ['Missouri', 'MO', '29', '225', 'Webster', 37.280804, -92.87608],
  ['Missouri', 'MO', '29', '227', 'Worth', 40.480482, -94.419129],
  ['Missouri', 'MO', '29', '229', 'Wright', 37.267636, -92.48001],
  ['Missouri', 'MO', '29', '510', 'St. Louis', 38.635699, -90.244582],
  ['Montana', 'MT', '30', '001', 'Beaverhead', 45.133863, -112.892869],
  ['Montana', 'MT', '30', '003', 'Big Horn', 45.407869, -107.518163],
  ['Montana', 'MT', '30', '005', 'Blaine', 48.428282, -108.967648],
  ['Montana', 'MT', '30', '007', 'Broadwater', 46.334476, -111.496103],
  ['Montana', 'MT', '30', '009', 'Carbon', 45.224475, -109.028551],
  ['Montana', 'MT', '30', '011', 'Carter', 45.516825, -104.515324],
  ['Montana', 'MT', '30', '013', 'Cascade', 47.316443, -111.350571],
  ['Montana', 'MT', '30', '015', 'Chouteau', 47.886837, -110.436189],
  ['Montana', 'MT', '30', '017', 'Custer', 46.261481, -105.550508],
  ['Montana', 'MT', '30', '019', 'Daniels', 48.794429, -105.541739],
  ['Montana', 'MT', '30', '021', 'Dawson', 47.272425, -104.901027],
  ['Montana', 'MT', '30', '023', 'Deer Lodge', 46.099059, -113.139108],
  ['Montana', 'MT', '30', '025', 'Fallon', 46.318184, -104.405718],
  ['Montana', 'MT', '30', '027', 'Fergus', 47.222997, -109.22328],
  ['Montana', 'MT', '30', '029', 'Flathead', 48.314696, -114.054319],
  ['Montana', 'MT', '30', '031', 'Gallatin', 45.535559, -111.173443],
  ['Montana', 'MT', '30', '033', 'Garfield', 47.281174, -106.982212],
  ['Montana', 'MT', '30', '035', 'Glacier', 48.705671, -112.990502],
  ['Montana', 'MT', '30', '037', 'Golden Valley', 46.380624, -109.174586],
  ['Montana', 'MT', '30', '039', 'Granite', 46.395358, -113.425683],
  ['Montana', 'MT', '30', '041', 'Hill', 48.628331, -110.106372],
  ['Montana', 'MT', '30', '043', 'Jefferson', 46.126557, -112.056771],
  ['Montana', 'MT', '30', '045', 'Judith Basin', 47.032558, -110.30532],
  ['Montana', 'MT', '30', '047', 'Lake', 47.642901, -114.083687],
  ['Montana', 'MT', '30', '049', 'Lewis and Clark', 47.122133, -112.382954],
  ['Montana', 'MT', '30', '051', 'Liberty', 48.559654, -111.036924],
  ['Montana', 'MT', '30', '053', 'Lincoln', 48.542232, -115.404343],
  ['Montana', 'MT', '30', '055', 'McCone', 47.629628, -105.757222],
  ['Montana', 'MT', '30', '057', 'Madison', 45.319288, -111.917328],
  ['Montana', 'MT', '30', '059', 'Meagher', 46.585706, -110.921781],
  ['Montana', 'MT', '30', '061', 'Mineral', 47.155213, -115.06455],
  ['Montana', 'MT', '30', '063', 'Missoula', 47.027263, -113.892681],
  ['Montana', 'MT', '30', '065', 'Musselshell', 46.505665, -108.436982],
  ['Montana', 'MT', '30', '067', 'Park', 45.421905, -110.532798],
  ['Montana', 'MT', '30', '069', 'Petroleum', 47.141917, -108.226575],
  ['Montana', 'MT', '30', '071', 'Phillips', 48.250155, -107.928833],
  ['Montana', 'MT', '30', '073', 'Pondera', 48.230312, -112.219928],
  ['Montana', 'MT', '30', '075', 'Powder River', 45.40889, -105.555259],
  ['Montana', 'MT', '30', '077', 'Powell', 46.844225, -112.9311],
  ['Montana', 'MT', '30', '079', 'Prairie', 46.852434, -105.375808],
  ['Montana', 'MT', '30', '081', 'Ravalli', 46.079298, -114.119065],
  ['Montana', 'MT', '30', '083', 'Richland', 47.785513, -104.563387],
  ['Montana', 'MT', '30', '085', 'Roosevelt', 48.282747, -104.99517],
  ['Montana', 'MT', '30', '087', 'Rosebud', 46.161734, -106.701953],
  ['Montana', 'MT', '30', '089', 'Sanders', 47.756469, -115.180225],
  ['Montana', 'MT', '30', '091', 'Sheridan', 48.705523, -104.533913],
  ['Montana', 'MT', '30', '093', 'Silver Bow', 45.895959, -112.660222],
  ['Montana', 'MT', '30', '095', 'Stillwater', 45.663496, -109.391752],
  ['Montana', 'MT', '30', '097', 'Sweet Grass', 45.81306, -109.941312],
  ['Montana', 'MT', '30', '099', 'Teton', 47.819035, -112.281725],
  ['Montana', 'MT', '30', '101', 'Toole', 48.645052, -111.733493],
  ['Montana', 'MT', '30', '103', 'Treasure', 46.209636, -107.280494],
  ['Montana', 'MT', '30', '105', 'Valley', 48.365713, -106.687883],
  ['Montana', 'MT', '30', '107', 'Wheatland', 46.497047, -109.857147],
  ['Montana', 'MT', '30', '109', 'Wibaux', 46.962866, -104.274466],
  ['Montana', 'MT', '30', '111', 'Yellowstone', 45.936987, -108.276656],
  ['Nebraska', 'NE', '31', '001', 'Adams', 40.520632, -98.500044],
  ['Nebraska', 'NE', '31', '003', 'Antelope', 42.183225, -98.058037],
  ['Nebraska', 'NE', '31', '005', 'Arthur', 41.570856, -101.697045],
  ['Nebraska', 'NE', '31', '007', 'Banner', 41.53975, -103.726263],
  ['Nebraska', 'NE', '31', '009', 'Blaine', 41.92523, -99.990771],
  ['Nebraska', 'NE', '31', '011', 'Boone', 41.7054, -98.066794],
  ['Nebraska', 'NE', '31', '013', 'Box Butte', 42.215665, -103.087821],
  ['Nebraska', 'NE', '31', '015', 'Boyd', 42.894448, -98.773022],
  ['Nebraska', 'NE', '31', '017', 'Brown', 42.34939, -99.923176],
  ['Nebraska', 'NE', '31', '019', 'Buffalo', 40.855226, -99.074983],
  ['Nebraska', 'NE', '31', '021', 'Burt', 41.854179, -96.337746],
  ['Nebraska', 'NE', '31', '023', 'Butler', 41.226072, -97.13204],
  ['Nebraska', 'NE', '31', '025', 'Cass', 40.909878, -96.140609],
  ['Nebraska', 'NE', '31', '027', 'Cedar', 42.604511, -97.256824],
  ['Nebraska', 'NE', '31', '029', 'Chase', 40.53039, -101.694192],
  ['Nebraska', 'NE', '31', '031', 'Cherry', 42.566985, -101.072622],
  ['Nebraska', 'NE', '31', '033', 'Cheyenne', 41.214236, -103.011929],
  ['Nebraska', 'NE', '31', '035', 'Clay', 40.523669, -98.05105],
  ['Nebraska', 'NE', '31', '037', 'Colfax', 41.574675, -97.089455],
  ['Nebraska', 'NE', '31', '039', 'Cuming', 41.915865, -96.788517],
  ['Nebraska', 'NE', '31', '041', 'Custer', 41.393893, -99.726866],
  ['Nebraska', 'NE', '31', '043', 'Dakota', 42.390456, -96.562549],
  ['Nebraska', 'NE', '31', '045', 'Dawes', 42.711214, -103.134872],
  ['Nebraska', 'NE', '31', '047', 'Dawson', 40.867378, -99.815154],
  ['Nebraska', 'NE', '31', '049', 'Deuel', 41.112988, -102.332604],
  ['Nebraska', 'NE', '31', '051', 'Dixon', 42.48528, -96.855862],
  ['Nebraska', 'NE', '31', '053', 'Dodge', 41.577008, -96.645853],
  ['Nebraska', 'NE', '31', '055', 'Douglas', 41.297091, -96.154066],
  ['Nebraska', 'NE', '31', '057', 'Dundy', 40.180165, -101.681133],
  ['Nebraska', 'NE', '31', '059', 'Fillmore', 40.52504, -97.596705],
  ['Nebraska', 'NE', '31', '061', 'Franklin', 40.183203, -98.96208],
  ['Nebraska', 'NE', '31', '063', 'Frontier', 40.530947, -100.406683],
  ['Nebraska', 'NE', '31', '065', 'Furnas', 40.191991, -99.909661],
  ['Nebraska', 'NE', '31', '067', 'Gage', 40.255234, -96.683453],
  ['Nebraska', 'NE', '31', '069', 'Garden', 41.659381, -102.328229],
  ['Nebraska', 'NE', '31', '071', 'Garfield', 41.905572, -98.922998],
  ['Nebraska', 'NE', '31', '073', 'Gosper', 40.509838, -99.824078],
  ['Nebraska', 'NE', '31', '075', 'Grant', 41.915867, -101.756875],
  ['Nebraska', 'NE', '31', '077', 'Greeley', 41.5676, -98.530566],
  ['Nebraska', 'NE', '31', '079', 'Hall', 40.866023, -98.498004],
  ['Nebraska', 'NE', '31', '081', 'Hamilton', 40.877145, -98.021943],
  ['Nebraska', 'NE', '31', '083', 'Harlan', 40.178766, -99.403418],
  ['Nebraska', 'NE', '31', '085', 'Hayes', 40.535786, -101.05558],
  ['Nebraska', 'NE', '31', '087', 'Hitchcock', 40.176896, -101.044217],
  ['Nebraska', 'NE', '31', '089', 'Holt', 42.459287, -98.784766],
  ['Nebraska', 'NE', '31', '091', 'Hooker', 41.9187, -101.11681],
  ['Nebraska', 'NE', '31', '093', 'Howard', 41.219403, -98.513303],
  ['Nebraska', 'NE', '31', '095', 'Jefferson', 40.175736, -97.143103],
  ['Nebraska', 'NE', '31', '097', 'Johnson', 40.395457, -96.268562],
  ['Nebraska', 'NE', '31', '099', 'Kearney', 40.506269, -98.948461],
  ['Nebraska', 'NE', '31', '101', 'Keith', 41.194404, -101.644474],
  ['Nebraska', 'NE', '31', '103', 'Keya Paha', 42.87548, -99.718351],
  ['Nebraska', 'NE', '31', '105', 'Kimball', 41.199155, -103.711105],
  ['Nebraska', 'NE', '31', '107', 'Knox', 42.634405, -97.891349],
  ['Nebraska', 'NE', '31', '109', 'Lancaster', 40.783547, -96.688658],
  ['Nebraska', 'NE', '31', '111', 'Lincoln', 41.050322, -100.744481],
  ['Nebraska', 'NE', '31', '113', 'Logan', 41.542156, -100.443665],
  ['Nebraska', 'NE', '31', '115', 'Loup', 41.897714, -99.509764],
  ['Nebraska', 'NE', '31', '117', 'McPherson', 41.596473, -101.060237],
  ['Nebraska', 'NE', '31', '119', 'Madison', 41.909929, -97.606856],
  ['Nebraska', 'NE', '31', '121', 'Merrick', 41.169801, -98.031051],
  ['Nebraska', 'NE', '31', '123', 'Morrill', 41.732205, -102.990599],
  ['Nebraska', 'NE', '31', '125', 'Nance', 41.402386, -97.991408],
  ['Nebraska', 'NE', '31', '127', 'Nemaha', 40.387448, -95.850954],
  ['Nebraska', 'NE', '31', '129', 'Nuckolls', 40.176492, -98.046842],
  ['Nebraska', 'NE', '31', '131', 'Otoe', 40.637992, -96.131027],
  ['Nebraska', 'NE', '31', '133', 'Pawnee', 40.13785, -96.245225],
  ['Nebraska', 'NE', '31', '135', 'Perkins', 40.856065, -101.636443],
  ['Nebraska', 'NE', '31', '137', 'Phelps', 40.516365, -99.406557],
  ['Nebraska', 'NE', '31', '139', 'Pierce', 42.271413, -97.610992],
  ['Nebraska', 'NE', '31', '141', 'Platte', 41.576866, -97.513467],
  ['Nebraska', 'NE', '31', '143', 'Polk', 41.187925, -97.570663],
  ['Nebraska', 'NE', '31', '145', 'Red Willow', 40.16942, -100.468576],
  ['Nebraska', 'NE', '31', '147', 'Richardson', 40.123743, -95.718603],
  ['Nebraska', 'NE', '31', '149', 'Rock', 42.413325, -99.45853],
  ['Nebraska', 'NE', '31', '151', 'Saline', 40.516802, -97.131755],
  ['Nebraska', 'NE', '31', '153', 'Sarpy', 41.115064, -96.109125],
  ['Nebraska', 'NE', '31', '155', 'Saunders', 41.223152, -96.640923],
  ['Nebraska', 'NE', '31', '157', 'Scotts Bluff', 41.851589, -103.70586],
  ['Nebraska', 'NE', '31', '159', 'Seward', 40.871944, -97.140383],
  ['Nebraska', 'NE', '31', '161', 'Sheridan', 42.507074, -102.389698],
  ['Nebraska', 'NE', '31', '163', 'Sherman', 41.218743, -98.972849],
  ['Nebraska', 'NE', '31', '165', 'Sioux', 42.483806, -103.742605],
  ['Nebraska', 'NE', '31', '167', 'Stanton', 41.904793, -97.174724],
  ['Nebraska', 'NE', '31', '169', 'Thayer', 40.173845, -97.596263],
  ['Nebraska', 'NE', '31', '171', 'Thomas', 41.84862, -100.506911],
  ['Nebraska', 'NE', '31', '173', 'Thurston', 42.154061, -96.533943],
  ['Nebraska', 'NE', '31', '175', 'Valley', 41.564094, -98.983484],
  ['Nebraska', 'NE', '31', '177', 'Washington', 41.533979, -96.224574],
  ['Nebraska', 'NE', '31', '179', 'Wayne', 42.210746, -97.126243],
  ['Nebraska', 'NE', '31', '181', 'Webster', 40.180646, -98.49859],
  ['Nebraska', 'NE', '31', '183', 'Wheeler', 41.937859, -98.522289],
  ['Nebraska', 'NE', '31', '185', 'York', 40.873056, -97.596742],
  ['Nevada', 'NV', '32', '001', 'Churchill', 39.525701, -118.270419],
  ['Nevada', 'NV', '32', '003', 'Clark', 36.214236, -115.013819],
  ['Nevada', 'NV', '32', '005', 'Douglas', 38.905129, -119.609019],
  ['Nevada', 'NV', '32', '007', 'Elko', 41.141133, -115.351424],
  ['Nevada', 'NV', '32', '009', 'Esmeralda', 37.778966, -117.632382],
  ['Nevada', 'NV', '32', '011', 'Eureka', 39.977788, -116.272208],
  ['Nevada', 'NV', '32', '013', 'Humboldt', 41.407914, -118.127592],
  ['Nevada', 'NV', '32', '015', 'Lander', 39.900211, -117.04724],
  ['Nevada', 'NV', '32', '017', 'Lincoln', 37.634605, -114.863037],
  ['Nevada', 'NV', '32', '019', 'Lyon', 39.022214, -119.197417],
  ['Nevada', 'NV', '32', '021', 'Mineral', 38.516647, -118.416279],
  ['Nevada', 'NV', '32', '023', 'Nye', 37.966379, -116.459047],
  ['Nevada', 'NV', '32', '027', 'Pershing', 40.439639, -118.409477],
  ['Nevada', 'NV', '32', '029', 'Storey', 39.438385, -119.524646],
  ['Nevada', 'NV', '32', '031', 'Washoe', 40.703311, -119.710315],
  ['Nevada', 'NV', '32', '033', 'White Pine', 39.417804, -114.900945],
  ['Nevada', 'NV', '32', '510', 'Carson City', 39.153447, -119.743442],
  ['New Hampshire', 'NH', '33', '001', 'Belknap', 43.519109, -71.425366],
  ['New Hampshire', 'NH', '33', '003', 'Carroll', 43.867567, -71.201665],
  ['New Hampshire', 'NH', '33', '005', 'Cheshire', 42.925455, -72.248217],
  ['New Hampshire', 'NH', '33', '007', 'Coos', 44.652419, -71.289383],
  ['New Hampshire', 'NH', '33', '009', 'Grafton', 43.926488, -71.842264],
  ['New Hampshire', 'NH', '33', '011', 'Hillsborough', 42.911643, -71.723101],
  ['New Hampshire', 'NH', '33', '013', 'Merrimack', 43.299485, -71.68013],
  ['New Hampshire', 'NH', '33', '015', 'Rockingham', 42.98936, -71.099437],
  ['New Hampshire', 'NH', '33', '017', 'Strafford', 43.293177, -71.035927],
  ['New Hampshire', 'NH', '33', '019', 'Sullivan', 43.361188, -72.222084],
  ['New Jersey', 'NJ', '34', '001', 'Atlantic', 39.469354, -74.633758],
  ['New Jersey', 'NJ', '34', '003', 'Bergen', 40.95909, -74.074522],
  ['New Jersey', 'NJ', '34', '005', 'Burlington', 39.875786, -74.663006],
  ['New Jersey', 'NJ', '34', '007', 'Camden', 39.802352, -74.961251],
  ['New Jersey', 'NJ', '34', '009', 'Cape May', 39.086143, -74.847716],
  ['New Jersey', 'NJ', '34', '011', 'Cumberland', 39.328387, -75.121644],
  ['New Jersey', 'NJ', '34', '013', 'Essex', 40.787217, -74.246136],
  ['New Jersey', 'NJ', '34', '015', 'Gloucester', 39.721019, -75.143708],
  ['New Jersey', 'NJ', '34', '017', 'Hudson', 40.731384, -74.078627],
  ['New Jersey', 'NJ', '34', '019', 'Hunterdon', 40.565283, -74.91197],
  ['New Jersey', 'NJ', '34', '021', 'Mercer', 40.282503, -74.703724],
  ['New Jersey', 'NJ', '34', '023', 'Middlesex', 40.439593, -74.407585],
  ['New Jersey', 'NJ', '34', '025', 'Monmouth', 40.287056, -74.152446],
  ['New Jersey', 'NJ', '34', '027', 'Morris', 40.858581, -74.547427],
  ['New Jersey', 'NJ', '34', '029', 'Ocean', 39.86585, -74.263027],
  ['New Jersey', 'NJ', '34', '031', 'Passaic', 41.033763, -74.300308],
  ['New Jersey', 'NJ', '34', '033', 'Salem', 39.573828, -75.357356],
  ['New Jersey', 'NJ', '34', '035', 'Somerset', 40.565522, -74.61993],
  ['New Jersey', 'NJ', '34', '037', 'Sussex', 41.137424, -74.691855],
  ['New Jersey', 'NJ', '34', '039', 'Union', 40.659871, -74.308696],
  ['New Jersey', 'NJ', '34', '041', 'Warren', 40.853524, -75.009542],
  ['New Mexico', 'NM', '35', '001', 'Bernalillo', 35.054002, -106.669065],
  ['New Mexico', 'NM', '35', '003', 'Catron', 33.901814, -108.392097],
  ['New Mexico', 'NM', '35', '005', 'Chaves', 33.361605, -104.469837],
  ['New Mexico', 'NM', '35', '006', 'Cibola', 34.93205, -108.000255],
  ['New Mexico', 'NM', '35', '007', 'Colfax', 36.612963, -104.640126],
  ['New Mexico', 'NM', '35', '009', 'Curry', 34.572984, -103.346055],
  ['New Mexico', 'NM', '35', '011', 'De Baca', 34.351429, -104.401527],
  ['New Mexico', 'NM', '35', '013', 'Dona Ana', 32.350912, -106.832182],
  ['New Mexico', 'NM', '35', '015', 'Eddy', 32.457858, -104.306471],
  ['New Mexico', 'NM', '35', '017', 'Grant', 32.732087, -108.381504],
  ['New Mexico', 'NM', '35', '019', 'Guadalupe', 34.869782, -104.784968],
  ['New Mexico', 'NM', '35', '021', 'Harding', 35.863152, -103.829931],
  ['New Mexico', 'NM', '35', '023', 'Hidalgo', 31.899658, -108.745729],
  ['New Mexico', 'NM', '35', '025', 'Lea', 32.795687, -103.413271],
  ['New Mexico', 'NM', '35', '027', 'Lincoln', 33.740941, -105.449083],
  ['New Mexico', 'NM', '35', '028', 'Los Alamos', 35.870047, -106.307968],
  ['New Mexico', 'NM', '35', '029', 'Luna', 32.184482, -107.746639],
  ['New Mexico', 'NM', '35', '031', 'McKinley', 35.573732, -108.255307],
  ['New Mexico', 'NM', '35', '033', 'Mora', 35.982841, -104.921898],
  ['New Mexico', 'NM', '35', '035', 'Otero', 32.588776, -105.781079],
  ['New Mexico', 'NM', '35', '037', 'Quay', 35.107018, -103.548071],
  ['New Mexico', 'NM', '35', '039', 'Rio Arriba', 36.509669, -106.693983],
  ['New Mexico', 'NM', '35', '041', 'Roosevelt', 34.021457, -103.482725],
  ['New Mexico', 'NM', '35', '043', 'Sandoval', 35.685073, -106.882618],
  ['New Mexico', 'NM', '35', '045', 'San Juan', 36.511625, -108.324578],
  ['New Mexico', 'NM', '35', '047', 'San Miguel', 35.476876, -104.803515],
  ['New Mexico', 'NM', '35', '049', 'Santa Fe', 35.513722, -105.966441],
  ['New Mexico', 'NM', '35', '051', 'Sierra', 33.119479, -107.188161],
  ['New Mexico', 'NM', '35', '053', 'Socorro', 33.991614, -106.939003],
  ['New Mexico', 'NM', '35', '055', 'Taos', 36.576529, -105.637987],
  ['New Mexico', 'NM', '35', '057', 'Torrance', 34.634644, -105.846836],
  ['New Mexico', 'NM', '35', '059', 'Union', 36.488085, -103.475723],
  ['New Mexico', 'NM', '35', '061', 'Valencia', 34.71684, -106.806582],
  ['New York', 'NY', '36', '001', 'Albany', 42.588271, -73.974014],
  ['New York', 'NY', '36', '003', 'Allegany', 42.247894, -78.026176],
  ['New York', 'NY', '36', '005', 'Bronx', 40.848711, -73.852939],
  ['New York', 'NY', '36', '007', 'Broome', 42.161977, -75.830291],
  ['New York', 'NY', '36', '009', 'Cattaraugus', 42.244853, -78.681006],
  ['New York', 'NY', '36', '011', 'Cayuga', 43.008546, -76.574587],
  ['New York', 'NY', '36', '013', 'Chautauqua', 42.304216, -79.407595],
  ['New York', 'NY', '36', '015', 'Chemung', 42.155281, -76.747179],
  ['New York', 'NY', '36', '017', 'Chenango', 42.489732, -75.604905],
  ['New York', 'NY', '36', '019', 'Clinton', 44.75271, -73.705648],
  ['New York', 'NY', '36', '021', 'Columbia', 42.247729, -73.626805],
  ['New York', 'NY', '36', '023', 'Cortland', 42.593824, -76.076265],
  ['New York', 'NY', '36', '025', 'Delaware', 42.193987, -74.966728],
  ['New York', 'NY', '36', '027', 'Dutchess', 41.755009, -73.739951],
  ['New York', 'NY', '36', '029', 'Erie', 42.752759, -78.778192],
  ['New York', 'NY', '36', '031', 'Essex', 44.108971, -73.777573],
  ['New York', 'NY', '36', '033', 'Franklin', 44.594374, -74.31067],
  ['New York', 'NY', '36', '035', 'Fulton', 43.115609, -74.423678],
  ['New York', 'NY', '36', '037', 'Genesee', 43.00091, -78.192778],
  ['New York', 'NY', '36', '039', 'Greene', 42.286951, -74.149495],
  ['New York', 'NY', '36', '041', 'Hamilton', 43.657879, -74.502456],
  ['New York', 'NY', '36', '043', 'Herkimer', 43.461635, -74.894694],
  ['New York', 'NY', '36', '045', 'Jefferson', 43.995371, -76.053522],
  ['New York', 'NY', '36', '047', 'Kings', 40.635133, -73.950777],
  ['New York', 'NY', '36', '049', 'Lewis', 43.786397, -75.442617],
  ['New York', 'NY', '36', '051', 'Livingston', 42.727485, -77.76978],
  ['New York', 'NY', '36', '053', 'Madison', 42.910026, -75.663575],
  ['New York', 'NY', '36', '055', 'Monroe', 43.464475, -77.664656],
  ['New York', 'NY', '36', '057', 'Montgomery', 42.900891, -74.435358],
  ['New York', 'NY', '36', '059', 'Nassau', 40.729687, -73.589384],
  ['New York', 'NY', '36', '061', 'New York', 40.776557, -73.970174],
  ['New York', 'NY', '36', '063', 'Niagara', 43.456731, -78.792143],
  ['New York', 'NY', '36', '065', 'Oneida', 43.242727, -75.434282],
  ['New York', 'NY', '36', '067', 'Onondaga', 43.00653, -76.196117],
  ['New York', 'NY', '36', '069', 'Ontario', 42.856695, -77.303277],
  ['New York', 'NY', '36', '071', 'Orange', 41.40241, -74.306252],
  ['New York', 'NY', '36', '073', 'Orleans', 43.502287, -78.229726],
  ['New York', 'NY', '36', '075', 'Oswego', 43.461443, -76.209258],
  ['New York', 'NY', '36', '077', 'Otsego', 42.629776, -75.028841],
  ['New York', 'NY', '36', '079', 'Putnam', 41.427904, -73.743882],
  ['New York', 'NY', '36', '081', 'Queens', 40.658557, -73.837929],
  ['New York', 'NY', '36', '083', 'Rensselaer', 42.710421, -73.513845],
  ['New York', 'NY', '36', '085', 'Richmond', 40.563855, -74.137063],
  ['New York', 'NY', '36', '087', 'Rockland', 41.154785, -74.024772],
  ['New York', 'NY', '36', '089', 'St. Lawrence', 44.488113, -75.074311],
  ['New York', 'NY', '36', '091', 'Saratoga', 43.106135, -73.855387],
  ['New York', 'NY', '36', '093', 'Schenectady', 42.817542, -74.043583],
  ['New York', 'NY', '36', '095', 'Schoharie', 42.591294, -74.438172],
  ['New York', 'NY', '36', '097', 'Schuyler', 42.419776, -76.938604],
  ['New York', 'NY', '36', '099', 'Seneca', 42.782294, -76.827088],
  ['New York', 'NY', '36', '101', 'Steuben', 42.266725, -77.385525],
  ['New York', 'NY', '36', '103', 'Suffolk', 40.943554, -72.692218],
  ['New York', 'NY', '36', '105', 'Sullivan', 41.720176, -74.76468],
  ['New York', 'NY', '36', '107', 'Tioga', 42.178057, -76.29745],
  ['New York', 'NY', '36', '109', 'Tompkins', 42.453281, -76.473712],
  ['New York', 'NY', '36', '111', 'Ulster', 41.947232, -74.265447],
  ['New York', 'NY', '36', '113', 'Warren', 43.555105, -73.838139],
  ['New York', 'NY', '36', '115', 'Washington', 43.312377, -73.439428],
  ['New York', 'NY', '36', '117', 'Wayne', 43.458758, -77.063164],
  ['New York', 'NY', '36', '119', 'Westchester', 41.15277, -73.745912],
  ['New York', 'NY', '36', '121', 'Wyoming', 42.701363, -78.228567],
  ['New York', 'NY', '36', '123', 'Yates', 42.638237, -77.104324],
  ['North Carolina', 'NC', '37', '001', 'Alamance', 36.041974, -79.399935],
  ['North Carolina', 'NC', '37', '003', 'Alexander', 35.920951, -81.177467],
  ['North Carolina', 'NC', '37', '005', 'Alleghany', 36.489356, -81.132299],
  ['North Carolina', 'NC', '37', '007', 'Anson', 34.974996, -80.109959],
  ['North Carolina', 'NC', '37', '009', 'Ashe', 36.436305, -81.49877],
  ['North Carolina', 'NC', '37', '011', 'Avery', 36.072894, -81.920363],
  ['North Carolina', 'NC', '37', '013', 'Beaufort', 35.482313, -76.842014],
  ['North Carolina', 'NC', '37', '015', 'Bertie', 36.06133, -76.962367],
  ['North Carolina', 'NC', '37', '017', 'Bladen', 34.591949, -78.539513],
  ['North Carolina', 'NC', '37', '019', 'Brunswick', 34.038708, -78.227688],
  ['North Carolina', 'NC', '37', '021', 'Buncombe', 35.609371, -82.530426],
  ['North Carolina', 'NC', '37', '023', 'Burke', 35.746182, -81.70618],
  ['North Carolina', 'NC', '37', '025', 'Cabarrus', 35.387845, -80.552868],
  ['North Carolina', 'NC', '37', '027', 'Caldwell', 35.957857, -81.530076],
  ['North Carolina', 'NC', '37', '029', 'Camden', 36.342344, -76.162488],
  ['North Carolina', 'NC', '37', '031', 'Carteret', 34.858313, -76.526967],
  ['North Carolina', 'NC', '37', '033', 'Caswell', 36.393097, -79.332546],
  ['North Carolina', 'NC', '37', '035', 'Catawba', 35.663182, -81.214151],
  ['North Carolina', 'NC', '37', '037', 'Chatham', 35.704994, -79.251454],
  ['North Carolina', 'NC', '37', '039', 'Cherokee', 35.136233, -84.061308],
  ['North Carolina', 'NC', '37', '041', 'Chowan', 36.127288, -76.60207],
  ['North Carolina', 'NC', '37', '043', 'Clay', 35.052997, -83.752264],
  ['North Carolina', 'NC', '37', '045', 'Cleveland', 35.33463, -81.557115],
  ['North Carolina', 'NC', '37', '047', 'Columbus', 34.260471, -78.636378],
  ['North Carolina', 'NC', '37', '049', 'Craven', 35.118179, -77.082541],
  ['North Carolina', 'NC', '37', '051', 'Cumberland', 35.050192, -78.828719],
  ['North Carolina', 'NC', '37', '053', 'Currituck', 36.372174, -75.941224],
  ['North Carolina', 'NC', '37', '055', 'Dare', 35.606269, -75.767536],
  ['North Carolina', 'NC', '37', '057', 'Davidson', 35.795123, -80.206525],
  ['North Carolina', 'NC', '37', '059', 'Davie', 35.929356, -80.542542],
  ['North Carolina', 'NC', '37', '061', 'Duplin', 34.934403, -77.933543],
  ['North Carolina', 'NC', '37', '063', 'Durham', 36.036589, -78.877919],
  ['North Carolina', 'NC', '37', '065', 'Edgecombe', 35.917055, -77.602655],
  ['North Carolina', 'NC', '37', '067', 'Forsyth', 36.131667, -80.257289],
  ['North Carolina', 'NC', '37', '069', 'Franklin', 36.088241, -78.28309],
  ['North Carolina', 'NC', '37', '071', 'Gaston', 35.293344, -81.177256],
  ['North Carolina', 'NC', '37', '073', 'Gates', 36.442135, -76.702355],
  ['North Carolina', 'NC', '37', '075', 'Graham', 35.348111, -83.830909],
  ['North Carolina', 'NC', '37', '077', 'Granville', 36.299884, -78.657634],
  ['North Carolina', 'NC', '37', '079', 'Greene', 35.481933, -77.681667],
  ['North Carolina', 'NC', '37', '081', 'Guilford', 36.079065, -79.788665],
  ['North Carolina', 'NC', '37', '083', 'Halifax', 36.251438, -77.644842],
  ['North Carolina', 'NC', '37', '085', 'Harnett', 35.368635, -78.87161],
  ['North Carolina', 'NC', '37', '087', 'Haywood', 35.557097, -82.972807],
  ['North Carolina', 'NC', '37', '089', 'Henderson', 35.336424, -82.479634],
  ['North Carolina', 'NC', '37', '091', 'Hertford', 36.363517, -76.981616],
  ['North Carolina', 'NC', '37', '093', 'Hoke', 35.017233, -79.241964],
  ['North Carolina', 'NC', '37', '095', 'Hyde', 35.408157, -76.153687],
  ['North Carolina', 'NC', '37', '097', 'Iredell', 35.806356, -80.874545],
  ['North Carolina', 'NC', '37', '099', 'Jackson', 35.286463, -83.130641],
  ['North Carolina', 'NC', '37', '101', 'Johnston', 35.513405, -78.367267],
  ['North Carolina', 'NC', '37', '103', 'Jones', 35.03216, -77.356443],
  ['North Carolina', 'NC', '37', '105', 'Lee', 35.476075, -79.17222],
  ['North Carolina', 'NC', '37', '107', 'Lenoir', 35.238062, -77.639023],
  ['North Carolina', 'NC', '37', '109', 'Lincoln', 35.487825, -81.225176],
  ['North Carolina', 'NC', '37', '111', 'McDowell', 35.682232, -82.048029],
  ['North Carolina', 'NC', '37', '113', 'Macon', 35.152959, -83.421901],
  ['North Carolina', 'NC', '37', '115', 'Madison', 35.86408, -82.712731],
  ['North Carolina', 'NC', '37', '117', 'Martin', 35.841059, -77.112867],
  ['North Carolina', 'NC', '37', '119', 'Mecklenburg', 35.246862, -80.833832],
  ['North Carolina', 'NC', '37', '121', 'Mitchell', 36.013102, -82.163554],
  ['North Carolina', 'NC', '37', '123', 'Montgomery', 35.338071, -79.904196],
  ['North Carolina', 'NC', '37', '125', 'Moore', 35.310163, -79.480664],
  ['North Carolina', 'NC', '37', '127', 'Nash', 35.965945, -77.987555],
  ['North Carolina', 'NC', '37', '129', 'New Hanover', 34.177466, -77.871378],
  ['North Carolina', 'NC', '37', '131', 'Northampton', 36.421774, -77.398352],
  ['North Carolina', 'NC', '37', '133', 'Onslow', 34.76346, -77.503297],
  ['North Carolina', 'NC', '37', '135', 'Orange', 36.062499, -79.119355],
  ['North Carolina', 'NC', '37', '137', 'Pamlico', 35.147462, -76.665069],
  ['North Carolina', 'NC', '37', '139', 'Pasquotank', 36.265276, -76.260355],
  ['North Carolina', 'NC', '37', '141', 'Pender', 34.512581, -77.888029],
  ['North Carolina', 'NC', '37', '143', 'Perquimans', 36.178261, -76.404269],
  ['North Carolina', 'NC', '37', '145', 'Person', 36.386387, -78.965471],
  ['North Carolina', 'NC', '37', '147', 'Pitt', 35.591065, -77.372404],
  ['North Carolina', 'NC', '37', '149', 'Polk', 35.278928, -82.167667],
  ['North Carolina', 'NC', '37', '151', 'Randolph', 35.709915, -79.806215],
  ['North Carolina', 'NC', '37', '153', 'Richmond', 35.001957, -79.747809],
  ['North Carolina', 'NC', '37', '155', 'Robeson', 34.63921, -79.100881],
  ['North Carolina', 'NC', '37', '157', 'Rockingham', 36.380927, -79.782889],
  ['North Carolina', 'NC', '37', '159', 'Rowan', 35.639218, -80.525344],
  ['North Carolina', 'NC', '37', '161', 'Rutherford', 35.402747, -81.919583],
  ['North Carolina', 'NC', '37', '163', 'Sampson', 34.990575, -78.371382],
  ['North Carolina', 'NC', '37', '165', 'Scotland', 34.840023, -79.477337],
  ['North Carolina', 'NC', '37', '167', 'Stanly', 35.310523, -80.254355],
  ['North Carolina', 'NC', '37', '169', 'Stokes', 36.404195, -80.239271],
  ['North Carolina', 'NC', '37', '171', 'Surry', 36.415416, -80.686463],
  ['North Carolina', 'NC', '37', '173', 'Swain', 35.568849, -83.465614],
  ['North Carolina', 'NC', '37', '175', 'Transylvania', 35.210095, -82.816696],
  ['North Carolina', 'NC', '37', '177', 'Tyrrell', 35.87042, -76.165345],
  ['North Carolina', 'NC', '37', '179', 'Union', 34.991501, -80.530131],
  ['North Carolina', 'NC', '37', '181', 'Vance', 36.365481, -78.405434],
  ['North Carolina', 'NC', '37', '183', 'Wake', 35.789846, -78.650624],
  ['North Carolina', 'NC', '37', '185', 'Warren', 36.397979, -78.099924],
  ['North Carolina', 'NC', '37', '187', 'Washington', 35.844589, -76.572334],
  ['North Carolina', 'NC', '37', '189', 'Watauga', 36.235371, -81.709919],
  ['North Carolina', 'NC', '37', '191', 'Wayne', 35.362741, -78.004826],
  ['North Carolina', 'NC', '37', '193', 'Wilkes', 36.209303, -81.165354],
  ['North Carolina', 'NC', '37', '195', 'Wilson', 35.704125, -77.918982],
  ['North Carolina', 'NC', '37', '197', 'Yadkin', 36.158765, -80.665164],
  ['North Carolina', 'NC', '37', '199', 'Yancey', 35.889504, -82.30398],
  ['North Dakota', 'ND', '38', '001', 'Adams', 46.096815, -102.533198],
  ['North Dakota', 'ND', '38', '003', 'Barnes', 46.94255, -98.070195],
  ['North Dakota', 'ND', '38', '005', 'Benson', 48.071738, -99.361987],
  ['North Dakota', 'ND', '38', '007', 'Billings', 47.009283, -103.364924],
  ['North Dakota', 'ND', '38', '009', 'Bottineau', 48.794412, -100.831257],
  ['North Dakota', 'ND', '38', '011', 'Bowman', 46.107807, -103.506586],
  ['North Dakota', 'ND', '38', '013', 'Burke', 48.786453, -102.520087],
  ['North Dakota', 'ND', '38', '015', 'Burleigh', 46.971843, -100.462001],
  ['North Dakota', 'ND', '38', '017', 'Cass', 46.927003, -97.252375],
  ['North Dakota', 'ND', '38', '019', 'Cavalier', 48.768439, -98.46379],
  ['North Dakota', 'ND', '38', '021', 'Dickey', 46.107756, -98.496518],
  ['North Dakota', 'ND', '38', '023', 'Divide', 48.818142, -103.497653],
  ['North Dakota', 'ND', '38', '025', 'Dunn', 47.35461, -102.612365],
  ['North Dakota', 'ND', '38', '027', 'Eddy', 47.723436, -98.900475],
  ['North Dakota', 'ND', '38', '029', 'Emmons', 46.284255, -100.237842],
  ['North Dakota', 'ND', '38', '031', 'Foster', 47.464327, -98.872817],
  ['North Dakota', 'ND', '38', '033', 'Golden Valley', 46.938924, -103.844612],
  ['North Dakota', 'ND', '38', '035', 'Grand Forks', 47.926003, -97.450851],
  ['North Dakota', 'ND', '38', '037', 'Grant', 46.357827, -101.639049],
  ['North Dakota', 'ND', '38', '039', 'Griggs', 47.463463, -98.232444],
  ['North Dakota', 'ND', '38', '041', 'Hettinger', 46.434939, -102.458385],
  ['North Dakota', 'ND', '38', '043', 'Kidder', 46.935385, -99.742727],
  ['North Dakota', 'ND', '38', '045', 'LaMoure', 46.464195, -98.526057],
  ['North Dakota', 'ND', '38', '047', 'Logan', 46.469278, -99.504585],
  ['North Dakota', 'ND', '38', '049', 'McHenry', 48.233842, -100.633267],
  ['North Dakota', 'ND', '38', '051', 'McIntosh', 46.117034, -99.443041],
  ['North Dakota', 'ND', '38', '053', 'McKenzie', 47.742475, -103.403215],
  ['North Dakota', 'ND', '38', '055', 'McLean', 47.653055, -101.421794],
  ['North Dakota', 'ND', '38', '057', 'Mercer', 47.303279, -101.820765],
  ['North Dakota', 'ND', '38', '059', 'Morton', 46.713789, -101.279743],
  ['North Dakota', 'ND', '38', '061', 'Mountrail', 48.210291, -102.364756],
  ['North Dakota', 'ND', '38', '063', 'Nelson', 47.918667, -98.204428],
  ['North Dakota', 'ND', '38', '065', 'Oliver', 47.118079, -101.33142],
  ['North Dakota', 'ND', '38', '067', 'Pembina', 48.766896, -97.545405],
  ['North Dakota', 'ND', '38', '069', 'Pierce', 48.238883, -99.966497],
  ['North Dakota', 'ND', '38', '071', 'Ramsey', 48.265551, -98.737329],
  ['North Dakota', 'ND', '38', '073', 'Ransom', 46.449276, -97.647554],
  ['North Dakota', 'ND', '38', '075', 'Renville', 48.712782, -101.658152],
  ['North Dakota', 'ND', '38', '077', 'Richland', 46.265219, -96.93796],
  ['North Dakota', 'ND', '38', '079', 'Rolette', 48.768272, -99.840463],
  ['North Dakota', 'ND', '38', '081', 'Sargent', 46.108206, -97.63003],
  ['North Dakota', 'ND', '38', '083', 'Sheridan', 47.581373, -100.341499],
  ['North Dakota', 'ND', '38', '085', 'Sioux', 46.109282, -101.047525],
  ['North Dakota', 'ND', '38', '087', 'Slope', 46.445834, -103.462462],
  ['North Dakota', 'ND', '38', '089', 'Stark', 46.817031, -102.662026],
  ['North Dakota', 'ND', '38', '091', 'Steele', 47.458288, -97.718895],
  ['North Dakota', 'ND', '38', '093', 'Stutsman', 46.971103, -98.957592],
  ['North Dakota', 'ND', '38', '095', 'Towner', 48.682183, -99.248158],
  ['North Dakota', 'ND', '38', '097', 'Traill', 47.446215, -97.164754],
  ['North Dakota', 'ND', '38', '099', 'Walsh', 48.376979, -97.72223],
  ['North Dakota', 'ND', '38', '101', 'Ward', 48.216686, -101.540537],
  ['North Dakota', 'ND', '38', '103', 'Wells', 47.580851, -99.671502],
  ['North Dakota', 'ND', '38', '105', 'Williams', 48.345867, -103.4874],
  ['Ohio', 'OH', '39', '001', 'Adams', 38.841681, -83.474173],
  ['Ohio', 'OH', '39', '003', 'Allen', 40.771528, -84.106546],
  ['Ohio', 'OH', '39', '005', 'Ashland', 40.843274, -82.270121],
  ['Ohio', 'OH', '39', '007', 'Ashtabula', 41.906644, -80.745641],
  ['Ohio', 'OH', '39', '009', 'Athens', 39.333848, -82.046008],
  ['Ohio', 'OH', '39', '011', 'Auglaize', 40.561309, -84.224018],
  ['Ohio', 'OH', '39', '013', 'Belmont', 40.017682, -80.967727],
  ['Ohio', 'OH', '39', '015', 'Brown', 38.931377, -83.866772],
  ['Ohio', 'OH', '39', '017', 'Butler', 39.439915, -84.565397],
  ['Ohio', 'OH', '39', '019', 'Carroll', 40.579884, -81.090787],
  ['Ohio', 'OH', '39', '021', 'Champaign', 40.132759, -83.767543],
  ['Ohio', 'OH', '39', '023', 'Clark', 39.917032, -83.783676],
  ['Ohio', 'OH', '39', '025', 'Clermont', 39.052084, -84.149614],
  ['Ohio', 'OH', '39', '027', 'Clinton', 39.414041, -83.814542],
  ['Ohio', 'OH', '39', '029', 'Columbiana', 40.768462, -80.777231],
  ['Ohio', 'OH', '39', '031', 'Coshocton', 40.29672, -81.930112],
  ['Ohio', 'OH', '39', '033', 'Crawford', 40.848508, -82.924771],
  ['Ohio', 'OH', '39', '035', 'Cuyahoga', 41.760392, -81.724217],
  ['Ohio', 'OH', '39', '037', 'Darke', 40.132176, -84.620438],
  ['Ohio', 'OH', '39', '039', 'Defiance', 41.321679, -84.486433],
  ['Ohio', 'OH', '39', '041', 'Delaware', 40.278941, -83.007462],
  ['Ohio', 'OH', '39', '043', 'Erie', 41.554006, -82.525897],
  ['Ohio', 'OH', '39', '045', 'Fairfield', 39.752935, -82.628276],
  ['Ohio', 'OH', '39', '047', 'Fayette', 39.553845, -83.459327],
  ['Ohio', 'OH', '39', '049', 'Franklin', 39.969447, -83.008258],
  ['Ohio', 'OH', '39', '051', 'Fulton', 41.597264, -84.124267],
  ['Ohio', 'OH', '39', '053', 'Gallia', 38.817046, -82.301746],
  ['Ohio', 'OH', '39', '055', 'Geauga', 41.499322, -81.173505],
  ['Ohio', 'OH', '39', '057', 'Greene', 39.687479, -83.894894],
  ['Ohio', 'OH', '39', '059', 'Guernsey', 40.056665, -81.497875],
  ['Ohio', 'OH', '39', '061', 'Hamilton', 39.196927, -84.544187],
  ['Ohio', 'OH', '39', '063', 'Hancock', 41.000471, -83.666034],
  ['Ohio', 'OH', '39', '065', 'Hardin', 40.660415, -83.664077],
  ['Ohio', 'OH', '39', '067', 'Harrison', 40.292318, -81.091565],
  ['Ohio', 'OH', '39', '069', 'Henry', 41.335072, -84.065882],
  ['Ohio', 'OH', '39', '071', 'Highland', 39.184488, -83.603668],
  ['Ohio', 'OH', '39', '073', 'Hocking', 39.490343, -82.483445],
  ['Ohio', 'OH', '39', '075', 'Holmes', 40.565309, -81.929869],
  ['Ohio', 'OH', '39', '077', 'Huron', 41.14508, -82.594641],
  ['Ohio', 'OH', '39', '079', 'Jackson', 39.013477, -82.614142],
  ['Ohio', 'OH', '39', '081', 'Jefferson', 40.399188, -80.76141],
  ['Ohio', 'OH', '39', '083', 'Knox', 40.40362, -82.422393],
  ['Ohio', 'OH', '39', '085', 'Lake', 41.924116, -81.392643],
  ['Ohio', 'OH', '39', '087', 'Lawrence', 38.603866, -82.517186],
  ['Ohio', 'OH', '39', '089', 'Licking', 40.093609, -82.481251],
  ['Ohio', 'OH', '39', '091', 'Logan', 40.387553, -83.766343],
  ['Ohio', 'OH', '39', '093', 'Lorain', 41.438805, -82.179722],
  ['Ohio', 'OH', '39', '095', 'Lucas', 41.682321, -83.468867],
  ['Ohio', 'OH', '39', '097', 'Madison', 39.896607, -83.400885],
  ['Ohio', 'OH', '39', '099', 'Mahoning', 41.01088, -80.770396],
  ['Ohio', 'OH', '39', '101', 'Marion', 40.588208, -83.172927],
  ['Ohio', 'OH', '39', '103', 'Medina', 41.116051, -81.899566],
  ['Ohio', 'OH', '39', '105', 'Meigs', 39.089807, -82.028397],
  ['Ohio', 'OH', '39', '107', 'Mercer', 40.535333, -84.632059],
  ['Ohio', 'OH', '39', '109', 'Miami', 40.053326, -84.228414],
  ['Ohio', 'OH', '39', '111', 'Monroe', 39.725969, -81.090658],
  ['Ohio', 'OH', '39', '113', 'Montgomery', 39.755218, -84.290546],
  ['Ohio', 'OH', '39', '115', 'Morgan', 39.624946, -81.861699],
  ['Ohio', 'OH', '39', '117', 'Morrow', 40.525266, -82.797729],
  ['Ohio', 'OH', '39', '119', 'Muskingum', 39.966046, -81.943506],
  ['Ohio', 'OH', '39', '121', 'Noble', 39.767232, -81.452604],
  ['Ohio', 'OH', '39', '123', 'Ottawa', 41.544467, -83.009253],
  ['Ohio', 'OH', '39', '125', 'Paulding', 41.119141, -84.576967],
  ['Ohio', 'OH', '39', '127', 'Perry', 39.743187, -82.237953],
  ['Ohio', 'OH', '39', '129', 'Pickaway', 39.648947, -83.052827],
  ['Ohio', 'OH', '39', '131', 'Pike', 39.071365, -83.052921],
  ['Ohio', 'OH', '39', '133', 'Portage', 41.16864, -81.196932],
  ['Ohio', 'OH', '39', '135', 'Preble', 39.737906, -84.645358],
  ['Ohio', 'OH', '39', '137', 'Putnam', 41.024533, -84.129879],
  ['Ohio', 'OH', '39', '139', 'Richland', 40.774167, -82.542715],
  ['Ohio', 'OH', '39', '141', 'Ross', 39.323763, -83.059585],
  ['Ohio', 'OH', '39', '143', 'Sandusky', 41.355291, -83.142735],
  ['Ohio', 'OH', '39', '145', 'Scioto', 38.815019, -82.999028],
  ['Ohio', 'OH', '39', '147', 'Seneca', 41.120008, -83.127436],
  ['Ohio', 'OH', '39', '149', 'Shelby', 40.33668, -84.204143],
  ['Ohio', 'OH', '39', '151', 'Stark', 40.814131, -81.365667],
  ['Ohio', 'OH', '39', '153', 'Summit', 41.121851, -81.534936],
  ['Ohio', 'OH', '39', '155', 'Trumbull', 41.308936, -80.767656],
  ['Ohio', 'OH', '39', '157', 'Tuscarawas', 40.447441, -81.471157],
  ['Ohio', 'OH', '39', '159', 'Union', 40.295901, -83.367042],
  ['Ohio', 'OH', '39', '161', 'Van Wert', 40.85552, -84.585775],
  ['Ohio', 'OH', '39', '163', 'Vinton', 39.252014, -82.485961],
  ['Ohio', 'OH', '39', '165', 'Warren', 39.425652, -84.169906],
  ['Ohio', 'OH', '39', '167', 'Washington', 39.450684, -81.490653],
  ['Ohio', 'OH', '39', '169', 'Wayne', 40.829661, -81.887194],
  ['Ohio', 'OH', '39', '171', 'Williams', 41.564958, -84.584323],
  ['Ohio', 'OH', '39', '173', 'Wood', 41.360183, -83.622682],
  ['Ohio', 'OH', '39', '175', 'Wyandot', 40.840122, -83.313172],
  ['Oklahoma', 'OK', '40', '001', 'Adair', 35.8975, -94.651671],
  ['Oklahoma', 'OK', '40', '003', 'Alfalfa', 36.729703, -98.323445],
  ['Oklahoma', 'OK', '40', '005', 'Atoka', 34.374813, -96.034705],
  ['Oklahoma', 'OK', '40', '007', 'Beaver', 36.748334, -100.483056],
  ['Oklahoma', 'OK', '40', '009', 'Beckham', 35.273945, -99.671638],
  ['Oklahoma', 'OK', '40', '011', 'Blaine', 35.877782, -98.428934],
  ['Oklahoma', 'OK', '40', '013', 'Bryan', 33.964004, -96.264137],
  ['Oklahoma', 'OK', '40', '015', 'Caddo', 35.16792, -98.381045],
  ['Oklahoma', 'OK', '40', '017', 'Canadian', 35.543416, -97.979836],
  ['Oklahoma', 'OK', '40', '019', 'Carter', 34.251848, -97.287927],
  ['Oklahoma', 'OK', '40', '021', 'Cherokee', 35.904367, -94.996796],
  ['Oklahoma', 'OK', '40', '023', 'Choctaw', 34.027645, -95.554208],
  ['Oklahoma', 'OK', '40', '025', 'Cimarron', 36.755276, -102.508735],
  ['Oklahoma', 'OK', '40', '027', 'Cleveland', 35.203117, -97.328332],
  ['Oklahoma', 'OK', '40', '029', 'Coal', 34.582861, -96.288039],
  ['Oklahoma', 'OK', '40', '031', 'Comanche', 34.662628, -98.476597],
  ['Oklahoma', 'OK', '40', '033', 'Cotton', 34.290676, -98.373438],
  ['Oklahoma', 'OK', '40', '035', 'Craig', 36.76389, -95.201553],
  ['Oklahoma', 'OK', '40', '037', 'Creek', 35.907732, -96.379793],
  ['Oklahoma', 'OK', '40', '039', 'Custer', 35.645601, -98.997386],
  ['Oklahoma', 'OK', '40', '041', 'Delaware', 36.393369, -94.808206],
  ['Oklahoma', 'OK', '40', '043', 'Dewey', 35.978433, -99.014094],
  ['Oklahoma', 'OK', '40', '045', 'Ellis', 36.224258, -99.750139],
  ['Oklahoma', 'OK', '40', '047', 'Garfield', 36.378273, -97.787729],
  ['Oklahoma', 'OK', '40', '049', 'Garvin', 34.70935, -97.312723],
  ['Oklahoma', 'OK', '40', '051', 'Grady', 35.021058, -97.88689],
  ['Oklahoma', 'OK', '40', '053', 'Grant', 36.788254, -97.788151],
  ['Oklahoma', 'OK', '40', '055', 'Greer', 34.935263, -99.552968],
  ['Oklahoma', 'OK', '40', '057', 'Harmon', 34.745971, -99.844194],
  ['Oklahoma', 'OK', '40', '059', 'Harper', 36.800456, -99.662842],
  ['Oklahoma', 'OK', '40', '061', 'Haskell', 35.232294, -95.109578],
  ['Oklahoma', 'OK', '40', '063', 'Hughes', 35.052934, -96.251183],
  ['Oklahoma', 'OK', '40', '065', 'Jackson', 34.593949, -99.41221],
  ['Oklahoma', 'OK', '40', '067', 'Jefferson', 34.105092, -97.838814],
  ['Oklahoma', 'OK', '40', '069', 'Johnston', 34.313455, -96.654255],
  ['Oklahoma', 'OK', '40', '071', 'Kay', 36.814842, -97.143755],
  ['Oklahoma', 'OK', '40', '073', 'Kingfisher', 35.949431, -97.934568],
  ['Oklahoma', 'OK', '40', '075', 'Kiowa', 34.921489, -98.981617],
  ['Oklahoma', 'OK', '40', '077', 'Latimer', 34.875137, -95.272263],
  ['Oklahoma', 'OK', '40', '079', 'Le Flore', 34.899642, -94.703491],
  ['Oklahoma', 'OK', '40', '081', 'Lincoln', 35.703118, -96.881392],
  ['Oklahoma', 'OK', '40', '083', 'Logan', 35.914151, -97.450764],
  ['Oklahoma', 'OK', '40', '085', 'Love', 33.957775, -97.245124],
  ['Oklahoma', 'OK', '40', '087', 'McClain', 35.016414, -97.449811],
  ['Oklahoma', 'OK', '40', '089', 'McCurtain', 34.117073, -94.766086],
  ['Oklahoma', 'OK', '40', '091', 'McIntosh', 35.369092, -95.671764],
  ['Oklahoma', 'OK', '40', '093', 'Major', 36.313119, -98.542015],
  ['Oklahoma', 'OK', '40', '095', 'Marshall', 34.027007, -96.770533],
  ['Oklahoma', 'OK', '40', '097', 'Mayes', 36.303804, -95.235638],
  ['Oklahoma', 'OK', '40', '099', 'Murray', 34.485766, -97.071557],
  ['Oklahoma', 'OK', '40', '101', 'Muskogee', 35.617551, -95.383911],
  ['Oklahoma', 'OK', '40', '103', 'Noble', 36.384901, -97.236335],
  ['Oklahoma', 'OK', '40', '105', 'Nowata', 36.789615, -95.613312],
  ['Oklahoma', 'OK', '40', '107', 'Okfuskee', 35.466804, -96.327762],
  ['Oklahoma', 'OK', '40', '109', 'Oklahoma', 35.554611, -97.409401],
  ['Oklahoma', 'OK', '40', '111', 'Okmulgee', 35.646879, -95.96458],
  ['Oklahoma', 'OK', '40', '113', 'Osage', 36.62468, -96.408385],
  ['Oklahoma', 'OK', '40', '115', 'Ottawa', 36.835764, -94.802681],
  ['Oklahoma', 'OK', '40', '117', 'Pawnee', 36.313704, -96.696669],
  ['Oklahoma', 'OK', '40', '119', 'Payne', 36.079225, -96.975255],
  ['Oklahoma', 'OK', '40', '121', 'Pittsburg', 34.92554, -95.74813],
  ['Oklahoma', 'OK', '40', '123', 'Pontotoc', 34.721071, -96.692738],
  ['Oklahoma', 'OK', '40', '125', 'Pottawatomie', 35.211393, -96.957007],
  ['Oklahoma', 'OK', '40', '127', 'Pushmataha', 34.377896, -95.408085],
  ['Oklahoma', 'OK', '40', '129', 'Roger Mills', 35.708554, -99.741572],
  ['Oklahoma', 'OK', '40', '131', 'Rogers', 36.378082, -95.601337],
  ['Oklahoma', 'OK', '40', '133', 'Seminole', 35.158366, -96.602858],
  ['Oklahoma', 'OK', '40', '135', 'Sequoyah', 35.502435, -94.750757],
  ['Oklahoma', 'OK', '40', '137', 'Stephens', 34.481361, -97.855607],
  ['Oklahoma', 'OK', '40', '139', 'Texas', 36.741964, -101.488434],
  ['Oklahoma', 'OK', '40', '141', 'Tillman', 34.371085, -98.931701],
  ['Oklahoma', 'OK', '40', '143', 'Tulsa', 36.120121, -95.941731],
  ['Oklahoma', 'OK', '40', '145', 'Wagoner', 35.963479, -95.5141],
  ['Oklahoma', 'OK', '40', '147', 'Washington', 36.70438, -95.906155],
  ['Oklahoma', 'OK', '40', '149', 'Washita', 35.290177, -98.991962],
  ['Oklahoma', 'OK', '40', '151', 'Woods', 36.765141, -98.868967],
  ['Oklahoma', 'OK', '40', '153', 'Woodward', 36.425619, -99.273661],
  ['Oregon', 'OR', '41', '001', 'Baker', 44.703427, -117.691933],
  ['Oregon', 'OR', '41', '003', 'Benton', 44.490623, -123.426317],
  ['Oregon', 'OR', '41', '005', 'Clackamas', 45.160493, -122.195127],
  ['Oregon', 'OR', '41', '007', 'Clatsop', 46.024509, -123.705014],
  ['Oregon', 'OR', '41', '009', 'Columbia', 45.941932, -123.081079],
  ['Oregon', 'OR', '41', '011', 'Coos', 43.184193, -124.092557],
  ['Oregon', 'OR', '41', '013', 'Crook', 44.163054, -120.371585],
  ['Oregon', 'OR', '41', '015', 'Curry', 42.466671, -124.211407],
  ['Oregon', 'OR', '41', '017', 'Deschutes', 43.915118, -121.225575],
  ['Oregon', 'OR', '41', '019', 'Douglas', 43.285904, -123.15438],
  ['Oregon', 'OR', '41', '021', 'Gilliam', 45.381666, -120.211851],
  ['Oregon', 'OR', '41', '023', 'Grant', 44.496326, -119.014057],
  ['Oregon', 'OR', '41', '025', 'Harney', 43.064355, -118.985949],
  ['Oregon', 'OR', '41', '027', 'Hood River', 45.511775, -121.655976],
  ['Oregon', 'OR', '41', '029', 'Jackson', 42.411782, -122.675797],
  ['Oregon', 'OR', '41', '031', 'Jefferson', 44.64379, -121.183021],
  ['Oregon', 'OR', '41', '033', 'Josephine', 42.385382, -123.597245],
  ['Oregon', 'OR', '41', '035', 'Klamath', 42.683761, -121.646168],
  ['Oregon', 'OR', '41', '037', 'Lake', 42.788401, -120.38979],
  ['Oregon', 'OR', '41', '039', 'Lane', 43.928276, -122.897678],
  ['Oregon', 'OR', '41', '041', 'Lincoln', 44.641076, -123.911183],
  ['Oregon', 'OR', '41', '043', 'Linn', 44.494824, -122.543756],
  ['Oregon', 'OR', '41', '045', 'Malheur', 43.1877, -117.603976],
  ['Oregon', 'OR', '41', '047', 'Marion', 44.900898, -122.57626],
  ['Oregon', 'OR', '41', '049', 'Morrow', 45.425496, -119.602311],
  ['Oregon', 'OR', '41', '051', 'Multnomah', 45.547693, -122.417173],
  ['Oregon', 'OR', '41', '053', 'Polk', 44.904395, -123.397329],
  ['Oregon', 'OR', '41', '055', 'Sherman', 45.399216, -120.678512],
  ['Oregon', 'OR', '41', '057', 'Tillamook', 45.455743, -123.759327],
  ['Oregon', 'OR', '41', '059', 'Umatilla', 45.5912, -118.73388],
  ['Oregon', 'OR', '41', '061', 'Union', 45.304047, -117.999136],
  ['Oregon', 'OR', '41', '063', 'Wallowa', 45.593753, -117.18558],
  ['Oregon', 'OR', '41', '065', 'Wasco', 45.164536, -121.165069],
  ['Oregon', 'OR', '41', '067', 'Washington', 45.553542, -123.097615],
  ['Oregon', 'OR', '41', '069', 'Wheeler', 44.736411, -120.026875],
  ['Oregon', 'OR', '41', '071', 'Yamhill', 45.248138, -123.316117],
  ['Pennsylvania', 'PA', '42', '001', 'Adams', 39.869471, -77.21773],
  ['Pennsylvania', 'PA', '42', '003', 'Allegheny', 40.46892, -79.98092],
  ['Pennsylvania', 'PA', '42', '005', 'Armstrong', 40.812379, -79.464128],
  ['Pennsylvania', 'PA', '42', '007', 'Beaver', 40.68414, -80.350721],
  ['Pennsylvania', 'PA', '42', '009', 'Bedford', 39.997115, -78.48998],
  ['Pennsylvania', 'PA', '42', '011', 'Berks', 40.413957, -75.92686],
  ['Pennsylvania', 'PA', '42', '013', 'Blair', 40.497926, -78.31064],
  ['Pennsylvania', 'PA', '42', '015', 'Bradford', 41.791495, -76.502124],
  ['Pennsylvania', 'PA', '42', '017', 'Bucks', 40.336887, -75.10706],
  ['Pennsylvania', 'PA', '42', '019', 'Butler', 40.913834, -79.91896],
  ['Pennsylvania', 'PA', '42', '021', 'Cambria', 40.494127, -78.715284],
  ['Pennsylvania', 'PA', '42', '023', 'Cameron', 41.438289, -78.198315],
  ['Pennsylvania', 'PA', '42', '025', 'Carbon', 40.917833, -75.709428],
  ['Pennsylvania', 'PA', '42', '027', 'Centre', 40.90916, -77.84783],
  ['Pennsylvania', 'PA', '42', '029', 'Chester', 39.973965, -75.749732],
  ['Pennsylvania', 'PA', '42', '031', 'Clarion', 41.198239, -79.420404],
  ['Pennsylvania', 'PA', '42', '033', 'Clearfield', 41.000249, -78.473749],
  ['Pennsylvania', 'PA', '42', '035', 'Clinton', 41.245301, -77.649141],
  ['Pennsylvania', 'PA', '42', '037', 'Columbia', 41.045517, -76.40426],
  ['Pennsylvania', 'PA', '42', '039', 'Crawford', 41.68684, -80.107811],
  ['Pennsylvania', 'PA', '42', '041', 'Cumberland', 40.164782, -77.26344],
  ['Pennsylvania', 'PA', '42', '043', 'Dauphin', 40.412565, -76.792634],
  ['Pennsylvania', 'PA', '42', '045', 'Delaware', 39.91667, -75.398786],
  ['Pennsylvania', 'PA', '42', '047', 'Elk', 41.427334, -78.653938],
  ['Pennsylvania', 'PA', '42', '049', 'Erie', 42.117952, -80.096386],
  ['Pennsylvania', 'PA', '42', '051', 'Fayette', 39.914115, -79.644586],
  ['Pennsylvania', 'PA', '42', '053', 'Forest', 41.513304, -79.249705],
  ['Pennsylvania', 'PA', '42', '055', 'Franklin', 39.926686, -77.724485],
  ['Pennsylvania', 'PA', '42', '057', 'Fulton', 39.910751, -78.122617],
  ['Pennsylvania', 'PA', '42', '059', 'Greene', 39.848983, -80.225694],
  ['Pennsylvania', 'PA', '42', '061', 'Huntingdon', 40.422321, -77.968584],
  ['Pennsylvania', 'PA', '42', '063', 'Indiana', 40.651432, -79.087545],
  ['Pennsylvania', 'PA', '42', '065', 'Jefferson', 41.130287, -78.999044],
  ['Pennsylvania', 'PA', '42', '067', 'Juniata', 40.530673, -77.400438],
  ['Pennsylvania', 'PA', '42', '069', 'Lackawanna', 41.44025, -75.609587],
  ['Pennsylvania', 'PA', '42', '071', 'Lancaster', 40.041992, -76.250198],
  ['Pennsylvania', 'PA', '42', '073', 'Lawrence', 40.992735, -80.334446],
  ['Pennsylvania', 'PA', '42', '075', 'Lebanon', 40.367344, -76.458009],
  ['Pennsylvania', 'PA', '42', '077', 'Lehigh', 40.614241, -75.590627],
  ['Pennsylvania', 'PA', '42', '079', 'Luzerne', 41.172787, -75.976035],
  ['Pennsylvania', 'PA', '42', '081', 'Lycoming', 41.343624, -77.055253],
  ['Pennsylvania', 'PA', '42', '083', 'McKean', 41.81459, -78.572463],
  ['Pennsylvania', 'PA', '42', '085', 'Mercer', 41.300014, -80.252786],
  ['Pennsylvania', 'PA', '42', '087', 'Mifflin', 40.61189, -77.620661],
  ['Pennsylvania', 'PA', '42', '089', 'Monroe', 41.056233, -75.329037],
  ['Pennsylvania', 'PA', '42', '091', 'Montgomery', 40.209999, -75.370201],
  ['Pennsylvania', 'PA', '42', '093', 'Montour', 41.029261, -76.665259],
  ['Pennsylvania', 'PA', '42', '095', 'Northampton', 40.752791, -75.307447],
  ['Pennsylvania', 'PA', '42', '097', 'Northumberland', 40.851524, -76.709877],
  ['Pennsylvania', 'PA', '42', '099', 'Perry', 40.39778, -77.266328],
  ['Pennsylvania', 'PA', '42', '101', 'Philadelphia', 40.009376, -75.133346],
  ['Pennsylvania', 'PA', '42', '103', 'Pike', 41.325949, -75.031514],
  ['Pennsylvania', 'PA', '42', '105', 'Potter', 41.748222, -77.894735],
  ['Pennsylvania', 'PA', '42', '107', 'Schuylkill', 40.70369, -76.2178],
  ['Pennsylvania', 'PA', '42', '109', 'Snyder', 40.755348, -77.072954],
  ['Pennsylvania', 'PA', '42', '111', 'Somerset', 39.981297, -79.028486],
  ['Pennsylvania', 'PA', '42', '113', 'Sullivan', 41.43979, -76.511525],
  ['Pennsylvania', 'PA', '42', '115', 'Susquehanna', 41.819665, -75.800969],
  ['Pennsylvania', 'PA', '42', '117', 'Tioga', 41.766859, -77.257288],
  ['Pennsylvania', 'PA', '42', '119', 'Union', 40.962179, -77.055475],
  ['Pennsylvania', 'PA', '42', '121', 'Venango', 41.40198, -79.754418],
  ['Pennsylvania', 'PA', '42', '123', 'Warren', 41.843669, -79.313173],
  ['Pennsylvania', 'PA', '42', '125', 'Washington', 40.200005, -80.252132],
  ['Pennsylvania', 'PA', '42', '127', 'Wayne', 41.646589, -75.292485],
  ['Pennsylvania', 'PA', '42', '129', 'Westmoreland', 40.311068, -79.466688],
  ['Pennsylvania', 'PA', '42', '131', 'Wyoming', 41.525137, -76.00878],
  ['Pennsylvania', 'PA', '42', '133', 'York', 39.921839, -76.728446],
  ['Rhode Island', 'RI', '44', '001', 'Bristol', 41.705271, -71.285053],
  ['Rhode Island', 'RI', '44', '003', 'Kent', 41.67775, -71.576314],
  ['Rhode Island', 'RI', '44', '005', 'Newport', 41.502732, -71.284063],
  ['Rhode Island', 'RI', '44', '007', 'Providence', 41.870488, -71.578242],
  ['Rhode Island', 'RI', '44', '009', 'Washington', 41.401162, -71.617612],
  ['South Carolina', 'SC', '45', '001', 'Abbeville', 34.213809, -82.46046],
  ['South Carolina', 'SC', '45', '003', 'Aiken', 33.549317, -81.63387],
  ['South Carolina', 'SC', '45', '005', 'Allendale', 32.979784, -81.363421],
  ['South Carolina', 'SC', '45', '007', 'Anderson', 34.519549, -82.638086],
  ['South Carolina', 'SC', '45', '009', 'Bamberg', 33.203021, -81.053161],
  ['South Carolina', 'SC', '45', '011', 'Barnwell', 33.26068, -81.433753],
  ['South Carolina', 'SC', '45', '013', 'Beaufort', 32.358147, -80.68932],
  ['South Carolina', 'SC', '45', '015', 'Berkeley', 33.2077, -79.953655],
  ['South Carolina', 'SC', '45', '017', 'Calhoun', 33.67478, -80.780347],
  ['South Carolina', 'SC', '45', '019', 'Charleston', 32.800458, -79.94248],
  ['South Carolina', 'SC', '45', '021', 'Cherokee', 35.049796, -81.607647],
  ['South Carolina', 'SC', '45', '023', 'Chester', 34.689345, -81.161249],
  ['South Carolina', 'SC', '45', '025', 'Chesterfield', 34.637018, -80.159227],
  ['South Carolina', 'SC', '45', '027', 'Clarendon', 33.664682, -80.217889],
  ['South Carolina', 'SC', '45', '029', 'Colleton', 32.83498, -80.655345],
  ['South Carolina', 'SC', '45', '031', 'Darlington', 34.332185, -79.962116],
  ['South Carolina', 'SC', '45', '033', 'Dillon', 34.390172, -79.374964],
  ['South Carolina', 'SC', '45', '035', 'Dorchester', 33.082186, -80.404697],
  ['South Carolina', 'SC', '45', '037', 'Edgefield', 33.776498, -81.968245],
  ['South Carolina', 'SC', '45', '039', 'Fairfield', 34.395669, -81.127001],
  ['South Carolina', 'SC', '45', '041', 'Florence', 34.028535, -79.710233],
  ['South Carolina', 'SC', '45', '043', 'Georgetown', 33.41753, -79.300812],
  ['South Carolina', 'SC', '45', '045', 'Greenville', 34.892645, -82.372077],
  ['South Carolina', 'SC', '45', '047', 'Greenwood', 34.155796, -82.127876],
  ['South Carolina', 'SC', '45', '049', 'Hampton', 32.778323, -81.143362],
  ['South Carolina', 'SC', '45', '051', 'Horry', 33.909269, -78.976675],
  ['South Carolina', 'SC', '45', '053', 'Jasper', 32.43059, -81.021627],
  ['South Carolina', 'SC', '45', '055', 'Kershaw', 34.338356, -80.590885],
  ['South Carolina', 'SC', '45', '057', 'Lancaster', 34.686818, -80.703689],
  ['South Carolina', 'SC', '45', '059', 'Laurens', 34.483477, -82.005657],
  ['South Carolina', 'SC', '45', '061', 'Lee', 34.15864, -80.251209],
  ['South Carolina', 'SC', '45', '063', 'Lexington', 33.892554, -81.272853],
  ['South Carolina', 'SC', '45', '065', 'McCormick', 33.897605, -82.316192],
  ['South Carolina', 'SC', '45', '067', 'Marion', 34.080701, -79.362131],
  ['South Carolina', 'SC', '45', '069', 'Marlboro', 34.601805, -79.677942],
  ['South Carolina', 'SC', '45', '071', 'Newberry', 34.28973, -81.600053],
  ['South Carolina', 'SC', '45', '073', 'Oconee', 34.748759, -83.061522],
  ['South Carolina', 'SC', '45', '075', 'Orangeburg', 33.436135, -80.802913],
  ['South Carolina', 'SC', '45', '077', 'Pickens', 34.887362, -82.725368],
  ['South Carolina', 'SC', '45', '079', 'Richland', 34.029783, -80.896566],
  ['South Carolina', 'SC', '45', '081', 'Saluda', 34.005278, -81.727903],
  ['South Carolina', 'SC', '45', '083', 'Spartanburg', 34.933239, -81.991053],
  ['South Carolina', 'SC', '45', '085', 'Sumter', 33.916046, -80.382472],
  ['South Carolina', 'SC', '45', '087', 'Union', 34.690514, -81.615831],
  ['South Carolina', 'SC', '45', '089', 'Williamsburg', 33.626463, -79.716475],
  ['South Carolina', 'SC', '45', '091', 'York', 34.97019, -81.183189],
  ['South Dakota', 'SD', '46', '003', 'Aurora', 43.724719, -98.577587],
  ['South Dakota', 'SD', '46', '005', 'Beadle', 44.418265, -98.279422],
  ['South Dakota', 'SD', '46', '007', 'Bennett', 43.184826, -101.676426],
  ['South Dakota', 'SD', '46', '009', 'Bon Homme', 42.986031, -97.885613],
  ['South Dakota', 'SD', '46', '011', 'Brookings', 44.376675, -96.797797],
  ['South Dakota', 'SD', '46', '013', 'Brown', 45.589254, -98.352175],
  ['South Dakota', 'SD', '46', '015', 'Brule', 43.72988, -99.092941],
  ['South Dakota', 'SD', '46', '017', 'Buffalo', 44.044306, -99.203998],
  ['South Dakota', 'SD', '46', '019', 'Butte', 44.896435, -103.501762],
  ['South Dakota', 'SD', '46', '021', 'Campbell', 45.782241, -100.027951],
  ['South Dakota', 'SD', '46', '023', 'Charles Mix', 43.206185, -98.595143],
  ['South Dakota', 'SD', '46', '025', 'Clark', 44.855211, -97.724912],
  ['South Dakota', 'SD', '46', '027', 'Clay', 42.912997, -96.979795],
  ['South Dakota', 'SD', '46', '029', 'Codington', 44.966324, -97.198843],
  ['South Dakota', 'SD', '46', '031', 'Corson', 45.69834, -101.176017],
  ['South Dakota', 'SD', '46', '033', 'Custer', 43.684943, -103.46225],
  ['South Dakota', 'SD', '46', '035', 'Davison', 43.680439, -98.155868],
  ['South Dakota', 'SD', '46', '037', 'Day', 45.362283, -97.593734],
  ['South Dakota', 'SD', '46', '039', 'Deuel', 44.75629, -96.690239],
  ['South Dakota', 'SD', '46', '041', 'Dewey', 45.150005, -100.852218],
  ['South Dakota', 'SD', '46', '043', 'Douglas', 43.391506, -98.358433],
  ['South Dakota', 'SD', '46', '045', 'Edmunds', 45.41168, -99.205362],
  ['South Dakota', 'SD', '46', '047', 'Fall River', 43.221504, -103.512102],
  ['South Dakota', 'SD', '46', '049', 'Faulk', 45.065476, -99.153564],
  ['South Dakota', 'SD', '46', '051', 'Grant', 45.172637, -96.772261],
  ['South Dakota', 'SD', '46', '053', 'Gregory', 43.179094, -99.202158],
  ['South Dakota', 'SD', '46', '055', 'Haakon', 44.284312, -101.59179],
  ['South Dakota', 'SD', '46', '057', 'Hamlin', 44.680619, -97.178598],
  ['South Dakota', 'SD', '46', '059', 'Hand', 44.546713, -99.004575],
  ['South Dakota', 'SD', '46', '061', 'Hanson', 43.680612, -97.796845],
  ['South Dakota', 'SD', '46', '063', 'Harding', 45.596612, -103.473867],
  ['South Dakota', 'SD', '46', '065', 'Hughes', 44.392258, -99.985846],
  ['South Dakota', 'SD', '46', '067', 'Hutchinson', 43.33671, -97.749383],
  ['South Dakota', 'SD', '46', '069', 'Hyde', 44.537301, -99.492148],
  ['South Dakota', 'SD', '46', '071', 'Jackson', 43.677294, -101.626455],
  ['South Dakota', 'SD', '46', '073', 'Jerauld', 44.063416, -98.623188],
  ['South Dakota', 'SD', '46', '075', 'Jones', 43.95199, -100.686139],
  ['South Dakota', 'SD', '46', '077', 'Kingsbury', 44.362969, -97.499313],
  ['South Dakota', 'SD', '46', '079', 'Lake', 44.02845, -97.123223],
  ['South Dakota', 'SD', '46', '081', 'Lawrence', 44.353669, -103.796528],
  ['South Dakota', 'SD', '46', '083', 'Lincoln', 43.27942, -96.722286],
  ['South Dakota', 'SD', '46', '085', 'Lyman', 43.894812, -99.841925],
  ['South Dakota', 'SD', '46', '087', 'McCook', 43.678924, -97.362036],
  ['South Dakota', 'SD', '46', '089', 'McPherson', 45.784249, -99.211421],
  ['South Dakota', 'SD', '46', '091', 'Marshall', 45.737054, -97.580884],
  ['South Dakota', 'SD', '46', '093', 'Meade', 44.606792, -102.715864],
  ['South Dakota', 'SD', '46', '095', 'Mellette', 43.58493, -100.760598],
  ['South Dakota', 'SD', '46', '097', 'Miner', 44.017327, -97.60979],
  ['South Dakota', 'SD', '46', '099', 'Minnehaha', 43.667472, -96.795726],
  ['South Dakota', 'SD', '46', '101', 'Moody', 44.012429, -96.676054],
  ['South Dakota', 'SD', '46', '102', 'Oglala Lakota', 43.341937, -102.55948],
  ['South Dakota', 'SD', '46', '103', 'Pennington', 44.002349, -102.823802],
  ['South Dakota', 'SD', '46', '105', 'Perkins', 45.483387, -102.467995],
  ['South Dakota', 'SD', '46', '107', 'Potter', 45.064276, -99.949631],
  ['South Dakota', 'SD', '46', '109', 'Roberts', 45.623397, -96.947551],
  ['South Dakota', 'SD', '46', '111', 'Sanborn', 44.021032, -98.092105],
  ['South Dakota', 'SD', '46', '115', 'Spink', 44.931034, -98.339644],
  ['South Dakota', 'SD', '46', '117', 'Stanley', 44.415547, -100.749163],
  ['South Dakota', 'SD', '46', '119', 'Sully', 44.722325, -100.131399],
  ['South Dakota', 'SD', '46', '121', 'Todd', 43.208172, -100.717204],
  ['South Dakota', 'SD', '46', '123', 'Tripp', 43.349729, -99.876219],
  ['South Dakota', 'SD', '46', '125', 'Turner', 43.30867, -97.150185],
  ['South Dakota', 'SD', '46', '127', 'Union', 42.831106, -96.650829],
  ['South Dakota', 'SD', '46', '129', 'Walworth', 45.427605, -100.027856],
  ['South Dakota', 'SD', '46', '135', 'Yankton', 43.006607, -97.388339],
  ['South Dakota', 'SD', '46', '137', 'Ziebach', 44.981666, -101.669225],
  ['Tennessee', 'TN', '47', '001', 'Anderson', 36.116731, -84.195418],
  ['Tennessee', 'TN', '47', '003', 'Bedford', 35.51366, -86.458294],
  ['Tennessee', 'TN', '47', '005', 'Benton', 36.069253, -88.071212],
  ['Tennessee', 'TN', '47', '007', 'Bledsoe', 35.593668, -85.205979],
  ['Tennessee', 'TN', '47', '009', 'Blount', 35.688185, -83.922973],
  ['Tennessee', 'TN', '47', '011', 'Bradley', 35.153914, -84.859414],
  ['Tennessee', 'TN', '47', '013', 'Campbell', 36.401592, -84.15925],
  ['Tennessee', 'TN', '47', '015', 'Cannon', 35.808394, -86.062404],
  ['Tennessee', 'TN', '47', '017', 'Carroll', 35.967896, -88.451659],
  ['Tennessee', 'TN', '47', '019', 'Carter', 36.284744, -82.126593],
  ['Tennessee', 'TN', '47', '021', 'Cheatham', 36.25518, -87.100816],
  ['Tennessee', 'TN', '47', '023', 'Chester', 35.416639, -88.605505],
  ['Tennessee', 'TN', '47', '025', 'Claiborne', 36.501557, -83.660724],
  ['Tennessee', 'TN', '47', '027', 'Clay', 36.545765, -85.545718],
  ['Tennessee', 'TN', '47', '029', 'Cocke', 35.916198, -83.119223],
  ['Tennessee', 'TN', '47', '031', 'Coffee', 35.488759, -86.078219],
  ['Tennessee', 'TN', '47', '033', 'Crockett', 35.811312, -89.135349],
  ['Tennessee', 'TN', '47', '035', 'Cumberland', 35.952398, -84.994761],
  ['Tennessee', 'TN', '47', '037', 'Davidson', 36.169129, -86.78479],
  ['Tennessee', 'TN', '47', '039', 'Decatur', 35.603422, -88.107384],
  ['Tennessee', 'TN', '47', '041', 'DeKalb', 35.98222, -85.833596],
  ['Tennessee', 'TN', '47', '043', 'Dickson', 36.145533, -87.364155],
  ['Tennessee', 'TN', '47', '045', 'Dyer', 36.054196, -89.398306],
  ['Tennessee', 'TN', '47', '047', 'Fayette', 35.196993, -89.413803],
  ['Tennessee', 'TN', '47', '049', 'Fentress', 36.376079, -84.932703],
  ['Tennessee', 'TN', '47', '051', 'Franklin', 35.155926, -86.099203],
  ['Tennessee', 'TN', '47', '053', 'Gibson', 35.991694, -88.933776],
  ['Tennessee', 'TN', '47', '055', 'Giles', 35.202723, -87.035319],
  ['Tennessee', 'TN', '47', '057', 'Grainger', 36.277463, -83.509493],
  ['Tennessee', 'TN', '47', '059', 'Greene', 36.178998, -82.847746],
  ['Tennessee', 'TN', '47', '061', 'Grundy', 35.387273, -85.722188],
  ['Tennessee', 'TN', '47', '063', 'Hamblen', 36.218397, -83.266071],
  ['Tennessee', 'TN', '47', '065', 'Hamilton', 35.159186, -85.202296],
  ['Tennessee', 'TN', '47', '067', 'Hancock', 36.52142, -83.227453],
  ['Tennessee', 'TN', '47', '069', 'Hardeman', 35.218131, -88.989037],
  ['Tennessee', 'TN', '47', '071', 'Hardin', 35.201893, -88.185696],
  ['Tennessee', 'TN', '47', '073', 'Hawkins', 36.452206, -82.931386],
  ['Tennessee', 'TN', '47', '075', 'Haywood', 35.58669, -89.282536],
  ['Tennessee', 'TN', '47', '077', 'Henderson', 35.653995, -88.387674],
  ['Tennessee', 'TN', '47', '079', 'Henry', 36.325398, -88.300384],
  ['Tennessee', 'TN', '47', '081', 'Hickman', 35.802396, -87.467114],
  ['Tennessee', 'TN', '47', '083', 'Houston', 36.285777, -87.705605],
  ['Tennessee', 'TN', '47', '085', 'Humphreys', 36.04044, -87.790625],
  ['Tennessee', 'TN', '47', '087', 'Jackson', 36.354242, -85.674182],
  ['Tennessee', 'TN', '47', '089', 'Jefferson', 36.048479, -83.440966],
  ['Tennessee', 'TN', '47', '091', 'Johnson', 36.453204, -81.861237],
  ['Tennessee', 'TN', '47', '093', 'Knox', 35.992727, -83.937721],
  ['Tennessee', 'TN', '47', '095', 'Lake', 36.333905, -89.485537],
  ['Tennessee', 'TN', '47', '097', 'Lauderdale', 35.762951, -89.627732],
  ['Tennessee', 'TN', '47', '099', 'Lawrence', 35.220476, -87.396546],
  ['Tennessee', 'TN', '47', '101', 'Lewis', 35.523244, -87.496983],
  ['Tennessee', 'TN', '47', '103', 'Lincoln', 35.142532, -86.593388],
  ['Tennessee', 'TN', '47', '105', 'Loudon', 35.73745, -84.316204],
  ['Tennessee', 'TN', '47', '107', 'McMinn', 35.424471, -84.619963],
  ['Tennessee', 'TN', '47', '109', 'McNairy', 35.175626, -88.564671],
  ['Tennessee', 'TN', '47', '111', 'Macon', 36.537838, -86.001231],
  ['Tennessee', 'TN', '47', '113', 'Madison', 35.606056, -88.833424],
  ['Tennessee', 'TN', '47', '115', 'Marion', 35.133422, -85.618399],
  ['Tennessee', 'TN', '47', '117', 'Marshall', 35.468387, -86.765886],
  ['Tennessee', 'TN', '47', '119', 'Maury', 35.615696, -87.077763],
  ['Tennessee', 'TN', '47', '121', 'Meigs', 35.503397, -84.823888],
  ['Tennessee', 'TN', '47', '123', 'Monroe', 35.447666, -84.249786],
  ['Tennessee', 'TN', '47', '125', 'Montgomery', 36.500354, -87.380887],
  ['Tennessee', 'TN', '47', '127', 'Moore', 35.288889, -86.358684],
  ['Tennessee', 'TN', '47', '129', 'Morgan', 36.138697, -84.639262],
  ['Tennessee', 'TN', '47', '131', 'Obion', 36.358175, -89.150175],
  ['Tennessee', 'TN', '47', '133', 'Overton', 36.34485, -85.283076],
  ['Tennessee', 'TN', '47', '135', 'Perry', 35.659785, -87.877027],
  ['Tennessee', 'TN', '47', '137', 'Pickett', 36.559364, -85.075741],
  ['Tennessee', 'TN', '47', '139', 'Polk', 35.109437, -84.541112],
  ['Tennessee', 'TN', '47', '141', 'Putnam', 36.140807, -85.496928],
  ['Tennessee', 'TN', '47', '143', 'Rhea', 35.600587, -84.949552],
  ['Tennessee', 'TN', '47', '145', 'Roane', 35.847472, -84.523861],
  ['Tennessee', 'TN', '47', '147', 'Robertson', 36.52753, -86.869377],
  ['Tennessee', 'TN', '47', '149', 'Rutherford', 35.843369, -86.417213],
  ['Tennessee', 'TN', '47', '151', 'Scott', 36.437239, -84.498386],
  ['Tennessee', 'TN', '47', '153', 'Sequatchie', 35.372335, -85.410344],
  ['Tennessee', 'TN', '47', '155', 'Sevier', 35.791284, -83.521955],
  ['Tennessee', 'TN', '47', '157', 'Shelby', 35.183794, -89.895397],
  ['Tennessee', 'TN', '47', '159', 'Smith', 36.25565, -85.942078],
  ['Tennessee', 'TN', '47', '161', 'Stewart', 36.511756, -87.851548],
  ['Tennessee', 'TN', '47', '163', 'Sullivan', 36.510212, -82.299397],
  ['Tennessee', 'TN', '47', '165', 'Sumner', 36.470015, -86.458517],
  ['Tennessee', 'TN', '47', '167', 'Tipton', 35.500297, -89.763708],
  ['Tennessee', 'TN', '47', '169', 'Trousdale', 36.39303, -86.156691],
  ['Tennessee', 'TN', '47', '171', 'Unicoi', 36.100215, -82.418245],
  ['Tennessee', 'TN', '47', '173', 'Union', 36.28414, -83.836088],
  ['Tennessee', 'TN', '47', '175', 'Van Buren', 35.699245, -85.458411],
  ['Tennessee', 'TN', '47', '177', 'Warren', 35.678282, -85.777363],
  ['Tennessee', 'TN', '47', '179', 'Washington', 36.295665, -82.495037],
  ['Tennessee', 'TN', '47', '181', 'Wayne', 35.242687, -87.819703],
  ['Tennessee', 'TN', '47', '183', 'Weakley', 36.303523, -88.720785],
  ['Tennessee', 'TN', '47', '185', 'White', 35.927062, -85.455766],
  ['Tennessee', 'TN', '47', '187', 'Williamson', 35.894972, -86.896958],
  ['Tennessee', 'TN', '47', '189', 'Wilson', 36.148476, -86.29021],
  ['Texas', 'TX', '48', '001', 'Anderson', 31.841266, -95.661744],
  ['Texas', 'TX', '48', '003', 'Andrews', 32.312258, -102.640206],
  ['Texas', 'TX', '48', '005', 'Angelina', 31.251951, -94.611056],
  ['Texas', 'TX', '48', '007', 'Aransas', 28.104225, -96.977983],
  ['Texas', 'TX', '48', '009', 'Archer', 33.616305, -98.687267],
  ['Texas', 'TX', '48', '011', 'Armstrong', 34.964179, -101.356636],
  ['Texas', 'TX', '48', '013', 'Atascosa', 28.894296, -98.528187],
  ['Texas', 'TX', '48', '015', 'Austin', 29.891901, -96.27017],
  ['Texas', 'TX', '48', '017', 'Bailey', 34.067521, -102.830345],
  ['Texas', 'TX', '48', '019', 'Bandera', 29.755748, -99.260682],
  ['Texas', 'TX', '48', '021', 'Bastrop', 30.103128, -97.311859],
  ['Texas', 'TX', '48', '023', 'Baylor', 33.618172, -99.197228],
  ['Texas', 'TX', '48', '025', 'Bee', 28.416077, -97.742586],
  ['Texas', 'TX', '48', '027', 'Bell', 31.04211, -97.481921],
  ['Texas', 'TX', '48', '029', 'Bexar', 29.448671, -98.520147],
  ['Texas', 'TX', '48', '031', 'Blanco', 30.265605, -98.399977],
  ['Texas', 'TX', '48', '033', 'Borden', 32.744062, -101.433033],
  ['Texas', 'TX', '48', '035', 'Bosque', 31.900764, -97.637632],
  ['Texas', 'TX', '48', '037', 'Bowie', 33.446051, -94.422375],
  ['Texas', 'TX', '48', '039', 'Brazoria', 29.167818, -95.434647],
  ['Texas', 'TX', '48', '041', 'Brazos', 30.656725, -96.302389],
  ['Texas', 'TX', '48', '043', 'Brewster', 29.808997, -103.252458],
  ['Texas', 'TX', '48', '045', 'Briscoe', 34.525173, -101.205893],
  ['Texas', 'TX', '48', '047', 'Brooks', 27.034994, -98.215276],
  ['Texas', 'TX', '48', '049', 'Brown', 31.764103, -98.99847],
  ['Texas', 'TX', '48', '051', 'Burleson', 30.493487, -96.622091],
  ['Texas', 'TX', '48', '053', 'Burnet', 30.789616, -98.201195],
  ['Texas', 'TX', '48', '055', 'Caldwell', 29.840422, -97.631097],
  ['Texas', 'TX', '48', '057', 'Calhoun', 28.445366, -96.5833],
  ['Texas', 'TX', '48', '059', 'Callahan', 32.29315, -99.372249],
  ['Texas', 'TX', '48', '061', 'Cameron', 26.102923, -97.478958],
  ['Texas', 'TX', '48', '063', 'Camp', 32.974581, -94.979085],
  ['Texas', 'TX', '48', '065', 'Carson', 35.405496, -101.355356],
  ['Texas', 'TX', '48', '067', 'Cass', 33.083698, -94.357579],
  ['Texas', 'TX', '48', '069', 'Castro', 34.533621, -102.258786],
  ['Texas', 'TX', '48', '071', 'Chambers', 29.703933, -94.668875],
  ['Texas', 'TX', '48', '073', 'Cherokee', 31.843859, -95.156504],
  ['Texas', 'TX', '48', '075', 'Childress', 34.529337, -100.208336],
  ['Texas', 'TX', '48', '077', 'Clay', 33.785904, -98.212918],
  ['Texas', 'TX', '48', '079', 'Cochran', 33.60844, -102.830449],
  ['Texas', 'TX', '48', '081', 'Coke', 31.877105, -100.635236],
  ['Texas', 'TX', '48', '083', 'Coleman', 31.914205, -99.346622],
  ['Texas', 'TX', '48', '085', 'Collin', 33.193885, -96.578153],
  ['Texas', 'TX', '48', '087', 'Collingsworth', 34.963358, -100.272135],
  ['Texas', 'TX', '48', '089', 'Colorado', 29.595908, -96.508389],
  ['Texas', 'TX', '48', '091', 'Comal', 29.803019, -98.255201],
  ['Texas', 'TX', '48', '093', 'Comanche', 31.951645, -98.549617],
  ['Texas', 'TX', '48', '095', 'Concho', 31.318865, -99.863648],
  ['Texas', 'TX', '48', '097', 'Cooke', 33.639169, -97.210372],
  ['Texas', 'TX', '48', '099', 'Coryell', 31.391177, -97.798022],
  ['Texas', 'TX', '48', '101', 'Cottle', 34.091906, -100.276442],
  ['Texas', 'TX', '48', '103', 'Crane', 31.422797, -102.487774],
  ['Texas', 'TX', '48', '105', 'Crockett', 30.717532, -101.404211],
  ['Texas', 'TX', '48', '107', 'Crosby', 33.609144, -101.29871],
  ['Texas', 'TX', '48', '109', 'Culberson', 31.445909, -104.526945],
  ['Texas', 'TX', '48', '111', 'Dallam', 36.28637, -102.59402],
  ['Texas', 'TX', '48', '113', 'Dallas', 32.766987, -96.778424],
  ['Texas', 'TX', '48', '115', 'Dawson', 32.741934, -101.947322],
  ['Texas', 'TX', '48', '117', 'Deaf Smith', 34.940766, -102.607564],
  ['Texas', 'TX', '48', '119', 'Delta', 33.385933, -95.67335],
  ['Texas', 'TX', '48', '121', 'Denton', 33.205005, -97.119046],
  ['Texas', 'TX', '48', '123', 'DeWitt', 29.082342, -97.361656],
  ['Texas', 'TX', '48', '125', 'Dickens', 33.614666, -100.786095],
  ['Texas', 'TX', '48', '127', 'Dimmit', 28.423587, -99.765871],
  ['Texas', 'TX', '48', '129', 'Donley', 34.955036, -100.815846],
  ['Texas', 'TX', '48', '131', 'Duval', 27.681123, -98.497393],
  ['Texas', 'TX', '48', '133', 'Eastland', 32.324645, -98.83656],
  ['Texas', 'TX', '48', '135', 'Ector', 31.865301, -102.542507],
  ['Texas', 'TX', '48', '137', 'Edwards', 29.985877, -100.307373],
  ['Texas', 'TX', '48', '139', 'Ellis', 32.347279, -96.798336],
  ['Texas', 'TX', '48', '141', 'El Paso', 31.766403, -106.241391],
  ['Texas', 'TX', '48', '143', 'Erath', 32.238136, -98.222377],
  ['Texas', 'TX', '48', '145', 'Falls', 31.25193, -96.934128],
  ['Texas', 'TX', '48', '147', 'Fannin', 33.591161, -96.104988],
  ['Texas', 'TX', '48', '149', 'Fayette', 29.877886, -96.921231],
  ['Texas', 'TX', '48', '151', 'Fisher', 32.740473, -100.40312],
  ['Texas', 'TX', '48', '153', 'Floyd', 34.073731, -101.303274],
  ['Texas', 'TX', '48', '155', 'Foard', 33.980404, -99.777427],
  ['Texas', 'TX', '48', '157', 'Fort Bend', 29.526602, -95.771015],
  ['Texas', 'TX', '48', '159', 'Franklin', 33.175846, -95.219066],
  ['Texas', 'TX', '48', '161', 'Freestone', 31.701654, -96.144237],
  ['Texas', 'TX', '48', '163', 'Frio', 28.869334, -99.108788],
  ['Texas', 'TX', '48', '165', 'Gaines', 32.743942, -102.631562],
  ['Texas', 'TX', '48', '167', 'Galveston', 29.228706, -94.894865],
  ['Texas', 'TX', '48', '169', 'Garza', 33.183792, -101.301134],
  ['Texas', 'TX', '48', '171', 'Gillespie', 30.32639, -98.942106],
  ['Texas', 'TX', '48', '173', 'Glasscock', 31.868591, -101.528971],
  ['Texas', 'TX', '48', '175', 'Goliad', 28.6607, -97.430415],
  ['Texas', 'TX', '48', '177', 'Gonzales', 29.468704, -97.477738],
  ['Texas', 'TX', '48', '179', 'Gray', 35.402542, -100.812374],
  ['Texas', 'TX', '48', '181', 'Grayson', 33.624508, -96.675699],
  ['Texas', 'TX', '48', '183', 'Gregg', 32.486397, -94.816276],
  ['Texas', 'TX', '48', '185', 'Grimes', 30.543231, -95.988082],
  ['Texas', 'TX', '48', '187', 'Guadalupe', 29.583208, -97.949027],
  ['Texas', 'TX', '48', '189', 'Hale', 34.068436, -101.822888],
  ['Texas', 'TX', '48', '191', 'Hall', 34.453189, -100.576343],
  ['Texas', 'TX', '48', '193', 'Hamilton', 31.706982, -98.111794],
  ['Texas', 'TX', '48', '195', 'Hansford', 36.272847, -101.35693],
  ['Texas', 'TX', '48', '197', 'Hardeman', 34.289904, -99.745697],
  ['Texas', 'TX', '48', '199', 'Hardin', 30.329612, -94.393149],
  ['Texas', 'TX', '48', '201', 'Harris', 29.857273, -95.393037],
  ['Texas', 'TX', '48', '203', 'Harrison', 32.547993, -94.374425],
  ['Texas', 'TX', '48', '205', 'Hartley', 35.840244, -102.610047],
  ['Texas', 'TX', '48', '207', 'Haskell', 33.175965, -99.730773],
  ['Texas', 'TX', '48', '209', 'Hays', 30.061225, -98.029267],
  ['Texas', 'TX', '48', '211', 'Hemphill', 35.816237, -100.284807],
  ['Texas', 'TX', '48', '213', 'Henderson', 32.211633, -95.853418],
  ['Texas', 'TX', '48', '215', 'Hidalgo', 26.396384, -98.18099],
  ['Texas', 'TX', '48', '217', 'Hill', 31.98224, -97.129886],
  ['Texas', 'TX', '48', '219', 'Hockley', 33.605932, -102.343398],
  ['Texas', 'TX', '48', '221', 'Hood', 32.430149, -97.831677],
  ['Texas', 'TX', '48', '223', 'Hopkins', 33.148959, -95.565194],
  ['Texas', 'TX', '48', '225', 'Houston', 31.323036, -95.4216],
  ['Texas', 'TX', '48', '227', 'Howard', 32.303583, -101.43853],
  ['Texas', 'TX', '48', '229', 'Hudspeth', 31.450868, -105.377549],
  ['Texas', 'TX', '48', '231', 'Hunt', 33.123438, -96.083807],
  ['Texas', 'TX', '48', '233', 'Hutchinson', 35.837047, -101.362746],
  ['Texas', 'TX', '48', '235', 'Irion', 31.303424, -100.981304],
  ['Texas', 'TX', '48', '237', 'Jack', 33.232277, -98.171902],
  ['Texas', 'TX', '48', '239', 'Jackson', 28.959802, -96.58908],
  ['Texas', 'TX', '48', '241', 'Jasper', 30.752932, -94.022294],
  ['Texas', 'TX', '48', '243', 'Jeff Davis', 30.617087, -104.18786],
  ['Texas', 'TX', '48', '245', 'Jefferson', 29.854, -94.149331],
  ['Texas', 'TX', '48', '247', 'Jim Hogg', 27.041212, -98.700127],
  ['Texas', 'TX', '48', '249', 'Jim Wells', 27.733516, -98.090814],
  ['Texas', 'TX', '48', '251', 'Johnson', 32.379511, -97.364823],
  ['Texas', 'TX', '48', '253', 'Jones', 32.743709, -99.87443],
  ['Texas', 'TX', '48', '255', 'Karnes', 28.907618, -97.860767],
  ['Texas', 'TX', '48', '257', 'Kaufman', 32.598944, -96.288378],
  ['Texas', 'TX', '48', '259', 'Kendall', 29.944524, -98.711094],
  ['Texas', 'TX', '48', '261', 'Kenedy', 26.890232, -97.591233],
  ['Texas', 'TX', '48', '263', 'Kent', 33.18478, -100.76972],
  ['Texas', 'TX', '48', '265', 'Kerr', 30.053928, -99.351968],
  ['Texas', 'TX', '48', '267', 'Kimble', 30.479472, -99.746396],
  ['Texas', 'TX', '48', '269', 'King', 33.614159, -100.250548],
  ['Texas', 'TX', '48', '271', 'Kinney', 29.347087, -100.4177],
  ['Texas', 'TX', '48', '273', 'Kleberg', 27.438735, -97.66062],
  ['Texas', 'TX', '48', '275', 'Knox', 33.616657, -99.747112],
  ['Texas', 'TX', '48', '277', 'Lamar', 33.667263, -95.570348],
  ['Texas', 'TX', '48', '279', 'Lamb', 34.068862, -102.348018],
  ['Texas', 'TX', '48', '281', 'Lampasas', 31.196731, -98.240889],
  ['Texas', 'TX', '48', '283', 'La Salle', 28.351098, -99.096774],
  ['Texas', 'TX', '48', '285', 'Lavaca', 29.382578, -96.923633],
  ['Texas', 'TX', '48', '287', 'Lee', 30.321105, -96.976365],
  ['Texas', 'TX', '48', '289', 'Leon', 31.300493, -95.995622],
  ['Texas', 'TX', '48', '291', 'Liberty', 30.162189, -94.822682],
  ['Texas', 'TX', '48', '293', 'Limestone', 31.547543, -96.593623],
  ['Texas', 'TX', '48', '295', 'Lipscomb', 36.2802, -100.272683],
  ['Texas', 'TX', '48', '297', 'Live Oak', 28.351535, -98.126961],
  ['Texas', 'TX', '48', '299', 'Llano', 30.707585, -98.68469],
  ['Texas', 'TX', '48', '301', 'Loving', 31.844936, -103.561229],
  ['Texas', 'TX', '48', '303', 'Lubbock', 33.611469, -101.819944],
  ['Texas', 'TX', '48', '305', 'Lynn', 33.178412, -101.818493],
  ['Texas', 'TX', '48', '307', 'McCulloch', 31.205477, -99.359856],
  ['Texas', 'TX', '48', '309', 'McLennan', 31.549493, -97.201472],
  ['Texas', 'TX', '48', '311', 'McMullen', 28.384922, -98.578853],
  ['Texas', 'TX', '48', '313', 'Madison', 30.966878, -95.930372],
  ['Texas', 'TX', '48', '315', 'Marion', 32.797757, -94.357673],
  ['Texas', 'TX', '48', '317', 'Martin', 32.30983, -101.961836],
  ['Texas', 'TX', '48', '319', 'Mason', 30.703232, -99.237608],
  ['Texas', 'TX', '48', '321', 'Matagorda', 28.783341, -95.997755],
  ['Texas', 'TX', '48', '323', 'Maverick', 28.745217, -100.311368],
  ['Texas', 'TX', '48', '325', 'Medina', 29.353661, -99.111085],
  ['Texas', 'TX', '48', '327', 'Menard', 30.883707, -99.854936],
  ['Texas', 'TX', '48', '329', 'Midland', 31.870896, -102.024326],
  ['Texas', 'TX', '48', '331', 'Milam', 30.791242, -96.984395],
  ['Texas', 'TX', '48', '333', 'Mills', 31.494889, -98.594623],
  ['Texas', 'TX', '48', '335', 'Mitchell', 32.303781, -100.92458],
  ['Texas', 'TX', '48', '337', 'Montague', 33.676289, -97.724747],
  ['Texas', 'TX', '48', '339', 'Montgomery', 30.302364, -95.503523],
  ['Texas', 'TX', '48', '341', 'Moore', 35.835676, -101.890502],
  ['Texas', 'TX', '48', '343', 'Morris', 33.116466, -94.731265],
  ['Texas', 'TX', '48', '345', 'Motley', 34.058383, -100.793696],
  ['Texas', 'TX', '48', '347', 'Nacogdoches', 31.62056, -94.62025],
  ['Texas', 'TX', '48', '349', 'Navarro', 32.04845, -96.476908],
  ['Texas', 'TX', '48', '351', 'Newton', 30.786718, -93.73925],
  ['Texas', 'TX', '48', '353', 'Nolan', 32.312338, -100.418108],
  ['Texas', 'TX', '48', '355', 'Nueces', 27.739406, -97.521643],
  ['Texas', 'TX', '48', '357', 'Ochiltree', 36.278744, -100.815864],
  ['Texas', 'TX', '48', '359', 'Oldham', 35.401921, -102.59762],
  ['Texas', 'TX', '48', '361', 'Orange', 30.120918, -93.893358],
  ['Texas', 'TX', '48', '363', 'Palo Pinto', 32.75221, -98.317974],
  ['Texas', 'TX', '48', '365', 'Panola', 32.163978, -94.305156],
  ['Texas', 'TX', '48', '367', 'Parker', 32.777096, -97.805905],
  ['Texas', 'TX', '48', '369', 'Parmer', 34.532163, -102.784853],
  ['Texas', 'TX', '48', '371', 'Pecos', 30.770894, -102.71986],
  ['Texas', 'TX', '48', '373', 'Polk', 30.784553, -94.837338],
  ['Texas', 'TX', '48', '375', 'Potter', 35.398675, -101.893804],
  ['Texas', 'TX', '48', '377', 'Presidio', 30.005891, -104.261619],
  ['Texas', 'TX', '48', '379', 'Rains', 32.87058, -95.79544],
  ['Texas', 'TX', '48', '381', 'Randall', 34.962529, -101.895547],
  ['Texas', 'TX', '48', '383', 'Reagan', 31.372895, -101.513901],
  ['Texas', 'TX', '48', '385', 'Real', 29.82303, -99.805303],
  ['Texas', 'TX', '48', '387', 'Red River', 33.619626, -95.048429],
  ['Texas', 'TX', '48', '389', 'Reeves', 31.308366, -103.712706],
  ['Texas', 'TX', '48', '391', 'Refugio', 28.312496, -97.160479],
  ['Texas', 'TX', '48', '393', 'Roberts', 35.836216, -100.807555],
  ['Texas', 'TX', '48', '395', 'Robertson', 31.025481, -96.514941],
  ['Texas', 'TX', '48', '397', 'Rockwall', 32.889216, -96.407501],
  ['Texas', 'TX', '48', '399', 'Runnels', 31.833311, -99.967856],
  ['Texas', 'TX', '48', '401', 'Rusk', 32.109423, -94.756382],
  ['Texas', 'TX', '48', '403', 'Sabine', 31.3433, -93.851913],
  ['Texas', 'TX', '48', '405', 'San Augustine', 31.382449, -94.16318],
  ['Texas', 'TX', '48', '407', 'San Jacinto', 30.574218, -95.162852],
  ['Texas', 'TX', '48', '409', 'San Patricio', 28.011782, -97.517165],
  ['Texas', 'TX', '48', '411', 'San Saba', 31.155138, -98.819292],
  ['Texas', 'TX', '48', '413', 'Schleicher', 30.896233, -100.527216],
  ['Texas', 'TX', '48', '415', 'Scurry', 32.744462, -100.913399],
  ['Texas', 'TX', '48', '417', 'Shackelford', 32.743788, -99.347045],
  ['Texas', 'TX', '48', '419', 'Shelby', 31.790137, -94.142565],
  ['Texas', 'TX', '48', '421', 'Sherman', 36.277628, -101.894716],
  ['Texas', 'TX', '48', '423', 'Smith', 32.377093, -95.26963],
  ['Texas', 'TX', '48', '425', 'Somervell', 32.217942, -97.769211],
  ['Texas', 'TX', '48', '427', 'Starr', 26.546335, -98.715803],
  ['Texas', 'TX', '48', '429', 'Stephens', 32.731531, -98.840081],
  ['Texas', 'TX', '48', '431', 'Sterling', 31.835774, -101.054911],
  ['Texas', 'TX', '48', '433', 'Stonewall', 33.17958, -100.253807],
  ['Texas', 'TX', '48', '435', 'Sutton', 30.517865, -100.505395],
  ['Texas', 'TX', '48', '437', 'Swisher', 34.53046, -101.732852],
  ['Texas', 'TX', '48', '439', 'Tarrant', 32.77204, -97.291291],
  ['Texas', 'TX', '48', '441', 'Taylor', 32.295684, -99.89322],
  ['Texas', 'TX', '48', '443', 'Terrell', 30.232332, -102.072539],
  ['Texas', 'TX', '48', '445', 'Terry', 33.171229, -102.339284],
  ['Texas', 'TX', '48', '447', 'Throckmorton', 33.170712, -99.206137],
  ['Texas', 'TX', '48', '449', 'Titus', 33.214599, -94.966783],
  ['Texas', 'TX', '48', '451', 'Tom Green', 31.401583, -100.461355],
  ['Texas', 'TX', '48', '453', 'Travis', 30.239513, -97.69127],
  ['Texas', 'TX', '48', '455', 'Trinity', 31.087483, -95.153291],
  ['Texas', 'TX', '48', '457', 'Tyler', 30.769579, -94.379449],
  ['Texas', 'TX', '48', '459', 'Upshur', 32.735878, -94.941649],
  ['Texas', 'TX', '48', '461', 'Upton', 31.353849, -102.042013],
  ['Texas', 'TX', '48', '463', 'Uvalde', 29.35034, -99.761074],
  ['Texas', 'TX', '48', '465', 'Val Verde', 29.884961, -101.146646],
  ['Texas', 'TX', '48', '467', 'Van Zandt', 32.558948, -95.836391],
  ['Texas', 'TX', '48', '469', 'Victoria', 28.79637, -96.971198],
  ['Texas', 'TX', '48', '471', 'Walker', 30.74309, -95.569888],
  ['Texas', 'TX', '48', '473', 'Waller', 30.013578, -95.982102],
  ['Texas', 'TX', '48', '475', 'Ward', 31.513069, -103.105113],
  ['Texas', 'TX', '48', '477', 'Washington', 30.215075, -96.410272],
  ['Texas', 'TX', '48', '479', 'Webb', 27.770584, -99.326641],
  ['Texas', 'TX', '48', '481', 'Wharton', 29.278481, -96.229675],
  ['Texas', 'TX', '48', '483', 'Wheeler', 35.392593, -100.253107],
  ['Texas', 'TX', '48', '485', 'Wichita', 33.991103, -98.716851],
  ['Texas', 'TX', '48', '487', 'Wilbarger', 34.08492, -99.24244],
  ['Texas', 'TX', '48', '489', 'Willacy', 26.481092, -97.584224],
  ['Texas', 'TX', '48', '491', 'Williamson', 30.64903, -97.605069],
  ['Texas', 'TX', '48', '493', 'Wilson', 29.174303, -98.085899],
  ['Texas', 'TX', '48', '495', 'Winkler', 31.831416, -103.055986],
  ['Texas', 'TX', '48', '497', 'Wise', 33.219095, -97.653997],
  ['Texas', 'TX', '48', '499', 'Wood', 32.783588, -95.382166],
  ['Texas', 'TX', '48', '501', 'Yoakum', 33.172397, -102.823771],
  ['Texas', 'TX', '48', '503', 'Young', 33.158787, -98.678267],
  ['Texas', 'TX', '48', '505', 'Zapata', 26.996981, -99.182603],
  ['Texas', 'TX', '48', '507', 'Zavala', 28.864652, -99.75983],
  ['Utah', 'UT', '49', '001', 'Beaver', 38.357535, -113.238948],
  ['Utah', 'UT', '49', '003', 'Box Elder', 41.476021, -113.052922],
  ['Utah', 'UT', '49', '005', 'Cache', 41.734225, -111.744581],
  ['Utah', 'UT', '49', '007', 'Carbon', 39.67005, -110.590357],
  ['Utah', 'UT', '49', '009', 'Daggett', 40.890099, -109.505786],
  ['Utah', 'UT', '49', '011', 'Davis', 41.037045, -112.202123],
  ['Utah', 'UT', '49', '013', 'Duchesne', 40.289649, -110.42983],
  ['Utah', 'UT', '49', '015', 'Emery', 39.009028, -110.721111],
  ['Utah', 'UT', '49', '017', 'Garfield', 37.831622, -111.450886],
  ['Utah', 'UT', '49', '019', 'Grand', 38.974326, -109.57345],
  ['Utah', 'UT', '49', '021', 'Iron', 37.882727, -113.290059],
  ['Utah', 'UT', '49', '023', 'Juab', 39.710827, -112.794262],
  ['Utah', 'UT', '49', '025', 'Kane', 37.275118, -111.815413],
  ['Utah', 'UT', '49', '027', 'Millard', 39.09454, -113.525014],
  ['Utah', 'UT', '49', '029', 'Morgan', 41.091027, -111.577885],
  ['Utah', 'UT', '49', '031', 'Piute', 38.335881, -112.129376],
  ['Utah', 'UT', '49', '033', 'Rich', 41.627598, -111.240227],
  ['Utah', 'UT', '49', '035', 'Salt Lake', 40.667882, -111.924244],
  ['Utah', 'UT', '49', '037', 'San Juan', 37.623064, -109.78932],
  ['Utah', 'UT', '49', '039', 'Sanpete', 39.380588, -111.570451],
  ['Utah', 'UT', '49', '041', 'Sevier', 38.746825, -111.81193],
  ['Utah', 'UT', '49', '043', 'Summit', 40.87206, -110.968486],
  ['Utah', 'UT', '49', '045', 'Tooele', 40.467692, -113.124015],
  ['Utah', 'UT', '49', '047', 'Uintah', 40.125887, -109.517748],
  ['Utah', 'UT', '49', '049', 'Utah', 40.120409, -111.668667],
  ['Utah', 'UT', '49', '051', 'Wasatch', 40.334884, -111.161568],
  ['Utah', 'UT', '49', '053', 'Washington', 37.262531, -113.4878],
  ['Utah', 'UT', '49', '055', 'Wayne', 38.261229, -110.990323],
  ['Utah', 'UT', '49', '057', 'Weber', 41.270355, -111.875879],
  ['Vermont', 'VT', '50', '001', 'Addison', 44.031248, -73.141581],
  ['Vermont', 'VT', '50', '003', 'Bennington', 43.035325, -73.11146],
  ['Vermont', 'VT', '50', '005', 'Caledonia', 44.468791, -72.112168],
  ['Vermont', 'VT', '50', '007', 'Chittenden', 44.460676, -73.070525],
  ['Vermont', 'VT', '50', '009', 'Essex', 44.724021, -71.732736],
  ['Vermont', 'VT', '50', '011', 'Franklin', 44.858964, -72.909402],
  ['Vermont', 'VT', '50', '013', 'Grand Isle', 44.801788, -73.300758],
  ['Vermont', 'VT', '50', '015', 'Lamoille', 44.603504, -72.638356],
  ['Vermont', 'VT', '50', '017', 'Orange', 44.003392, -72.369687],
  ['Vermont', 'VT', '50', '019', 'Orleans', 44.828442, -72.25163],
  ['Vermont', 'VT', '50', '021', 'Rutland', 43.580844, -73.038196],
  ['Vermont', 'VT', '50', '023', 'Washington', 44.274953, -72.609475],
  ['Vermont', 'VT', '50', '025', 'Windham', 42.999143, -72.716335],
  ['Vermont', 'VT', '50', '027', 'Windsor', 43.588143, -72.591515],
  ['Virginia', 'VA', '51', '001', 'Accomack', 37.765944, -75.757807],
  ['Virginia', 'VA', '51', '003', 'Albemarle', 38.024184, -78.553506],
  ['Virginia', 'VA', '51', '005', 'Alleghany', 37.787905, -80.008669],
  ['Virginia', 'VA', '51', '007', 'Amelia', 37.336131, -77.973218],
  ['Virginia', 'VA', '51', '009', 'Amherst', 37.630362, -79.147848],
  ['Virginia', 'VA', '51', '011', 'Appomattox', 37.370725, -78.81094],
  ['Virginia', 'VA', '51', '013', 'Arlington', 38.878337, -77.100703],
  ['Virginia', 'VA', '51', '015', 'Augusta', 38.167807, -79.146682],
  ['Virginia', 'VA', '51', '017', 'Bath', 38.068988, -79.732898],
  ['Virginia', 'VA', '51', '019', 'Bedford', 37.312408, -79.527947],
  ['Virginia', 'VA', '51', '021', 'Bland', 37.130612, -81.125853],
  ['Virginia', 'VA', '51', '023', 'Botetourt', 37.553193, -79.805318],
  ['Virginia', 'VA', '51', '025', 'Brunswick', 36.764531, -77.860916],
  ['Virginia', 'VA', '51', '027', 'Buchanan', 37.26812, -82.038151],
  ['Virginia', 'VA', '51', '029', 'Buckingham', 37.573928, -78.529169],
  ['Virginia', 'VA', '51', '031', 'Campbell', 37.210151, -79.095429],
  ['Virginia', 'VA', '51', '033', 'Caroline', 38.030319, -77.352348],
  ['Virginia', 'VA', '51', '035', 'Carroll', 36.732426, -80.728043],
  ['Virginia', 'VA', '51', '036', 'Charles City', 37.361048, -77.054171],
  ['Virginia', 'VA', '51', '037', 'Charlotte', 37.014091, -78.661424],
  ['Virginia', 'VA', '51', '041', 'Chesterfield', 37.378434, -77.585847],
  ['Virginia', 'VA', '51', '043', 'Clarke', 39.115931, -77.992004],
  ['Virginia', 'VA', '51', '045', 'Craig', 37.473129, -80.231734],
  ['Virginia', 'VA', '51', '047', 'Culpeper', 38.48593, -77.956476],
  ['Virginia', 'VA', '51', '049', 'Cumberland', 37.520189, -78.252836],
  ['Virginia', 'VA', '51', '051', 'Dickenson', 37.137081, -82.349208],
  ['Virginia', 'VA', '51', '053', 'Dinwiddie', 37.073498, -77.635492],
  ['Virginia', 'VA', '51', '057', 'Essex', 37.93948, -76.941871],
  ['Virginia', 'VA', '51', '059', 'Fairfax', 38.833743, -77.276117],
  ['Virginia', 'VA', '51', '061', 'Fauquier', 38.744103, -77.821585],
  ['Virginia', 'VA', '51', '063', 'Floyd', 36.931438, -80.350309],
  ['Virginia', 'VA', '51', '065', 'Fluvanna', 37.830606, -78.284445],
  ['Virginia', 'VA', '51', '067', 'Franklin', 36.99235, -79.883959],
  ['Virginia', 'VA', '51', '069', 'Frederick', 39.203637, -78.263916],
  ['Virginia', 'VA', '51', '071', 'Giles', 37.31193, -80.717178],
  ['Virginia', 'VA', '51', '073', 'Gloucester', 37.403541, -76.523505],
  ['Virginia', 'VA', '51', '075', 'Goochland', 37.724166, -77.914273],
  ['Virginia', 'VA', '51', '077', 'Grayson', 36.656303, -81.225337],
  ['Virginia', 'VA', '51', '079', 'Greene', 38.297981, -78.470163],
  ['Virginia', 'VA', '51', '081', 'Greensville', 36.680225, -77.560261],
  ['Virginia', 'VA', '51', '083', 'Halifax', 36.766461, -78.939614],
  ['Virginia', 'VA', '51', '085', 'Hanover', 37.760166, -77.490992],
  ['Virginia', 'VA', '51', '087', 'Henrico', 37.437521, -77.300333],
  ['Virginia', 'VA', '51', '089', 'Henry', 36.620593, -79.980584],
  ['Virginia', 'VA', '51', '091', 'Highland', 38.356672, -79.567958],
  ['Virginia', 'VA', '51', '093', 'Isle of Wight', 36.901418, -76.707569],
  ['Virginia', 'VA', '51', '095', 'James City', 37.324427, -76.778319],
  ['Virginia', 'VA', '51', '097', 'King and Queen', 37.720995, -76.89109],
  ['Virginia', 'VA', '51', '099', 'King George', 38.277179, -77.162702],
  ['Virginia', 'VA', '51', '101', 'King William', 37.70826, -77.091054],
  ['Virginia', 'VA', '51', '103', 'Lancaster', 37.703831, -76.413199],
  ['Virginia', 'VA', '51', '105', 'Lee', 36.702162, -83.130334],
  ['Virginia', 'VA', '51', '107', 'Loudoun', 39.08113, -77.638857],
  ['Virginia', 'VA', '51', '109', 'Louisa', 37.971681, -77.959178],
  ['Virginia', 'VA', '51', '111', 'Lunenburg', 36.945757, -78.242313],
  ['Virginia', 'VA', '51', '113', 'Madison', 38.412059, -78.276961],
  ['Virginia', 'VA', '51', '115', 'Mathews', 37.425348, -76.268808],
  ['Virginia', 'VA', '51', '117', 'Mecklenburg', 36.687256, -78.368959],
  ['Virginia', 'VA', '51', '119', 'Middlesex', 37.606828, -76.527958],
  ['Virginia', 'VA', '51', '121', 'Montgomery', 37.174885, -80.387314],
  ['Virginia', 'VA', '51', '125', 'Nelson', 37.790016, -78.879394],
  ['Virginia', 'VA', '51', '127', 'New Kent', 37.498974, -76.993339],
  ['Virginia', 'VA', '51', '131', 'Northampton', 37.302629, -75.923868],
  ['Virginia', 'VA', '51', '133', 'Northumberland', 37.856974, -76.379687],
  ['Virginia', 'VA', '51', '135', 'Nottoway', 37.143696, -78.052162],
  ['Virginia', 'VA', '51', '137', 'Orange', 38.250439, -78.00998],
  ['Virginia', 'VA', '51', '139', 'Page', 38.623751, -78.490471],
  ['Virginia', 'VA', '51', '141', 'Patrick', 36.667327, -80.286141],
  ['Virginia', 'VA', '51', '143', 'Pittsylvania', 36.821721, -79.398502],
  ['Virginia', 'VA', '51', '145', 'Powhatan', 37.549404, -77.912855],
  ['Virginia', 'VA', '51', '147', 'Prince Edward', 37.224881, -78.432957],
  ['Virginia', 'VA', '51', '149', 'Prince George', 37.187326, -77.220993],
  ['Virginia', 'VA', '51', '153', 'Prince William', 38.702332, -77.478887],
  ['Virginia', 'VA', '51', '155', 'Pulaski', 37.063385, -80.713444],
  ['Virginia', 'VA', '51', '157', 'Rappahannock', 38.684522, -78.168824],
  ['Virginia', 'VA', '51', '159', 'Richmond', 37.942894, -76.730561],
  ['Virginia', 'VA', '51', '161', 'Roanoke', 37.331077, -80.19011],
  ['Virginia', 'VA', '51', '163', 'Rockbridge', 37.814517, -79.447754],
  ['Virginia', 'VA', '51', '165', 'Rockingham', 38.511257, -78.876307],
  ['Virginia', 'VA', '51', '167', 'Russell', 36.93342, -82.095934],
  ['Virginia', 'VA', '51', '169', 'Scott', 36.712778, -82.613627],
  ['Virginia', 'VA', '51', '171', 'Shenandoah', 38.856204, -78.573987],
  ['Virginia', 'VA', '51', '173', 'Smyth', 36.842318, -81.539786],
  ['Virginia', 'VA', '51', '175', 'Southampton', 36.720173, -77.103856],
  ['Virginia', 'VA', '51', '177', 'Spotsylvania', 38.182311, -77.65628],
  ['Virginia', 'VA', '51', '179', 'Stafford', 38.418933, -77.459043],
  ['Virginia', 'VA', '51', '181', 'Surry', 37.119761, -76.880172],
  ['Virginia', 'VA', '51', '183', 'Sussex', 36.926645, -77.259732],
  ['Virginia', 'VA', '51', '185', 'Tazewell', 37.125395, -81.562924],
  ['Virginia', 'VA', '51', '187', 'Warren', 38.908187, -78.207131],
  ['Virginia', 'VA', '51', '191', 'Washington', 36.747813, -81.950291],
  ['Virginia', 'VA', '51', '193', 'Westmoreland', 38.109191, -76.80417],
  ['Virginia', 'VA', '51', '195', 'Wise', 36.974561, -82.62156],
  ['Virginia', 'VA', '51', '197', 'Wythe', 36.901471, -81.084209],
  ['Virginia', 'VA', '51', '199', 'York', 37.220914, -76.395533],
  ['Virginia', 'VA', '51', '510', 'Alexandria', 38.818343, -77.082026],
  ['Virginia', 'VA', '51', '515', 'Bedford', 37.338156, -79.520705],
  ['Virginia', 'VA', '51', '520', 'Bristol', 36.616954, -82.157564],
  ['Virginia', 'VA', '51', '530', 'Buena Vista', 37.731663, -79.356375],
  ['Virginia', 'VA', '51', '540', 'Charlottesville', 38.037658, -78.485381],
  ['Virginia', 'VA', '51', '550', 'Chesapeake', 36.679376, -76.301788],
  ['Virginia', 'VA', '51', '570', 'Colonial Heights', 37.261685, -77.396804],
  ['Virginia', 'VA', '51', '580', 'Covington', 37.778143, -79.986039],
  ['Virginia', 'VA', '51', '590', 'Danville', 36.583334, -79.408071],
  ['Virginia', 'VA', '51', '595', 'Emporia', 36.696182, -77.535975],
  ['Virginia', 'VA', '51', '600', 'Fairfax', 38.853183, -77.299025],
  ['Virginia', 'VA', '51', '610', 'Falls Church', 38.883787, -77.174639],
  ['Virginia', 'VA', '51', '620', 'Franklin', 36.684014, -76.941396],
  ['Virginia', 'VA', '51', '630', 'Fredericksburg', 38.299272, -77.486658],
  ['Virginia', 'VA', '51', '640', 'Galax', 36.66564, -80.914308],
  ['Virginia', 'VA', '51', '650', 'Hampton', 37.04803, -76.297149],
  ['Virginia', 'VA', '51', '660', 'Harrisonburg', 38.436013, -78.874197],
  ['Virginia', 'VA', '51', '670', 'Hopewell', 37.29101, -77.298944],
  ['Virginia', 'VA', '51', '678', 'Lexington', 37.782332, -79.44432],
  ['Virginia', 'VA', '51', '680', 'Lynchburg', 37.399016, -79.195458],
  ['Virginia', 'VA', '51', '683', 'Manassas', 38.747561, -77.484727],
  ['Virginia', 'VA', '51', '685', 'Manassas Park', 38.768991, -77.448681],
  ['Virginia', 'VA', '51', '690', 'Martinsville', 36.683527, -79.863648],
  ['Virginia', 'VA', '51', '700', 'Newport News', 37.075978, -76.521719],
  ['Virginia', 'VA', '51', '710', 'Norfolk', 36.923015, -76.244641],
  ['Virginia', 'VA', '51', '720', 'Norton', 36.931549, -82.625996],
  ['Virginia', 'VA', '51', '730', 'Petersburg', 37.20473, -77.392368],
  ['Virginia', 'VA', '51', '735', 'Poquoson', 37.12836, -76.303534],
  ['Virginia', 'VA', '51', '740', 'Portsmouth', 36.85943, -76.356269],
  ['Virginia', 'VA', '51', '750', 'Radford', 37.120036, -80.557048],
  ['Virginia', 'VA', '51', '760', 'Richmond', 37.531399, -77.476009],
  ['Virginia', 'VA', '51', '770', 'Roanoke', 37.27783, -79.958472],
  ['Virginia', 'VA', '51', '775', 'Salem', 37.285333, -80.055241],
  ['Virginia', 'VA', '51', '790', 'Staunton', 38.158056, -79.061501],
  ['Virginia', 'VA', '51', '800', 'Suffolk', 36.697157, -76.634781],
  ['Virginia', 'VA', '51', '810', 'Virginia Beach', 36.779322, -76.02402],
  ['Virginia', 'VA', '51', '820', 'Waynesboro', 38.067157, -78.90142],
  ['Virginia', 'VA', '51', '830', 'Williamsburg', 37.269293, -76.706717],
  ['Virginia', 'VA', '51', '840', 'Winchester', 39.173869, -78.176356],
  ['Washington', 'WA', '53', '001', 'Adams', 47.00484, -118.533308],
  ['Washington', 'WA', '53', '003', 'Asotin', 46.181861, -117.227781],
  ['Washington', 'WA', '53', '005', 'Benton', 46.228072, -119.516864],
  ['Washington', 'WA', '53', '007', 'Chelan', 47.859892, -120.618543],
  ['Washington', 'WA', '53', '009', 'Clallam', 48.113009, -123.930611],
  ['Washington', 'WA', '53', '011', 'Clark', 45.771674, -122.485903],
  ['Washington', 'WA', '53', '013', 'Columbia', 46.292851, -117.911635],
  ['Washington', 'WA', '53', '015', 'Cowlitz', 46.185923, -122.658682],
  ['Washington', 'WA', '53', '017', 'Douglas', 47.735866, -119.69588],
  ['Washington', 'WA', '53', '019', 'Ferry', 48.437246, -118.517074],
  ['Washington', 'WA', '53', '021', 'Franklin', 46.53458, -118.906944],
  ['Washington', 'WA', '53', '023', 'Garfield', 46.429474, -117.536715],
  ['Washington', 'WA', '53', '025', 'Grant', 47.213633, -119.467788],
  ['Washington', 'WA', '53', '027', 'Grays Harbor', 47.142786, -123.827043],
  ['Washington', 'WA', '53', '029', 'Island', 48.158436, -122.670503],
  ['Washington', 'WA', '53', '031', 'Jefferson', 47.802641, -123.52181],
  ['Washington', 'WA', '53', '033', 'King', 47.493554, -121.832375],
  ['Washington', 'WA', '53', '035', 'Kitsap', 47.639687, -122.649636],
  ['Washington', 'WA', '53', '037', 'Kittitas', 47.124444, -120.676714],
  ['Washington', 'WA', '53', '039', 'Klickitat', 45.869509, -120.780117],
  ['Washington', 'WA', '53', '041', 'Lewis', 46.580071, -122.377444],
  ['Washington', 'WA', '53', '043', 'Lincoln', 47.582718, -118.417668],
  ['Washington', 'WA', '53', '045', 'Mason', 47.354126, -123.17385],
  ['Washington', 'WA', '53', '047', 'Okanogan', 48.550971, -119.691035],
  ['Washington', 'WA', '53', '049', 'Pacific', 46.556587, -123.782419],
  ['Washington', 'WA', '53', '051', 'Pend Oreille', 48.543877, -117.232183],
  ['Washington', 'WA', '53', '053', 'Pierce', 47.040716, -122.144709],
  ['Washington', 'WA', '53', '055', 'San Juan', 48.508862, -123.100616],
  ['Washington', 'WA', '53', '057', 'Skagit', 48.493066, -121.816278],
  ['Washington', 'WA', '53', '059', 'Skamania', 46.024782, -121.953227],
  ['Washington', 'WA', '53', '061', 'Snohomish', 48.054913, -121.766412],
  ['Washington', 'WA', '53', '063', 'Spokane', 47.620379, -117.404392],
  ['Washington', 'WA', '53', '065', 'Stevens', 48.390648, -117.854897],
  ['Washington', 'WA', '53', '067', 'Thurston', 46.932598, -122.829441],
  ['Washington', 'WA', '53', '069', 'Wahkiakum', 46.294638, -123.424458],
  ['Washington', 'WA', '53', '071', 'Walla Walla', 46.254606, -118.480374],
  ['Washington', 'WA', '53', '073', 'Whatcom', 48.842653, -121.836433],
  ['Washington', 'WA', '53', '075', 'Whitman', 46.903322, -117.522962],
  ['Washington', 'WA', '53', '077', 'Yakima', 46.456558, -120.740145],
  ['West Virginia', 'WV', '54', '001', 'Barbour', 39.139754, -79.996914],
  ['West Virginia', 'WV', '54', '003', 'Berkeley', 39.457854, -78.032338],
  ['West Virginia', 'WV', '54', '005', 'Boone', 38.022838, -81.713314],
  ['West Virginia', 'WV', '54', '007', 'Braxton', 38.699302, -80.731649],
  ['West Virginia', 'WV', '54', '009', 'Brooke', 40.272645, -80.578691],
  ['West Virginia', 'WV', '54', '011', 'Cabell', 38.41958, -82.243392],
  ['West Virginia', 'WV', '54', '013', 'Calhoun', 38.844159, -81.115478],
  ['West Virginia', 'WV', '54', '015', 'Clay', 38.459826, -81.081866],
  ['West Virginia', 'WV', '54', '017', 'Doddridge', 39.263482, -80.701147],
  ['West Virginia', 'WV', '54', '019', 'Fayette', 38.030933, -81.086051],
  ['West Virginia', 'WV', '54', '021', 'Gilmer', 38.915865, -80.849409],
  ['West Virginia', 'WV', '54', '023', 'Grant', 39.105988, -79.195064],
  ['West Virginia', 'WV', '54', '025', 'Greenbrier', 37.924418, -80.45059],
  ['West Virginia', 'WV', '54', '027', 'Hampshire', 39.31214, -78.611989],
  ['West Virginia', 'WV', '54', '029', 'Hancock', 40.516931, -80.570057],
  ['West Virginia', 'WV', '54', '031', 'Hardy', 39.010818, -78.843641],
  ['West Virginia', 'WV', '54', '033', 'Harrison', 39.279199, -80.386487],
  ['West Virginia', 'WV', '54', '035', 'Jackson', 38.834234, -81.677717],
  ['West Virginia', 'WV', '54', '037', 'Jefferson', 39.307399, -77.86322],
  ['West Virginia', 'WV', '54', '039', 'Kanawha', 38.328061, -81.523522],
  ['West Virginia', 'WV', '54', '041', 'Lewis', 38.988844, -80.495518],
  ['West Virginia', 'WV', '54', '043', 'Lincoln', 38.171651, -82.077547],
  ['West Virginia', 'WV', '54', '045', 'Logan', 37.830591, -81.940853],
  ['West Virginia', 'WV', '54', '047', 'McDowell', 37.382663, -81.658205],
  ['West Virginia', 'WV', '54', '049', 'Marion', 39.505839, -80.243402],
  ['West Virginia', 'WV', '54', '051', 'Marshall', 39.854717, -80.671378],
  ['West Virginia', 'WV', '54', '053', 'Mason', 38.759288, -82.024172],
  ['West Virginia', 'WV', '54', '055', 'Mercer', 37.403448, -81.106456],
  ['West Virginia', 'WV', '54', '057', 'Mineral', 39.405626, -78.956581],
  ['West Virginia', 'WV', '54', '059', 'Mingo', 37.721151, -82.158993],
  ['West Virginia', 'WV', '54', '061', 'Monongalia', 39.633645, -80.059074],
  ['West Virginia', 'WV', '54', '063', 'Monroe', 37.550353, -80.547891],
  ['West Virginia', 'WV', '54', '065', 'Morgan', 39.554835, -78.257314],
  ['West Virginia', 'WV', '54', '067', 'Nicholas', 38.291416, -80.797516],
  ['West Virginia', 'WV', '54', '069', 'Ohio', 40.098932, -80.620728],
  ['West Virginia', 'WV', '54', '071', 'Pendleton', 38.686836, -79.333707],
  ['West Virginia', 'WV', '54', '073', 'Pleasants', 39.368133, -81.161172],
  ['West Virginia', 'WV', '54', '075', 'Pocahontas', 38.332513, -80.012092],
  ['West Virginia', 'WV', '54', '077', 'Preston', 39.46903, -79.668865],
  ['West Virginia', 'WV', '54', '079', 'Putnam', 38.510513, -81.906109],
  ['West Virginia', 'WV', '54', '081', 'Raleigh', 37.76247, -81.264671],
  ['West Virginia', 'WV', '54', '083', 'Randolph', 38.781087, -79.867783],
  ['West Virginia', 'WV', '54', '085', 'Ritchie', 39.177112, -81.066317],
  ['West Virginia', 'WV', '54', '087', 'Roane', 38.743033, -81.354502],
  ['West Virginia', 'WV', '54', '089', 'Summers', 37.656003, -80.856315],
  ['West Virginia', 'WV', '54', '091', 'Taylor', 39.329072, -80.045629],
  ['West Virginia', 'WV', '54', '093', 'Tucker', 39.111175, -79.559968],
  ['West Virginia', 'WV', '54', '095', 'Tyler', 39.465981, -80.879493],
  ['West Virginia', 'WV', '54', '097', 'Upshur', 38.902537, -80.231335],
  ['West Virginia', 'WV', '54', '099', 'Wayne', 38.145531, -82.419698],
  ['West Virginia', 'WV', '54', '101', 'Webster', 38.492985, -80.430262],
  ['West Virginia', 'WV', '54', '103', 'Wetzel', 39.596574, -80.634394],
  ['West Virginia', 'WV', '54', '105', 'Wirt', 39.020034, -81.382975],
  ['West Virginia', 'WV', '54', '107', 'Wood', 39.211679, -81.515928],
  ['West Virginia', 'WV', '54', '109', 'Wyoming', 37.603717, -81.548884],
  ['Wisconsin', 'WI', '55', '001', 'Adams', 43.973763, -89.767223],
  ['Wisconsin', 'WI', '55', '003', 'Ashland', 46.546291, -90.665154],
  ['Wisconsin', 'WI', '55', '005', 'Barron', 45.437192, -91.852892],
  ['Wisconsin', 'WI', '55', '007', 'Bayfield', 46.634199, -91.177282],
  ['Wisconsin', 'WI', '55', '009', 'Brown', 44.473961, -87.995926],
  ['Wisconsin', 'WI', '55', '011', 'Buffalo', 44.389759, -91.758714],
  ['Wisconsin', 'WI', '55', '013', 'Burnett', 45.865255, -92.367978],
  ['Wisconsin', 'WI', '55', '015', 'Calumet', 44.07841, -88.212132],
  ['Wisconsin', 'WI', '55', '017', 'Chippewa', 45.069092, -91.283505],
  ['Wisconsin', 'WI', '55', '019', 'Clark', 44.733596, -90.610201],
  ['Wisconsin', 'WI', '55', '021', 'Columbia', 43.471882, -89.330472],
  ['Wisconsin', 'WI', '55', '023', 'Crawford', 43.24991, -90.95123],
  ['Wisconsin', 'WI', '55', '025', 'Dane', 43.067468, -89.417852],
  ['Wisconsin', 'WI', '55', '027', 'Dodge', 43.422706, -88.704379],
  ['Wisconsin', 'WI', '55', '029', 'Door', 45.067808, -87.087936],
  ['Wisconsin', 'WI', '55', '031', 'Douglas', 46.463316, -91.89258],
  ['Wisconsin', 'WI', '55', '033', 'Dunn', 44.947741, -91.89772],
  ['Wisconsin', 'WI', '55', '035', 'Eau Claire', 44.726355, -91.286414],
  ['Wisconsin', 'WI', '55', '037', 'Florence', 45.849646, -88.400322],
  ['Wisconsin', 'WI', '55', '039', 'Fond du Lac', 43.754722, -88.493284],
  ['Wisconsin', 'WI', '55', '041', 'Forest', 45.666882, -88.773225],
  ['Wisconsin', 'WI', '55', '043', 'Grant', 42.870062, -90.695368],
  ['Wisconsin', 'WI', '55', '045', 'Green', 42.677728, -89.605639],
  ['Wisconsin', 'WI', '55', '047', 'Green Lake', 43.76141, -88.987228],
  ['Wisconsin', 'WI', '55', '049', 'Iowa', 43.001021, -90.133692],
  ['Wisconsin', 'WI', '55', '051', 'Iron', 46.32655, -90.261299],
  ['Wisconsin', 'WI', '55', '053', 'Jackson', 44.324895, -90.806541],
  ['Wisconsin', 'WI', '55', '055', 'Jefferson', 43.013807, -88.773986],
  ['Wisconsin', 'WI', '55', '057', 'Juneau', 43.932836, -90.113984],
  ['Wisconsin', 'WI', '55', '059', 'Kenosha', 42.579703, -87.424898],
  ['Wisconsin', 'WI', '55', '061', 'Kewaunee', 44.500949, -87.161813],
  ['Wisconsin', 'WI', '55', '063', 'La Crosse', 43.908222, -91.111758],
  ['Wisconsin', 'WI', '55', '065', 'Lafayette', 42.655578, -90.130292],
  ['Wisconsin', 'WI', '55', '067', 'Langlade', 45.259204, -89.06819],
  ['Wisconsin', 'WI', '55', '069', 'Lincoln', 45.338319, -89.742082],
  ['Wisconsin', 'WI', '55', '071', 'Manitowoc', 44.105108, -87.313828],
  ['Wisconsin', 'WI', '55', '073', 'Marathon', 44.898036, -89.757823],
  ['Wisconsin', 'WI', '55', '075', 'Marinette', 45.346899, -87.991198],
  ['Wisconsin', 'WI', '55', '077', 'Marquette', 43.826053, -89.409095],
  ['Wisconsin', 'WI', '55', '078', 'Menominee', 44.991304, -88.669251],
  ['Wisconsin', 'WI', '55', '079', 'Milwaukee', 43.017655, -87.481575],
  ['Wisconsin', 'WI', '55', '081', 'Monroe', 43.945175, -90.619969],
  ['Wisconsin', 'WI', '55', '083', 'Oconto', 44.996575, -88.206516],
  ['Wisconsin', 'WI', '55', '085', 'Oneida', 45.713791, -89.536693],
  ['Wisconsin', 'WI', '55', '087', 'Outagamie', 44.418226, -88.464988],
  ['Wisconsin', 'WI', '55', '089', 'Ozaukee', 43.360715, -87.496553],
  ['Wisconsin', 'WI', '55', '091', 'Pepin', 44.627436, -91.83489],
  ['Wisconsin', 'WI', '55', '093', 'Pierce', 44.725337, -92.426279],
  ['Wisconsin', 'WI', '55', '095', 'Polk', 45.46803, -92.453154],
  ['Wisconsin', 'WI', '55', '097', 'Portage', 44.476246, -89.49807],
  ['Wisconsin', 'WI', '55', '099', 'Price', 45.679072, -90.35965],
  ['Wisconsin', 'WI', '55', '101', 'Racine', 42.754075, -87.414676],
  ['Wisconsin', 'WI', '55', '103', 'Richland', 43.376199, -90.435693],
  ['Wisconsin', 'WI', '55', '105', 'Rock', 42.669931, -89.075119],
  ['Wisconsin', 'WI', '55', '107', 'Rusk', 45.472734, -91.136745],
  ['Wisconsin', 'WI', '55', '109', 'St. Croix', 45.028959, -92.447284],
  ['Wisconsin', 'WI', '55', '111', 'Sauk', 43.427998, -89.943329],
  ['Wisconsin', 'WI', '55', '113', 'Sawyer', 45.864913, -91.14713],
  ['Wisconsin', 'WI', '55', '115', 'Shawano', 44.789641, -88.755813],
  ['Wisconsin', 'WI', '55', '117', 'Sheboygan', 43.746002, -87.730546],
  ['Wisconsin', 'WI', '55', '119', 'Taylor', 45.211656, -90.504853],
  ['Wisconsin', 'WI', '55', '121', 'Trempealeau', 44.30305, -91.358867],
  ['Wisconsin', 'WI', '55', '123', 'Vernon', 43.599858, -90.815226],
  ['Wisconsin', 'WI', '55', '125', 'Vilas', 46.049848, -89.501254],
  ['Wisconsin', 'WI', '55', '127', 'Walworth', 42.66811, -88.541731],
  ['Wisconsin', 'WI', '55', '129', 'Washburn', 45.892463, -91.796423],
  ['Wisconsin', 'WI', '55', '131', 'Washington', 43.391156, -88.232917],
  ['Wisconsin', 'WI', '55', '133', 'Waukesha', 43.019308, -88.306707],
  ['Wisconsin', 'WI', '55', '135', 'Waupaca', 44.478004, -88.967006],
  ['Wisconsin', 'WI', '55', '137', 'Waushara', 44.112825, -89.239752],
  ['Wisconsin', 'WI', '55', '139', 'Winnebago', 44.085707, -88.668149],
  ['Wisconsin', 'WI', '55', '141', 'Wood', 44.461413, -90.038825],
  ['Wyoming', 'WY', '56', '001', 'Albany', 41.665514, -105.721883],
  ['Wyoming', 'WY', '56', '003', 'Big Horn', 44.525654, -107.993321],
  ['Wyoming', 'WY', '56', '005', 'Campbell', 44.241321, -105.552029],
  ['Wyoming', 'WY', '56', '007', 'Carbon', 41.70359, -106.933153],
  ['Wyoming', 'WY', '56', '009', 'Converse', 42.972839, -105.507367],
  ['Wyoming', 'WY', '56', '011', 'Crook', 44.589266, -104.567298],
  ['Wyoming', 'WY', '56', '013', 'Fremont', 43.055303, -108.605531],
  ['Wyoming', 'WY', '56', '015', 'Goshen', 42.08958, -104.353482],
  ['Wyoming', 'WY', '56', '017', 'Hot Springs', 43.720871, -108.435652],
  ['Wyoming', 'WY', '56', '019', 'Johnson', 44.044048, -106.588541],
  ['Wyoming', 'WY', '56', '021', 'Laramie', 41.29283, -104.660395],
  ['Wyoming', 'WY', '56', '023', 'Lincoln', 42.228788, -110.679842],
  ['Wyoming', 'WY', '56', '025', 'Natrona', 42.973641, -106.764877],
  ['Wyoming', 'WY', '56', '027', 'Niobrara', 43.062159, -104.468373],
  ['Wyoming', 'WY', '56', '029', 'Park', 44.492387, -109.593598],
  ['Wyoming', 'WY', '56', '031', 'Platte', 42.130319, -104.960809],
  ['Wyoming', 'WY', '56', '033', 'Sheridan', 44.781369, -106.881211],
  ['Wyoming', 'WY', '56', '035', 'Sublette', 42.767928, -109.91617],
  ['Wyoming', 'WY', '56', '037', 'Sweetwater', 41.660339, -108.875676],
  ['Wyoming', 'WY', '56', '039', 'Teton', 44.049321, -110.588102],
  ['Wyoming', 'WY', '56', '041', 'Uinta', 41.284726, -110.558947],
  ['Wyoming', 'WY', '56', '043', 'Washakie', 43.878831, -107.669052],
  ['Wyoming', 'WY', '56', '045', 'Weston', 43.846213, -104.57002],
];
