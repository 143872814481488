import React, {FC} from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components/macro';

const SheetItemWrapper = styled.div`
  flex: 0 0 52px;
  padding: 16px;
  width: 252px;
  flex-direction: row;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  color: #000000;

  background: #ffffff;
  text-overflow: ellipsis;

  svg {
    stroke: grey;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  &.active,
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.Colors.Oranges._000};
    color: #ffffff;

    svg {
      stroke: white;
    }
  }
`;

const SheetWrapper = styled.div`
  padding: 12px;
  width: 276px;
  flex: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
`;

export interface SheetItemProps {
  label: string;
  icon?: any;
  active?: boolean;
  onClick?: () => void;

  // We allow custom fields so developers can set IDs or other props to help them process user input
  [key: string]: any;
}

export const SheetItem: FC<SheetItemProps> = ({label, icon, active, onClick, style}) => {
  return (
    <SheetItemWrapper
      onClick={onClick}
      className={active ? 'active' : ''}
      style={style}
      data-tip={label.length > 30 ? label : null} // Only show tooltip if the label is too long
      data-for="sheet-item-tooltip"
      data-delay-show="600">
      {icon ? icon : <></>}
      {label}
    </SheetItemWrapper>
  );
};

export interface SheetProps {
  items: SheetItemProps[];
  onClick: (item: SheetItemProps, index: number) => void;
  itemStyle?: React.CSSProperties;
}

export const Sheet: FC<SheetProps> = ({items, onClick, itemStyle, ...props}) => (
  <SheetWrapper {...props}>
    {items.map((item, index) => (
      <SheetItem {...item} style={itemStyle} onClick={() => onClick(item, index)} key={index} />
    ))}
    <ReactTooltip
      id="sheet-item-tooltip"
      place="right"
      type="dark"
      effect="solid"
      offset={{top: 2, left: -5}}
      wrapper="span"
      className="info-tooltip"
    />
  </SheetWrapper>
);
