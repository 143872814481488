import React, {useState} from 'react';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import {DropdownWithSuggestions} from './DropdownWithSuggestions';

interface SearchProps {
  onChange?: (query: any) => void;
  onSelectAddress: (address: {lat: number; lng: number; entry: any; results?: any}) => void;
  placeholder?: string;
  value?: string;
  name?: string;
  onBlur?: (e: any) => void;
  disabled?: boolean;
}

//const service = new google.maps.places.PlacesService(map);
const service = new window.google.maps.places.AutocompleteService();

export const AddressSearch: React.FunctionComponent<SearchProps> = ({
  onChange,
  onSelectAddress,
  placeholder,
  value,
  name,
  onBlur,
  disabled,
}) => {
  const [suggestions, setSuggestions] = useState([]);

  const handleSearchChange = async (value: any) => {
    onChange && onChange(value);
    await service.getPlacePredictions(
      {input: value, types: ['geocode'], componentRestrictions: {country: ['us', 'pr', 'vi', 'gu', 'mp']}},
      (results: any) => {
        setSuggestions(results);
      },
    );
  };

  const options = suggestions?.map((suggestion: any) => ({
    label: suggestion.description,
    value: suggestion,
  }));

  const selectAddress = async (entry: any) => {
    console.log({value: entry});
    try {
      const results = await geocodeByAddress(entry.description);
      const {lat, lng} = await getLatLng(results[0]);

      console.log('Geocoded address', {lat, lng, entry});
      onSelectAddress({lat, lng, entry, results});
    } catch (e) {
      console.log(`Unable to geocode`, e, entry);
    }

    setSuggestions([]);
  };

  return (
    <DropdownWithSuggestions
      placeholder={placeholder || 'Search Places...'}
      onSearchChange={handleSearchChange}
      value={value || ''}
      onSelect={selectAddress}
      options={options}
      name={name}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};
