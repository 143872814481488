import {useMutation} from 'react-query';
import {resendEmailVerification} from '../Calls/Onboarding';

export const useResendEmailVerification = () => {
  return useMutation(resendEmailVerification, {
    onSuccess: (params) => {
      console.log('Resending verification email', params);
    },

    onError: (err: Error) => {
      console.log('Error resending verification email', err.message);
    },
  });
};
