import {FC} from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components/macro';
import {StandardButton} from '.';
import {FlexRow} from '..';
import {H4, Theme} from '../Atoms';

ReactModal.setAppElement('body');
export interface IModal {
  hideButtons?: boolean;
  button1Action?: () => void;
  button1Disabled?: boolean;
  button1Outline?: boolean;
  button1Text?: string;
  button2Action?: () => void;
  button2Disabled?: boolean;
  button2Outline?: boolean;
  button2Text?: string;
  isOpen: boolean;
  title?: string;
  hideButton1?: boolean;
  width?: number;
}

const ModalContainer = styled.div`
  background-color: white;
  align-self: center;
  padding: 28px;
  box-shadow: 0px 10px 15px -3px rgba(106, 111, 123, 0.1), 0px 4px 6px -2px rgba(106, 111, 123, 0.05);
  border: none;
  border-radius: 16px;
  height: fit-content;
  margin: auto;
  flex: 0;
`;

const ModalTitle = styled(H4)`
  text-align: center;
  // color: ${(props) => props.theme.Colors.Oranges._000};
  margin-bottom: 28px;
`;

const overlayStyle = {
  backgroundColor: Theme.Colors.Transparents._000,
  zIndex: 200,
};

const contentStyle = {
  background: 'transparent',
  border: 'none',
  display: 'flex',
  inset: 0,
  alignSelf: 'center',
  alignItems: 'center',
  margin: 'auto',
};

const defaultFunction = () => {};

export const Modal: FC<IModal> = ({
  hideButtons = false,
  button1Action = defaultFunction,
  button1Disabled = false,
  button1Outline = false,
  button1Text = 'OK',
  button2Action = defaultFunction,
  button2Disabled = false,
  button2Outline = true,
  button2Text,
  children,
  isOpen,
  width = 600,
  title = '',
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={button1Action}
      shouldCloseOnOverlayClick
      style={{
        overlay: overlayStyle,
        content: contentStyle,
      }}>
      <ModalContainer style={{width}}>
        {title && <ModalTitle>{title}</ModalTitle>}
        {children}
        {!hideButtons && (
          <FlexRow style={{marginTop: 8}}>
            <StandardButton onClick={button1Action} style={{flex: 1}} outline={button1Outline} disabled={button1Disabled}>
              {button1Text || 'OK'}
            </StandardButton>
            {button2Text && (
              <StandardButton onClick={button2Action} style={{flex: 1, marginLeft: 30}} outline={button2Outline} disabled={button2Disabled}>
                {button2Text}
              </StandardButton>
            )}
          </FlexRow>
        )}
      </ModalContainer>
    </ReactModal>
  );
};
