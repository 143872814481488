import {useMutation, useQueryClient} from 'react-query';
import {updateUser} from '../Calls/Admin';

export const useUpdateUser = () => {
  const qc = useQueryClient();

  return useMutation(updateUser, {
    onSuccess: (result) => {
      // const data = qc.getQueryData(['ADMIN', 'USERS']) as IUser[];
      // if (data) {
      //   const updatedRecord = data.find((user) => user.id === result.id);
      //   if (updatedRecord) {
      //     Object.assign(updatedRecord, result);
      //     qc.setQueryData(['ADMIN', 'USERS'], data);
      //   }
      // }

      qc.invalidateQueries(['ADMIN', 'USERS']);
    },

    onError: (err: Error) => {
      console.log('Error updating user', err.message);
    },
  });
};
