// @ts-ignore
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {createSearchParams, useSearchParams} from 'react-router-dom';
import {IBuildingProperties, useAdminProducts} from '../../API';
import {useMyProfile} from '../../API/Queries/useMyProfile';
import {ITractProperties} from '../../Managers/Datasets/Types';
import {setHoveredPin, setHoveredTract} from '../../Redux/Slices/Map';
import {useAppDispatch, useAppSelector} from '../../store';
import {Overlays} from './Overlays';
import {useRoles} from '../../Hooks/useRoles';

// mapboxgl.accessToken = 'pk.eyJ1IjoiZ3JlZW5saW5rIiwiYSI6ImNrOGllb3Q4cjA1YjQzZm0xdW14MTYxaDIifQ.z5Xpov5KzrXFDB4REayaUA';
mapboxgl.accessToken = 'pk.eyJ1IjoiY2xhcmt0dGVjaCIsImEiOiJjbDQ0cGMwYzkwM3FqM29uamIzaGlybWduIn0.5OxK78QHrXhlnys48Hx-bw';
mapboxgl.prewarm();

// NOTE: This is a really bad pattern and definitely not recommended. We wrestled with it for awhile before using it. But Mapbox was really
// not designed to be run from within React, let alone function components, and even though there are techniques to track refs for it, it's
// still written to be a global JS object and matching DOM element tree that it manages on its own. It's actually a little cleaner if we
// wrap it in a class component instead of a FC, because those are persistent. But that ship has sailed and we don't have the budget to
// rejigger this whole thing so we moved this out to a global singleton to at least address the issues we were having.
export let mapComponent: mapboxgl.Map | null = null;

// See https://docs.mapbox.com/help/tutorials/use-mapbox-gl-js-with-react/
export const MapBox: FC = () => {
  const mapContainer = useRef(null);
  const map = useRef<any>(null);
  const [lng, setLng] = useState(-95.7129);
  const [lat, setLat] = useState(37.0902);
  const [zoom, setZoom] = useState(5);
  const [authorizationId] = useState(null);
  const [styleLoaded, setStyleLoaded] = useState(false);
  const [layersLoaded, setLayersLoaded] = useState(false);
  const {isAdmin} = useRoles();

  const products = useAdminProducts(isAdmin);
  const dispatch = useAppDispatch();
  const {data: myProfile, isFetching: isFetchingProfile} = useMyProfile();
  const showCities = useAppSelector((state) => state.map.showCities);
  const showRoads = useAppSelector((state) => state.map.showRoads);
  const showPOI = useAppSelector((state) => state.map.showPOI);
  const showBuildingPins = useAppSelector((state) => state.map.showBuildingPins);

  const {hoveredPin, hoveredTract} = useAppSelector((state) => state.map);
  const organization = myProfile?.user?.organization;

  // TODO: Replace this with the actual year from the slice
  const year = 2019;

  const [searchParams, setSearchParams] = useSearchParams();

  const initialLat = organization?.initialLat;
  const initialLon = organization?.initialLon;
  const initialZoom = organization?.initialZoom;

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/clarkttech/clkalo2ok00ev01r1e1ew5ddz',
      center: [lng, lat],
      zoom: zoom,
      preserveDrawingBuffer: true,
      attributionControl: false,
      dragRotate: false,
      boxZoom: false,
      maxZoom: 17,
      minZoom: 3,
      // collectResourceTiming: true,
      keyboard: false,
      pitchWithRotate: false,
      touchPitch: false,
      //limit bounds to US
      maxBounds: [
        [-200, 0], // Southwest coordinates
        [-10, 80], // Northeast coordinates
      ],
    });

    map.current.on('style.load', () => setStyleLoaded(true));

    mapComponent = map.current;
  });

  useEffect(() => {
    // Fly to the initial location if we have it, only once
    const ln = isNaN(Number(searchParams.get('ln'))) ? initialLon : searchParams.get('ln');
    const lt = isNaN(Number(searchParams.get('lt'))) ? initialLat : searchParams.get('lt');
    const zm = isNaN(Number(searchParams.get('zm'))) ? initialZoom : searchParams.get('zm');

    if (((initialLat && initialLon && initialZoom) || (lt && ln && zm)) && !products?.isLoading) {
      map.current?.flyTo({center: [ln || initialLon, lt || initialLat], zoom: zm || initialZoom});
    }
    handlePositionChange();
  }, [initialLat, initialLon, initialZoom, products?.isLoading, isFetchingProfile]);

  const handlePositionChange = () => {
    setLng(map.current?.getCenter().lng.toFixed(4));
    setLat(map.current?.getCenter().lat.toFixed(4));
    setZoom(map.current?.getZoom().toFixed(2));
  };

  useEffect(() => {
    if (!map.current || !styleLoaded || products?.isLoading) return;
    const newParams = createSearchParams(searchParams);
    newParams.set('lt', String(lat));
    newParams.set('ln', String(lng));
    newParams.set('zm', String(zoom));
    setSearchParams(newParams);
  }, [lat, lng, zoom]);

  useEffect(() => {
    if (!map.current || !styleLoaded || products?.isLoading) return;
    if (!map.current || !styleLoaded || !layersLoaded) return;

    const layers = map.current.getStyle().layers;

    const poiLayers = layers.filter((layer: any) => layer.id.includes('poi'));
    poiLayers.forEach((layer: any) => {
      mapComponent?.setLayoutProperty(layer.id, 'visibility', showPOI ? 'visible' : 'none');
    });

    const roadLayers = layers.filter((layer: any) => layer.id.includes('road'));
    roadLayers.forEach((layer: any) => {
      mapComponent?.setLayoutProperty(layer.id, 'visibility', showRoads ? 'visible' : 'none');
    });

    if (map.current.getLayer('building-pins')) {
      mapComponent?.setPaintProperty('building-pins', 'icon-opacity', showBuildingPins ? 1 : 0);
    }

    if (map.current.getLayer('city-border')) {
      mapComponent?.setPaintProperty('city-border', 'line-opacity', showCities ? 1 : 0);
    }
  }, [showPOI, showRoads, styleLoaded, layersLoaded, showBuildingPins, showCities]);

  useEffect(() => {
    if (!map.current || !styleLoaded || products?.isLoading) return;

    // Find the index of the first symbol layer in the map style.
    const layers = map.current.getStyle().layers;
    const firstLineLayer = layers.find((layer: {type: string}) => layer.type === 'line');
    const firstSymbolLayer = layers.find((layer: {type: string}) => layer.type === 'symbol');
    const firstLineId = firstLineLayer?.id;
    const firstSymbolId = firstSymbolLayer?.id;

    // Add an image to use as a custom marker
    map.current.loadImage('https://voltaic-phalanx-276320.web.app/basic-pin.png', (error: any, image: any) => {
      if (error) {
        console.log('[MAPBOX] Error loading custom pin', error);
      } else {
        map.current.addImage('basic-pin', image);
      }
    });

    map.current.addSource('tracts', {
      type: 'geojson',
      data: {type: 'FeatureCollection', features: []},
    });

    map.current.addSource('city', {type: 'geojson', data: {type: 'FeatureCollection', features: []}});

    map.current.addSource('building-pins', {
      type: 'geojson',
      data: 'https://us-central1-voltaic-phalanx-276320.cloudfunctions.net/getBuildingPins?centerLat=39&centerLon=-104',
    });

    map.current.addLayer(
      {
        id: 'tract-fills',
        type: 'fill',
        source: 'tracts',
        layout: {},
        paint: {
          'fill-color': 'rgb(0,0,0)',
          'fill-opacity': 0.01,
        },
      },
      firstLineId,
    );

    map.current.addLayer(
      {
        id: 'tract-borders',
        type: 'line',
        source: 'tracts',
        layout: {},
        paint: {
          'line-color': '#ffffff',
          'line-width': 2,
        },
      },
      firstLineId,
    );

    map.current.addLayer(
      {
        id: 'tract-hovers',
        type: 'line',
        source: 'tracts',
        layout: {},
        paint: {
          'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.8, 0],
          'line-blur': 6,
          'line-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#000000', '#ffffff'],
          'line-width': 4,
          'line-offset': 3,
        },
      },
      firstSymbolId,
    );

    map.current.addLayer({
      id: 'building-pins',
      type: 'symbol',
      source: 'building-pins',
      layout: {
        'icon-image': 'basic-pin',
        // Mapbox doesn't support changing this on hover so we tried to make something similar with halo.
        'icon-size': 1,
      },
      paint: {
        'icon-opacity': 0,
        // 'icon-translate': ['case', ['boolean', ['feature-state', 'hover'], false], [-2, -2], [0, 0]],
      },
    });

    map.current.addLayer(
      {
        id: 'city-border',
        type: 'line',
        source: 'city',
        layout: {},
        paint: {
          'line-color': '#000000',
          // 'line-color': '#4188be',
          'line-width': 2,
          'line-opacity': 0,
        },
      },
      firstSymbolId,
    );

    map.current.on('moveend', () => {
      handlePositionChange();
    });

    // We inhibited the overlays from trying to display until now because that avoids them having to try to be
    // overly clever about things like not setting the colors for the tracts layer before it's on the map.
    setLayersLoaded(true);
  }, [products?.isLoading, styleLoaded]);

  // A little helper to clean up the logic in the mmouse handlers
  const setTractHover = useCallback(
    (properties: ITractProperties | null) => {
      // The source values are strings but the IDs in Mapbox are ints so we need '12138' -> 12138
      const oldHoverId = +(hoveredTract?.GEOID || 0);
      const newHoverId = +(properties?.GEOID || 0);

      if (newHoverId) {
        // If the hover is changing and there is an old one, turn that off first
        if (oldHoverId && newHoverId !== oldHoverId) {
          map.current?.setFeatureState({source: 'tracts', id: oldHoverId}, {hover: false});
        }

        // Change the hover if needed
        if (newHoverId !== oldHoverId) {
          map.current?.setFeatureState({source: 'tracts', id: newHoverId}, {hover: true});
          dispatch(setHoveredTract(properties));
        }
      } else {
        // Turn off the hover, if there is one
        if (oldHoverId) {
          map.current?.setFeatureState({source: 'tracts', id: oldHoverId}, {hover: false});
          dispatch(setHoveredTract(null));
        }
      }
    },
    [hoveredTract, dispatch],
  );

  const setPinHover = useCallback(
    (properties: IBuildingProperties | null) => {
      // The source values are strings but the IDs in Mapbox are ints so we need '12138' -> 12138
      const oldHoverId = +(hoveredPin?.id || 0);
      const newHoverId = +(properties?.id || 0);

      if (newHoverId) {
        // If the hover is changing and there is an old one, turn that off first
        if (oldHoverId && newHoverId !== oldHoverId) {
          map.current?.setFeatureState({source: 'building-pins', id: oldHoverId}, {hover: false});
        }

        // Change the hover if needed
        if (newHoverId !== oldHoverId) {
          map.current?.setFeatureState({source: 'building-pins', id: newHoverId}, {hover: true});
          dispatch(setHoveredPin(properties));
        }
      } else {
        // Turn off the hover, if there is one
        if (oldHoverId) {
          map.current?.setFeatureState({source: 'building-pins', id: oldHoverId}, {hover: false});
          dispatch(setHoveredPin(null));
        }
      }
    },
    [hoveredPin, dispatch],
  );

  useEffect(() => {
    if (!map?.current) return;

    const moveListener = (e: any) => {
      // The result is an array even if we query only one layer. We only care about the first match.
      const [feature] = e.target.queryRenderedFeatures(e.point, {layers: ['tract-fills']});
      setTractHover(feature?.properties || null);

      // The result is an array even if we query only one layer. We only care about the first match.
      const [pin] = map?.current?.getLayer('cluster-count') ? e.target.queryRenderedFeatures(e.point, {layers: ['building-pins']}) : [];
      setPinHover(pin?.properties || null);
    };

    const outListener = () => {
      setTractHover(null);
      setPinHover(null);
    };

    map.current.on('mousemove', moveListener);

    // When the mouse leaves the state-fill layer, update the feature state of the previously hovered feature.
    map.current.on('mouseout', outListener);

    return () => {
      map.current.off('mousemove', moveListener);
      map.current.off('mouseout', outListener);
    };
  }, [setTractHover, setPinHover]);

  return (
    <div>
      <div ref={mapContainer} className="map-container" style={{height: '100%', width: '100%'}} />
      {layersLoaded ? <Overlays authorizationId={authorizationId} lat={lat} lng={lng} year={year} zoom={zoom} /> : <></>}
    </div>
  );
};
