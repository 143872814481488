// Create a custom hook to manage the organization and its data

import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '../store';
import {
  setOrganization,
  addAuthorization,
  deleteAuthorization,
  addUser,
  deleteUser,
  resetCart as resetCartState,
  setAuthorizations,
  setUsers,
} from '../Redux/Slices/Cart';
import {IAuthorization, IOrganization, IUser} from '../API/Types';
import {useAuth0} from '@auth0/auth0-react';

// Define a custom hook
export const useCartOrganization = (organizationId?: string) => {
  const dispatch = useDispatch();
  const {user, isLoading} = useAuth0();

  const userId = organizationId || user?.email;

  const organizations = useSelector((state: RootState) => state.cart.organizations);

  const updateCartOrganization = (organization: IOrganization) => {
    if (!userId) {
      return;
    }

    dispatch(setOrganization({userId, organization}));
  };

  const addCartAuthorization = (authorization: IAuthorization) => {
    if (!userId) {
      return;
    }
    dispatch(addAuthorization({userId, authorization}));
  };

  const updateCartAuthorizations = (authorizations: IAuthorization[]) => {
    if (!userId) {
      return;
    }
    dispatch(setAuthorizations({userId, authorizations}));
  };

  const deleteCartAuthorization = (authorizationId: number) => {
    if (!userId) {
      return;
    }
    dispatch(deleteAuthorization({userId, authorizationId}));
  };

  const addCartUser = (user: IUser) => {
    if (!userId) {
      return;
    }
    dispatch(addUser({userId, user}));
  };

  const updateCartUsers = (users: IUser[]) => {
    if (!userId) {
      return;
    }
    dispatch(setUsers({userId, users}));
  };

  const deleteCartUser = (userIdToDelete: string) => {
    if (!userId) {
      return;
    }
    dispatch(deleteUser({userId, userIdToDelete}));
  };

  const resetCart = () => {
    if (!userId) {
      return;
    }
    dispatch(resetCartState());
  };

  return {
    organization: userId ? organizations?.[userId] : (undefined as IOrganization | undefined),
    updateCartOrganization: updateCartOrganization,
    addCartAuthorization,
    updateCartAuthorizations,
    deleteCartAuthorization,
    addCartUser,
    updateCartUsers,
    deleteCartUser,
    resetCart,
    isLoading,
  };
};
