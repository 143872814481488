import {FC} from 'react';
import BackgroundSlider from 'react-background-slider';
import styled from 'styled-components/macro';
import BG1 from './img/max-bottinger-8VL7meb_k7U-unsplash.jpg';
import BG2 from './img/ahmet-sali-k4E5lUXAtyk-unsplash.jpg';
import BG3 from './img/ameer-basheer-euyV2Ae_KX0-unsplash.jpg';
import BG4 from './img/andrea-leopardi-5qhwt_Lula4-unsplash.jpg';
import BG5 from './img/tom-rumble-7lvzopTxjOU-unsplash.jpg';

interface BackgroundSlideshowProps {
  duration?: number;
  transition?: number;
}

const Container = styled.div`
  flex: 0;
`;

export const BackgroundSlideshow: FC<BackgroundSlideshowProps> = ({duration = 60, transition = 1}) => (
  <Container>
    <BackgroundSlider images={[BG1, BG2, BG3, BG4, BG5]} duration={duration} transition={transition} />
  </Container>
);
